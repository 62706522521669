import { t } from "@lingui/macro";
import * as React from "react";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

import logo from "../../images/logos/elinoi-logo-label-aqua.svg";
import styles from "./index.module.scss";

export const LoadingPage: React.FC = () => (
  <>
    <Helmet>
      <title>{t`LoadingScene.title`}</title>
      <meta name="" content="" />
    </Helmet>
    <div className={styles.loadingPageWrapper}>
      <div className={styles.pageHeader}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Élinoï" />
        </div>
      </div>
      <div className={styles.loaderContainer}>
        <Spinner animation="grow" />
      </div>
    </div>
  </>
);
