import client from "../client";

export function login(code: string, redirectUri: string) {
  return client.post("/api/v2/userspace/auth/login", { code, redirectUri }, { withCredentials: true });
}

export function refresh(refreshToken: string) {
  return client.post(
    "/api/v2/userspace/auth/refresh",
    {
      refresh_token: refreshToken,
    },
    { withCredentials: true },
  );
}

export function logout() {
  return client.post("/api/v2/userspace/auth/logout");
}
