import { t } from "@lingui/macro";

interface IConst {
  label: { [key: string]: string };
  content: { [key: string]: string };
  action: { [key: string]: string };
}

const transGauge: IConst = {
  action: {
    advanced: t`gauge.action.advanced`,
    beginner: t`gauge.action.beginner`,
    expert: t`gauge.action.expert`,
    intermediate: t`gauge.action.intermediate`,
  },
  content: {
    advanced: t`gauge.content.advanced`,
    beginner: t`gauge.content.beginner`,
    expert: t`gauge.content.expert`,
    intermediate: t`gauge.content.intermediate`,
  },
  label: {
    advanced: t`gauge.label.advanced`,
    beginner: t`gauge.label.beginner`,
    expert: t`gauge.label.expert`,
    intermediate: t`gauge.label.intermediate`,
  },
};

export default transGauge;
