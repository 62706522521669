import { t } from "@lingui/macro";
import * as React from "react";
import { NewNotificationsHelmet } from "../../../blocks/newNotificationsHelmet";

import { ReferralPage } from "../../../pages/ReferralPage";

const SponsorScene = (): JSX.Element => (
  <>
    <NewNotificationsHelmet title={t`SponsorScene.title`} />
    <ReferralPage />
  </>
);

export default SponsorScene;
