import { AxiosResponse } from "axios";
import { extractIdFromIri } from "../../../components/Utils/helpers";
import { Company } from "../../../interfaces/resources/company";
import { Talent } from "../../../interfaces/resources/talent";
import client from "../client";

export function getOffer(slug: string): Promise<AxiosResponse<any>> {
  return client.get(`/api/v2/offers?slug=${slug}&groups[]=userspace:read:offer:item`);
}

export function getOffers(currentTalent: Talent): Promise<AxiosResponse<any>> {
  return client.get(`${currentTalent["@id"]}/offers`);
}
export function getOffersFromCompany(company: Company): Promise<AxiosResponse<any>> {
  return client.get(`/api/v2/offers?company=${extractIdFromIri(company["@id"])}`);
}
export function getLastAcceptedApplications(id: string): Promise<AxiosResponse<any>> {
  return client.get(`${id}/applications?status=accepted&order[updatedAt]=DESC`);
}
