import {
  CreateLinkedinResumeItem,
  CreateManualLinkedin,
  DeleteLinkedinItem,
  EditLinkedinItem,
  GetLinkedinResume,
} from "../../../actionTypes/linkedinResume";

export function editLinkedinItemAction(item: any) {
  return {
    item,
    type: EditLinkedinItem.REQUEST,
  };
}

export function getLinkedinResumeAction(talentIri: string) {
  return {
    talent: talentIri,
    type: GetLinkedinResume.REQUEST,
  };
}

export function deleteLinkedinItemAction(item: any) {
  return {
    item,
    type: DeleteLinkedinItem.REQUEST,
  };
}

export function createLinkedinItemAction(item: any, element: string) {
  return {
    item,
    element,
    type: CreateLinkedinResumeItem.REQUEST,
  };
}

export function createManualLinkedinAction(currentTalentIri: string) {
  return {
    currentTalentIri,
    type: CreateManualLinkedin.REQUEST,
  };
}
