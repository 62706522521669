import { t } from "@lingui/macro";
import cx from "classnames";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { EditCandidateProfileModalCta } from "../../components/EditCandidateProfileModal";
import { Loader } from "../../components/Loader";
import { State } from "../../interfaces/api/state";
import { desiredPlacesRemote } from "../../interfaces/resources/desiredPlace";
import { ResponsibilityWithSeniority, TalentProfileResponsibility } from "../../interfaces/resources/responsibility";
import globalStyle from "../../styles/global.module.scss";
import { transTalent } from "../../translations/constants/talent";

import styles from "./index.module.scss";

interface ReducedResponsibility {
  responsibilityType: string;
  responsibilities: ResponsibilityWithSeniority[];
}

export const CandidateProfileCard: React.FC = () => {
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const talentProfileState = useSelector((state: State) => state.talentProfile);
  const talentProfile = !!talentProfileState
    ? talentProfileState
    : !!currentTalent?.talentProfile
      ? currentTalent.talentProfile
      : undefined;
  const talentResponsibilities = talentProfile?.responsibilities;
  const desiredPlaces = talentProfile?.desiredPlaces?.filter((e) => !desiredPlacesRemote.includes(e.value));
  const remotePlaces = talentProfile?.desiredPlaces?.filter((e) => desiredPlacesRemote.includes(e.value));
  const talentDesiredPlaces = desiredPlaces?.map((dsrP) => dsrP.value).join(", ");
  const talentRemotePlaces = remotePlaces?.map((dsrP) => dsrP.value).join(", ");
  const getReducedResponsibilities: (responsibilities: TalentProfileResponsibility[]) => ReducedResponsibility[] = (
    responsibilities,
  ): ReducedResponsibility[] =>
    responsibilities.reduce((result: ReducedResponsibility[], elem: TalentProfileResponsibility) => {
      const index = result.findIndex(
        (e: ReducedResponsibility) => e.responsibilityType === elem.responsibility.responsibilityType.value,
      );
      const elemResp: ResponsibilityWithSeniority = {
        ...elem.responsibility,
        seniority: elem.seniority,
      };
      if (index === -1) {
        result.push({
          responsibilities: [elemResp],
          responsibilityType: elem.responsibility.responsibilityType.value,
        });
      } else {
        result[index].responsibilities.push(elemResp);
      }
      return result;
    }, []);

  return (
    <div className={styles.card}>
      {!currentTalent ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div>
          <div className={styles.cardHeader}>
            <h2 className={cx(globalStyle.mainTitle, styles.title)}>
              <ReactMarkdown
                disallowedTypes={["paragraph"]}
                unwrapDisallowed
              >{t`CandidateProfileCard.title`}</ReactMarkdown>
            </h2>
            <div>
              <EditCandidateProfileModalCta />
            </div>
          </div>
          <div>
            <ul className={styles.list}>
              {!!talentProfile?.currentJob || !!talentProfile?.currentCompany ? (
                <li className={styles.currentJobCompanyItem}>
                  <ReactMarkdown disallowedTypes={["paragraph"]} unwrapDisallowed>
                    {t({
                      id: "CandidateProfileCard.currentPost.text",
                      values: {
                        job: talentProfile?.currentJob,
                        company: talentProfile?.currentCompany,
                      },
                    })}
                  </ReactMarkdown>
                </li>
              ) : null}
              {currentTalent.searchStatus ? (
                <li>
                  <strong>{t`CandidateProfileCard.searchStatus.label`}</strong> :{" "}
                  {t({ id: transTalent.searchStatuses[currentTalent.searchStatus] })}
                </li>
              ) : null}
              <li>
                <strong>{t`CandidateProfileCard.experienceYears.label`}</strong> :{" "}
                {talentProfile?.experience ? `${talentProfile.experience} ans` : "Mise à jour requise"}
              </li>
              <li>
                <strong>{t`CandidateProfileCard.salaryExpectations.label`}</strong> :{" "}
                {talentProfile?.expectedRemuneration?.fixedSalary && talentProfile?.expectedRemuneration?.fullPackage
                  ? `${talentProfile.expectedRemuneration.fixedSalary}k fixe + ${
                      talentProfile.expectedRemuneration.fullPackage - talentProfile.expectedRemuneration.fixedSalary
                    }k variable`
                  : "Mise à jour requise"}
              </li>
              <li>
                <strong>{t`CandidateProfileCard.desiredPlaces.label`}</strong> : {talentDesiredPlaces}
              </li>
              <li>
                <strong>{t`CandidateProfileCard.remotePlaces.label`}</strong> : {talentRemotePlaces}
              </li>
              <li className={styles.respItem}>
                <strong>{t`CandidateProfileCard.responsibilites.label`}</strong> :
                <div className={styles.responsibilitiesContainer}>
                  {talentResponsibilities && talentResponsibilities.length ? (
                    <>
                      {getReducedResponsibilities(talentResponsibilities).map(
                        (reducedResp: ReducedResponsibility, i: number) => {
                          const { responsibilityType, responsibilities } = reducedResp;
                          return (
                            <div key={`${responsibilityType}-${i}`} className={styles.respTypeContainer}>
                              <div className={styles.respFamName}>{responsibilityType}</div>
                              <ul className={styles.respList}>
                                {responsibilities.map((resp, j) => (
                                  <li key={`${responsibilityType}-${resp["@id"]}-${j}`}>
                                    {resp.value} {resp.seniority ? `(${resp.seniority})` : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          );
                        },
                      )}
                    </>
                  ) : (
                    <div className={styles.emptyResponsibilities}>{t`TalentResponsibilities.empty`}</div>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
