import { t } from "@lingui/macro";
import anime from "animejs";
import cx from "classnames";
import React, { FC, SVGProps, useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

import { ReactComponent as AdvancedIcon } from "../../images/advanced.svg";
import { ReactComponent as BeginnerIcon } from "../../images/beginner.svg";
import { ReactComponent as CheckIcon } from "../../images/check-solid.svg";
import { ReactComponent as ExpertIcon } from "../../images/expert.svg";
import { ReactComponent as FavouriteIcon } from "../../images/favorites.svg";
import { ReactComponent as IntermediateIcon } from "../../images/intermediate.svg";
import { ReactComponent as TrophyIcon } from "../../images/trophy.svg";
import { routes } from "../../routes";

import global from "../../styles/global.module.scss";
import transGauge from "../../translations/constants/gauge";
import styles from "./index.module.scss";
interface Props {
  strength: number;
}
export interface ItemProps {
  label: string;
  content: string;
  actionLink: {
    type: string;
    url: string;
  };
  actionLabel: string;
  ContentIcon: FC<SVGProps<SVGSVGElement>>;
  strength: number;
  Icon: FC<SVGProps<SVGSVGElement>>;
}

const items: ItemProps[] = [
  {
    ContentIcon: BeginnerIcon,
    Icon: FavouriteIcon,
    actionLabel: t({ id: transGauge.action.beginner }),
    actionLink: {
      type: "internal",
      url: routes.app.offers,
    },
    content: t({ id: transGauge.content.beginner }),
    label: t({ id: transGauge.label.beginner }),
    strength: 1,
  },
  {
    ContentIcon: IntermediateIcon,
    Icon: FavouriteIcon,
    actionLabel: transGauge.action.intermediate,
    actionLink: {
      type: "external",
      url: "https://blog.elinoi.com/",
    },
    content: transGauge.content.intermediate,
    label: transGauge.label.intermediate,
    strength: 2,
  },
  {
    ContentIcon: AdvancedIcon,
    Icon: FavouriteIcon,
    actionLabel: transGauge.action.advanced,
    actionLink: {
      type: "internal",
      url: routes.app.sponsor,
    },
    content: transGauge.content.advanced,
    label: transGauge.label.advanced,
    strength: 3,
  },
  {
    ContentIcon: ExpertIcon,
    Icon: TrophyIcon,
    actionLabel: transGauge.action.expert,
    actionLink: {
      type: "internal",
      url: "",
    },
    content: transGauge.content.expert,
    label: transGauge.label.expert,
    strength: 4,
  },
];

export const StrengthGauge: React.FC<Props> = (props: Props) => {
  const { strength } = props;
  const $bar = useRef(null);
  const [activeStep, setActiveStep] = useState<number | undefined>(undefined);
  const str = -100 + 25 * strength;
  const onMouseEnterStepIcon = (step: ItemProps): void => {
    setActiveStep(step.strength <= strength + 1 ? step.strength : undefined);
  };
  const onMouseLeaveStepIcon = (): void => {
    setActiveStep(undefined);
  };
  useEffect(() => {
    anime({
      easing: "linear",
      targets: $bar.current,
      translateX: ["-96%", `${str}%`],
      delay: 2000,
      duration: 1000,
    });
  }, [str]);
  return (
    <>
      <div className={styles.barWrapper}>
        <div className={styles.barContainer}>
          <div className={styles.bar} ref={$bar}></div>
        </div>
        <div className={styles.stepsIconContainer}>
          {items.map((e, i) => (
            <div
              className={cx(styles.iconContainer, { [styles.active]: strength >= e.strength })}
              style={{ left: `${25 * e.strength}%` }}
              key={`step-strengh-${i}`}
              data-testid={`step-strengh-${e.strength}`}
              onMouseEnter={(): void => onMouseEnterStepIcon(e)}
              onMouseLeave={onMouseLeaveStepIcon}
            >
              {strength === e.strength ? <CheckIcon /> : <e.Icon />}
            </div>
          ))}
        </div>

        <div className={styles.stepsTooltipContainer}>
          {items.map((e, i) => (
            <div
              className={cx(styles.tooltip, { [styles.active]: activeStep === e.strength })}
              style={{ left: `${25 * e.strength}%` }}
              key={`stepTooltip-${i}`}
              data-testid={`stepTooltip-${e.strength}`}
              onMouseEnter={(): void => onMouseEnterStepIcon(e)}
              onMouseLeave={onMouseLeaveStepIcon}
            >
              <h5 className={styles.tooltipTitle}>
                <Markdown source={e.label} escapeHtml={false} disallowedTypes={["paragraph"]} unwrapDisallowed />
              </h5>
              <p className={styles.tooltipContent}>
                <Markdown source={e.content} escapeHtml={false} disallowedTypes={["paragraph"]} unwrapDisallowed />
              </p>
              <div className={styles.tooltipCtaContainer}>
                {e.actionLink.type === "internal" ? (
                  <Link className={cx(styles.tooltipCta, global.cta, global.primaryCta)} to={e.actionLink.url}>
                    {e.actionLabel}
                  </Link>
                ) : (
                  <a
                    className={cx(styles.tooltipCta, global.cta, global.primaryCta)}
                    href={e.actionLink.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {e.actionLabel}
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
