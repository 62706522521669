import { AxiosPromise } from "axios";
import { DesiredPlace } from "../../../interfaces/resources/desiredPlace";
import { ResponsibilityWithSeniority, TalentProfileResponsibility } from "../../../interfaces/resources/responsibility";
import { Talent, TalentProfile, TalentSetting, EditTalentProps } from "../../../interfaces/resources/talent";
import client from "../client";

export function editTalent(talent: EditTalentProps): AxiosPromise<Talent> {
  const {
    birthDate,
    email,
    firstname,
    imageFile,
    lastname,
    needReactivation,
    phone,
    reactivationStatus,
    searchStatus,
    status,
    searchStatusReason,
    searchStatusData,
  } = talent;
  return client.put(`${talent["@id"]}/profile`, {
    birthDate,
    email,
    firstname,
    imageFile: imageFile && !imageFile.startsWith("http") ? imageFile : undefined,
    lastname,
    needReactivation,
    phone,
    reactivationStatus,
    searchStatus,
    status,
    searchStatusReason,
    searchStatusData,
  });
}

export function getCurrentTalent(): AxiosPromise<Talent> {
  return client.get("/api/v2/talent/current");
}

export function getTalentProfile(talent: Talent): AxiosPromise<TalentProfile> {
  return client.get(`${talent["@id"]}/talent_profile`);
}

export function getTalentSetting(talent: Talent): AxiosPromise<TalentSetting> {
  return client.get(`${talent["@id"]}/talent_setting`);
}

export function getLinkedInProfile(talent: Talent): AxiosPromise<Talent> {
  return client.get(`${talent["@id"]}/linked_in_profile`);
}

export function getQuizAnswers(talent: Talent) {
  return client.get(`${talent["@id"]}/talent_profile/quiz_answers?order[createdAt]`);
}

export function sendMessage(talent: Talent, content: string) {
  return client.post("api/v2/userspace/contact", {
    message: content,
    talent: talent["@id"],
  });
}

export function editTalentProfile(talentProfile: TalentProfile): AxiosPromise<TalentProfile> {
  return client.put(talentProfile["@id"], {
    desiredPlaces: talentProfile.desiredPlaces.map((city: DesiredPlace) => city["@id"]),
    currentJob: talentProfile.currentJob,
    currentCompany: talentProfile.currentCompany,
    experience: talentProfile.experience,
    expectedRemuneration: talentProfile.expectedRemuneration,
    coreResponsibility: talentProfile.coreResponsibility ? talentProfile.coreResponsibility["@id"] : null,
  });
}

export function editTalentSetting({
  codeOfConduct,
  subscribeMessenger,
  newOffersFrequency,
  subscribeNewsLetter,
  subscribeEvents,
  subscribeNotifications,
  ...rest
}: TalentSetting): AxiosPromise<TalentSetting> {
  return client.put(rest["@id"], {
    codeOfConduct,
    subscribeEvents,
    subscribeMessenger,
    subscribeNewsLetter,
    subscribeNotifications,
    newOffersFrequency,
  });
}

export function createLinkedinResume(iri: string, file: string) {
  return client.put(`${iri}/profile`, file);
}

export function createManualLinkedin(currentTalentIri: string) {
  return client.post("api/v2/linkedin_resumes", { talent: currentTalentIri });
}

export function createTalentProfileResponsibility(responsibility: ResponsibilityWithSeniority, talentProfile: string) {
  return client.post("/api/v2/talent_profile_responsibilities", {
    responsibility: responsibility["@id"],
    seniority: responsibility.seniority,
    talentProfile,
  });
}

export function deleteTalentProfileResponsibility(talentProfileResponsibility: TalentProfileResponsibility) {
  return client.delete(talentProfileResponsibility["@id"]);
}

export function editTalentProfileResponsibility(talentProfileResponsibility: TalentProfileResponsibility) {
  return client.put(talentProfileResponsibility["@id"], {
    talentProfileResponsibility: talentProfileResponsibility["@id"],
    seniority: talentProfileResponsibility.seniority,
  });
}

export function createTalentProfileSkill(skill: string, talentProfile: string) {
  return client.post("/api/v2/talent_profile_skills", {
    skill: skill,
    talentProfile,
  });
}

export function deleteTalentProfileSkill(talentProfileSkill: string) {
  return client.delete(talentProfileSkill);
}
