import { EditNotification, GetNotifications } from "../../../actionTypes/notification";

import { Notification } from "../../../interfaces/resources/notification";
import { Offer } from "../../../interfaces/resources/offer";
import { Talent } from "../../../interfaces/resources/talent";

export function getNotifications(
  talent: Talent,
  offer?: Offer,
): {
  talent: Talent;
  offer: Offer | undefined;
  type: string;
} {
  return {
    offer,
    talent,
    type: GetNotifications.REQUEST,
  };
}

export function editNotification(notification: Notification): {
  notification: Notification;
  type: string;
} {
  return {
    notification,
    type: EditNotification.REQUEST,
  };
}
