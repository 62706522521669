import { FieldProps, useField } from "formik";
import * as React from "react";

import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../../utils/hooks";
import ErrorMessage from "../ErrorMessage";
import styles from "./index.module.scss";

interface Props extends FieldProps<any> {
  title: string;
  label: string;
  onChangeCustom?: (e: any) => void;
  violations?: Violation[];
  submitOnChange?: boolean;
}

export const Switch: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    field,
    form: { setFieldTouched, submitForm },
    onChangeCustom,
    violations,
    submitOnChange = false,
    ...rest
  } = props;
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);

  const onBlurInput = (e: React.SyntheticEvent): void => {
    setFieldTouched(field.name, true);
    field.onBlur(e);
  };
  const onChangeInput = (e: React.SyntheticEvent): void => {
    field.onChange(e);
    if (submitOnChange) {
      submitForm();
    }
    if (typeof onChangeCustom === "function") {
      onChangeCustom(e);
    }
  };

  return (
    <div className={styles.inputContainer}>
      <input
        {...rest}
        type="checkbox"
        name={field.name}
        id={field.name}
        checked={field.value}
        onChange={onChangeInput}
        onBlur={onBlurInput}
        className={styles.inputCheckbox}
      />
      <label className={styles.switch} htmlFor={field.name}></label>
      {meta.touched && !!meta.error ? <ErrorMessage name={field.name} message={error} /> : null}
    </div>
  );
};
