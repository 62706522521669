import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { all, AllEffect, ForkEffect } from "redux-saga/effects";

import * as applicationReducers from "./services/api/applications/reducers";
import applicationSaga from "./services/api/applications/saga";
import * as authenticationReducers from "./services/api/authentication/reducers";
import authenticationSaga from "./services/api/authentication/saga";
import * as calendlyEventReducers from "./services/api/calendlyEvent/reducers";
import calendlyEventSaga from "./services/api/calendlyEvent/saga";
import companyEventSaga from "./services/api/company/companyEvent/saga";
import * as companyReducers from "./services/api/company/reducers";

import companySaga from "./services/api/company/saga";
import * as desiredPlaceReducers from "./services/api/desiredPlaces/reducers";
import desiredPlaceSaga from "./services/api/desiredPlaces/saga";
import * as experienceYearsReducers from "./services/api/experienceYears/reducers";
import experienceYearsSaga from "./services/api/experienceYears/saga";
import * as linkedinResumeReducers from "./services/api/linkedinResume/reducers";
import linkedinResumeSaga from "./services/api/linkedinResume/saga";
import * as notificationReducers from "./services/api/notification/reducers";
import notificationSaga from "./services/api/notification/saga";
import offerEventSaga from "./services/api/offer/offerEvent/saga";
import * as offerReducers from "./services/api/offer/reducers";
import offerSaga from "./services/api/offer/saga";
import * as quizReducers from "./services/api/quiz/reducers";
import quizSaga from "./services/api/quiz/saga";
import * as apiReducers from "./services/api/reducer";
import * as referralReducers from "./services/api/referrals/reducers";
import referralSaga from "./services/api/referrals/saga";
import * as responsibilityReducers from "./services/api/responsibility/reducers";
import responsibilitySaga from "./services/api/responsibility/saga";
import apiSaga from "./services/api/saga";
import * as salaryExpectationsReducers from "./services/api/salaryExpectations/reducers";
import salaryExpectationsSaga from "./services/api/salaryExpectations/saga";
import * as schoolReducers from "./services/api/school/reducers";
import schoolSaga from "./services/api/school/saga";
import * as sectorReducers from "./services/api/sector/reducers";
import sectorSaga from "./services/api/sector/saga";
import * as skillsReducers from "./services/api/skills/reducers";
import skillsSaga from "./services/api/skills/saga";
import * as talentReducers from "./services/api/talent/reducers";
import talentSaga from "./services/api/talent/saga";
import * as stateReducers from "./services/state/reducers";

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  ...apiReducers,
  ...applicationReducers,
  ...authenticationReducers,
  ...calendlyEventReducers,
  ...companyReducers,
  ...desiredPlaceReducers,
  ...experienceYearsReducers,
  ...notificationReducers,
  ...offerReducers,
  ...quizReducers,
  ...referralReducers,
  ...responsibilityReducers,
  ...salaryExpectationsReducers,
  ...schoolReducers,
  ...sectorReducers,
  ...skillsReducers,
  ...stateReducers,
  ...talentReducers,
  ...linkedinResumeReducers,
  router: connectRouter(history),
});

function* rootSaga(): Generator<AllEffect<Generator<ForkEffect<never>, void, unknown>>, void, unknown> {
  yield all([
    apiSaga(),
    applicationSaga(),
    authenticationSaga(),
    calendlyEventSaga(),
    companySaga(),
    companyEventSaga(),
    desiredPlaceSaga(),
    experienceYearsSaga(),
    notificationSaga(),
    offerEventSaga(),
    offerSaga(),
    quizSaga(),
    referralSaga(),
    responsibilitySaga(),
    salaryExpectationsSaga(),
    schoolSaga(),
    sectorSaga(),
    skillsSaga(),
    talentSaga(),
    linkedinResumeSaga(),
  ]);
}

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);
