import { push, RouterRootState, RouterState } from "connected-react-router";
import qs from "query-string";
import ReactPixel from "react-facebook-pixel";
import LinkedInTag from "react-linkedin-insight";
import { AnyAction } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  ApiAuthenticationLogin,
  ApiAuthenticationLogout,
  ApiAuthenticationRefresh,
} from "../../../actionTypes/authentication";
import { routes } from "../../../routes";
import { gtagService } from "../../google/gtag";
import { apiFailure, getSuccessType, removeTokens } from "../helpers";
import * as apiClient from "./api";

export const getRouterState = (state: RouterRootState) => state.router;

export function* login(action: AnyAction) {
  try {
    const response = yield call(apiClient.login, action.code, action.redirectUri);

    const routerState: RouterState = yield select(getRouterState);

    const isRegistration = response.headers["x-registration"] === "1";
    if (isRegistration) {
      LinkedInTag.track("1782610");
      ReactPixel.track("linkedin_registration", {});
      gtagService.conversion("AW-853378430/tJk2CJass4oBEP6K9pYD");
    } else {
      LinkedInTag.track("1782626");
      ReactPixel.track("linkedin_login", {});
    }

    yield put({
      locationSearch: routerState.location.search,
      type: ApiAuthenticationLogin.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response") || !e.response) {
      throw new Error(e.message);
    } else {
      yield put(apiFailure(e, action));
    }
  }
}

export function* loginSuccess(action: AnyAction) {
  const searchParams: { next?: string } = qs.parse(action.locationSearch);
  yield put(push(searchParams.next ? searchParams.next : routes.quiz.quiz));
}

export function* refresh(action: AnyAction) {
  try {
    yield call(apiClient.refresh, action.refreshToken);
    push(routes.quiz.quiz);
    yield put({
      type: ApiAuthenticationRefresh.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* logout(action: AnyAction) {
  try {
    yield call(apiClient.logout);
    yield put({
      type: getSuccessType(action),
    });
    removeTokens();
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* logoutSuccess() {
  const routerState: RouterState = yield select(getRouterState);

  if (routerState.location.pathname !== routes.auth.login) {
    yield put(push(routes.auth.login));
  }
}

export default function* authenticationSaga() {
  yield takeLatest(ApiAuthenticationLogin.REQUEST, login);
  yield takeLatest(ApiAuthenticationLogout.REQUEST, logout);
  yield takeLatest(ApiAuthenticationRefresh.REQUEST, refresh);
  yield takeLatest(ApiAuthenticationLogin.SUCCESS, loginSuccess);
  yield takeLatest(ApiAuthenticationLogout.SUCCESS, logoutSuccess);
}
