import { FormikErrors } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { ActionType } from "../interfaces/api/actionType";
import { ApiErrorProps } from "../interfaces/api/error";
import { ApiRequestProps } from "../interfaces/api/request";
import { State } from "../interfaces/api/state";
import { ApiSuccessProps } from "../interfaces/api/success";
import { Violation } from "../interfaces/api/violation";

interface UseApiSelector {
  apiErrors: ApiErrorProps[];
  apiPendingRequests: ApiRequestProps[];
  apiSuccess: ApiSuccessProps[];
}

export const useApiSelector = (): UseApiSelector => {
  const apiErrors: ApiErrorProps[] = useSelector((state: State) => state.apiErrors);
  const apiPendingRequests: ApiRequestProps[] = useSelector((state: State) => state.apiPendingRequests);
  const apiSuccess: ApiSuccessProps[] = useSelector((state: State) => state.apiSuccess);

  return { apiErrors, apiPendingRequests, apiSuccess };
};

export const useViolations = (formName: string): Violation[] => {
  const [violations, setViolations] = useState<Violation[]>([]);

  const { apiErrors, apiSuccess } = useApiSelector();

  useEffect(() => {
    if (apiSuccess.some((suc: ApiSuccessProps) => suc.form === formName)) {
      setViolations([]);
    }
  }, [apiSuccess, formName]);

  useEffect(() => {
    if (apiErrors.some((err: ApiErrorProps) => err.form === formName)) {
      const apiError = apiErrors.find((err: ApiErrorProps) => err.form === formName);
      if (apiError) {
        setViolations(apiError.payload.violations || []);
      }
    }
  }, [apiErrors, formName]);

  return violations;
};

export const getViolationMessage = (
  fieldName: string,
  violations: Violation[],
  errors?: FormikErrors<any> | null,
): string | undefined => {
  const yupError = errors && fieldName.split(".").reduce((acc: any, val: any) => acc && acc[val], errors);
  const violation = violations.find((item: Violation) => fieldName === item.propertyPath);

  return yupError ? String(yupError) : violation ? violation.message : undefined;
};

export const useGetResourceHook = (
  apiErrors: ApiErrorProps[],
  apiPendingRequests: ApiRequestProps[],
  apiSuccess: ApiSuccessProps[],
  actionType: ActionType,
  value: any,
  callback: (...args: any) => void,
  callbackArgs: any[] = [],
): void => {
  useEffect(() => {
    if (
      (!value || value.length === 0)
      && !apiPendingRequests.some((req: ApiRequestProps) => req.type === actionType.REQUEST)
      && !apiErrors.some((err: ApiErrorProps) => err.type === actionType.FAILURE)
      && !apiSuccess.some((suc: ApiSuccessProps) => suc.type === actionType.SUCCESS)
    ) {
      callback(...callbackArgs);
    }
  }, [actionType, apiErrors, apiPendingRequests, apiSuccess, callback, value, callbackArgs]);
};

export const usePrevious = <T>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};
