import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { CreateOfferEvent } from "../../../../actionTypes/offerEvent";
import { apiFailure } from "../../helpers";
import { createOfferEventSuccess } from "./actions";
import * as apiClient from "./api";

export function* createOfferEvent(action: AnyAction) {
  try {
    const response = yield call(apiClient.createOfferEvent, action.offerEvent);
    const offerEvent = response.data;
    yield put(createOfferEventSuccess(offerEvent));
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* offerEventSaga() {
  yield takeLatest(CreateOfferEvent.REQUEST, createOfferEvent);
}
