import { LOCATION_CHANGE } from "connected-react-router";

import { GetCompanies, GetCompany } from "../../../actionTypes/companies";
import { Company } from "../../../interfaces/resources/company";

export function companies(state: Company[] = [], action: any): Company[] {
  switch (action.type) {
    case GetCompanies.SUCCESS:
      return action.companies;
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}
export function company(state: Company | null = null, action: any): Company | null {
  switch (action.type) {
    case GetCompany.SUCCESS:
      return action.company;
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
}
