import { SalaryExpectations } from "../../../interfaces/resources/salaryExpectations";
import { extractIdFromIri } from "../helpers";

export const isConsecutive = (arr: SalaryExpectations[]): boolean => {
  if (arr.length === 0) {
    return false;
  }
  if (arr.length > 1) {
    const result = arr.reduce((accumulator: number, item: SalaryExpectations, index) => {
      const id = extractIdFromIri(item["@id"]);
      index % 2 === 0 ? (accumulator += id) : (accumulator -= id);
      return accumulator;
    }, 0);
    if (result === 1 || result === -1) {
      return true; // si le reducer donne un écart entre les id de 1 alors les choix sont valides
    }
    return false; // si l'écart entre les id est supérieur à 1 alors les choix ne sont pas valides
  }
  return true; // si le nombre de choix est égal à 1 alors les choix sont valides - au-delà de 2 choix Yup.array().max() fait la validation
};
