import { t } from "@lingui/macro";
import * as React from "react";
import { event } from "react-ga";
import { Link } from "react-router-dom";

import BlogImg from "../../images/blog-ressources.png";
import MieuxImg from "../../images/mieux-ressources.png";
import QuizImg from "../../images/quiz-ressources.png";
import styles from "./index.module.scss";

interface Props {
  eventPage: string;
}

interface RessourcesProps {
  slug: string;
  src: string;
  title: string;
  url: {
    external?: boolean;
    to: string;
  };
  description: string;
}

export const RessourcesList: React.FC<Props> = (props: Props) => {
  const { eventPage } = props;
  const ressources: RessourcesProps[] = [
    {
      slug: "rsc-blog",
      src: BlogImg,
      title: t`RessourcesList.blog.title`,
      description: t`RessourcesList.blog.text`,
      url: {
        external: true,
        to: process.env.REACT_APP_BLOG_URL || "",
      },
    },
    {
      slug: "rsc-mieux",
      src: MieuxImg,
      title: t`RessourcesList.kit.title`,
      description: t`RessourcesList.kit.text`,
      url: {
        external: true,
        to: process.env.REACT_APP_KIT_MIEUX_URL || "",
      },
    },
    {
      slug: "rsc-quiz",
      src: QuizImg,
      title: t`RessourcesList.quiz.title`,
      description: t`RessourcesList.quiz.text`,
      url: {
        external: true,
        to: process.env.REACT_APP_QUIZ_METIER_URL || "",
      },
    },
  ];

  const onClickRessource = (r: RessourcesProps) => {
    event({
      action: "click",
      category: "talent",
      label: `${eventPage} - ressource - ${r.title}`,
    });
  };
  return (
    <ul className={styles.ressourcesList}>
      {ressources.map((r) => (
        <li key={`item-${r.slug}`} className={styles.item}>
          <div className={styles.itemContent}>
            {!!r.url.external ? (
              <a
                target="_blank"
                rel="noreferrer noopener"
                className={styles.link}
                href={r.url.to}
                onClick={() => {
                  onClickRessource(r);
                }}
              >
                {" "}
              </a>
            ) : (
              <Link
                className={styles.link}
                to={r.url.to}
                onClick={() => {
                  onClickRessource(r);
                }}
              />
            )}
            <div className={styles.imgContainer}>
              <img className={styles.img} src={r.src} alt={r.title} />
            </div>
            <h3 className={styles.title}>{r.title}</h3>
            <p className={styles.description}>{r.description}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};
