import { ActionType } from "../interfaces/api/actionType";

export const GetNotifications: ActionType = {
  FAILURE: "GET_NOTIFICATIONS_FAILURE",
  REQUEST: "GET_NOTIFICATIONS_REQUEST",
  SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
};

export const EditNotification: ActionType = {
  FAILURE: "EDIT_NOTIFICATION_FAILURE",
  REQUEST: "EDIT_NOTIFICATION_REQUEST",
  SUCCESS: "EDIT_NOTIFICATION_SUCCESS",
};
