import { ActionType } from "../interfaces/api/actionType";

export const GetNextQuestion: ActionType = {
  FAILURE: "GET_NEXT_QUESTION_FAILURE",
  REQUEST: "GET_NEXT_QUESTION_REQUEST",
  SUCCESS: "GET_NEXT_QUESTION_SUCCESS",
};

export const PutCurrentQuestion: ActionType = {
  FAILURE: "PUT_CURRENT_QUESTION_FAILURE",
  REQUEST: "PUT_CURRENT_QUESTION_REQUEST",
  SUCCESS: "PUT_CURRENT_QUESTION_SUCCESS",
};

export const GetCurrentQuestion: ActionType = {
  FAILURE: "GET_CURRENT_QUESTION_FAILURE",
  REQUEST: "GET_CURRENT_QUESTION_REQUEST",
  SUCCESS: "GET_CURRENT_QUESTION_SUCCESS",
};

export const GetPreviousQuestion: ActionType = {
  FAILURE: "GET_PREVIOUS_QUESTION_FAILURE",
  REQUEST: "GET_PREVIOUS_QUESTION_REQUEST",
  SUCCESS: "GET_PREVIOUS_QUESTION_SUCCESS",
};

export const GetLastQuestion: ActionType = {
  FAILURE: "GET_LAST_QUESTION_FAILURE",
  REQUEST: "GET_LAST_QUESTION_REQUEST",
  SUCCESS: "GET_LAST_QUESTION_SUCCESS",
};

export const GetQuizChoices: ActionType = {
  FAILURE: "GET_QUIZ_CHOICES_FAILURE",
  REQUEST: "GET_QUIZ_CHOICES_REQUEST",
  SUCCESS: "GET_QUIZ_CHOICES_SUCCESS",
};

export const PostAnswer: ActionType = {
  FAILURE: "POST_ANSWER_FAILURE",
  REQUEST: "POST_ANSWER_REQUEST",
  SUCCESS: "POST_ANSWER_SUCCESS",
};
