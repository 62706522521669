import { t } from "@lingui/macro";
import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { FaLinkedinIn } from "react-icons/fa";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useLocation } from "react-router-dom";

import logo from "../../images/elinoi-logo-black.svg";
import { State } from "../../interfaces/api/state";
import { hashes, routes } from "../../routes";

import { loginAction } from "../../services/api/authentication/actions";
import { onInitPageLoadTracking } from "../../utils/tracking";

import styles from "./index.module.scss";

export const LoginPage: React.FC = () => {
  const auth = useSelector((state: State) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSignUp] = useState(!!location.hash && location.hash === hashes.signup);
  const redirectUri = process.env.REACT_APP_BASE_URL + routes.auth.login_check;
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = (data: any): void => {
    dispatch(loginAction(data.code, redirectUri));
    setErrorMessage("");
  };

  const handleFailure = (error: any): void => {
    setErrorMessage(error.errorMessage);
  };

  React.useEffect(() => {
    onInitPageLoadTracking();
  }, []);

  return auth.isAuthed ? (
    <Redirect to={routes.app.offers} />
  ) : (
    <>
      <Helmet>
        <title>{t`LoginScene.title`}</title>
        <meta name="" content="" />
      </Helmet>
      <div className={styles.loadingPageContainer}>
        <div className={styles.loginContainer}>
          <div className={styles.logoContainer}>
            <img src={logo} className={styles.logo} alt="Élinoï" />
          </div>
          <p className={styles.loginText}>{isSignUp ? t`Login.text` : t`Login.connection.text`}</p>
          <LinkedIn
            className={styles.linkedInCta}
            clientId={process.env.REACT_APP_LINKED_IN_CLIENT_ID || ""}
            scope="r_emailaddress r_liteprofile"
            onFailure={handleFailure}
            onSuccess={handleSuccess}
            redirectUri={redirectUri}
          >
            <FaLinkedinIn /> {t`Login.callToAction`}
          </LinkedIn>
          <span className="error-message">{errorMessage}</span>
        </div>
      </div>
    </>
  );
};
