import { GetResponsibilities } from "../../../actionTypes/responsibility";
import { Responsibility } from "../../../interfaces/resources/responsibility";

export function responsibilities(state: Responsibility[] = [], action: any) {
  switch (action.type) {
    case GetResponsibilities.SUCCESS:
      return action.responsibilities;
    default:
      return state;
  }
}
