import { t } from "@lingui/macro";
import anime from "animejs";
import cx from "classnames";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import globalStyles from "../../styles/global.module.scss";
import { elinoiSettings } from "../../utils/elinoiSettings";
import styles from "./index.module.scss";

interface PopinForm {
  className?: string;
  testid?: string;
  dataheap?: string;
}

export const PopinForm: React.FC<PopinForm> = (props: PopinForm) => {
  const { className = "", testid = "popinForm", dataheap = "popin-form" } = props;
  const $popinContainer = React.useRef<HTMLDivElement>(null);
  const $badgeContainer = React.useRef<HTMLDivElement>(null);
  const settings = elinoiSettings.get();
  const [show, setShow] = React.useState(settings?.popinForm !== undefined ? settings?.popinForm : true);
  const [hidden, setHidden] = React.useState(false);

  const showAnimation = (): void => {
    const tl = anime.timeline({
      duration: 300,
      easing: "easeInOutExpo",
    });

    tl.add({
      translateY: "100%",
      targets: $badgeContainer.current,
    }).add({
      duration: 800,
      targets: $popinContainer.current,
      translateY: "0%",
    });
  };
  const hideAnimation = (): void => {
    const tl = anime.timeline({
      complete: () => {
        setHidden(true);
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      duration: 800,
      translateY: "100%",
      targets: $popinContainer.current,
    }).add({
      targets: $badgeContainer.current,
      translateY: "0%",
    });
  };

  const showModal = (): void => {
    setHidden(false);
    setShow(true);
    elinoiSettings.set({ ...settings, popinForm: true });
  };
  const hideModal = (): void => {
    setShow(false);
    elinoiSettings.set({ ...settings, popinForm: false });
  };

  React.useEffect(() => {
    if (!show && !hidden) {
      hideAnimation();
      return;
    } else if (show && !hidden) {
      showAnimation();
    }
  }, [show, hidden]);

  return (
    <div className={cx(styles.poppinWrapper)}>
      <div className={styles.badgeContainer} ref={$badgeContainer} style={{ transform: "translateY(0%)" }}>
        <div className={styles.badgeContent}>
          <div className={styles.badge} onClick={showModal}>
            <span>{t`popinForm.badge.text`}</span>
          </div>
        </div>
      </div>
      <div className={cx(styles.poppinContainer)} ref={$popinContainer} style={{ transform: "translateY(100%)" }}>
        <div
          className={cx(styles.poppinContent, { [className]: !!className })}
          data-heap={dataheap}
          data-testid={testid}
        >
          <h3 className={cx(styles.title, globalStyles.blockTitle)}>
            <ReactMarkdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`popinForm.modal.title`}</ReactMarkdown>
          </h3>
          <p className={styles.text}>{t`popinForm.modal.text`}</p>
          <div className={styles.formLinkContainer}>
            <a
              href="https://www.elinoi.com/formulaires/50-startups-ou-postuler-en-2024"
              className={cx(globalStyles.cta, globalStyles.alertCta)}
              target="_blank"
              rel="noopener noreferrer"
              data-heap="form-link"
            >
              {t`popinForm.modal.cta.text`}
            </a>
          </div>
          <div className={styles.closeCtaContainer}>
            <button className={styles.closeCta} type="button" onClick={hideModal} data-heap="close-form-modal">
              {t`popinForm.modal.close.text`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
