import { AnyAction } from "redux";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { CreateReferral, GetReferrals, GetReferred, ValidateReferral } from "../../../actionTypes/referral";
import { apiFailure, getSuccessType } from "../helpers";
import * as apiClient from "./api";

export function* getReferrals(action: any) {
  try {
    const response = yield call(apiClient.getReferrals, action.currentTalent);
    yield put({
      referrals: response.data["hydra:member"],
      type: getSuccessType(action),
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* getReferred(action: AnyAction) {
  try {
    const response = yield call(apiClient.getReferred, action.currentTalent);
    yield put({
      referred: response.data,
      type: getSuccessType(action),
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* createReferral(action: any) {
  try {
    const response = yield call(apiClient.createReferral, action.referral);
    yield put({
      referral: response.data,
      type: getSuccessType(action),
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* validateReferral(action: any) {
  try {
    const response = yield call(apiClient.validateReferral, action.referralCode, action.currentTalent);
    yield put({
      referral: response.data,
      type: getSuccessType(action),
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* referralSaga() {
  yield takeLatest(GetReferrals.REQUEST, getReferrals);
  yield takeLatest(GetReferred.REQUEST, getReferred);
  yield takeEvery(CreateReferral.REQUEST, createReferral);
  yield takeEvery(ValidateReferral.REQUEST, validateReferral);
}
