import { t } from "@lingui/macro";
import * as React from "react";
import { Helmet } from "react-helmet";

import { ContactPage } from "../../pages/ContactPage";

const ContactUsScene = (): JSX.Element => (
  <>
    <Helmet>
      <title>{t`ContactUsScene.title`}</title>
      <meta name="" content="" />
    </Helmet>
    <ContactPage />
  </>
);

export default ContactUsScene;
