import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";

declare global {
  interface Window {
    _linkedin_data_partner_ids: string[];
  }
}

const LinkedInConversionSDK: FC = () => {
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(process.env.REACT_APP_LINKEDIN_PARTNER_ID || "");
  useEffect(() => {
    (() => {
      const s = document.getElementsByTagName("script")[0];
      const b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      if (s.parentNode) {
        s.parentNode.insertBefore(b, s);
      }
    })();
  });

  return (
    <Helmet>
      <noscript>
        {"<img alt=\"\" height=\"1\" "
          + `src="https://dc.ads.linkedin.com/collect/?pid=${process.env.REACT_APP_LINKEDIN_PARTNER_ID}&fmt=gif" `
          + "style=\"display: none;\" width=\"1\""
          + "/>"}
      </noscript>
    </Helmet>
  );
};

export default LinkedInConversionSDK;
