import { t } from "@lingui/macro";
import cx from "classnames";
import React, { SyntheticEvent } from "react";

import avatar from "../../images/defaults/avatar.png";
import styles from "./index.module.scss";

interface Props {
  classNames?: string;
  hasOverlay?: boolean;
  isRound?: boolean;
  src?: string;
  alt?: string;
}

export const PersonProfileImg: React.FC<Props> = (props: Props) => {
  const { classNames = "", hasOverlay = true, isRound = true, src = "", alt } = props;
  const pImgSrc = src ? src : avatar;

  return (
    <div
      className={cx(
        styles.personImgContainer,
        { [styles.hasOverlay]: hasOverlay, [styles.isRound]: isRound },
        classNames,
      )}
    >
      <div className={styles.personImgContent}>
        <img
          data-testid="img"
          onError={(i: SyntheticEvent<HTMLImageElement>): string => (i.currentTarget.src = avatar)}
          className={styles.personImg}
          src={pImgSrc}
          alt={alt ? alt : t`alts.profile.photo`}
        />
      </div>
    </div>
  );
};
