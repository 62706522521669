import { extractIdFromIri } from "../../../components/Utils/helpers";
import { Referral } from "../../../interfaces/resources/referral";
import { Talent } from "../../../interfaces/resources/talent";
import client from "../client";

export function getReferrals(currentTalent: Talent) {
  return client.get(`${currentTalent["@id"]}/referrals`);
}

export function getReferred(currentTalent: Talent) {
  return client.get(`${currentTalent["@id"]}/referred`);
}

export function formatData(referral: Partial<Referral>) {
  return {
    refereeEmail: referral.refereeEmail,
    referrerId: referral.referrer && extractIdFromIri(referral.referrer["@id"]),
  };
}

export function createReferral(referral: Partial<Referral>) {
  return client.post("/api/v2/referrals", formatData(referral));
}

export function validateReferral(referralCode: string, currentTalent: Talent) {
  return client.post("/api/v2/quiz/validate_referral", {
    currentTalentId: extractIdFromIri(currentTalent["@id"]),
    referralCode,
  });
}
