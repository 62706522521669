import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import ReactPixel from "react-facebook-pixel";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { GetDesiredPlaces } from "../../actionTypes/desiredPlaces";
import { GetExperienceYears } from "../../actionTypes/experienceYears";
import { GetSalaryExpectations } from "../../actionTypes/salaryExpectations";
import { EditTalent, GetTalentProfile } from "../../actionTypes/talent";
import { CandidateProfileCard } from "../../blocks/candidateProfileCard";
import { NewNotificationsHelmet } from "../../blocks/newNotificationsHelmet";
import { PersonProfileImg } from "../../blocks/personProfileImg";
import { EditProfileModal } from "../../components/EditProfileModal";
import { ModalWrapper } from "../../components/ModalWrapper";
import { State } from "../../interfaces/api/state";
import { Talent } from "../../interfaces/resources/talent";
import { getDesiredPlacesAction } from "../../services/api/desiredPlaces/actions";
import { getExperienceYearsAction } from "../../services/api/experienceYears/actions";
import { getSalaryExpectationsAction } from "../../services/api/salaryExpectations/actions";
import { getTalentProfileAction } from "../../services/api/talent/actions";
import globalStyle from "../../styles/global.module.scss";
import { useApiSelector, useGetResourceHook } from "../../utils/hooks";
import { onInitPageLoadTracking } from "../../utils/tracking";

import styles from "./index.module.scss";

export interface Props {
  editTalent: (talent: Talent) => void;
  getDesiredPlaces: () => void;
  getSalaryExpectations: () => void;
  getExperienceYears: () => void;
  getTalentProfile: (talent: Talent) => void;
}

export const ProfilePage: React.FC<Props> = () => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const talentProfile = useSelector((state: State) => state.talentProfile);
  const desiredPlaces = useSelector((state: State) => state.desiredPlaces);
  const salaryExpectations = useSelector((state: State) => state.salaryExpectations);
  const experienceYears = useSelector((state: State) => state.experienceYears);
  const [openProfileModal, setOpenProfileModal] = React.useState(false);
  const [initialSubmit, setInitialSubmit] = React.useState(true);
  const closeModal = () => {
    setOpenProfileModal(false);
  };

  const getTalentProfile = (talent: Talent): void => {
    dispatch(getTalentProfileAction(talent));
  };
  const getDesiredPlaces = (): void => {
    dispatch(getDesiredPlacesAction());
  };
  const getExperienceYears = (): void => {
    dispatch(getExperienceYearsAction());
  };
  const getSalaryExpectations = (): void => {
    dispatch(getSalaryExpectationsAction());
  };

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetTalentProfile, talentProfile, getTalentProfile, [
    currentTalent,
  ]);
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetDesiredPlaces, desiredPlaces, getDesiredPlaces);
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetSalaryExpectations,
    salaryExpectations,
    getSalaryExpectations,
  );
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetExperienceYears,
    experienceYears,
    getExperienceYears,
  );

  React.useEffect(() => {
    if (initialSubmit && apiSuccess.some((e) => e.type === EditTalent.SUCCESS)) {
      ReactPixel.track("information_completed", {});
      setInitialSubmit(false);
    }
  }, [apiSuccess, initialSubmit]);

  React.useEffect(() => {
    onInitPageLoadTracking();
  }, []);

  return (
    <>
      <NewNotificationsHelmet title={t`ProfileScene.title`} />
      <div className={cx(styles.pageWrapper)}>
        <h2 className={globalStyle.mainTitle}>
          <ReactMarkdown source={t`ProfilePage.title`} disallowedTypes={["paragraph"]} unwrapDisallowed />
        </h2>
        <div className={styles.profileImgInfosContainer}>
          <div className={styles.profileImgContainer}>
            <PersonProfileImg src={currentTalent.image.url} />
          </div>
          <div>
            <div>
              <h3
                className={cx(styles.info, styles.nameInfo)}
              >{`${currentTalent.firstname} ${currentTalent.lastname}`}</h3>
              <span className={styles.info}>{currentTalent.email}</span>
              <span className={styles.info}>{currentTalent.phone}</span>
            </div>
            <div className={styles.profileCtaContainer}>
              <button
                className={cx(globalStyle.cta, globalStyle.primaryCta, globalStyle.inverted, globalStyle.smallerCta)}
                onClick={() => {
                  setOpenProfileModal(true);
                }}
              >
                {t`EditProfileModal.openModal.cta.text`}
              </button>
              <ModalWrapper open={openProfileModal} closeModal={closeModal}>
                <EditProfileModal closeModal={closeModal} />
              </ModalWrapper>
            </div>
          </div>
        </div>

        <div className={styles.candidateProfileCardContainer}>
          <CandidateProfileCard />
        </div>
      </div>
    </>
  );
};
