import { ActionType } from "../interfaces/api/actionType";

export const EditLinkedinItem: ActionType = {
  FAILURE: "EDIT_LINKEDIN_ITEM_FAILURE",
  REQUEST: "EDIT_LINKEDIN_ITEM_REQUEST",
  SUCCESS: "EDIT_LINKEDIN_ITEM_SUCCESS",
};

export const DeleteLinkedinItem: ActionType = {
  FAILURE: "DELETE_LINKEDIN_ITEM_FAILURE",
  REQUEST: "DELETE_LINKEDIN_ITEM_REQUEST",
  SUCCESS: "DELETE_LINKEDIN_ITEM_SUCCESS",
};

export const GetLinkedinResume: ActionType = {
  FAILURE: "GET_LINKEDIN_RESUME_FAILURE",
  REQUEST: "GET_LINKEDIN_RESUME_REQUEST",
  SUCCESS: "GET_LINKEDIN_RESUME_SUCCESS",
};

export const CreateLinkedinResumeItem: ActionType = {
  FAILURE: "CREATE_LINKEDIN_RESUME_ITEM_FAILURE",
  REQUEST: "CREATE_LINKEDIN_RESUME_ITEM_REQUEST",
  SUCCESS: "CREATE_LINKEDIN_RESUME_ITEM_SUCCESS",
};

export const CreateManualLinkedin: ActionType = {
  FAILURE: "CREATE_MANUAL_LINKEDIN_FAILURE",
  REQUEST: "CREATE_MANUAL_LINKEDIN_REQUEST",
  SUCCESS: "CREATE_MANUAL_LINKEDIN_SUCCESS",
};
