import { differenceInMinutes, parseISO } from "date-fns";
import { Application, applicationStatuses } from "../interfaces/resources/application";
import {
  Responsibility,
  ResponsibilityWithSeniority,
  TalentProfileResponsibility,
} from "../interfaces/resources/responsibility";
import { TalentProfileSkill } from "../interfaces/resources/skill";
import { TalentProfile } from "../interfaces/resources/talent";

export const setRef = (el: any, array: React.RefObject<any[]>): void => {
  if (array.current !== null && el && !array.current.includes(el)) array.current.push(el);
};

export const isMobile = (): boolean => {
  const wW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  return wW <= 800;
};

export const hasApplied = (application: Application | null | undefined): boolean => {
  const { contacted, toContact, interesting } = applicationStatuses;
  return !!application && ![contacted, toContact, interesting].includes(application.statusAsTalent);
};

export const hasBeenRefused = (application: Application | null | undefined): boolean => {
  const { refusedElinoi, refusedStartup, refusedTalent } = applicationStatuses;
  return !!application && [refusedElinoi, refusedStartup, refusedTalent].includes(application.statusAsTalent);
};

export const isOdd = (id: number): boolean => {
  return !!(id % 2);
};

export const isProcessPanelDisabled = (application: Application | null): boolean => {
  if (!application) {
    return true;
  }

  const {
    awaitingShortlist,
    shortlistReady,
    interviews,
    shortlisted,
    accepted,
    offer: offerStatus,
  } = applicationStatuses;
  const { statusAsTalent } = application;

  if ([offerStatus, interviews, shortlistReady, shortlisted, accepted].includes(statusAsTalent)) {
    return false;
  }
  if (awaitingShortlist === application.statusAsTalent) {
    const statusEvent = [...application.applicationStatusEvents].pop();
    if (statusEvent && differenceInMinutes(new Date(), parseISO(statusEvent.createdAt)) >= 15) {
      return false;
    }
  }
  return true;
};

export function getStringValue(value: any, field = "@id"): string | undefined {
  return value ? ("string" === typeof value ? value : value[field]) : undefined;
}

export const getAddDeletePutTalentResponsibility = (
  talentProfile: TalentProfile,
  respToSort: ResponsibilityWithSeniority[] | null,
): {
  respsToAdd: Partial<ResponsibilityWithSeniority>[];
  respsToDelete: Partial<TalentProfileResponsibility>[];
  respsToPut: Partial<TalentProfileResponsibility>[];
} => {
  const respos = respToSort || [];
  const respsToPut: Partial<TalentProfileResponsibility>[] = [];
  const respsToDelete: Partial<TalentProfileResponsibility>[] = [];
  const respsToAdd = respos.filter(
    (resp) => !talentProfile.responsibilities.some((r: any) => resp["@id"] === r.responsibility["@id"]),
  );

  talentProfile.responsibilities.forEach((r: TalentProfileResponsibility) => {
    if (!respos.some((res: Responsibility) => res["@id"] === r.responsibility["@id"])) {
      respsToDelete.push(r);
    }
    if (respos.some((respo: any) => respo["@id"] === r.responsibility["@id"] && respo.seniority !== r.seniority)) {
      const respo = respos.find((res: any) => res["@id"] === r.responsibility["@id"]);
      if (respo) {
        respsToPut.push({ "@id": r["@id"], seniority: respo.seniority });
      }
    }
  });
  return { respsToAdd, respsToDelete, respsToPut };
};

export const getAddDeleteTalentProfileSkills = (
  talentProfile: TalentProfile,
  skillToSort: any[] | null,
): {
  skillsToAdd: any[];
  skillsToDelete: Partial<TalentProfileSkill>[];
} => {
  const skills = skillToSort || [];
  const skillsToDelete: Partial<TalentProfileSkill>[] = [];
  const skillsToAdd = skills.filter(
    (skill) => !talentProfile.talentProfileSkills.some((tps: any) => skill["@id"] === tps),
  );

  talentProfile.talentProfileSkills.forEach((tps: TalentProfileSkill) => {
    if (!skills.some((skill: any) => skill["@id"] === tps)) {
      skillsToDelete.push(tps);
    }
  });
  return { skillsToAdd, skillsToDelete };
};
