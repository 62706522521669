import { t } from "@lingui/macro";
import cx from "classnames";

import { Field, Form, Formik } from "formik";
import * as React from "react";
import { CgArrowRight } from "react-icons/cg";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { quizContext } from "../..";
import { GetSkill } from "../../../../actionTypes/skills";
import { SkillsComp } from "../../../../components/Skills";
import { State } from "../../../../interfaces/api/state";
import { QuizStepSlug } from "../../../../interfaces/resources/quiz";
import "react-input-range/lib/css/index.css";
import { getSkillAction } from "../../../../services/api/skills/actions";
import globalStyle from "../../../../styles/global.module.scss";

import { useApiSelector } from "../../../../utils/hooks";
import quizStyle from "../../index.module.scss";
import styles from "./index.module.scss";

const QUIZ_STEP_SLUG: QuizStepSlug = "skills";
const QUIZ_STEP_SLUG_CAREER: QuizStepSlug = "career";

export const PartSkills: React.FC = () => {
  const { apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const skills = useSelector((state: State) => state.skills);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const context = React.useContext(quizContext);
  const answerCareer = context?.getAnswer(QUIZ_STEP_SLUG_CAREER);
  const answer = context?.getAnswer(QUIZ_STEP_SLUG);

  const submitForm = (values: any) => {
    if (context === null) {
      return;
    }
    context.handleSubmit({
      data: { skills: values },
      value: QUIZ_STEP_SLUG,
    });
  };

  const handlePrevious = () => {
    if (context === null) {
      return;
    }
    context.handlePrevious(QUIZ_STEP_SLUG);
  };

  React.useEffect(() => {
    setIsLoading(apiPendingRequests.length > 0);
    if (
      answerCareer?.data?.coreResponsibility
      && skills.length === 0
      && !apiPendingRequests.some((s) => s.type === GetSkill.REQUEST)
      && !apiSuccess.some((success) => success.type === GetSkill.SUCCESS)
    ) {
      dispatch(getSkillAction(answerCareer.data.coreResponsibility));
    }
  }, [answerCareer, dispatch, skills, apiPendingRequests, isLoading, apiSuccess]);

  const initialValues = { talentProfileSkills: answer?.data?.skills?.talentProfileSkills || [] };
  return (
    <div className={styles.qualificationFormWrapper}>
      <div className={quizStyle.questionHeader}>
        <h1 className={cx(globalStyle.mainTitle, quizStyle.mainTitle)}>
          <ReactMarkdown
            className="formLabel"
            source="Vos Compétences"
            disallowedTypes={["paragraph"]}
            unwrapDisallowed
          />
        </h1>
        <p className={quizStyle.textHeader}>
          <ReactMarkdown source={t`Quiz.skills.subtitle`} disallowedTypes={["paragraph"]} unwrapDisallowed />
        </p>
      </div>
      {skills.length > 0 ? (
        <div className={styles.formContainer}>
          <Formik
            onSubmit={(values): void => submitForm(values)}
            initialValues={initialValues}
            enableReinitialize={false}
          >
            {({ handleSubmit, values, isSubmitting }): JSX.Element => {
              return (
                <div>
                  <Form onSubmit={handleSubmit} className={cx("form", { loading: isLoading })}>
                    <Field
                      name="talentProfileSkills"
                      // className={cx("fieldClientFacing field", styles.skillsWrapper)}
                      component={SkillsComp}
                      label="Compétences"
                      instruction="Gagnez en visibilité auprès de nos partenaires en cochant les compétences que vous avez développées lors de vos expériences"
                      isRequired={false}
                      options={skills}
                      getOptionLabel={(option: any): any => option.value || option}
                      getOptionValue={(option: any): any => (option.skill ? option.skill["@id"] : option["@id"])}
                    />
                    <div className={quizStyle.ctasContainer}>
                      <div>
                        <span onClick={handlePrevious} className={quizStyle.previousCta}>
                          {t`Quiz.previousCta.text`}
                        </span>
                      </div>
                      <div className={quizStyle.submitCtaContainer}>
                        <button
                          type="submit"
                          className={quizStyle.submitCta}
                          disabled={isSubmitting || !values.talentProfileSkills.length}
                        >
                          Suivant <CgArrowRight />
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      ) : null}
    </div>
  );
};
