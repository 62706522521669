import { t } from "@lingui/macro";
import anime from "animejs";
import cx from "classnames";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import { GetApplications, GetAvailableApplications } from "../../../../actionTypes/applications";
import { GetOffers } from "../../../../actionTypes/offers";

import { OfferCard } from "../../../../blocks/offerCard";
import { Loader } from "../../../../components/Loader";
import { OffersCardList } from "../../../../components/OffersCardList";
import { RessourcesList } from "../../../../components/RessourcesList";

import { ApiRequestProps } from "../../../../interfaces/api/request";
import { State } from "../../../../interfaces/api/state";
import { Offer, offerModes } from "../../../../interfaces/resources/offer";
import { hashes } from "../../../../routes";
import { getAvailableApplications as getAvailableApplicationsAction } from "../../../../services/api/applications/actions";
import { getOffers as getOffersAction } from "../../../../services/api/offer/actions";
import global from "../../../../styles/global.module.scss";
import { useApiSelector, useGetResourceHook } from "../../../../utils/hooks";
import { onInitPageLoadTracking } from "../../../../utils/tracking";
import tabs from "../index.module.scss";
import styles from "./index.module.scss";

export const PanelMyProcess: React.FC = () => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const activeOffers = useSelector((state: State) => state.activeOffers).filter(
    (o) => o.mode !== offerModes.spontaneous,
  );
  const archivedOffers = useSelector((state: State) => state.archivedOffers).filter(
    (o) => o.mode !== offerModes.spontaneous,
  );
  const getAvailableApplications = (): void => {
    dispatch(getAvailableApplicationsAction(currentTalent));
  };
  const [loading, setLoading] = React.useState<boolean>(true);
  const $arContainerRef = React.useRef<HTMLDivElement>(null);
  const $arListRef = React.useRef<HTMLUListElement>(null);
  const [showList, setShowList] = React.useState(false);
  const [isAnimating, setIsAnimating] = React.useState(false);

  const getOffers = (): void => {
    dispatch(getOffersAction(currentTalent));
  };

  const onClickArchiveTitle = (): void => {
    if (isAnimating) {
      return;
    }
    setIsAnimating(true);
    setShowList(!showList);
  };

  const showArchivedList = (): void => {
    if (!!!$arContainerRef.current || !!!$arListRef.current) {
      return;
    }
    const h = $arListRef.current.getBoundingClientRect().height;
    const tl = anime.timeline({
      duration: 300,
      easing: "linear",
      complete: () => {
        anime.set($arContainerRef.current, { overflow: null });
        if ($arContainerRef.current) {
          $arContainerRef.current.style.height = "auto";
        }
        setIsAnimating(false);
      },
    });

    tl.add({
      height: h,
      targets: $arContainerRef.current,
    });
  };
  const hideArchivedList = (): void => {
    if (!!!$arContainerRef.current || !!!$arListRef.current) {
      return;
    }
    const h = $arListRef.current.getBoundingClientRect().height;
    const tl = anime.timeline({
      duration: 300,
      easing: "linear",
      complete: () => {
        setIsAnimating(false);
      },
    });

    anime.set($arContainerRef.current, { overflow: "hidden" });
    tl.add({
      height: [h, "0px"],
      targets: $arContainerRef.current,
    });
  };

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetOffers,
    [...activeOffers, ...archivedOffers],
    getOffers,
    [currentTalent],
  );
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetAvailableApplications,
    [...activeOffers, ...archivedOffers],
    getAvailableApplications,
    [currentTalent],
  );
  React.useEffect(() => {
    setLoading(
      apiPendingRequests.some((e: ApiRequestProps) =>
        [GetAvailableApplications.REQUEST, GetApplications.REQUEST].includes(e.type),
      ),
    );
  }, [apiPendingRequests]);

  React.useEffect(() => {
    if (!showList) {
      hideArchivedList();
    } else if (showList) {
      showArchivedList();
    }
  }, [showList]);

  React.useEffect(() => {
    onInitPageLoadTracking();
  }, []);

  return (
    <>
      <div className={tabs.tabPanelContainer} data-testid={`panel-${hashes.process}`}>
        <div className={tabs.tabTitleContainer}>
          <h2 className={global.mainTitle}>
            <Markdown source={t`PanelMyProcess.title`} disallowedTypes={["paragraph"]} unwrapDisallowed />
          </h2>
        </div>
        {loading ? (
          <div key="loaderContainer" className={tabs.loaderContainer} data-testid="panel-myprocess-loader-container">
            <Loader />
          </div>
        ) : (
          <div key="partsContainer" className={tabs.partsContainer}>
            {!!!activeOffers.length && !loading ? (
              <div className={tabs.part} key="ressourcespart" data-testid="panel-myprocess-ressources-container">
                <div className={tabs.partHeaderContainer}>
                  <h3 className={tabs.partTitle}>{t`PanelMyProcess.noProcess.title`}</h3>
                  <div className={tabs.partSubText}>{t`PanelMyProcess.noProcess.subText`}</div>
                </div>
                <div className={tabs.partHeaderContainer}>
                  <h3 className={tabs.partTitle}>{t`PanelMyOffersMyProcess.otherRessources.title`}</h3>
                </div>
                <div>
                  <RessourcesList eventPage="mes process" />
                </div>
              </div>
            ) : (
              <div className={tabs.part} key="processofferspart" data-testid="panel-myprocess-processoffers">
                <div className={tabs.partHeaderContainer}>
                  <h3 className={tabs.partTitle}>{t`PanelMyProcess.currentProcess.title`}</h3>
                </div>
                <div>
                  <OffersCardList
                    offers={activeOffers}
                    render={(offer: Offer): JSX.Element => <OfferCard offer={offer} />}
                  />
                </div>
              </div>
            )}
            {!!archivedOffers.length ? (
              <div className={tabs.part} key="archivedofferspart" data-testid="panel-myprocess-archivedoffers">
                <div className={tabs.partHeaderContainer}>
                  <h3
                    className={cx(styles.archiveTitle, tabs.partTitle)}
                    onClick={onClickArchiveTitle}
                    data-testid="ar-title"
                  >
                    {t`PanelMyProcess.archivedOffers.title`}
                    <span className={cx(styles.arrow, { [styles.isOpen]: showList })}>
                      <IoIosArrowDown />
                    </span>
                  </h3>
                </div>
                <div ref={$arContainerRef} style={{ overflow: "hidden", height: "0px" }} data-testid="ar-offer">
                  <OffersCardList
                    refElem={$arListRef}
                    offers={archivedOffers}
                    render={(offer: Offer): JSX.Element => <OfferCard offer={offer} />}
                  />
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};
