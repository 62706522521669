import { CoreUser } from "./coreUser";
import { DesiredPlace } from "./desiredPlace";
import { ExperienceYears } from "./experienceYears";
import { LinkedinResume } from "./linkedinResume";
import { QuizStepSlug } from "./quiz";
import { Responsibility, TalentProfileResponsibility } from "./responsibility";
import { SalaryExpectations } from "./salaryExpectations";
import { TalentProfileSkill } from "./skill";

export interface TalentProfile {
  "@id": string;
  completed: number;
  context: string;
  currentJob: string | null;
  currentCompany: string | null;
  currentQuizStep?: QuizStepSlug | null;
  nextQuizStep?: QuizStepSlug | null;
  desiredPlaces: DesiredPlace[];
  experienceYears?: ExperienceYears;
  lastQualificationUpdate: string | null;
  lastTalentProfileEvent?: TalentProfileEvent | null;
  responsibilities: TalentProfileResponsibility[];
  coreResponsibility?: Responsibility;
  salaryExpectations?: SalaryExpectations[];
  storyTelling: string;
  strength: number;
  experience?: number;
  expectedRemuneration?: Remuneration;
  talentProfileSkills: TalentProfileSkill[];
}

export interface TalentProfileEvent {
  "@id": string;
  createdAt: string;
  talentProfile: string;
}

export interface Remuneration {
  fixedSalary: number;
  fullPackage: number;
}

export interface TalentSetting {
  "@id": string;
  subscribeMessenger: boolean;
  newOffersFrequency: string;
  subscribeNewsLetter: boolean;
  subscribeEvents: boolean;
  subscribeNotifications: boolean;
  codeOfConduct: boolean;
}

export interface LinkedInProfile {
  "@id": string;
  educations: Array<{
    schoolName: string;
    endDate: string;
  }>;
  url: string;
}

export interface TalentStatusEventsProps {
  status: string;
  data?: string;
  coreUser?: CoreUser;
}

export interface TalentSearchStatusEventsProps {
  status: string;
  reason?: string;
  data?: string;
  createdAt: string;
}

export interface Talent {
  "@id": string;
  accessToPlatine?: boolean;
  firstname: string;
  birthDate: string;
  codeOfConduct: boolean;
  email: string;
  image: {
    url: string;
  };
  imageFile?: string;
  isSecondChance: boolean;
  lastlogin: string;
  lastname: string;
  lastPhoneCall: string | null;
  lastReactivationStatus?: string | null;
  lastTalentStatusEvent?: TalentStatusEventsProps;
  lastTalentSearchStatusEvent?: TalentSearchStatusEventsProps;
  linkedInProfile: any;
  needReactivation: boolean;
  phone?: string;
  previousLogin: string;
  reactivationStatus?: string | null;
  searchStatus?: string;
  segment: string | null;
  status: string;
  talentManager: CoreUser;
  talentProfile?: TalentProfile;
  talentSetting: string;
  token: string;
  username: string;
  documentFile: any;
  linkedinResume?: LinkedinResume;
}

export interface EditTalentProps extends Talent {
  searchStatusReason: string | null;
  searchStatusData: {
    [key: string]: any;
  };
}

export const talentStatuses = {
  accepted: "status_accepted",
  blacklisted: "status_blacklisted",
  callBooked: "status_call_booked",
  pending: "status_pending",
  queued: "status_queued",
  refused: "status_denied",
  toCoach: "status_to_call",
  toOnboard: "status_to_onboard",
  toTest: "status_to_test",
  cLevel: "status_to_c_level",
};

export const talentReactivationStatuses = {
  searching: "searching",
  passive_searching: "passive_searching",
  not_searching: "not_searching",
};

export const talentSearchStatuses = {
  searching: "searching",
  passive_searching: "passive_searching",
  not_searching: "not_searching",
};
export const talentSearchStatusReasons = {
  job_found: "job_found",
  keep_current_job: "keep_current_job",
  project_launch: "project_launch",
  other: "other",
};
