import postCall from "../../images/icon-notification-post_call.svg";
import toPitch from "../../images/icon-notification-to_pitch.svg";

import { CoreUser } from "./coreUser";

export interface Notification {
  "@id": string;
  author?: CoreUser;
  text: string;
  type: string;
  shortText: string;
  seen: boolean;
  sendDate: string;
  offer?: {
    "@id": string;
  };
  url?: string;
  notificationType: {
    label: string;
  };
}

export const notificationLabels: { [key: string]: string } = {
  blogPresentation: "blog.presentation",
  blogNewArticle: "blog.new_article",
  profileStrengthUp: "profile.strength_up",
  postCall: "coaching.post_call",
  pushOffers: "coaching.push_offers",
  toPitch: "application.to_pitch",
  awaitingShortlist: "application.awaiting_shortlist",
  accepted: "application.accepted",
  call: "coaching.call",
  callReminder: "coaching.call_reminder",
  welcome: "welcome",
  applicationRefused: "application.refused",
  offerDeactivated: "offer.deactivated",
  callBookedConfirm: "call_booked.confirm",
  callBookedRemind: "call_booked.remind",
  applicationToPitchRemind: "application.to_pitch.remind",
  applicationAppliedConfirm: "application.applied.confirm",
  referral: "referral",
  referral2: "referral.2",
  referral3: "referral.3",
  referral4: "referral.4",
  referral5: "referral.5",
  settings: "settings",
};

export const notificationIcons: {
  [key: string]: string;
} = {
  [notificationLabels.pushOffers]: postCall,
  [notificationLabels.toSend]: postCall,
  [notificationLabels.toPitch]: toPitch,
  [notificationLabels.confirmation]: toPitch,
};
