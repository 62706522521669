import { t } from "@lingui/macro";
import cx from "classnames";
import React from "react";
import Markdown from "react-markdown";

import BlogImg from "../../images/blog-ressource.png";
import MieuxImg from "../../images/mieux-ressource.png";
import QuizJobImg from "../../images/quiz-ressource.png";
import SimulateurImg from "../../images/simulateur-ressource.png";
import { gtagService } from "../../services/google/gtag";
import global from "../../styles/global.module.scss";
import styles from "./index.module.scss";

interface RessourceProps {
  slug: string;
  title: string;
  text: {
    desktop: string;
    mobile: string;
  };
  tagline: string;
  src: string;
  url?: string;
  cta?: string;
}

export const RessourcesPage: React.FC = () => {
  const ressources: RessourceProps[] = [
    {
      slug: "kit-mieux",
      title: t`RessourcesPage.Mieux.title`,
      text: {
        desktop: t`RessourcesPage.Mieux.text.desktop`,
        mobile: t`RessourcesPage.Mieux.text.mobile`,
      },
      tagline: t`RessourcesPage.Mieux.tagline`,
      src: MieuxImg,
      cta: t`RessourcesPage.Mieux.cta`,
      url: process.env.REACT_APP_KIT_MIEUX_URL,
    },
    {
      slug: "simulateur-de-salaires",
      title: t`RessourcesPage.simulateur.title`,
      text: {
        desktop: t`RessourcesPage.Simulateur.text.desktop`,
        mobile: t`RessourcesPage.Simulateur.text.mobile`,
      },
      tagline: "",
      src: SimulateurImg,
      cta: t`RessourcesPage.Simulateur.cta`,
      url: process.env.REACT_APP_SIMULATEUR_URL,
    },
    {
      slug: "blog",
      title: t`RessourcesPage.blog.title`,
      text: {
        desktop: t`RessourcesPage.blog.text.desktop`,
        mobile: t`RessourcesPage.blog.text.mobile`,
      },
      tagline: "",
      src: BlogImg,
      cta: t`RessourcesPage.blog.cta`,
      url: process.env.REACT_APP_BLOG_URL,
    },
    {
      slug: "quiz-metier",
      title: t`RessourcesPage.quizJobs.title`,
      text: {
        desktop: t`RessourcesPage.quizJobs.text.desktop`,
        mobile: t`RessourcesPage.quizJobs.text.mobile`,
      },
      tagline: "",
      src: QuizJobImg,
      cta: t`RessourcesPage.quizJobs.cta`,
      url: process.env.REACT_APP_QUIZ_URL,
    },
  ];

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.titleContainer}>
        <h2 className={global.mainTitle}>
          <Markdown source={t`RessourcesPage.title`} disallowedTypes={["paragraph"]} unwrapDisallowed />
        </h2>
        <h3 className={global.subText}>
          <Markdown source={t`RessourcesPage.subtitle`} disallowedTypes={["paragraph"]} unwrapDisallowed />
        </h3>
      </div>
      <ul className={styles.resssourcesList} data-testid="ressources-list">
        {ressources.map((r, i) => (
          <li key={`ressource-page-${r.slug}`} className={cx(styles.item, { [styles.firstItem]: i === 0 })}>
            <div className={styles.itemContent}>
              <div className={styles.imgContainer}>
                <img alt="pdf mieux" src={r.src} />
              </div>
              <div>
                <div className={styles.itemTextContainer}>
                  <h2>
                    <Markdown source={r.title} disallowedTypes={["paragraph"]} unwrapDisallowed />
                  </h2>
                  <p className={styles.desktopText}>
                    <Markdown source={r.text.desktop} disallowedTypes={["paragraph"]} unwrapDisallowed />
                  </p>
                  <p className={styles.mobileText}>
                    <Markdown source={r.text.mobile} disallowedTypes={["paragraph"]} unwrapDisallowed />
                  </p>
                  <p className={styles.tagline}>
                    <Markdown source={r.tagline} disallowedTypes={["paragraph"]} unwrapDisallowed />
                  </p>
                </div>
                {r.url ? (
                  <div className={styles.ctaContainer} data-testid="cta-container">
                    <a
                      className={cx(global.cta, global.secondaryCta)}
                      data-testid={`ressource-cta-${i}`}
                      href={r.url}
                      onClick={(): void => {
                        gtagService.event("click", {
                          event_label: `Page ressource - ${r.slug}`,
                        });
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {r.cta}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
