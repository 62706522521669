import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";

import { MiniTalentCard } from "../../../../blocks/miniTalentCard";
import { State } from "../../../../interfaces/api/state";
import { LastAcceptedApplication } from "../../../../interfaces/resources/application";
import { Offer } from "../../../../interfaces/resources/offer";

import { gtagService } from "../../../../services/google/gtag";
import transCriterion from "../../../../translations/constants/criterion";

import tabs from "../index.module.scss";

interface Props {
  offer: Offer;
}

export const PanelOffer: React.FC<Props> = (props: Props) => {
  const lastAcceptedApplications: LastAcceptedApplication[] = useSelector(
    (state: State) => state.lastAcceptedApplications,
  );
  const { offer } = props;

  return (
    <div className={tabs.tabPanelContainer}>
      <div className={cx(tabs.blocksContainer, tabs.inlineBlocksContainer, tabs.noBgBlocksContainer)}>
        <h2 className={tabs.sumupBlockTitle}>{t`PanelOffer.sumup.title`}</h2>
        <div className={tabs.inlineBlocksInnerContainer}>
          {offer.offerTalentView.ytPitch ? (
            <div className={cx(tabs.videoBlocksRow, tabs.blocksRow)}>
              <div className={cx(tabs.videoBlock, tabs.block)}>
                <div className={tabs.videoContainer}>
                  <div className={tabs.video}>
                    <iframe
                      title={`offer-${offer.title}-video`}
                      src={offer.offerTalentView.ytPitch}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className={cx(tabs.sumupBlocksRow, tabs.blocksRow)}>
            {offer.offerCriteria ? (
              <div className={cx(tabs.sumupBlock, tabs.block)}>
                <div className={tabs.subBlockContainer}>
                  <div className={tabs.subBlock}>
                    <h3 className={tabs.blockTitle}>{t`PanelOffer.sumup.criteriaScoring.title`}</h3>
                    <ul className={tabs.criteriaList}>
                      {offer.offerCriteria.map((item) => (
                        <li key={`criteria-${item.criterion.label}`} className={tabs.criteriaItem}>
                          <div className={tabs.criteriaBarContainer}>
                            <div
                              className={tabs.criteriaBar}
                              style={{ transform: `translateX(${-100 + item.score * 20}%)` }}
                            />
                          </div>
                          <div className={tabs.criteriaLabel}>
                            {item.criterion && t({ id: transCriterion.labels[item.criterion.label] })}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={cx(tabs.sumupBlock, tabs.block)}>
              <div className={tabs.subBlockContainer}>
                <div className={tabs.subBlock}>
                  <h3 className={tabs.blockTitle}>{t`PanelOffer.sumup.team.title`}</h3>
                  <div className={tabs.blockText}>
                    <ReactMarkdown source={offer.offerTalentView.team} />
                  </div>
                </div>
              </div>
              <div className={tabs.subBlockContainer}>
                <div className={tabs.subBlock}>
                  <h3 className={tabs.blockTitle}>{t`PanelOffer.sumup.salary.title`}</h3>
                  <div className={tabs.blockText}>{offer.printSalaries}</div>
                </div>
                {offer.stapleLocation ? (
                  <div className={tabs.subBlock}>
                    <h3 className={tabs.blockTitle}>{t`PanelOffer.sumup.location.title`}</h3>
                    <div className={tabs.blockText}>{offer.stapleLocation.value}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={tabs.blocksContainer}>
        <div className={tabs.blocksRow}>
          <div className={tabs.block}>
            <h3 className={tabs.blockTitle}>{t`PanelOffer.context.title`}</h3>
            <div className={tabs.blockText}>
              <ReactMarkdown source={offer.offerTalentView.context} />
            </div>
          </div>
        </div>
        <div className={tabs.blocksRow}>
          <div className={tabs.block}>
            <h3 className={tabs.blockTitle}>{t`PanelOffer.description.title`}</h3>
            <div className={tabs.blockText}>
              <ReactMarkdown source={offer.offerTalentView.description} />
            </div>
          </div>
        </div>
        <div className={cx(tabs.blocksRow, tabs.blocksMobileCard)}>
          <div className={tabs.block}>
            <h3 className={tabs.blockTitle}>{t`PanelOffer.expectedProfile.title`}</h3>
            <div className={tabs.blockText}>
              <ReactMarkdown source={offer.offerTalentView.expectedProfile} />
            </div>
          </div>
          <div className={tabs.block}>
            <h3 className={tabs.blockTitle}>{t`PanelOffer.requirements.title`}</h3>
            <div className={tabs.blockText}>
              <ReactMarkdown source={offer.offerTalentView.requirements} />
            </div>
          </div>
          <div className={tabs.block}>
            <h3 className={tabs.blockTitle}>{t`PanelOffer.howToShine.title`}</h3>
            <div className={tabs.blockText}>
              <ReactMarkdown source={offer.offerTalentView.howToShine} />
            </div>
          </div>
        </div>
      </div>
      <div className={cx(tabs.blocksContainer, tabs.darkBgBlocksContainer)}>
        <div className={tabs.blocksRow}>
          <div className={cx(tabs.block, tabs.noBefore)}>
            <h3 className={tabs.blockTitle}>{t`PanelOffer.toLearn.title`}</h3>
            <div className={tabs.blockText}>
              <ReactMarkdown source={offer.offerTalentView.toLearn} />
            </div>
          </div>
        </div>
      </div>
      {offer.document && !Array.isArray(offer.document) && offer.document.url ? (
        <div
          className={cx(tabs.companyVersionBlocksContainer, tabs.noBgBlocksContainer, tabs.blocksContainer)}
          data-testid="companyVersion"
        >
          <a
            onClick={(): void =>
              gtagService.event("click", {
                event_label: "offre - telecharger la version de notre partenaire",
              })
            }
            data-testid="companyVersion-cta"
            href={offer.document.url}
            download
            target="_blank"
            rel="noopener noreferrer"
            className={tabs.companyVersionLink}
          >
            {t`PanelOffer.download.label`}
          </a>
        </div>
      ) : null}
      {lastAcceptedApplications && lastAcceptedApplications.length ? (
        <div
          className={cx(tabs.noBgBlocksContainer, tabs.blocksContainer)}
          data-testid="lastAcceptedApplications-block"
        >
          <div className={tabs.blocksRow}>
            <div className={tabs.block}>
              <h3 className={tabs.blockTitle}>{t`PanelOffer.placedTalents.title`}</h3>
              <ul className={tabs.lastAcceptedList}>
                {lastAcceptedApplications.map((lastApp, lastAppI) => {
                  if (lastAppI >= 3) return null;
                  return (
                    <li key={`last-accepted-app-${lastApp["@id"]}`} data-testid="lastAcceptedApplications-item">
                      <MiniTalentCard talent={lastApp.talent} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
