import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Formik, Form } from "formik";
import React, { FC, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { EditTalentOnly } from "../../actionTypes/talent";
import { State } from "../../interfaces/api/state";
import { Violation } from "../../interfaces/api/violation";
import { Talent } from "../../interfaces/resources/talent";
import { editTalentOnlyAction } from "../../services/api/talent/actions";
import globalStyle from "../../styles/global.module.scss";
import { useApiSelector, usePrevious } from "../../utils/hooks";
import { Input } from "../Utils/Form/Input";
import { PhoneInput } from "../Utils/Form/PhoneInput";
import Yup from "../Utils/Yup";
import styles from "./index.module.scss";

interface Props {
  closeModal: () => void;
}

export const EditProfileModal: FC<Props> = ({ closeModal }: Props) => {
  const { apiErrors, apiSuccess } = useApiSelector();
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const dispatch = useDispatch();
  const [error, setError] = useState<boolean | string>(false);
  const [errors, setErrors] = useState<Violation[]>([]);
  const [success, setSuccess] = useState(false);
  const prevApiSuccess = usePrevious(apiSuccess);
  const prevApiErrors = usePrevious(apiErrors);
  const editTalentOnly = (talent: Partial<Talent>) => dispatch(editTalentOnlyAction(talent));
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    phone: Yup.string().required(),
  });
  const initialValues = {
    "@id": currentTalent["@id"],
    email: currentTalent.email || "",
    firstname: currentTalent.firstname || "",
    lastname: currentTalent.lastname || "",
    phone: currentTalent.phone || "",
  };

  const onSubmit = (values: Partial<Talent>) => {
    editTalentOnly(values);
  };

  useEffect(() => {
    if (
      prevApiSuccess
      && apiSuccess
      && prevApiSuccess.length !== apiSuccess.length
      && apiSuccess.some((e) => EditTalentOnly.SUCCESS === e.type)
    ) {
      setError(false);
      setErrors([]);
      setSuccess(true);
      setTimeout(closeModal, 500);
    }
  }, [apiSuccess, prevApiSuccess, closeModal, setError, setErrors, setSuccess]);

  useEffect(() => {
    if (
      prevApiErrors
      && apiErrors
      && prevApiErrors.length !== apiErrors.length
      && apiErrors.some((e) => EditTalentOnly.FAILURE === e.type)
    ) {
      const apiError = apiErrors.find((e) => EditTalentOnly.FAILURE === e.type);
      if (apiError) {
        setErrors(apiError.payload.violations || []);
        setError(apiError.payload.message);
        setSuccess(false);
      }
    }
  }, [apiErrors, prevApiErrors, setError, setErrors, setSuccess]);

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h1 className={globalStyle.mainTitle}>
            <ReactMarkdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`EditProfileModal.title`}</ReactMarkdown>
          </h1>
        </div>
        <Formik onSubmit={onSubmit} validationSchema={validationSchema} initialValues={initialValues}>
          {({ isSubmitting }): JSX.Element => (
            <>
              <Form className="form">
                {success || error ? (
                  <div className={styles.messagesContainer}>
                    {success ? (
                      <div className={cx(styles.message, styles.successMessage)}>
                        <h4>{t`EditProfileModal.success.header`}</h4>
                        <p>{t`EditProfileModal.success.content`}</p>
                      </div>
                    ) : null}
                    {error ? (
                      <div className={cx(styles.message, styles.errorMessage)}>
                        <h4>{t`EditProfileModal.error.header`}</h4>
                        <p>{t`EditProfileModal.error.content`}</p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="formGroup formInlineGroup double">
                  <Field
                    name="firstname"
                    component={Input}
                    label={t`EditProfileModal.firstname.label`}
                    violation={errors}
                  />
                  <Field
                    name="lastname"
                    component={Input}
                    label={t`EditProfileModal.lastname.label`}
                    violation={errors}
                  />
                </div>
                <div className="formGroup formInlineGroup double">
                  <Field name="email" component={Input} label={t`EditProfileModal.email.label`} violation={errors} />
                  <Field
                    name="phone"
                    component={PhoneInput}
                    label={t`EditProfileModal.phone.label`}
                    violation={errors}
                  />
                </div>

                <div className={styles.formBtnContainer}>
                  <div>
                    <button
                      type="button"
                      className={cx(globalStyle.cta, globalStyle.darkCta, globalStyle.inverted, globalStyle.rectangle)}
                      onClick={closeModal}
                    >
                      {t`EditProfileModal.fields.cancel`}
                    </button>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={cx(globalStyle.cta, globalStyle.primaryCta)}
                    >
                      {t`EditProfileModal.submit`}
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};
