import { AnyAction } from "redux";

import { ApiAuthenticationLogin, ApiAuthenticationLogout } from "../../../actionTypes/authentication";
import { GetCurrentTalent } from "../../../actionTypes/talent";

import { Auth } from "../../../interfaces/api/authentication";

import { cookies, JWT_TOKEN_KEY } from "../helpers";

const defaultAuthState: Auth = {
  isAuthed: cookies.get(JWT_TOKEN_KEY) !== undefined,
};

export function auth(state: Auth = defaultAuthState, action: AnyAction) {
  if (state.currentTalent === null && action.type === GetCurrentTalent.SUCCESS) {
    return {
      isAuthed: false,
    };
  }
  switch (action.type) {
    case ApiAuthenticationLogin.SUCCESS:
      return {
        isAuthed: true,
      };
    case ApiAuthenticationLogin.FAILURE:
    case ApiAuthenticationLogout.SUCCESS:
      return {
        isAuthed: false,
      };
    case ApiAuthenticationLogout.FAILURE:
    default:
      return state;
  }
}
