import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../../utils/hooks";
import ErrorMessage from "../ErrorMessage";

export interface TextareaProps extends FieldProps {
  type?: string;
  label?: string;
  extraText?: string;
  className: string;
  violations?: Violation[];
}
export const Textarea = ({ field, label, extraText, className, violations, ...rest }: TextareaProps): JSX.Element => {
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);

  return (
    <>
      {label ? <label className="formLabel">{label}</label> : null}
      <textarea {...field} {...(rest as any)} className={cx({ [className]: !!className })} />
      {extraText ? <small className="formAdditionalInfos">{extraText}</small> : null}
      {meta.touched && !!meta.error ? <ErrorMessage name={field.name} message={error} /> : null}
    </>
  );
};
