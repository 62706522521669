import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { FaLaptopCode, FaLinkedinIn } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import ReactMarkdown from "react-markdown";

import { useSelector } from "react-redux";
import { CreateLinkedinResumeItem, EditLinkedinItem, GetLinkedinResume } from "../../../../actionTypes/linkedinResume";
import { CreateLinkedinResume } from "../../../../actionTypes/talent";
import { Loader } from "../../../../components/Loader";
import { State } from "../../../../interfaces/api/state";
import { LinkedinResume } from "../../../../interfaces/resources/linkedinResume";

import globalStyle from "../../../../styles/global.module.scss";
import { useApiSelector, usePrevious } from "../../../../utils/hooks";

import quizStyle from "../../index.module.scss";
import styles from "./index.module.scss";
import { Instruction } from "./Instruction";
import { LinkedinResumeInfos } from "./LinkedinResumeInfos";

export const PartLinkedin: React.FC = () => {
  const { apiSuccess, apiErrors, apiPendingRequests } = useApiSelector();
  const linkedinResume = useSelector((state: State) => state.linkedinResume);
  const [error, setError] = React.useState<boolean | string>(false);
  const [success, setSuccess] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [localStateLinkedinResume, setLocalStateLinkedinResume] = React.useState<LinkedinResume | null>(null);
  const [showMethod, setShowMethod] = React.useState(true);
  const [importMode, setImportMode] = React.useState<"linkedin" | "manual" | null>(null);
  const prevApiSuccess = usePrevious(apiSuccess);
  const prevApiErrors = usePrevious(apiErrors);
  const erroressageTimeoutRef = React.useRef<any>(null);
  const successMessageTimeoutRef = React.useRef<any>(null);

  React.useEffect(() => {
    const checkApiSuccessForTypes = (successRequest: any, types: any) =>
      successRequest.some((e: any) => types.includes(e.type));
    const hasSuccessForTypes = (types: string[]) => checkApiSuccessForTypes(apiSuccess, types);
    const hasPendingRequestForType = (type: string) => apiPendingRequests.some((e) => e.type === type);

    if (linkedinResume) {
      setLocalStateLinkedinResume(linkedinResume);
      setShowMethod(false);
    }

    if (hasPendingRequestForType(GetLinkedinResume.REQUEST)) {
      setLoader(true);
    }

    if (hasSuccessForTypes([GetLinkedinResume.SUCCESS])) {
      setLoader(false);
    }
  }, [linkedinResume, apiPendingRequests, apiSuccess]);

  React.useEffect(() => {
    if (
      prevApiSuccess
      && apiSuccess
      && prevApiSuccess.length !== apiSuccess.length
      && (apiSuccess.some((e) => EditLinkedinItem.SUCCESS === e.type)
        || apiSuccess.some((e) => CreateLinkedinResumeItem.SUCCESS === e.type)
        || apiSuccess.some((e) => CreateLinkedinResume.SUCCESS === e.type))
    ) {
      setError(false);
      setSuccess(true);
    }
    successMessageTimeoutRef.current = setTimeout(() => setSuccess(false), 2000);
    return () => {
      clearTimeout(successMessageTimeoutRef.current);
    };
  }, [apiSuccess, prevApiSuccess, setError, setSuccess]);

  React.useEffect(() => {
    const apiError =
      apiErrors.find((e) => EditLinkedinItem.FAILURE === e.type)
      || apiErrors.find((e) => CreateLinkedinResume.FAILURE === e.type);

    if (prevApiErrors && apiErrors && prevApiErrors.length !== apiErrors.length && !!apiError) {
      setError(apiError.payload.message);
      setImportMode(null);
      setSuccess(false);
    }
    erroressageTimeoutRef.current = setTimeout(() => setError(false), 5000);
    return () => {
      clearTimeout(erroressageTimeoutRef.current);
    };
  }, [apiErrors, prevApiErrors, setError, setSuccess]);

  return (
    <div data-heap="question-linkedIn">
      {success || error ? (
        <div className={styles.messagesContainer}>
          {success ? (
            <div className={cx(styles.message, styles.successMessage)}>
              <ReactMarkdown className={styles.headerMessage} source={t`EditProfileModal.success.header`} />
              <ReactMarkdown source={t`EditProfileModal.success.content`} />
            </div>
          ) : null}
          {error ? (
            <div className={cx(styles.message, styles.errorMessage)}>
              <ReactMarkdown className={styles.headerMessage} source={t`EditProfileModal.error.header`} />
              <ReactMarkdown source={t`EditProfileModal.error.content`} />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={quizStyle.questionHeader}>
        <h1 className={cx(globalStyle.mainTitle, quizStyle.mainTitle)}>
          <ReactMarkdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`questions.linkedIn.label`}</ReactMarkdown>
        </h1>
        <p className={quizStyle.textHeader}>
          <ReactMarkdown
            disallowedTypes={["paragraph"]}
            unwrapDisallowed
          >{t`questions.linkedIn.explanation`}</ReactMarkdown>
        </p>
      </div>
      <div>
        {loader ? (
          <Loader />
        ) : (
          <div className={styles.pageBody}>
            <div className={styles.importOptionsWrapper}>
              <div className={styles.titleContainer}>
                <div className={styles.flexContainer}>
                  <h6 className={cx(styles.title, { [styles.titleClicked]: !showMethod })}>
                    Quelle méthode souhaitez-vous utiliser ?
                  </h6>
                  <div
                    onClick={() => setShowMethod(true)}
                    className={cx(styles.hideOptionsButton, { [styles.ButtonVisible]: !showMethod })}
                  >
                    <IoIosArrowDown />
                  </div>
                </div>
                <div
                  className={cx(styles.importOptionsContainer, styles.showMethod, { [styles.hideMethod]: !showMethod })}
                >
                  <div className={styles.additionalInfo}>
                    <span role="img" aria-label="attention">
                      ⚠️
                    </span>{" "}
                    Vous pourrez ajouter/modifier/supprimer les informations si besoin{" "}
                    <span role="img" aria-label="attention">
                      ⚠️
                    </span>
                  </div>
                  <div
                    className={cx(styles.importOption, { [styles.active]: importMode === "linkedin" })}
                    onClick={() => {
                      setImportMode("linkedin");
                      setShowMethod(false);
                    }}
                  >
                    <div>
                      <FaLinkedinIn />
                    </div>
                    <div className={styles.optionText}>
                      <div>Import automatique depuis LinkedIn</div>
                      <div>Récupérez vos experiences, formations et langues</div>
                    </div>
                  </div>
                  <div
                    className={cx(styles.importOption, { [styles.active]: importMode === "manual" })}
                    onClick={() => {
                      setImportMode("manual");
                      setShowMethod(false);
                    }}
                  >
                    <div>
                      <FaLaptopCode />
                    </div>
                    <div className={styles.optionText}>
                      <div>Ajout manuel de votre parcours</div>
                      <div>Construisez votre parcours professionnel en 2min</div>
                    </div>
                  </div>
                </div>
              </div>
              {importMode !== null ? (
                <div className={styles.container}>
                  <Instruction importMode={importMode} localStateLinkedinResume={localStateLinkedinResume} />
                </div>
              ) : null}
              {!!localStateLinkedinResume ? (
                <div className={styles.container}>
                  <LinkedinResumeInfos />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
