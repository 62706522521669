import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetCalendlyEvents } from "../../../actionTypes/calendlyEvents";
import { CalendlyEvent } from "../../../interfaces/resources/calendlyEvent";
import { apiFailure } from "../helpers";
import { getCalendlyEventsActionSuccess } from "./actions";
import * as apiClient from "./api";

export function* getCalendlyEvents(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": CalendlyEvent[];
    }> = yield call(apiClient.getCalendlyEvents, action.talent);
    yield put(getCalendlyEventsActionSuccess(response.data["hydra:member"]));
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* calendlyEventSaga() {
  yield takeLatest(GetCalendlyEvents.REQUEST, getCalendlyEvents);
}
