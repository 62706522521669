import { AxiosResponse } from "axios";
import { CompanyEvent } from "../../../../interfaces/resources/companyEvent";
import client from "../../client";

const formData = (companyEvent: CompanyEvent): { company: string; status: string; talent: string } => ({
  company: companyEvent.company && companyEvent.company["@id"],
  status: companyEvent.status,
  talent: companyEvent.talent && companyEvent.talent["@id"],
});
export function createCompanyEvent(companyEvent: CompanyEvent): Promise<AxiosResponse<any>> {
  return client.post("/api/v2/company_events", formData(companyEvent));
}
