import { PostAnswer } from "../../../actionTypes/quiz";
import {
  CreateTalentProfileResponsibility,
  DeleteTalentProfileResponsibility,
  EditTalent,
  EditTalentOnly,
  EditTalentSetting,
  GetCurrentTalent,
  GetLinkedInProfile,
  GetQuizAnswers,
  GetTalentProfile,
  GetTalentSetting,
} from "../../../actionTypes/talent";
import { Answer } from "../../../interfaces/resources/answer";
import { TalentProfileResponsibility } from "../../../interfaces/resources/responsibility";
import { Talent, TalentProfile, TalentSetting, LinkedInProfile } from "../../../interfaces/resources/talent";

export function currentTalent(state: Talent | null = null, action: any) {
  switch (action.type) {
    case GetCurrentTalent.SUCCESS:
      return action.currentTalent;
    case EditTalent.SUCCESS:
    case EditTalentOnly.SUCCESS:
      return action.talent;
    default:
      return state;
  }
}

export function talentProfile(state: TalentProfile | null = null, action: any) {
  switch (action.type) {
    case GetTalentProfile.SUCCESS:
    case EditTalent.SUCCESS:
      return action.talentProfile;
    case CreateTalentProfileResponsibility.SUCCESS:
      return state
        ? { ...state, responsibilities: [...state?.responsibilities, action.talentProfileResponsibility] }
        : null;
    case DeleteTalentProfileResponsibility.SUCCESS:
      return state?.responsibilities
        ? {
            ...state,
            responsibilities: state.responsibilities.filter(
              (e: TalentProfileResponsibility) => e["@id"] !== action.talentProfileResponsibility,
            ),
          }
        : state;
    default:
      return state;
  }
}

export function linkedInProfile(state: LinkedInProfile | null = null, action: any) {
  switch (action.type) {
    case GetLinkedInProfile.SUCCESS:
      return action.linkedInProfile;
    default:
      return state;
  }
}

export function talentSetting(state: TalentSetting | null = null, action: any) {
  switch (action.type) {
    case GetTalentSetting.SUCCESS:
    case EditTalentSetting.SUCCESS:
      return action.talentSetting;
    default:
      return state;
  }
}

export function quizAnswers(state: Answer[] = [], action: any) {
  switch (action.type) {
    case PostAnswer.SUCCESS:
      state.splice(
        state.findIndex((e: Answer) => action.answer["@id"] === e["@id"]),
        1,
        action.answer,
      );
      return [...state];
    case GetQuizAnswers.SUCCESS:
      return [...state, ...action.answers];
    default:
      return state;
  }
}
