import { t } from "@lingui/macro";

interface Const {
  status: { [key: string]: string };
}

const transApplication: Const = {
  status: {
    accepted: t`application.status.accepted`,
    applied: t`application.status.applied`,
    awaiting_shortlist: t`application.status.awaiting_shortlist`,
    call_booked: t`application.status.call_booked`,
    interviews: t`application.status.interviews`,
    offer: t`application.status.interviews`,
    pending: t`application.status.pending`,
    refused_elinoi: t`application.status.refused_elinoi`,
    refused_startup: t`application.status.refused_startup`,
    refused_talent: t`application.status.refused_talent`,
    shortlist_ready: t`application.status.shortlist_ready`,
    shortlisted: t`application.status.shortlisted`,
    to_pitch: t`application.status.to_pitch`,
  },
};

export default transApplication;
