import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetSectors } from "../../../actionTypes/sectors";
import { Sector } from "../../../interfaces/resources/sector";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri } from "../helpers";
import { getSectorsAction, getSectorsSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getSectors(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": Sector[];
      "hydra:totalItems": number;
      "hydra:view"?: {
        "hydra:next"?: string;
      };
    }> = yield call(apiClient.getSectors, action.page);
    yield put(getSectorsSuccessAction(response.data["hydra:member"], action));
    if (response.data["hydra:view"] && response.data["hydra:view"]["hydra:next"]) {
      yield put(getSectorsAction(extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"])));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* sectorSaga() {
  yield takeLatest(GetSectors.REQUEST, getSectors);
}
