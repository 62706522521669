import { t } from "@lingui/macro";

interface IConst {
  labels: { [key: string]: string };
}

const transCriterion: IConst = {
  labels: {
    complex_sales: t`criterion.labels.complex_sales`,
    coordination: t`criterion.labels.coordination`,
    creative: t`criterion.labels.creative`,
    data: t`criterion.labels.data`,
    entrepreneurship: t`criterion.labels.entrepreneurship`,
    management: t`criterion.labels.management`,
    mission_scope: t`criterion.labels.mission_scope`,
    organisation: t`criterion.labels.organisation`,
    process_setup: t`criterion.labels.process_setup`,
    product_complexity: t`criterion.labels.product_complexity`,
    risk_factor: t`criterion.labels.risk_factor`,
    sales_academy: t`criterion.labels.sales_academy`,
    social_skills: t`criterion.labels.social_skills`,
    strategy: t`criterion.labels.strategy`,
    team_size: t`criterion.labels.team_size`,
    thinker_doer: t`criterion.labels.thinker_doer`,
  },
};

export default transCriterion;
