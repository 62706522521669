import { t } from "@lingui/macro";
import cx from "classnames";
import React from "react";
import { event as trackAction } from "react-ga";

import { useSelector } from "react-redux";
import avatar from "../../images/defaults/avatar.png";
import { State } from "../../interfaces/api/state";
import { Talent } from "../../interfaces/resources/talent";
import { PersonProfileImg } from "../personProfileImg";
import styles from "./index.module.scss";

interface Props {
  talent?: Talent;
  classNames?: string;
  src?: string;
}

export const MiniTalentCard: React.FC<Props> = (props: Props) => {
  const { talent, classNames = "", src = "" } = props;
  const stateTalent = useSelector((state: State) => state.currentTalent);
  const currTalent: Talent = talent || stateTalent;
  const talentImgSrc = src ? src : currTalent ? currTalent.image.url : avatar;

  return (
    <div className={cx(styles.miniTalentCardContainer, classNames)}>
      <div className={styles.miniTalentCard}>
        <div className={styles.imgContainer}>
          <PersonProfileImg src={talentImgSrc} hasOverlay={false} alt={currTalent.firstname} />
        </div>
        <div className={styles.textContainer}>
          <h4 className={styles.name}>{`${currTalent.firstname} ${currTalent.lastname}`}</h4>
          <a
            className={styles.linkedinLink}
            href={currTalent.linkedInProfile.url}
            target="_blank"
            onClick={() => {
              trackAction({
                action: "click",
                category: "talent",
                label: `offre - linkedin - ${currTalent.firstname} ${currTalent.lastname}`,
              });
            }}
            rel="noopener noreferrer"
          >
            {t`miniTalentCard.see.linkedin`}
          </a>
        </div>
      </div>
    </div>
  );
};
