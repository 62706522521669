import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetCompanies, GetCompany } from "../../../actionTypes/companies";
import { apiFailure } from "../helpers";
import * as apiClient from "./api";

export function* getCompany(action: AnyAction) {
  try {
    const response = yield call(apiClient.getCompany, action.slug);
    if (response.data.hasOwnProperty("hydra:member") && response.data["hydra:member"].length > 0) {
      const company = response.data["hydra:member"][0];
      yield put({
        company,
        type: GetCompany.SUCCESS,
      });
    } else {
      yield put({
        type: GetCompany.FAILURE,
      });
    }
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* getCompanies(action: AnyAction) {
  try {
    const response = yield call(apiClient.getCompanies, action.params);
    const companies = response.data["hydra:member"];
    yield put({
      companies,
      type: GetCompanies.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* companySaga() {
  yield takeLatest(GetCompany.REQUEST, getCompany);
  yield takeLatest(GetCompanies.REQUEST, getCompanies);
}
