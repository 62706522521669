import { GetDesiredPlaces } from "../../../actionTypes/desiredPlaces";

import { DesiredPlace } from "../../../interfaces/resources/desiredPlace";

export function desiredPlaces(state: DesiredPlace[] = [], action: any): DesiredPlace[] {
  switch (action.type) {
    case GetDesiredPlaces.SUCCESS:
      return !!action.page ? [...state, ...action.desiredPlaces] : action.desiredPlaces;
    default:
      return state;
  }
}
