import React from "react";
import { OfferCard } from "../../../../blocks/offerCard";
import { Offer } from "../../../../interfaces/resources/offer";

import tabs from "../index.module.scss";

interface Props {
  offers: Offer[];
}

export const PanelOffers: React.FC<Props> = (props: Props) => {
  const { offers } = props;

  return (
    <>
      <div className={tabs.tabPanelContainer}>
        <ul className={tabs.offerCardsList}>
          {offers.map((o, i) => {
            return (
              <li key={`companyPage-offerCard-${o["@id"]}-${i}`}>
                <OfferCard offer={o} />{" "}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
