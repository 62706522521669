import { GetSchools } from "../../../actionTypes/school";
import { School } from "../../../interfaces/resources/school";

export function schools(state: School[] = [], action: any) {
  switch (action.type) {
    case GetSchools.SUCCESS:
      return action.schools;
    default:
      return state;
  }
}
