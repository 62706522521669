import { GetLastQuestion } from "../../../actionTypes/quiz";
import {
  CreateLinkedinResume,
  CreateTalentProfileResponsibility,
  DeleteTalentProfileResponsibility,
  EditLinkedinResume,
  EditTalent,
  EditTalentOnly,
  EditTalentProfileResponsibility,
  EditTalentSetting,
  GetCurrentTalent,
  GetLinkedInProfile,
  GetQuizAnswers,
  GetTalentProfile,
  GetTalentSetting,
  SendMessage,
} from "../../../actionTypes/talent";
import { LinkedinResume } from "../../../interfaces/resources/linkedinResume";
import { ResponsibilityWithSeniority, TalentProfileResponsibility } from "../../../interfaces/resources/responsibility";
import { Talent, TalentSetting, EditTalentProps } from "../../../interfaces/resources/talent";

export function editTalentAction(talent: EditTalentProps) {
  return {
    talent,
    type: EditTalent.REQUEST,
  };
}

export function editTalentOnlyAction(talent: Partial<Talent>) {
  return {
    talent,
    type: EditTalentOnly.REQUEST,
  };
}

export function getCurrentTalent() {
  return {
    type: GetCurrentTalent.REQUEST,
  };
}

export function getTalentProfileAction(currentTalent: Talent) {
  return {
    talent: currentTalent,
    type: GetTalentProfile.REQUEST,
  };
}

export function getTalentSetting(currentTalent: Talent) {
  return {
    talent: currentTalent,
    type: GetTalentSetting.REQUEST,
  };
}

export function getLinkedInProfileAction(currentTalent: Talent) {
  return {
    talent: currentTalent,
    type: GetLinkedInProfile.REQUEST,
  };
}

export function getQuizAnswersAction(currentTalent: Talent) {
  return {
    talent: currentTalent,
    type: GetQuizAnswers.REQUEST,
  };
}

export function editTalentSetting(talentSetting: Partial<TalentSetting>) {
  return {
    talentSetting,
    type: EditTalentSetting.REQUEST,
  };
}

export function sendMessage(currentTalent: Talent, content: string) {
  return {
    content,
    currentTalent,
    type: SendMessage.REQUEST,
  };
}

export function getLastQuestionAction(talent: Talent) {
  return {
    talent,
    type: GetLastQuestion.REQUEST,
  };
}

export function createLinkedinResumeAction(iri: string, file: string) {
  return {
    iri,
    file,
    type: CreateLinkedinResume.REQUEST,
  };
}

export function editLinkedinResume(values: string, linkedinResumeIri: string) {
  return {
    linkedinResumeIri,
    url: values,
    type: EditLinkedinResume.REQUEST,
  };
}

export function createLinkedinResumeSuccessAction(linkedinResume: LinkedinResume) {
  return {
    linkedinResume,
    type: CreateLinkedinResume.SUCCESS,
  };
}

export function createTalentProfileResponsibilityAction(
  responsibility: Partial<ResponsibilityWithSeniority>,
  talentProfile: string,
) {
  return {
    responsibility,
    talentProfile,
    type: CreateTalentProfileResponsibility.REQUEST,
  };
}

export function createTalentProfileResponsibilitySuccessAction(
  talentProfileResponsibility: TalentProfileResponsibility,
) {
  return {
    talentProfileResponsibility,
    type: CreateTalentProfileResponsibility.SUCCESS,
  };
}

export function deleteTalentProfileResponsibilityAction(responsibility: Partial<TalentProfileResponsibility>) {
  return {
    responsibility,
    type: DeleteTalentProfileResponsibility.REQUEST,
  };
}

export function deleteTalentProfileResponsibilitySuccessAction(talentProfileResponsibility: string) {
  return {
    talentProfileResponsibility,
    type: DeleteTalentProfileResponsibility.SUCCESS,
  };
}

export function editTalentProfileResponsibilityAction(responsibility: Partial<TalentProfileResponsibility>) {
  return {
    responsibility,
    type: EditTalentProfileResponsibility.REQUEST,
  };
}

export function editTalentProfileResponsibilitySuccessAction(responsibility: TalentProfileResponsibility) {
  return {
    responsibility,
    type: EditTalentProfileResponsibility.SUCCESS,
  };
}
