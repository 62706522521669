import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import Helmet from "react-helmet";
import { FiMenu, FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { GetDesiredPlaces } from "../../actionTypes/desiredPlaces";
import { GetResponsibilities } from "../../actionTypes/responsibility";
import { PageWithTabs, TabPanel } from "../../components/Tabs";
import logo from "../../images/logos/elinoi-logo-label-aqua.svg";
import { State } from "../../interfaces/api/state";
import { Answer } from "../../interfaces/resources/answer";
import { QuizStepSlug } from "../../interfaces/resources/quiz";
import { quizHashes } from "../../routes";
import { logoutAction } from "../../services/api/authentication/actions";
import { getDesiredPlacesAction } from "../../services/api/desiredPlaces/actions";
import { getLinkedinResumeAction } from "../../services/api/linkedinResume/actions";
import { postAnswerAction, putCurrentQuestionAction } from "../../services/api/quiz/actions";
import { getResponsibilitiesAction } from "../../services/api/responsibility/actions";
import { getQuizAnswersAction } from "../../services/api/talent/actions";
import { useApiSelector, useGetResourceHook, usePrevious } from "../../utils/hooks";

import quizStyle from "./index.module.scss";
import { PartCareer } from "./Questions/PartCareer";
import { PartCompleted } from "./Questions/PartCompleted";
import { PartGeneral } from "./Questions/PartGeneral";
import { PartLinkedin } from "./Questions/PartLinkedin";
import { PartLocation } from "./Questions/PartLocation";
import { PartSkills } from "./Questions/PartSkills";
import { PartSource } from "./Questions/PartSource";
import { PartWorkEnvironment } from "./Questions/PartWorkEnvironment";
interface TabProps {
  slug: string;
  eventName?: string;
  component: JSX.Element;
}
interface ContextValue {
  handleSubmit: (data: any) => void;
  handlePrevious: (slug: QuizStepSlug) => void;
  getAnswer: (slug: QuizStepSlug) => Answer | undefined;
}

export const quizContext = React.createContext<ContextValue | null>(null);

export const QuizPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [initLoading, setInitLoading] = React.useState(true);
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const quizSlugs = React.useRef(Object.keys(quizHashes) as QuizStepSlug[]).current;
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const desiredPlaces = useSelector((state: State) => state.desiredPlaces);
  const quizAnswers = useSelector((state: State) => state.quizAnswers);
  const currentQuestion = useSelector((state: State) => state.currentQuestion);
  const previousCQ = usePrevious(currentQuestion);
  const nextQuestion = useSelector((state: State) => state.nextQuestion);
  const previousNQ = usePrevious(nextQuestion);
  const responsibilities = useSelector((state: State) => state.responsibilities);
  const currProgress = React.useRef<number>(0);
  const arcLength = React.useRef<number>(2 * 3.14 * 34);
  const [progress, setProgress] = React.useState<number>(0);
  const [arcOffset, setArcOffset] = React.useState<number>(arcLength.current);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState<string | null>(null);
  const [tabs] = React.useState<TabProps[]>([
    {
      slug: quizHashes.general,
      component: <PartGeneral />,
    },
    {
      slug: quizHashes.career,
      component: <PartCareer />,
    },
    {
      slug: quizHashes.skills,
      component: <PartSkills />,
    },
    {
      slug: quizHashes.linkedin,
      component: <PartLinkedin />,
    },
    {
      slug: quizHashes.location,
      component: <PartLocation />,
    },
    {
      slug: quizHashes.workEnvironment,
      component: <PartWorkEnvironment />,
    },
    {
      slug: quizHashes.source,
      component: <PartSource />,
    },
    {
      slug: quizHashes.completed,
      component: <PartCompleted />,
    },
  ]);
  const logout = (): { type: string } => dispatch(logoutAction());
  const getResponsibilities = (): void => {
    dispatch(getResponsibilitiesAction());
  };
  const getDesiredPlaces = (): void => {
    dispatch(getDesiredPlacesAction());
  };
  const addHashToUrl = React.useCallback(
    (h: string): void => {
      if (location.hash !== h) {
        history.push(h);
      }
    },
    [history, location.hash],
  );
  const getAnswer = (slug: QuizStepSlug) => {
    return quizAnswers.find((a) => a.value === slug);
  };

  // [Navigation] Au success de postAnswer que l'on demande a l'API la prochaine question à afficher
  const handleSubmit = (data: any) => {
    const existingAnswerIri = getAnswer(data.value)?.["@id"];
    dispatch(postAnswerAction({ ...data, existingAnswerIri }));
  };

  // [Navigation] On dit à l'API que l'on souhaite afficher la question précédente
  const handlePrevious = (slug: QuizStepSlug) => {
    let index = quizSlugs.indexOf(slug) - 1;
    if (index < 0) {
      index = 0;
    }

    dispatch(putCurrentQuestionAction(quizSlugs[index]));
  };

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetResponsibilities,
    responsibilities,
    getResponsibilities,
  );

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetDesiredPlaces, desiredPlaces, getDesiredPlaces);

  React.useEffect(() => {
    if (currentTalent) {
      dispatch(getQuizAnswersAction(currentTalent));
      if (currentTalent.linkedinResume) {
        dispatch(getLinkedinResumeAction(currentTalent["@id"]));
      }
    }
  }, [currentTalent, dispatch]);

  // [Navigation] Cette fonction gère le changement de question en se basant sur
  // la valeur de currentQuestion et nextQuestion du talent.
  React.useEffect(() => {
    // (nextQuestion && nextQuestion !== previousNQ) = nextQuestion existe et n'est de la même valeur
    // (nextQuestion && nextQuestion !== previousNQ) = currentQuestion existe et n'est de la même valeur
    if ((nextQuestion && nextQuestion !== previousNQ) || (currentQuestion && currentQuestion !== previousCQ)) {
      // On n'utilise pas quizSlugs pour éviter des boucles infinies vu que ce n'est pas un useState
      const qSlugs = Object.keys(quizHashes) as QuizStepSlug[];
      const nQI = !!nextQuestion && qSlugs.indexOf(nextQuestion) ? qSlugs.indexOf(nextQuestion) : 0;
      const cQI = !!currentQuestion && qSlugs.indexOf(currentQuestion) ? qSlugs.indexOf(currentQuestion) : 0;
      // on regarde que la valeur de currentQuestion existe dans le tableau des questions et qu'elle n'est pas superieure à la dernière question accessible par le talent
      // si oui, on la montre, si non, on montre la denière question accéssible par le talent
      const questionToShow = cQI >= 0 && cQI <= nQI ? qSlugs[cQI] : qSlugs[nQI];

      addHashToUrl(quizHashes[questionToShow]);
      setActiveStep(quizHashes[questionToShow]);
    }
  }, [nextQuestion, previousNQ, currentQuestion, previousCQ, addHashToUrl]);

  // [Navigation] Cette fonction gère les actions trigger par le navigateur
  // (précédent / suivant / input direct dans l'url)
  React.useEffect(() => {
    const lHash = location.hash;
    // On n'utilise pas quizSlugs pour éviter des boucles infinies vu que ce n'est pas un useState
    const qSlugs = Object.keys(quizHashes) as QuizStepSlug[];
    const lSlug = qSlugs.find((key) => !!lHash && quizHashes[key] === lHash);
    const nQI = !!nextQuestion && qSlugs.indexOf(nextQuestion) ? qSlugs.indexOf(nextQuestion) : 0;
    const nSI = !!lSlug && qSlugs.indexOf(lSlug) ? qSlugs.indexOf(lSlug) : -1;

    // (!initLoading) = ce n'est pas premier chargement de la page
    // (lHash !== activeStep) = le Hash de l'url ne correspond pas a la question affichée
    // (lSlug) = le hash de l'url correspond a un slug de question existante
    // (nSI <= nQI) = dans l'ordre des question, la question de l'URL se trouve avant la dernière question accéssible par le talent
    if (!initLoading && lHash !== activeStep && lSlug && nSI <= nQI) {
      let index = qSlugs.indexOf(lSlug);
      if (index < 0) {
        index = 0;
      }
      setActiveStep(quizHashes[qSlugs[index]]);
      // On met à jour l'info dans la BDD
      dispatch(putCurrentQuestionAction(qSlugs[index]));
      return;
    }

    // Dans le cas de figure ou l'url demande une question inaccessble ou inexistante
    // on revoie sur la dernière question accessible par le talent
    if (lHash !== activeStep && (nSI > nQI || nSI < 0) && !initLoading) {
      setActiveStep(quizHashes[qSlugs[nQI]]);
      addHashToUrl(quizHashes[qSlugs[nQI]]);
      // On met à jour l'info dans la BDD
      dispatch(putCurrentQuestionAction(qSlugs[nQI]));
      return;
    }
  }, [location, activeStep, nextQuestion, initLoading, addHashToUrl, dispatch]);

  React.useEffect(() => {
    setInitLoading(false);
  }, []);

  React.useEffect(() => {
    if (nextQuestion) {
      // 34 = radius des cercle
      const progSteps = Math.round(100 / (quizSlugs.length - 1));
      const progLimit =
        quizSlugs.indexOf(nextQuestion) > -1
          ? progSteps * quizSlugs.indexOf(nextQuestion) > 100
            ? 100
            : progSteps * quizSlugs.indexOf(nextQuestion)
          : 0;
      const intervalId = setInterval(() => {
        currProgress.current++;
        setProgress(currProgress.current);
        setArcOffset(arcLength.current * ((100 - currProgress.current) / 100));
        if (currProgress.current >= progLimit) {
          clearInterval(intervalId);
          setIsCompleted(currProgress.current >= 100);
        }
      }, 50);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [nextQuestion, quizSlugs]);

  return !!!activeStep ? (
    <>loading</>
  ) : (
    <>
      <Helmet>
        <title>{t`QuizPage.title`}</title>
        <meta name="" content="" />
      </Helmet>
      <quizContext.Provider
        value={{
          handleSubmit,
          handlePrevious,
          getAnswer,
        }}
      >
        <div className={quizStyle.quizWrapper}>
          <PageWithTabs defaultActivePanel={activeStep}>
            <>
              <div>
                <div className={quizStyle.header}>
                  <div className={quizStyle.logoContainer}>
                    <img src={logo} alt="Élinoï" />
                  </div>
                  <div className={quizStyle.progressContainer}>
                    <svg className={quizStyle.progessSvg} viewBox="0 0 80 80">
                      <circle cx={40} cy={40} r={34} strokeWidth={4} className={quizStyle.progessTrack} />
                      <circle
                        cx={40}
                        cy={40}
                        r={34}
                        strokeWidth={6}
                        strokeDashoffset={arcOffset}
                        strokeDasharray={arcLength.current}
                        className={cx(quizStyle.progessIndicator, { [quizStyle.completed]: isCompleted })}
                      />
                    </svg>
                    <div className={quizStyle.progressNbContainer}>{progress}%</div>
                  </div>
                  <div className={quizStyle.menuContainer}>
                    <div
                      className={quizStyle.menuTrigger}
                      onClick={() => {
                        setIsMenuOpen(!isMenuOpen);
                      }}
                    >
                      {isMenuOpen ? <FiX /> : <FiMenu />}
                    </div>
                    <div className={cx(quizStyle.menuDropdown, { [quizStyle.menuOpen]: isMenuOpen })}>
                      <button
                        type="button"
                        className={quizStyle.logoutCta}
                        onClick={() => {
                          logout();
                        }}
                      >
                        {t`links.menu.logout`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={quizStyle.questionContainer}>
                {tabs.map((tab) => (
                  <TabPanel key={`quiz-step-panel--${tab.slug}`} panelKey={tab.slug}>
                    {tab.component}
                  </TabPanel>
                ))}
              </div>
            </>
          </PageWithTabs>
        </div>
      </quizContext.Provider>
    </>
  );
};
