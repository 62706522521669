import { call, put, takeLatest } from "redux-saga/effects";

import { GetResponsibilities } from "../../../actionTypes/responsibility";
import { apiFailure, getSuccessType } from "../helpers";
import * as apiClient from "./api";

export function* getResponsibilities(action: any) {
  try {
    const response = yield call(apiClient.getResponsibilities);
    yield put({
      responsibilities: response.data["hydra:member"],
      type: getSuccessType(action),
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* responsibilitySaga() {
  yield takeLatest(GetResponsibilities.REQUEST, getResponsibilities);
}
