import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  GetCurrentQuestion,
  GetNextQuestion,
  GetPreviousQuestion,
  GetQuizChoices,
  PostAnswer,
  PutCurrentQuestion,
} from "../../../actionTypes/quiz";
import { apiFailure, getSuccessType } from "../helpers";
import * as apiClient from "./api";

export function* getNextQuestion(action: AnyAction): any {
  try {
    const response = yield call(apiClient.getNextQuestion);
    yield put({
      question: response.data,
      type: GetNextQuestion.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response") || !e.response) {
      throw new Error(e.message);
    } else {
      yield put(apiFailure(e, action));
    }
  }
}

export function* getCurrentQuestion(action: AnyAction): any {
  try {
    const response = yield call(apiClient.getCurrentQuestion);
    yield put({
      question: response.data,
      type: GetCurrentQuestion.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response") || !e.response) {
      throw new Error(e.message);
    } else {
      yield put(apiFailure(e, action));
    }
  }
}

export function* putCurrentQuestion(action: AnyAction): any {
  try {
    const response = yield call(apiClient.putCurrentQuestion, action.questionSlug);
    yield put({
      question: response.data,
      type: PutCurrentQuestion.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response") || !e.response) {
      throw new Error(e.message);
    } else {
      yield put(apiFailure(e, action));
    }
  }
}

export function* getQuizChoices(action: any) {
  try {
    const response = yield call(apiClient.getQuizChoices);
    const choices = Array.isArray(response.data["hydra:member"]) ? response.data["hydra:member"] : [];
    yield put({
      choices,
      type: GetQuizChoices.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* getPreviousQuestion(action: AnyAction): any {
  try {
    const response = yield call(apiClient.getPreviousQuestion, action.question);
    yield put({
      answer: response.data,
      type: getSuccessType(action),
    });
  } catch (e) {
    if (!e.hasOwnProperty("response") || !e.response) {
      throw new Error(e.message);
    } else {
      yield put(apiFailure(e, action));
    }
  }
}

export function* postAnswer(action: AnyAction): any {
  try {
    const response = yield call(
      !!action.answer.existingAnswerIri ? apiClient.putAnswer : apiClient.postAnswer,
      action.answer,
    );
    yield put({
      answer: response.data,
      type: getSuccessType(action),
    });
    yield put({
      type: GetNextQuestion.REQUEST,
    });
    yield put({
      type: GetCurrentQuestion.REQUEST,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response") || !e.response) {
      throw new Error(e.message);
    } else {
      yield put(apiFailure(e, action));
    }
  }
}

export default function* authenticationSaga() {
  yield takeLatest(GetNextQuestion.REQUEST, getNextQuestion);
  yield takeLatest(GetCurrentQuestion.REQUEST, getCurrentQuestion);
  yield takeLatest(PutCurrentQuestion.REQUEST, putCurrentQuestion);
  yield takeLatest(GetPreviousQuestion.REQUEST, getPreviousQuestion);
  yield takeLatest(GetQuizChoices.REQUEST, getQuizChoices);
  yield takeLatest(PostAnswer.REQUEST, postAnswer);
}
