import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import * as React from "react";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { quizContext } from "../../..";
import { File } from "../../../../../components/Utils/Form/File";
import { Input } from "../../../../../components/Utils/Form/Input";
import Yup from "../../../../../components/Utils/Yup";
import linkedInStepDownload from "../../../../../images/linkedin-step-download.jpg";
import { State } from "../../../../../interfaces/api/state";
import { Violation } from "../../../../../interfaces/api/violation";
import { LinkedinResume } from "../../../../../interfaces/resources/linkedinResume";
import { QuizStepSlug } from "../../../../../interfaces/resources/quiz";
import { createManualLinkedinAction } from "../../../../../services/api/linkedinResume/actions";
import { createLinkedinResumeAction } from "../../../../../services/api/talent/actions";

import globalStyles from "../../../../../styles/global.module.scss";

import { usePrevious } from "../../../../../utils/hooks";
import styles from "./index.module.scss";

const QUIZ_STEP_SLUG: QuizStepSlug = "linkedin";
interface Props {
  importMode: "manual" | "linkedin";
  localStateLinkedinResume: LinkedinResume | null;
}

export const Instruction: React.FC<Props> = ({ importMode, localStateLinkedinResume }: Props) => {
  const dispatch = useDispatch();
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const prevImport = usePrevious(importMode);
  const UrlvalidationSchema = Yup.object().shape({
    url: Yup.string().linkedInProfileUrl(),
  });
  const validationSchema = Yup.object().shape({
    documentFile: Yup.string().required(t`quiz.validation.question.required`),
  });
  const context = React.useContext(quizContext);
  const answer = context?.getAnswer(QUIZ_STEP_SLUG);

  const [violations] = React.useState<Violation[]>([]);
  const initialValues = {
    documentFile: undefined,
  };

  const submitForm = (): void => {
    if (context === null) {
      return;
    }
    if (!!!localStateLinkedinResume) {
      dispatch(createManualLinkedinAction(currentTalent["@id"]));
    }
    setIsOpen(false);
  };

  const submitLinkedinResume = (values: any): void => {
    dispatch(createLinkedinResumeAction(currentTalent["@id"], values));
    setIsOpen(false);
  };
  React.useEffect(() => {
    if (prevImport !== importMode) {
      setIsOpen(true);
    }
  }, [prevImport, importMode, setIsOpen]);
  return (
    <div className={styles.instructionWrapper}>
      {importMode === "linkedin" ? (
        <>
          <div className={styles.titleContainer}>
            <h6 className={cx(styles.title, { [styles.titleClicked]: !isOpen })}>
              Instructions pour l&apos;import automatique
            </h6>
            <div>
              <div onClick={() => setIsOpen(!isOpen)} className={styles.ButtonVisible}>
                {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
          </div>
          {isOpen ? (
            <>
              <div>
                <ol className={styles.explicationsList}>
                  <li>
                    <div className={styles.explicationTitle}>Allez sur votre profil LinkedIn</div>
                    <div>
                      Rendez-vous sur{" "}
                      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        votre profil LinkedIn
                      </a>
                      , cliquez sur <strong>&quot;Vous&quot;</strong> puis <strong>&quot;Voir le profil&quot;</strong>
                    </div>
                    <div className={styles.mobileStep}>
                      (<strong>Sur mobile</strong>, cliquez ensuite sur les 3 points en haut à droite de votre page puis{" "}
                      <strong>&quot;Version pour ordinateur&quot;</strong>)
                    </div>
                  </li>
                  <li>
                    <div className={styles.explicationTitle}>Enregistrer votre profil au format PDF</div>
                    <div>
                      Ciquez sur <strong>&quot;Plus&quot;</strong>, puis sur{" "}
                      <strong>&quot;Enregistrer au format PDF&quot;</strong>
                    </div>
                    <div className={styles.linkedInImgContainer}>
                      <img src={linkedInStepDownload} alt="Enregistrer votre profil linkedIn au format PDF" />
                    </div>
                  </li>
                  <li>
                    <div className={styles.explicationTitle}>Importer votre PDF juste ici !</div>
                    <div>
                      On se charge de l&apos;analyser et vous pourrez modifier/ajouter des informations si vous le
                      souhaitez.
                    </div>
                  </li>
                </ol>
              </div>
              <div className={styles.linkedinFormContainer}>
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={submitLinkedinResume}
                >
                  {(): JSX.Element => (
                    <Form className={cx("form", styles.linkedinForm)}>
                      <div>
                        <Field
                          accept={{ "application/pdf": [] }}
                          component={File}
                          name="documentFile"
                          violations={violations}
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className={cx(globalStyles.cta, globalStyles.secondaryCta, globalStyles.smallerCta)}
                        >
                          Importer
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          ) : null}
        </>
      ) : importMode === "manual" ? (
        <>
          <div className={styles.titleContainer}>
            <h6 className={cx(styles.title, { [styles.titleClicked]: !isOpen })}>
              Instructions pour l&apos;ajout manuel
            </h6>
            <div>
              <div onClick={() => setIsOpen(!isOpen)} className={styles.ButtonVisible}>
                {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
          </div>
          {isOpen ? (
            <>
              <div>
                <ul className={styles.explicationsList}>
                  <li>
                    <div className={styles.explicationTitle}>Ajouter l&apos;URL de votre profil LinkedIn</div>
                    <div>
                      Rendez-vous sur{" "}
                      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        votre profil LinkedIn
                      </a>
                      , cliquez sur <strong>&quot;Vous&quot;</strong> puis <strong>&quot;Voir le profil&quot;</strong>{" "}
                      et copiez le lien.
                    </div>
                    <div className={styles.mobileStep}>
                      <div>
                        <strong>Site mobile</strong>, défilez jusqu&apos;à la section{" "}
                        <strong>&quot;Contacter&quot;</strong>
                      </div>
                      <div>
                        <strong>Application</strong>, Cliquez sur les 3 points et{" "}
                        <strong>&quot;Coordonnées&quot;</strong>
                      </div>
                    </div>
                    <div className={styles.urlForm}>
                      <Formik
                        initialValues={{ url: answer?.data?.url || localStateLinkedinResume?.url || "" }}
                        validationSchema={UrlvalidationSchema}
                        onSubmit={submitForm}
                      >
                        {(): JSX.Element => (
                          <>
                            <Form className="form">
                              <div className={styles.formUrl}>
                                <Field
                                  name="url"
                                  label="URL de votre profil Linkedin"
                                  placeholder="https://www.linkedin.com/in/...."
                                  component={Input}
                                  className={styles.urlInput}
                                />
                                <button
                                  type="submit"
                                  className={cx(globalStyles.cta, globalStyles.secondaryCta, styles.submitButton)}
                                >
                                  Importer
                                </button>
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </li>
                </ul>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
