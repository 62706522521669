import { t } from "@lingui/macro";
import cx from "classnames";
import { format, isBefore } from "date-fns";
import { fr } from "date-fns/locale";
import * as React from "react";
import { CgChevronRight } from "react-icons/cg";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";

import { GetAvailableApplications } from "../../actionTypes/applications";
import { GetCalendlyEvents } from "../../actionTypes/calendlyEvents";

import { CompanyImg } from "../../blocks/companyImg";
import { OfferApplicationCta } from "../../blocks/offerApplicationCta";
import { PersonProfileImg } from "../../blocks/personProfileImg";
import { StrengthGauge } from "../../blocks/strengthGauge";
import { Loader } from "../../components/Loader";
import { NotificationsList } from "../../components/NotificationsList";
import { ReactComponent as AlarmSvg } from "../../images/alarm.svg";
import { ApiRequestProps } from "../../interfaces/api/request";
import { State } from "../../interfaces/api/state";
import { applicationStatusesActive, Application } from "../../interfaces/resources/application";
import { CalendlyEvent } from "../../interfaces/resources/calendlyEvent";
import { Notification } from "../../interfaces/resources/notification";
import { Talent } from "../../interfaces/resources/talent";
import { hashes, routes } from "../../routes";
import { getAvailableApplications as getAvailableApplicationsAction } from "../../services/api/applications/actions";
import { getCalendlyEventsAction } from "../../services/api/calendlyEvent/actions";

import global from "../../styles/global.module.scss";
import { useApiSelector, useGetResourceHook } from "../../utils/hooks";
import { onInitPageLoadTracking } from "../../utils/tracking";
import styles from "./index.module.scss";

export const HomePage: React.FC = () => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const calendlyEvents: CalendlyEvent[] = useSelector((state: State) => state.calendlyEvents);
  const applications = useSelector((state: State) => state.availableApplications);
  const notifications: Notification[] = useSelector((state: State) => state.notifications);
  const hasUnseenNotifications = notifications && notifications.filter((e: Notification) => !e.seen).length;
  const calendlyLoading = apiPendingRequests.some((req: ApiRequestProps) => req.type === GetCalendlyEvents.REQUEST);
  const processLoading = apiPendingRequests.some(
    (req: ApiRequestProps) => req.type === GetAvailableApplications.REQUEST,
  );
  const filteredApplications = applications
    ? applications.filter((application: Application) => applicationStatusesActive.includes(application.statusAsTalent))
    : [];
  const filteredCalendlyEvents = calendlyEvents
    ? calendlyEvents.filter((c: CalendlyEvent) => {
        return !!c.coreUser && isBefore(new Date(), new Date(c.startTime));
      })
    : [];
  const getAvailableApplications = (
    talent: Talent,
  ): {
    currentTalent: Talent;
    type: string;
  } => dispatch(getAvailableApplicationsAction(talent));

  const getCalendlyEvents = (
    talent: Talent,
  ): {
    talent: Talent;
    type: string;
  } => dispatch(getCalendlyEventsAction(talent));

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetAvailableApplications,
    applications,
    getAvailableApplications,
    [currentTalent],
  );
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetCalendlyEvents, calendlyEvents, getCalendlyEvents, [
    currentTalent,
  ]);

  React.useEffect(() => {
    onInitPageLoadTracking();
  }, []);

  return (
    <div className={cx(styles.pageWrapper)}>
      <div className={styles.grid}>
        <div className={styles.areaLeft}>
          <div className={cx(styles.headerTitleContainer, styles.container)}>
            <h2 className={global.mainTitle}>
              <Markdown disallowedTypes={["paragraph"]} unwrapDisallowed>
                {t({ id: "HomePage.title", values: { talentFirstName: currentTalent.firstname } })}
              </Markdown>
            </h2>
            <p className={cx(styles.subText)}>{t`HomePage.subText`}</p>
          </div>
        </div>

        <div className={styles.areaLeft}>
          <div className={styles.block}>
            <h3 className={styles.blockTitle}>
              <Markdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`HomePage.profileStrength.title`}</Markdown>
            </h3>
            <div className={styles.container}>
              <StrengthGauge strength={(currentTalent && currentTalent.talentProfile?.strength) ?? 1} />
            </div>
          </div>
        </div>
        <div className={styles.areaRight}>
          <div className={cx(styles.notificationsBlock, styles.block, styles.noDeco)}>
            <h3 className={cx(styles.blockTitle, styles.notificationsTitle)}>
              <div className={styles.notificationsIconContainer}>
                <AlarmSvg />
                {hasUnseenNotifications ? (
                  <span
                    className={styles.notificationsBadge}
                    data-count={hasUnseenNotifications}
                    data-testid="notif-badge"
                  ></span>
                ) : null}
              </div>
              <Markdown source={t`alts.notification`} disallowedTypes={["paragraph"]} unwrapDisallowed />
            </h3>
            <div>
              <NotificationsList trackingPrefix="accueil" />
            </div>
          </div>
        </div>
        <div className={styles.areaLeft}>
          <div className={styles.block}>
            <h3 className={styles.blockTitle}>
              <Markdown source={t`HomePage.callBooked.title`} disallowedTypes={["paragraph"]} unwrapDisallowed />
            </h3>
            <div>
              {calendlyLoading ? (
                <Loader className={styles.loaderContainer} testid="calendly-loader" />
              ) : !!filteredCalendlyEvents.length ? (
                <ul className={styles.container} data-testid="calendly-list">
                  {filteredCalendlyEvents.map((c) => {
                    const src = c.coreUser && c.coreUser.image && c.coreUser.image.url;

                    return (
                      <li className={styles.calendlyCardItem} key={c["@id"]}>
                        <div className={styles.calendlyCardContent}>
                          <div className={styles.imgContainer}>
                            <PersonProfileImg src={src} hasOverlay={false} />
                          </div>
                          <div className={styles.callInfoContainer}>
                            <h3 className={styles.callInfoTitle}>
                              {t({ id: "HomePage.callBooked.callInfo", values: { name: c.coreUserFirstName } })}
                            </h3>
                            <div className={styles.callInfoDate}>
                              {format(new Date(c.startTime), "PP à p", { locale: fr })}
                            </div>
                          </div>
                          {c.offer ? (
                            <div data-testid="calendly-offer-cta">
                              <Link
                                className={cx(global.cta, global.darkCta)}
                                to={generatePath(routes.app.offer, { slug: c.offer.slug })}
                              >
                                {t`HomePage.callBooked.cta`}
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {t`Homepage.callBooked.empty`}
                  <Link
                    className={cx(global.cta, global.primaryCta, global.smallerCta)}
                    to={routes.app.offers}
                    style={{ marginTop: "12px", width: "fit-content" }}
                  >
                    {t`OfferMatching.action`}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.areaLeft}>
          <div className={styles.block}>
            <div className={cx(styles.blockTitleContainer, styles.container)}>
              <h3 className={cx(styles.blockTitle)}>
                <Markdown source={t`HomePage.process.title`} disallowedTypes={["paragraph"]} unwrapDisallowed />
              </h3>
              <Link className={styles.processSeeAllCta} to={routes.app.offers + hashes.process}>
                {t`HomePage.process.seeAll`} <CgChevronRight />
              </Link>
            </div>
            <div>
              {processLoading ? (
                <Loader className={styles.loaderContainer} testid="process-loader" />
              ) : !!filteredApplications.length ? (
                <ul className={styles.container} data-testid="process-list">
                  {filteredApplications.map((a) => {
                    const src = a.offer.company.image.url;
                    return (
                      <li className={styles.processCardItem} key={a["@id"]}>
                        <div className={styles.processCardContent}>
                          <Link
                            to={generatePath(routes.app.offer, { slug: a.offer.slug })}
                            className={styles.processCardLink}
                          ></Link>
                          <h3 className={styles.processCardTitle}>{a.offer.title}</h3>
                          <div className={styles.processCardBody}>
                            <div className={styles.imgContainer}>
                              <CompanyImg src={src} />
                            </div>
                            <div className={styles.processCardCompanyNameContainer}>{a.offer.company.name}</div>
                            <div className={styles.processCardCtaContainer}>
                              <OfferApplicationCta offer={a.offer} canApply={false} application={a} />
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div>
                  {t`Homepage.process.empty.text`}
                  <div className={styles.processEmptyCtaContainer}>
                    <Link className={cx(global.cta, global.primaryCta, global.smallerCta)} to={routes.app.offers}>
                      {t`Homepage.process.empty.cta`}
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
