import { LOCATION_CHANGE } from "connected-react-router";
import { AnyAction } from "redux";

import { GetNextQuestion, GetPreviousQuestion } from "../../actionTypes/quiz";
import { ApiErrorProps } from "../../interfaces/api/error";
import { ApiRequestProps } from "../../interfaces/api/request";
import { ApiSuccessProps } from "../../interfaces/api/success";
import { getFailType, getRequestType, getSuccessType } from "./helpers";

export function apiErrors(state: ApiErrorProps[] = [], action: AnyAction) {
  if (action.type === getFailType(action) && action.payload) {
    return [
      ...state,
      {
        ...(action as ApiErrorProps),
      },
    ];
  } else if (action.type === getRequestType(action) && action.form) {
    return state.filter((e: ApiErrorProps) => e.form !== action.form);
  } else if (LOCATION_CHANGE === action.type) {
    return [];
  }
  return state;
}

export function apiSuccess(state: ApiSuccessProps[] = [], action: any) {
  if (action.type === getSuccessType(action)) {
    return [
      ...state,
      {
        ...(action as ApiSuccessProps),
      },
    ];
  } else if (action.type === getRequestType(action) && action.form) {
    return state.filter((e: ApiSuccessProps) => e.form !== action.form);
  } else if ([LOCATION_CHANGE, GetPreviousQuestion.REQUEST, GetNextQuestion.REQUEST].includes(action.type)) {
    return [];
  }
  return state;
}

export function apiPendingRequests(state: ApiRequestProps[] = [], action: AnyAction) {
  if (action.type === getRequestType(action)) {
    return [
      ...state,
      {
        ...(action as ApiRequestProps),
      },
    ];
  } else if (action.type === getSuccessType(action) || action.type === getFailType(action)) {
    return [...state.filter((req) => req.type !== getRequestType(action))];
  }
  return state;
}
