import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { CgArrowRight } from "react-icons/cg";
import ReactMarkdown from "react-markdown";
import { quizContext } from "../..";
import ErrorMessage from "../../../../components/Utils/Form/ErrorMessage";

import Yup from "../../../../components/Utils/Yup";
import { QuizStepSlug } from "../../../../interfaces/resources/quiz";
import globalStyle from "../../../../styles/global.module.scss";
import quizStyle from "../../index.module.scss";
import styles from "./index.module.scss";

const QUIZ_STEP_SLUG: QuizStepSlug = "workEnvironment";

export const PartWorkEnvironment: React.FC = () => {
  const context = React.useContext(quizContext);
  const answer = context?.getAnswer(QUIZ_STEP_SLUG);
  const validationSchema = Yup.object().shape({
    context: Yup.string().required(t`quiz.validation.question1.context.required`),
  });

  const initialValues = {
    context: answer?.data?.context || "",
  };

  const submitForm = (values: any): void => {
    if (context === null) {
      return;
    }

    context.handleSubmit({
      data: values,
      value: QUIZ_STEP_SLUG,
    });
  };

  const handlePrevious = () => {
    if (context === null) {
      return;
    }
    context.handlePrevious(QUIZ_STEP_SLUG);
  };

  return (
    <div data-heap="question-storytelling">
      <div className={quizStyle.questionHeader}>
        <h1 className={cx(globalStyle.mainTitle, quizStyle.mainTitle)}>
          <ReactMarkdown
            disallowedTypes={["paragraph"]}
            unwrapDisallowed
          >{t`Quiz.workEnvironement.title`}</ReactMarkdown>
        </h1>
        <p className={quizStyle.textHeader}>
          <ReactMarkdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`question13.subtitle`}</ReactMarkdown>
        </p>
      </div>
      <div className={styles.formContainer}>
        <Formik
          onSubmit={submitForm}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }): JSX.Element => (
            <Form className="form">
              <div className="formGroup">
                <label className="formLabel" htmlFor="context">
                  <ReactMarkdown
                    disallowedTypes={["paragraph"]}
                    unwrapDisallowed
                  >{t`question13.text.1.a`}</ReactMarkdown>
                </label>
                <span className={styles.subLabeltext}>{t`question13.text.1.b`}</span>
                <div className={styles.textAreaContainer}>
                  <Field
                    name="context"
                    id="context"
                    as="textarea"
                    className={styles.textArea}
                    placeholder={t`question13.context.placeholder`}
                  />
                </div>
                <ErrorMessage name="context" />
              </div>
              <div className={quizStyle.ctasContainer}>
                <div>
                  <span onClick={handlePrevious} className={quizStyle.previousCta}>
                    {t`Quiz.previousCta.text`}
                  </span>
                </div>
                <div className={quizStyle.submitCtaContainer}>
                  <button type="submit" disabled={isSubmitting} className={quizStyle.submitCta}>
                    Suivant <CgArrowRight />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
