import { LOCATION_CHANGE } from "connected-react-router";
import { parseISO } from "date-fns";

import { GetApplications, SubmitApplication, GetAvailableApplications } from "../../../actionTypes/applications";
import { GetOffer } from "../../../actionTypes/offers";
import { Application } from "../../../interfaces/resources/application";

export function applications(state: Application[] = [], action: any) {
  switch (action.type) {
    case GetApplications.SUCCESS:
      return action.applications;
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}

export function availableApplications(state: Application[] = [], action: any) {
  switch (action.type) {
    case GetAvailableApplications.SUCCESS:
      return action.applications;
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}

export function application(state: Application | null = null, action: any) {
  switch (action.type) {
    case GetApplications.SUCCESS:
    case GetAvailableApplications.SUCCESS:
      return (
        action.applications.sort((a: Application, b: Application) =>
          parseISO(a.updatedAt) > parseISO(b.updatedAt) ? -1 : 1,
        )[0] || null
      );
    case SubmitApplication.SUCCESS:
      return action.application;
    case GetOffer.REQUEST:
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
}
