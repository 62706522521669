import { AnyAction } from "redux";
import { CreateTalentProfileSkill, DeleteTalentProfileSkill, GetSkill, GetSkills } from "../../../actionTypes/skills";
import { Responsibility } from "../../../interfaces/resources/responsibility";
import { Skill, TalentProfileSkill } from "../../../interfaces/resources/skill";

export function getSkillsAction(page?: number) {
  return {
    page,
    type: GetSkills.REQUEST,
  };
}

export function getSkillsSuccessAction(skills: Skill[], action: AnyAction) {
  return {
    skills,
    page: action.page,
    type: GetSkills.SUCCESS,
  };
}

export function getSkillAction(responsibility: Responsibility) {
  return {
    responsibility,
    type: GetSkill.REQUEST,
  };
}

export function getSkillSuccessAction(skill: Skill[]) {
  return {
    skill,
    type: GetSkill.SUCCESS,
  };
}

export function createTalentProfileSkillAction(skill: string, talentProfile: string) {
  return {
    skill,
    talentProfile,
    type: CreateTalentProfileSkill.REQUEST,
  };
}

export function createTalentProfileSkillSuccessAction(talentProfileSkill: TalentProfileSkill) {
  return {
    talentProfileSkill,
    type: CreateTalentProfileSkill.SUCCESS,
  };
}

export function deleteTalentProfileSkillAction(talentProfileSkill: Partial<TalentProfileSkill>) {
  return {
    talentProfileSkill,
    type: DeleteTalentProfileSkill.REQUEST,
  };
}

export function deleteTalentProfileSkillSuccessAction(talentProfileSkill: string) {
  return {
    talentProfileSkill,
    type: DeleteTalentProfileSkill.SUCCESS,
  };
}
