import { t } from "@lingui/macro";

interface IConst {
  labels: { [key: string]: string };
}

const transInterviewStep: IConst = {
  labels: {
    case_study: t`interview_type.labels.case_study`,
    fit: t`interview_type.labels.fit`,
    founder: t`interview_type.labels.founder`,
    hr: t`interview_type.labels.hr`,
    simulation: t`interview_type.labels.simulation`,
    team_fit: t`interview_type.labels.team_fit`,
  },
};

export default transInterviewStep;
