import {
  GetCurrentQuestion,
  GetNextQuestion,
  GetPreviousQuestion,
  GetQuizChoices,
  PostAnswer,
  PutCurrentQuestion,
} from "../../../actionTypes/quiz";
import { Answer } from "../../../interfaces/resources/answer";
import { Question } from "../../../interfaces/resources/question";
import { QuizStepSlug } from "../../../interfaces/resources/quiz";

export function getNextQuestionAction() {
  return {
    type: GetNextQuestion.REQUEST,
  };
}

export function putCurrentQuestionAction(questionSlug: QuizStepSlug) {
  return {
    questionSlug,
    type: PutCurrentQuestion.REQUEST,
  };
}
export function getCurrentQuestionAction() {
  return {
    type: GetCurrentQuestion.REQUEST,
  };
}

export function getQuizChoices() {
  return {
    type: GetQuizChoices.REQUEST,
  };
}

export function getPreviousQuestionAction(question: Question) {
  return {
    question,
    type: GetPreviousQuestion.REQUEST,
  };
}

export function postAnswerAction(answer: Partial<Answer>) {
  return {
    answer,
    type: PostAnswer.REQUEST,
  };
}
