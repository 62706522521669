import {
  ApiAuthenticationLogin,
  ApiAuthenticationLogout,
  ApiAuthenticationRefresh,
} from "../../../actionTypes/authentication";

export function loginAction(code: string, redirectUri: string) {
  return {
    code,
    redirectUri,
    type: ApiAuthenticationLogin.REQUEST,
  };
}

export function loginLocation(locationSearch: string) {
  return {
    locationSearch,
    type: ApiAuthenticationLogin.SUCCESS,
  };
}

export function refreshAction(refreshToken: string) {
  return {
    refreshToken,
    type: ApiAuthenticationRefresh.REQUEST,
  };
}

export function logoutAction() {
  return {
    type: ApiAuthenticationLogout.REQUEST,
  };
}
