import * as React from "react";
import { Spinner } from "react-bootstrap";

import "./index.scss";

const LoaderComp: React.FC = () => {
  return <Spinner animation="grow" role="status" />;
};

export default LoaderComp;
