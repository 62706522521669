import { t } from "@lingui/macro";
import React, { PropsWithChildren } from "react";
import signature from "../../images/graphique-elem.png";
import logo from "../../images/logo.svg";
import "./layouts.scss";

interface Props {
  renderMenu: () => React.ReactNode;
}

export const MenuLayout: React.FC<PropsWithChildren<Props>> = ({ children, renderMenu }: PropsWithChildren<Props>) => (
  <div className="menuLayoutWrapper">
    {renderMenu()}
    <div className="scroll-wrapper">
      <img src={logo} alt={t`alts.logo`} className="logo" />
      {children}
    </div>
  </div>
);

export const FullPageLayout: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<any>) => (
  <div className="fullPageLayoutWrapper">
    <img src={logo} alt={t`alts.logo`} className="logo" />
    {children}
    <img src={signature} alt={t`alts.signature`} className="signature" />
  </div>
);

export const QuizLayout: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<any>) => (
  <div className="quizLayoutWrapper">
    <div className="logo-container">
      <img src={logo} alt={t`alts.logo`} className="logo" />
    </div>
    {children}
    <div className="signature-container">
      <img src={signature} alt={t`alts.signature`} className="signature" />
    </div>
  </div>
);
