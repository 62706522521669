import { ActionType } from "../interfaces/api/actionType";

export const GetSkills: ActionType = {
  FAILURE: "GET_SKILLS_FAILURE",
  REQUEST: "GET_SKILLS_REQUEST",
  SUCCESS: "GET_SKILLS_SUCCESS",
};

export const GetSkill: ActionType = {
  FAILURE: "GET_SKILL_FAILURE",
  REQUEST: "GET_SKILL_REQUEST",
  SUCCESS: "GET_SKILL_SUCCESS",
};

export const CreateTalentProfileSkill: ActionType = {
  FAILURE: "CREATE_TALENT_PROFILE_SKILL_FAILURE",
  REQUEST: "CREATE_TALENT_PROFILE_SKILL_REQUEST",
  SUCCESS: "CREATE_TALENT_PROFILE_SKILL_SUCCESS",
};

export const DeleteTalentProfileSkill: ActionType = {
  FAILURE: "DELETE_TALENT_PROFILE_SKILL_FAILURE",
  REQUEST: "DELETE_TALENT_PROFILE_SKILL_REQUEST",
  SUCCESS: "DELETE_TALENT_PROFILE_SKILL_SUCCESS",
};
