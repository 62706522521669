import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { Helmet } from "react-helmet";
import { FaLinkedinIn, FaFacebook, FaInstagram } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../images/logos/elinoi-logo-label-aqua.svg";
import { State } from "../../interfaces/api/state";
import { editTalentOnlyAction } from "../../services/api/talent/actions";
import globalStyle from "../../styles/global.module.scss";

import styles from "./index.module.scss";

export const RefusedPage: React.FC = () => {
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const firstname = currentTalent.firstname;
  const dispatch = useDispatch();

  const handleSecondChance = (): void => {
    dispatch(
      editTalentOnlyAction({
        "@id": currentTalent["@id"],
        status: "status_retry",
      }),
    );
  };
  return (
    <>
      <Helmet>
        <title>{t`RefusedScene.title`}</title>
        <meta name="" content="" />
      </Helmet>
      <div className={styles.profileCompletedWrapper}>
        <div className={styles.pageHeader}>
          <div className={styles.logoContainer}>
            <img src={logo} alt="Élinoï" />
          </div>
        </div>
        <div className={styles.pageBody}>
          <div className={styles.pageBodyPart}>
            <h1 className={cx(globalStyle.mainTitle, styles.mainTitle)}>
              <ReactMarkdown disallowedTypes={["paragraph"]} unwrapDisallowed>
                {currentTalent.isSecondChance
                  ? t({ id: "EndPage.header.titleSecondChance", values: { firstname } })
                  : t({ id: "refused.title", values: { firstname } })}
              </ReactMarkdown>
            </h1>
            {!currentTalent.isSecondChance ? <ReactMarkdown>{t`refused.text1`}</ReactMarkdown> : null}
          </div>
          <div className={styles.pageBodyPart}>
            <div className={styles.bodyTextContainer}>
              {currentTalent.isSecondChance ? (
                <>
                  <ReactMarkdown>{t`secondChance.text1`}</ReactMarkdown>
                  <ReactMarkdown>{t`secondChance.text2`}</ReactMarkdown>
                </>
              ) : (
                <>
                  <ReactMarkdown>{t`refused.text2`}</ReactMarkdown>
                  <ReactMarkdown>{t`refused.text3`}</ReactMarkdown>
                </>
              )}
            </div>
          </div>
          {currentTalent.isSecondChance ? (
            <div className={styles.pageBodyPart}>
              <button type="button" onClick={handleSecondChance} className={styles.submitCta}>
                {t`secondChance.button`}
              </button>
            </div>
          ) : null}
          <div className={styles.pageBodyPart}>
            <h2 className={styles.partTitle}>
              <ReactMarkdown unwrapDisallowed disallowedTypes={["paragraph"]}>{t`social.follow`}</ReactMarkdown>
            </h2>
            <div className={styles.linksContainer}>
              <a
                className={cx(styles.links, styles.linkedin)}
                href="https://www.linkedin.com/company/elinoi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
              <a
                className={cx(styles.links, styles.facebook)}
                href="https://www.facebook.com/TeamElinoi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
              <a
                className={cx(styles.links, styles.instagram)}
                href="https://www.instagram.com/teamelinoi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
