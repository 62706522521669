import { call, put, takeLatest } from "redux-saga/effects";
import {
  CreateLinkedinResumeItem,
  DeleteLinkedinItem,
  EditLinkedinItem,
  GetLinkedinResume,
} from "../../../actionTypes/linkedinResume";

import { EditLinkedinResume } from "../../../actionTypes/talent";
import { apiFailureAction } from "../actions";
import * as apiClient from "./api";

export function* deleteItem(action: any) {
  try {
    const response = yield call(apiClient.deleteItem, action.item);
    const data = response.data;
    yield put({
      data,
      type: DeleteLinkedinItem.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editLinkedinItem(action: any) {
  try {
    const response = yield call(apiClient.editItem, action.item);
    const data = response.data;
    yield put({
      data,
      type: EditLinkedinItem.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* createLinkedinItem(action: any) {
  try {
    const response = yield call(apiClient.createLinkedinItem, action);
    const data = response.data;
    yield put({
      element: action.element,
      data,
      type: CreateLinkedinResumeItem.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editLinkedinResume(action: any) {
  try {
    const response = yield call(apiClient.editLinkedinResume, action);
    const data = response.data;
    yield put({
      data,
      type: EditLinkedinResume.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getLinkedinResume(action: any) {
  try {
    const response = yield call(apiClient.getLinkedinResume, action.talent);
    const data = response.data;
    yield put({
      linkedinResume: data,
      type: GetLinkedinResume.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* talentsSaga() {
  yield takeLatest(DeleteLinkedinItem.REQUEST, deleteItem);
  yield takeLatest(GetLinkedinResume.REQUEST, getLinkedinResume);
  yield takeLatest(EditLinkedinItem.REQUEST, editLinkedinItem);
  yield takeLatest(CreateLinkedinResumeItem.REQUEST, createLinkedinItem);
  yield takeLatest(EditLinkedinResume.REQUEST, editLinkedinResume);
}
