import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Formik, Form } from "formik";
import MultiRangeSlider from "multi-range-slider-react";
import React, { FC, useEffect, useState } from "react";
import InputRange from "react-input-range";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { GetDesiredPlaces } from "../../actionTypes/desiredPlaces";
import { GetExperienceYears } from "../../actionTypes/experienceYears";
import { GetResponsibilities } from "../../actionTypes/responsibility";
import { GetSalaryExpectations } from "../../actionTypes/salaryExpectations";
import { GetSkill } from "../../actionTypes/skills";
import { EditTalent, GetTalentProfile } from "../../actionTypes/talent";
import { State } from "../../interfaces/api/state";
import { Violation } from "../../interfaces/api/violation";
import { DesiredPlace, desiredPlacesRemote } from "../../interfaces/resources/desiredPlace";
import { Responsibility, ResponsibilityWithSeniority } from "../../interfaces/resources/responsibility";
import {
  Talent,
  TalentProfile,
  EditTalentProps,
  talentSearchStatuses,
  talentSearchStatusReasons,
} from "../../interfaces/resources/talent";
import { getDesiredPlacesAction } from "../../services/api/desiredPlaces/actions";
import { getExperienceYearsAction } from "../../services/api/experienceYears/actions";
import { getResponsibilitiesAction } from "../../services/api/responsibility/actions";
import { getSalaryExpectationsAction } from "../../services/api/salaryExpectations/actions";
import { getSkillAction } from "../../services/api/skills/actions";
import {
  createTalentProfileResponsibilityAction,
  deleteTalentProfileResponsibilityAction,
  editTalentAction,
  editTalentProfileResponsibilityAction,
  getTalentProfileAction,
} from "../../services/api/talent/actions";
import globalStyle from "../../styles/global.module.scss";
import { transTalent } from "../../translations/constants/talent";
import { getAddDeletePutTalentResponsibility } from "../../utils/helpers";
import { useApiSelector, useGetResourceHook, usePrevious } from "../../utils/hooks";
import { Loader } from "../Loader";
import { ModalWrapper } from "../ModalWrapper";
import { ErrorWording } from "../Utils/Form/ErrorMessage";
import { Input } from "../Utils/Form/Input";
import { Radio } from "../Utils/Form/Radio";
import { SearchingReasonRadio } from "../Utils/Form/SearchingReasonRadio";
import { Select } from "../Utils/Form/Select";
import { extractIdFromIri } from "../Utils/helpers";
import { ResponsibilitySelect } from "../Utils/ResponsibilitySelect";
import Yup from "../Utils/Yup";
import styles from "./index.module.scss";

interface Props {
  closeModal: () => void;
  showCancelCta?: boolean;
}

export const EditCandidateProfileModalCta: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <button
        className={cx(globalStyle.cta, globalStyle.primaryCta, globalStyle.smallerCta)}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {t`CandidateProfileCard.cta.text`}
      </button>
      <ModalWrapper open={openModal} closeModal={closeModal}>
        <EditCandidateProfileModal closeModal={closeModal} />
      </ModalWrapper>
    </>
  );
};

// To rework to get skillcomp to work

export const EditCandidateProfileModal: FC<Props> = ({ closeModal, showCancelCta = true }: Props) => {
  const { apiErrors, apiSuccess, apiPendingRequests } = useApiSelector();
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const talentProfile = useSelector((state: State) => state.talentProfile);
  const desiredPlacesState = useSelector((state: State) => state.desiredPlaces);
  const desiredPlaces = desiredPlacesState.filter((e) => !desiredPlacesRemote.includes(e.value));
  const remotePreferences = desiredPlacesState.filter((e) => desiredPlacesRemote.includes(e.value));
  const salaryExpectations = useSelector((state: State) => state.salaryExpectations);
  const experienceYears = useSelector((state: State) => state.experienceYears);
  const responsibilities = useSelector((state: State) => state.responsibilities);
  // const skills = useSelector((state: State) => state.skills);
  const dispatch = useDispatch();
  const [error, setError] = useState<boolean | string>(false);
  const [errors, setErrors] = useState<Violation[]>([]);
  const [success, setSuccess] = useState(false);
  const [notChanged, setNotChanged] = useState(false);
  const [selected, setSelected] = useState<ResponsibilityWithSeniority[] | null>(null);
  // const [skillsSelected, setSkillsSelected] = useState<string[]>([]);
  const [defaultMinSlryValue, setDefaultMinSlryValue] = React.useState<number>(33);
  const [defaultMaxSlryValue, setDefaultMaxSlryValue] = React.useState<number>(38);
  const [maxCaption, setMaxCaption] = React.useState<string>(`variable : ${38 - 33}k`);
  const [minCaption, setMinCaption] = React.useState<string>("fix : 33k");
  const [minSlryValue, setMinSlryValue] = React.useState<number | undefined>(undefined);
  const [maxSlryValue, setMaxSlryValue] = React.useState<number | undefined>(undefined);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [primarySelected, setPrimarySelected] = React.useState<Responsibility>();
  const prevPrimarySelected = usePrevious(primarySelected);
  const [jobFoundCompany, setJobFoundCompany] = useState<string>(
    currentTalent?.lastTalentSearchStatusEvent?.data
      ? JSON.parse(currentTalent?.lastTalentSearchStatusEvent?.data)?.jobFoundCompany
      : "",
  );
  const prevApiSuccess = usePrevious(apiSuccess);
  const prevApiErrors = usePrevious(apiErrors);
  const prevTalentProfile = usePrevious(talentProfile);
  const editTalent = (talent: EditTalentProps) => dispatch(editTalentAction(talent));
  const validationSchema = Yup.object().shape({
    talentProfile: Yup.object().shape({
      desiredPlaces: Yup.array()
        .of(
          Yup.object()
            .shape({
              "@id": Yup.string(),
              value: Yup.string(),
            })
            .required("required"),
        )
        .required(),
      currentJob: Yup.string().required(),
      currentCompany: Yup.string().required(),
      experience: Yup.number().required().nullable(),
    }),
  });
  const initialValues = {
    ...currentTalent,
    remotePreferences: talentProfile?.desiredPlaces.filter((e) => desiredPlacesRemote.includes(e.value)) || [],
    talentProfile: {
      ...talentProfile,
      desiredPlaces: talentProfile?.desiredPlaces.filter((e) => !desiredPlacesRemote.includes(e.value)) || [],
      currentJob: talentProfile?.currentJob || "",
      currentCompany: talentProfile?.currentCompany || "",
      experience: talentProfile?.experience || 0,
      coreResponsibility: talentProfile?.coreResponsibility || undefined,
    },
    talentProfileSkills: talentProfile?.talentProfileSkills.map((tps: any) => tps.skill["@id"]) || [],
    searchStatusReason: !!currentTalent?.lastTalentSearchStatusEvent?.reason
      ? currentTalent.lastTalentSearchStatusEvent.reason
      : null,
    searchStatusData: {
      doer: "talent",
      doerId: extractIdFromIri(currentTalent["@id"]),
    },
  };
  const [expValue, setExpValue] = React.useState<number>(
    currentTalent?.talentProfile?.experience ? currentTalent.talentProfile.experience : 0,
  );

  const handlePrimarySelect = (resp: Responsibility) => {
    setPrimarySelected(resp);
  };
  const handleChange = (result: ResponsibilityWithSeniority[]): void => {
    setSelected(result);
  };
  // const onChangeSkills = (skillsList: string[]): void => {
  //   setSkillsSelected(skillsList);
  // };
  const getResponsibilities = (): void => {
    dispatch(getResponsibilitiesAction());
  };
  const getTalentProfile = (talent: Talent): void => {
    dispatch(getTalentProfileAction(talent));
  };
  const getDesiredPlaces = (): void => {
    dispatch(getDesiredPlacesAction());
  };
  const getExperienceYears = (): void => {
    dispatch(getExperienceYearsAction());
  };
  const getSalaryExpectations = (): void => {
    dispatch(getSalaryExpectationsAction());
  };

  const onSubmit = (values: EditTalentProps) => {
    const { respsToAdd, respsToDelete, respsToPut } = getAddDeletePutTalentResponsibility(talentProfile, selected);
    // const { skillsToAdd, skillsToDelete } = getAddDeleteTalentProfileSkills(talentProfile, skillsSelected);
    const sortByIri = (a: any, b: any) => {
      const aId = extractIdFromIri(a["@id"]);
      const bId = extractIdFromIri(b["@id"]);

      return aId - bId;
    };

    const vals = {
      ...values,
      searchStatusReason: values.searchStatus !== talentSearchStatuses.not_searching ? null : values.searchStatusReason,
      remotePreferences: (values as any).remotePreferences.sort(sortByIri),
      talentProfile: {
        ...values.talentProfile,
        desiredPlaces: values.talentProfile?.desiredPlaces.sort(sortByIri),
      },
    } as any;
    const dPlaces = [...vals.talentProfile.desiredPlaces, ...vals.remotePreferences];
    const valuesNotChanged = JSON.stringify(vals) === JSON.stringify(initialValues);
    const salaryExpNotChanged =
      talentProfile.expectedRemuneration?.fixedSalary === minSlryValue
      && talentProfile.expectedRemuneration?.fullPackage === maxSlryValue;
    const responsiblitiesNotChanged = !respsToAdd.length && !respsToDelete.length && !respsToPut.length;
    const experienceNotChanged = talentProfile.experience === expValue;
    const coreResponsibilityNotChanged = talentProfile.coreResponsibility === primarySelected;

    // On fait un call seulement si les données sont différentes ou que le talent est OBLIGÉ de valider la modale
    // (i.e. il ne s'est pas connecté depuis plus de 6 mois)
    if (
      showCancelCta
      && valuesNotChanged
      && salaryExpNotChanged
      && responsiblitiesNotChanged
      && experienceNotChanged
      && coreResponsibilityNotChanged
    ) {
      setNotChanged(true);
      setTimeout(closeModal, 500);
      return;
    }
    if (talentProfile) {
      if (respsToAdd.length) {
        respsToAdd.forEach((resp) => dispatch(createTalentProfileResponsibilityAction(resp, talentProfile["@id"])));
      }
      if (respsToDelete.length) {
        respsToDelete.forEach((resp) => dispatch(deleteTalentProfileResponsibilityAction(resp)));
      }
      if (respsToPut.length) {
        respsToPut.forEach((resp) => dispatch(editTalentProfileResponsibilityAction(resp)));
      }

      // if (skillsToAdd.length) {
      //   skillsToAdd.forEach((skill) => dispatch(createTalentProfileSkillAction(skill, talentProfile["@id"])));
      // }

      // if (skillsToDelete) {
      //   skillsToDelete.forEach((skill) => dispatch(deleteTalentProfileSkillAction(skill)));
      // }
    }
    editTalent({
      ...values,
      searchStatusReason: values.searchStatus !== talentSearchStatuses.not_searching ? null : values.searchStatusReason,
      searchStatusData:
        values.searchStatus === talentSearchStatuses.not_searching
        && values.searchStatusReason === talentSearchStatusReasons.job_found
          ? {
              ...values.searchStatusData,
              jobFoundCompany,
            }
          : {
              ...values.searchStatusData,
            },
      talentProfile: {
        ...(values.talentProfile as TalentProfile),
        desiredPlaces: dPlaces,
        coreResponsibility: primarySelected ? primarySelected : undefined,
        experience: expValue,
        expectedRemuneration: {
          fixedSalary: minSlryValue ? minSlryValue : defaultMinSlryValue,
          fullPackage: maxSlryValue ? maxSlryValue : defaultMaxSlryValue,
        },
      },
    });
  };

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetTalentProfile, talentProfile, getTalentProfile, [
    currentTalent,
  ]);
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetDesiredPlaces, desiredPlaces, getDesiredPlaces);
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetSalaryExpectations,
    salaryExpectations,
    getSalaryExpectations,
  );
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetExperienceYears,
    experienceYears,
    getExperienceYears,
  );

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetResponsibilities,
    responsibilities,
    getResponsibilities,
  );

  useEffect(() => {
    if (!!!prevTalentProfile && !!talentProfile) {
      const resps = talentProfile.responsibilities.map((resp) => {
        return {
          ...resp.responsibility,
          seniority: resp.seniority,
        };
      });
      setSelected(resps);
    }
  }, [talentProfile, prevTalentProfile]);

  React.useEffect(() => {
    if (
      primarySelected
      && primarySelected !== prevPrimarySelected
      && !apiPendingRequests.some((s) => s.type === GetSkill.REQUEST)
    ) {
      dispatch(getSkillAction(primarySelected));
    }
  }, [primarySelected, dispatch, apiPendingRequests, prevPrimarySelected]);

  useEffect(() => {
    if (
      prevApiSuccess
      && apiSuccess
      && prevApiSuccess.length !== apiSuccess.length
      && apiSuccess.some((e) => EditTalent.SUCCESS === e.type)
    ) {
      setError(false);
      setErrors([]);
      setSuccess(true);
      setTimeout(closeModal, 500);
    }
  }, [apiSuccess, prevApiSuccess, closeModal, setError, setErrors, setSuccess]);

  React.useEffect(() => {
    const expRem = currentTalent.talentProfile?.expectedRemuneration;
    if (expRem?.fixedSalary && expRem?.fullPackage) {
      setDefaultMinSlryValue(expRem.fixedSalary);
      setDefaultMaxSlryValue(expRem.fullPackage);
    }
    if (talentProfile?.coreResponsibility) {
      setPrimarySelected(talentProfile.coreResponsibility);
    }
    // if (talentProfile?.talentProfileSkills.length > 0) {
    //   setSkillsSelected(talentProfile.talentProfileSkills.map((tps: TalentProfileSkill) => tps.skill["@id"]));
    // }
  }, [currentTalent, talentProfile]);

  React.useEffect(() => {
    const senioritiesMissing = !!selected && selected.some((s) => !!!s.seniority);
    setIsDisabled(!!!selected?.length || senioritiesMissing || !primarySelected);
  }, [selected, primarySelected]);

  useEffect(() => {
    if (
      prevApiErrors
      && apiErrors
      && prevApiErrors.length !== apiErrors.length
      && apiErrors.some((e) => EditTalent.FAILURE === e.type)
    ) {
      const apiError = apiErrors.find((e) => EditTalent.FAILURE === e.type);
      if (apiError) {
        setErrors(apiError.payload.violations || []);
        setError(apiError.payload.message);
        setSuccess(false);
      }
    }
  }, [apiErrors, prevApiErrors, setError, setErrors, setSuccess]);

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h1 className={globalStyle.mainTitle}>
            <ReactMarkdown
              disallowedTypes={["paragraph"]}
              unwrapDisallowed
            >{t`EditCandidateProfileModal.title`}</ReactMarkdown>
          </h1>
        </div>
        {talentProfile && selected !== null ? (
          <Formik
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ values, isSubmitting }): JSX.Element => (
              <>
                <Form className="form">
                  <div className="formGroup">
                    <label className="formLabel">Statut de ma recherche :</label>
                    <div className={styles.searchOptionsReasonsContainer}>
                      {Object.keys(talentSearchStatuses).map((e: string) => {
                        return (
                          <Field
                            key={`search-status-${e}`}
                            component={Radio}
                            name="searchStatus"
                            value={e}
                            label={t({ id: transTalent.searchStatuses[e] })}
                            showErrorMessage={false}
                            className={styles.searchStatusOption}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {values.searchStatus === talentSearchStatuses.not_searching ? (
                    <div className="formGroup">
                      <Field
                        component={SearchingReasonRadio}
                        name="searchStatusReason"
                        label="Je ne cherche plus car :"
                        options={Object.values(talentSearchStatusReasons)}
                        getOptionLabel={(option: string): string => transTalent.searchStatusReasons[option]}
                        getOptionValue={(option: string): string => {
                          return option;
                        }}
                        setJobFoundCompany={setJobFoundCompany}
                        jobFoundCompany={jobFoundCompany}
                        className={styles.searchStatusReasonsRadio}
                      />
                    </div>
                  ) : null}
                  <div className="formGroup formInlineGroup double mobileSingleLine">
                    <Field
                      name="talentProfile.currentJob"
                      component={Input}
                      label={t`CandidateProfileCard.currentJob.label`}
                      violation={errors}
                    />
                    <Field
                      name="talentProfile.currentCompany"
                      component={Input}
                      label={t`CandidateProfileCard.currentCompany.label`}
                      violation={errors}
                    />
                  </div>
                  <div className="formGroup">
                    <div>
                      <label className="formLabel">Mon niveau d&apos;expérience totale</label>
                    </div>
                    <div className={styles.inputRangeContainer}>
                      <InputRange
                        maxValue={16}
                        minValue={0}
                        step={1}
                        formatLabel={(value): string => {
                          return value === 0
                            ? "< 1 an"
                            : value === 1
                              ? `${value} an`
                              : value === 16
                                ? "+15 ans"
                                : `${value} ans`;
                        }}
                        value={expValue}
                        onChange={(val): void => {
                          setExpValue(val as number);
                        }}
                      />
                    </div>
                  </div>
                  <div className="formGroup">
                    <div>
                      <label className="formLabel">Salaire souhaité</label>
                    </div>
                    <div className={styles.inputRangeContainer}>
                      <MultiRangeSlider
                        min={30}
                        max={150}
                        step={1}
                        minValue={defaultMinSlryValue}
                        maxValue={defaultMaxSlryValue}
                        maxCaption={maxCaption}
                        minCaption={minCaption}
                        labels={["30k", "150k+"]}
                        ruler={false}
                        onChange={(e): void => {
                          setMinSlryValue(e.minValue);
                          setMaxSlryValue(e.maxValue);
                        }}
                        onInput={(e): void => {
                          setMinCaption(`fix : ${e.minValue}k`);
                          setMaxCaption(`variable : ${e.maxValue - e.minValue}k`);
                        }}
                      />
                    </div>
                  </div>

                  <div className="formGroup">
                    <Field
                      name="talentProfile.desiredPlaces"
                      component={Select}
                      getOptionLabel={(item: DesiredPlace): string => item.value}
                      getOptionValue={(item: DesiredPlace): string => item["@id"]}
                      isLoading={apiPendingRequests.some((e) => e.type === GetDesiredPlaces.REQUEST)}
                      isSearchable={true}
                      isClearable={false}
                      isMulti={true}
                      label={t`CandidateProfileCard.desiredPlaces.label`}
                      options={desiredPlaces}
                      violation={errors}
                    />
                  </div>
                  <div className="formGroup">
                    <Field
                      name="remotePreferences"
                      component={Select}
                      getOptionLabel={(item: DesiredPlace): string => item.value}
                      getOptionValue={(item: DesiredPlace): string => item["@id"]}
                      isLoading={apiPendingRequests.some((e) => e.type === GetDesiredPlaces.REQUEST)}
                      isSearchable={true}
                      isClearable={false}
                      isMulti={true}
                      label={t`CandidateProfileCard.remotePreferences.label`}
                      options={remotePreferences}
                      violation={errors}
                    />
                  </div>
                  {responsibilities.length > 0 ? (
                    <div className="formGroup">
                      <ResponsibilitySelect
                        primarySelect={handlePrimarySelect}
                        onChange={handleChange}
                        options={responsibilities}
                        value={selected}
                        coreResponsibility={primarySelected?.["@id"]}
                        layout="modal"
                      />
                      {isDisabled ? (
                        <div className={styles.respErrorWordingContainer}>
                          <ErrorWording message="Indique ton niveau d'expérience sur chaque métier et sélectionne un poste principal pour valider ton profil" />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {/* To rework to get it to work */}
                  {/* {skills.length > 0 ? (
                    <Field
                      name="talentProfileSkills"
                      className={cx("fieldClientFacing field", styles.skillsWrapper)}
                      component={SkillsComp}
                      label="Compétences"
                      instruction="Gagnez en visibilité auprès de nos partenaires en cochant au minimum 5 compétences"
                      isRequired={false}
                      options={skills}
                      getOptionLabel={(option: Skill): any => option.value || option}
                      getOptionValue={(option: any): any => (option.skill ? option.skill["@id"] : option["@id"])}
                      onChangeSkills={onChangeSkills}
                    />
                  ) : null} */}
                  {success || error || notChanged ? (
                    <div className={styles.messagesContainer}>
                      {notChanged ? (
                        <div className={cx(styles.message, styles.successMessage)}>
                          <h4>{t`EditCandidateProfileModal.notChanged.header`}</h4>
                          <p>{t`EditCandidateProfileModal.notChanged.content`}</p>
                        </div>
                      ) : null}
                      {success ? (
                        <div className={cx(styles.message, styles.successMessage)}>
                          <h4>{t`EditCandidateProfileModal.success.header`}</h4>
                          <p>{t`EditCandidateProfileModal.success.content`}</p>
                        </div>
                      ) : null}
                      {error ? (
                        <div className={cx(styles.message, styles.errorMessage)}>
                          <h4>{t`EditCandidateProfileModal.error.header`}</h4>
                          <p>
                            {t`EditCandidateProfileModal.error.content`} {error}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className={styles.formBtnContainer}>
                    {showCancelCta ? (
                      <div>
                        <button
                          type="button"
                          className={cx(
                            globalStyle.cta,
                            globalStyle.darkCta,
                            globalStyle.inverted,
                            globalStyle.rectangle,
                          )}
                          onClick={closeModal}
                        >
                          {t`EditProfileModal.fields.cancel`}
                        </button>
                      </div>
                    ) : null}
                    <div className={styles.submitCtaContainer}>
                      <button
                        className={cx(globalStyle.cta, globalStyle.primaryCta)}
                        type="submit"
                        disabled={isSubmitting || isDisabled}
                      >
                        {t`question.submit`}
                      </button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </div>
  );
};
