export interface LinkedinResume {
  "@id": string;
  email: string;
  url: string;
  talent: string;
  languages: LinkedinLanguage[];
  educations: LinkedinEducation[];
  experiences: LinkedinExperience[];
  summary?: string | null;
  updatedAt?: string;
  name: string;
}

export interface LinkedinLanguage {
  "@id": string;
  name: string;
  proficiency?: string;
}

export const linkedinLanguages: { [key: string]: string } = {
  professional_working: "Professional Working",
  native_or_bilingual: "Native or Bilingual",
  limited_working: "Limited Working",
  elementary: "Elementary",
  full_professional: "Full Professional",
};

export interface LinkedinEducation {
  "@id": string;
  schoolName: string;
  degreeName?: string;
  startDate?: string;
  endDate?: string;
}

export interface LinkedinExperience {
  "@id": string;
  companyName: string;
  jobTitle: string;
  startDate?: string;
  endDate?: string;
  description?: string;
  city?: string;
}
