import { t } from "@lingui/macro";
import cx from "classnames";
import React, { useState } from "react";
import { event as trackEvent } from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { Button, CalendlyModal } from "../../components/Offers/OfferCard";
import { extractIdFromIri } from "../../components/Utils/helpers";
import { State } from "../../interfaces/api/state";
import { Application, applicationStatuses } from "../../interfaces/resources/application";
import { Offer, offerStatuses } from "../../interfaces/resources/offer";
import { Talent } from "../../interfaces/resources/talent";
import { submitApplication } from "../../services/api/applications/actions";

import global from "../../styles/global.module.scss";
import { hasApplied, isOdd } from "../../utils/helpers";

interface Props {
  application?: Application | undefined;
  canApply?: boolean;
  isLoading?: boolean;
  offer: Offer;
  trackName?: string;
  applyTrackingEvent?: () => void;
}

export const OfferApplicationCta: React.FC<Props> = (props: Props) => {
  const { application, canApply = true, offer, isLoading, trackName, applyTrackingEvent } = props;
  const [open, setOpen] = useState(false);
  const currentTalent: Talent = useSelector((state: State) => state.currentTalent);
  const isTalentOdd: boolean = isOdd(extractIdFromIri(currentTalent["@id"]));
  const colorCtaClass = isTalentOdd ? global.secondaryCta : global.redCta;
  const dispatch = useDispatch();
  const applied = hasApplied(application);
  const isEnabled = offer.status
    ? [offerStatuses.activated, offerStatuses.standbyElinoi].includes(offer.status)
    : false;
  const apply = (): void => {
    dispatch(
      submitApplication({
        "@id": application && application["@id"],
        offer,
        status: applicationStatuses.applied,
        talent: currentTalent,
      }),
    );
  };
  const handleApply = (): void => {
    if (typeof applyTrackingEvent === "function") {
      applyTrackingEvent();
    } else {
      trackEvent({
        action: "click",
        category: "talent",
        label: "offre - en discuter avec Élinoï",
      });
    }
    apply();
  };

  if (canApply && !isEnabled) {
    return (
      <button className={cx(global.cta)} disabled={true} data-testid="offer-disabled-cta">
        {t`OfferHeader.action.disabled`}
      </button>
    );
  }

  if (!canApply || applied) {
    const isDisabled = !application || application.statusAsTalent !== applicationStatuses.toPitch;
    return (
      <>
        <Button
          isDisabled={isDisabled}
          offer={application ? { ...offer, application } : { ...offer }}
          setOpen={setOpen}
          classNames={cx(global.cta, { [global.secondaryCta]: !isDisabled })}
          trackName={trackName}
          showtooltip={application ? application.statusAsTalent === "to_pitch" : false}
        />
        <CalendlyModal open={open} setOpen={setOpen} offer={offer} talent={currentTalent} />
      </>
    );
  }

  if (canApply) {
    return (
      <>
        <button
          type="button"
          className={cx(global.cta, colorCtaClass)}
          disabled={!!isLoading}
          onClick={handleApply}
          data-testid="canApply-cta"
        >
          {t`OfferHeader.action`}
        </button>
      </>
    );
  }

  return null;
};
