import client from "../client";

export function deleteItem(iri: string) {
  return client.delete(iri);
}

export function editItem(item: any) {
  const { description, city, startDate, endDate, jobTitle, companyName, degreeName, schoolName, name, proficiency } =
    item;
  return client.put(`${item["@id"]}`, {
    description,
    city,
    startDate,
    endDate,
    jobTitle,
    companyName,
    degreeName,
    schoolName,
    name,
    proficiency,
  });
}

export function getLinkedinResume(talentIri: string) {
  return client.get(`${talentIri}/linkedin_resume`);
}

export function createLinkedinItem(action: any) {
  return client.post(`/api/v2/linkedin_${action.element}`, action.item);
}

export function editLinkedinResume(action: any) {
  return client.put(action.linkedinResumeIri, action.url);
}
