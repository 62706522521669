import { AnyAction } from "redux";
import { CreateReferral, GetReferrals, GetReferred, ValidateReferral } from "../../../actionTypes/referral";
import { Referral, ValidatedReferral } from "../../../interfaces/resources/referral";

export function referrals(state: Referral[] = [], action: any) {
  switch (action.type) {
    case GetReferrals.SUCCESS:
      return action.referrals;
    case CreateReferral.SUCCESS:
      return [...state, action.referral];
    default:
      return state;
  }
}

export function referred(state = null, action: AnyAction): Referral | null {
  switch (action.type) {
    case GetReferred.SUCCESS:
      return action.referred;
    default:
      return state;
  }
}

export function validatedReferral(state = null, action: AnyAction): ValidatedReferral | null {
  switch (action.type) {
    case ValidateReferral.SUCCESS:
      return action.referral;
    default:
      return state;
  }
}
