import { t } from "@lingui/macro";

export const transTalent: {
  searchStatuses: {
    [key: string]: string;
  };
  searchStatusReasons: {
    [key: string]: string;
  };
} = {
  searchStatuses: {
    searching: t`Constant.talent.searchStatuses.searching`,
    passive_searching: t`Constant.talent.searchStatuses.passive_searching`,
    not_searching: t`Constant.talent.searchStatuses.not_searching`,
  },
  searchStatusReasons: {
    job_found: t`Constant.talent.searchStatusReasons.job_found`,
    keep_current_job: t`Constant.talent.searchStatusReasons.keep_current_job`,
    project_launch: t`Constant.talent.searchStatusReasons.project_launch`,
    other: t`Constant.talent.searchStatusReasons.other`,
  },
};
