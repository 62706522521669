import anime from "animejs";
import { MiniSignalBinding } from "mini-signals";
import React, { FC, useEffect, useRef, useState } from "react";
import { eventDispatcher } from "../../utils/eventDispatcher";
import CodeOfConduct from "./CodeOfConduct";

import styles from "./index.module.scss";

export interface Props {
  open?: boolean;
}

export const CoCModalOpen = "CoCModal:open";
export const CoCModalClose = "CoCModal:close";
export const CoCModalShowLeftSide = "CoCModal:ShowLeftSide";

export const CodeOfConductModalWrapper: FC<Props> = ({ open = false }: Props) => {
  const modalWrapper = useRef(null);
  const modal = useRef(null);
  const openEvent = useRef<MiniSignalBinding>();
  const closeEvent = useRef<MiniSignalBinding>();
  const [show, setShow] = useState(open);
  const [hidden, setHidden] = useState(!open);

  const showAnimation = (): void => {
    document.body.style.overflow = "hidden";

    const tl = anime.timeline({
      duration: 300,
      easing: "easeInOutExpo",
    });

    tl.add({
      easing: "linear",
      opacity: 1,
      targets: modalWrapper.current,
    }).add({
      duration: 600,
      opacity: [0, 1],
      targets: modal.current,
      translateY: [-10, 0],
    });
  };
  const hideAnimation = (): void => {
    const tl = anime.timeline({
      complete: () => {
        document.body.style.overflow = "";
        setHidden(true);
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: 0,
      targets: modal.current,
      translateY: 20,
    }).add(
      {
        opacity: 0,
        targets: modalWrapper.current,
      },
      0,
    );
  };

  const showModal = (): void => {
    setHidden(false);
    setShow(true);
  };
  const hideModal = (): void => {
    setShow(false);
  };

  useEffect(() => {
    openEvent.current = eventDispatcher.on(CoCModalOpen, showModal);
    closeEvent.current = eventDispatcher.on(CoCModalClose, hideModal);

    return (): void => {
      if (openEvent.current) {
        eventDispatcher.off(CoCModalOpen, openEvent.current);
      }
      if (closeEvent.current) {
        eventDispatcher.off(CoCModalClose, closeEvent.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!show && !hidden) {
      hideAnimation();
      return;
    } else if (show && !hidden) {
      showAnimation();
    }
  }, [show, hidden]);

  return hidden ? null : (
    <div className={styles.modalWrapper} ref={modalWrapper} style={{ opacity: 0 }}>
      <div ref={modal} className={styles.modalContainer}>
        <CodeOfConduct />
      </div>
    </div>
  );
};
