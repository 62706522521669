import { AxiosResponse } from "axios";
import { OfferEvent } from "../../../../interfaces/resources/offerEvent";
import client from "../../client";

const formData = (offerEvent: OfferEvent): { offer: string; status: string; talent: string } => ({
  offer: offerEvent.offer && offerEvent.offer["@id"],
  status: offerEvent.status,
  talent: offerEvent.talent && offerEvent.talent["@id"],
});
export function createOfferEvent(offerEvent: OfferEvent): Promise<AxiosResponse<any>> {
  return client.post("/api/v2/offer_events", formData(offerEvent));
}
