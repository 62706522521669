import { call, put, takeLatest } from "redux-saga/effects";

import { GetSalaryExpectations } from "../../../actionTypes/salaryExpectations";
import { apiFailure, extractNextPageNumberFromIri } from "../helpers";
import * as apiClient from "./api";

export function* getSalaryExpectations(action: any) {
  try {
    const response = yield call(apiClient.getSalaryExpectations, action.page);
    const salaryExpectations = response.data["hydra:member"];
    yield put({
      page: action.page,
      salaryExpectations,
      type: GetSalaryExpectations.SUCCESS,
    });
    if (response.data.hasOwnProperty("hydra:view") && response.data["hydra:view"].hasOwnProperty("hydra:next")) {
      yield put({
        page: extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"]),
        type: GetSalaryExpectations.REQUEST,
      });
    }
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* getSalaryExpectationsSaga() {
  yield takeLatest(GetSalaryExpectations.REQUEST, getSalaryExpectations);
}
