import { t } from "@lingui/macro";
import cx from "classnames";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { BsCheckCircle } from "react-icons/bs";
import Markdown from "react-markdown";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { GetReferrals, GetReferred } from "../../actionTypes/referral";
import { PersonProfileImg } from "../../blocks/personProfileImg";
import { Loader } from "../../components/Loader";
import { ApiErrorProps } from "../../interfaces/api/error";
import { ApiRequestProps } from "../../interfaces/api/request";
import { State } from "../../interfaces/api/state";
import { ApiSuccessProps } from "../../interfaces/api/success";
import { Referral } from "../../interfaces/resources/referral";
import { Talent } from "../../interfaces/resources/talent";
import { createReferralAction, getReferralsAction, getReferredAction } from "../../services/api/referrals/actions";
import { gtagService } from "../../services/google/gtag";
import global from "../../styles/global.module.scss";
import { transReferral } from "../../translations/constants/referral";
import { useApiSelector, useGetResourceHook } from "../../utils/hooks";

import styles from "./index.module.scss";
import "react-multi-email/style.css";

export const ReferralPage: React.FC = () => {
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const referrals = useSelector((state: State) => state.referrals);
  const referred = useSelector((state: State) => state.referred);
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const shareLink = currentTalent.token || "";
  const [copied, setCopied] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [subscribedRef, setSubscribedRef] = React.useState<Referral[]>([]);
  const [notSubscribedRef, setNotSubscribedRef] = React.useState<Referral[]>([]);
  const [internalEmails, setInternalEmails] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const createReferral = (referral: Partial<Referral>): AnyAction => dispatch(createReferralAction(referral));
  const getReferrals = (talent: Talent): AnyAction => dispatch(getReferralsAction(talent));
  const getReferred = (talent: Talent): AnyAction => dispatch(getReferredAction(talent));
  const onCopyToClipboard = (): void => {
    if (!copied) {
      gtagService.event("click", {
        event_label: "referral - shareLink",
      });
    }
    setCopied(true);
  };

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetReferrals, referrals, getReferrals, [currentTalent]);
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetReferred, referred, getReferred, [currentTalent]);

  React.useEffect(() => {
    const hasRefSuccess = apiSuccess.some((e: ApiSuccessProps) => [GetReferrals.SUCCESS].includes(e.type));
    const hasRefLoading = apiPendingRequests.some((e: ApiRequestProps) => [GetReferrals.REQUEST].includes(e.type));
    const hasRefError = apiErrors.some((e: ApiErrorProps) => [GetReferrals.FAILURE].includes(e.type));
    const isLoading = hasRefLoading || !(hasRefSuccess || hasRefError);
    setLoading(isLoading);
  }, [apiPendingRequests, apiSuccess, apiErrors]);

  React.useEffect(() => {
    const subscribedReferrals: Referral[] = [];
    const notSubscribedReferrals: Referral[] = [];
    referrals.map((r) => {
      r.referee ? subscribedReferrals.push(r) : notSubscribedReferrals.push(r);
      return r;
    });

    setSubscribedRef(subscribedReferrals);
    setNotSubscribedRef(notSubscribedReferrals);
  }, [referrals]);

  const onMultiEmailChange = (val: string[]): void => {
    setInternalEmails(val);
  };

  const onSubmitMultiEmail = (): void => {
    gtagService.event("click", {
      event_label: "referral - multiEmail",
    });
    internalEmails.map((item: string) =>
      createReferral({
        refereeEmail: item,
        referrer: currentTalent,
      }),
    );
    setInternalEmails([]);
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageHeaderContainer}>
        <div className={styles.headerTitleContainer}>
          <h2 className={global.mainTitle}>
            <Markdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`ReferralPage.title`}</Markdown>
          </h2>
          <p className={cx(styles.subText, global.subText)}>{t`ReferralPage.subText`}</p>
        </div>
        {referred && referred.referrer ? (
          <div>
            <div className={styles.referrerCard}>
              <h3 className={cx(styles.partTitle, styles.referrerTitle)}>{t`ReferralPage.sponsor.title`}</h3>
              <div className={styles.imgContainer}>
                <PersonProfileImg
                  hasOverlay={false}
                  src={referred.referrer.image.url}
                  alt={`${referred.referrer.firstname} ${referred.referrer.lastname}`}
                />
              </div>
              <div className={styles.referrerName}>
                {referred.referrer.firstname} {referred.referrer.lastname}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.sharingHowContainer}>
        <div className={styles.sharingMethodes}>
          <div className={styles.shareLinkMethodeContainer}>
            <h3 className={styles.partTitle}>
              <Markdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`ReferralPage.shareLink.title`}</Markdown>
            </h3>
            <div className={styles.shareLinkWrapper}>
              <CopyToClipboard text={shareLink} onCopy={onCopyToClipboard}>
                <div className={cx(styles.shareLinkContainer, { [styles.copied]: copied })}>
                  <div>{shareLink}</div>
                  <div className={styles.copyCtaContainer} data-testid="copy-to-clip">
                    {copied ? (
                      <span>
                        {t`ReferralPage.shareLink.cta.copied`}
                        <BsCheckCircle className={styles.check} />
                      </span>
                    ) : (
                      <span>{t`ReferralPage.shareLink.cta.copy`}</span>
                    )}
                  </div>
                </div>
              </CopyToClipboard>
            </div>
          </div>
          <div>
            <h3 className={cx(styles.partTitle, styles.orTitle)}>Ou</h3>
          </div>
          <div className={styles.shareEmailMethodeContainer}>
            <h3 className={styles.partTitle}>
              <></>
              <Markdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`ReferralPage.shareEmail.title`}</Markdown>
            </h3>
            <div className={styles.shareEmailWrapper}>
              <ReactMultiEmail
                placeholder={t`ReferralPage.shareEmail.placeholder`}
                emails={internalEmails}
                className={styles.multiEmailInput}
                validateEmail={(email: string): boolean => isEmail(email)}
                onChange={onMultiEmailChange}
                getLabel={(email: string, i: number, removeEmail: (index: number) => void): JSX.Element => (
                  <div data-tag="key" key={i} className={styles.emailLabel}>
                    {email}
                    <span data-tag-handle onClick={(): void => removeEmail(i)} className={styles.removeEmail}>
                      x
                    </span>
                  </div>
                )}
              />
              <div className={styles.btnSendEmailsContainer}>
                <button
                  type="button"
                  onClick={onSubmitMultiEmail}
                  id="btnSendEmails"
                  disabled={!internalEmails.length}
                  className={cx(global.cta, global.primaryCta)}
                >
                  {t`ReferralPage.shareEmail.cta`}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.howItWorksTutoContainer}>
            <h3 className={styles.partTitle}>
              <Markdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`ReferralPage.howItWorks.title`}</Markdown>
            </h3>
            <ul className={styles.worksList}>
              <li>
                <div className={styles.worksItemContent}>
                  <span>#1</span>
                  <p>{t`ReferralPage.howItWorks.step1`}</p>
                </div>
              </li>
              <li>
                <div className={styles.worksItemContent}>
                  <span>#2</span>
                  <p>{t`ReferralPage.howItWorks.step2`}</p>
                </div>
              </li>
              <li>
                <div className={styles.worksItemContent}>
                  <span>#3</span>
                  <p>{t`ReferralPage.howItWorks.step3`}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.referralCardsContainer}>
        <h3 className={styles.partTitle}>
          <Markdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`ReferralPage.referee.title`}</Markdown>
        </h3>
        <div className={styles.refereeListContainer}>
          {loading ? (
            <div data-testid="ref-loader">
              <Loader />
            </div>
          ) : (
            <>
              {subscribedRef.length || notSubscribedRef.length ? (
                <>
                  {subscribedRef.length ? (
                    <div key="subRef">
                      <ul className={cx(styles.referralList)} data-testid="sub-list">
                        {subscribedRef.map((item: Referral) => (
                          <ReferralItem referral={item} key={item["@id"]} />
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  {notSubscribedRef.length ? (
                    <div key="notSubRef">
                      <h4 className={styles.subPartTitle}>{t`ReferralPage.referee.noAccount`} </h4>
                      <ul className={cx(styles.referralList)} data-testid="not-sub-list">
                        {notSubscribedRef.map((item: Referral) => (
                          <ReferralItem referral={item} key={item["@id"]} />
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </>
              ) : (
                <div>{t`ReferralPage.referrals.empty`}</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ReferralItem: React.FC<{ referral: Referral }> = (props: { referral: Referral }) => {
  const { referral } = props;
  const imgUrl = referral.referee ? referral.referee.image.url : undefined;
  const imgAlt = referral.referee ? `${t`alts.referral`} ${referral.referee.firstname}` : t`alts.profile.photo`;

  return (
    <li className={styles.referralItem}>
      <div className={styles.refCard}>
        <div className={styles.refCardHeader}>
          <span className={styles.refCardStatus}>{transReferral.status[referral.status]}</span>
        </div>
        <div className={styles.refCardBody}>
          <div className={styles.imgContainer}>
            <PersonProfileImg src={imgUrl} alt={imgAlt} />
          </div>
          <div className={styles.refTalentInfos}>
            {referral.referee ? (
              <h4 key="refereeName">
                {referral.referee.firstname} {referral.referee.lastname}
              </h4>
            ) : null}
            <div key="refereeEmail" title={referral.refereeEmail}>
              {referral.refereeEmail}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
