import qs from "query-string";

import { Talent } from "../../../interfaces/resources/talent";
import client from "../client";

export function getCalendlyEvents(talent: Talent) {
  const params: any = {
    canceled: false,
  };
  return client.get(`${talent["@id"]}/calendly_events?${qs.stringify(params)}`);
}
