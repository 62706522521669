import { isAfter, isWithinInterval, parseISO, subMonths } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";

import { GetCalendlyEvents } from "../../../actionTypes/calendlyEvents";
import { GetCurrentTalent } from "../../../actionTypes/talent";
import { ApiRequestProps } from "../../../interfaces/api/request";
import { State } from "../../../interfaces/api/state";
import { ApiSuccessProps } from "../../../interfaces/api/success";
import { Talent, talentStatuses } from "../../../interfaces/resources/talent";
import { LoadingPage } from "../../../pages/LoadingPage";
import { RefusedPage } from "../../../pages/RefusedPage";
import { routes } from "../../../routes";

import ErrorScene from "../../../scenes/App/ErrorScene";
import { getCalendlyEventsAction } from "../../../services/api/calendlyEvent/actions";
import { getCurrentTalent as getCurrentTalentAction } from "../../../services/api/talent/actions";
import { eventDispatcher } from "../../../utils/eventDispatcher";
import { useApiSelector, useGetResourceHook } from "../../../utils/hooks";
import { CoCModalOpen } from "../../CodeOfConductModalWrapper";
import { RcModalOpen } from "../../ReconnectionModalWrapper";

export const getComponentOrRedirect = (
  Component: any,
  currentTalent: Talent,
  props: RouteComponentProps,
): JSX.Element => {
  if (currentTalent.status === talentStatuses.refused) {
    return <RefusedPage />;
  }

  if (!!!currentTalent.accessToPlatine && props.match?.path !== routes.quiz.quiz) {
    return <Redirect to={routes.quiz.quiz} />;
  }

  if (!!currentTalent.accessToPlatine && props.match?.path === routes.quiz.quiz) {
    return <Redirect to={routes.app.home} />;
  }

  return <Component {...props} />;
};

export const PrivateRoute: React.FC<RouteProps> = (props: RouteProps): JSX.Element => {
  const { component: Component, location, ...rest } = props;
  const { apiSuccess, apiPendingRequests, apiErrors } = useApiSelector();
  const dispatch = useDispatch();
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const calendlyEvents = useSelector((state: State) => state.calendlyEvents);
  const auth = useSelector((state: State) => state.auth);
  const [shouldShowCoc, setShouldShowCoc] = React.useState(false);
  const [shouldShowEditCandidateProfileModal, setShouldShowEditCandidateProfileModal] = React.useState(false);
  const getCurrentTalent = (): void => {
    dispatch(getCurrentTalentAction());
  };
  const loading =
    apiPendingRequests.some((e: ApiRequestProps) => e.type === GetCurrentTalent.REQUEST) && !!!currentTalent;

  const oneMonthAgo = subMonths(new Date(), 1);
  const sixMonthAgo = subMonths(new Date(), 6);
  const now = new Date();
  const talentPreviousLogin = currentTalent && currentTalent.previousLogin ? currentTalent.previousLogin : now;
  const talentLastReactivationStatus =
    currentTalent && currentTalent.lastReactivationStatus ? currentTalent.lastReactivationStatus : undefined;
  const isLessThanXMonths =
    currentTalent
    && (isWithinInterval(new Date(talentPreviousLogin), {
      start: oneMonthAgo,
      end: now,
    })
      || (talentLastReactivationStatus
        && isWithinInterval(new Date(talentLastReactivationStatus), {
          start: oneMonthAgo,
          end: now,
        })));
  const lastTalentProfileEventCreatedAt = currentTalent?.talentProfile?.lastTalentProfileEvent?.createdAt;
  const isTalentProfileEventLessThanXMonths =
    lastTalentProfileEventCreatedAt
    && isWithinInterval(new Date(lastTalentProfileEventCreatedAt), {
      start: sixMonthAgo,
      end: now,
    });
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetCurrentTalent, currentTalent, getCurrentTalent, []);

  const callNotBooked =
    apiSuccess
    && apiSuccess.some((req: ApiSuccessProps) => req.type === GetCalendlyEvents.SUCCESS)
    && !calendlyEvents.some((el) => {
      return isAfter(parseISO(el.startTime), now);
    });

  const isAccepted = currentTalent && currentTalent.status === talentStatuses.accepted;

  React.useEffect(() => {
    if (
      currentTalent
      && location
      && location.pathname !== routes.quiz.quiz
      && !apiPendingRequests.some((e) => e.type === GetCalendlyEvents.REQUEST)
      && !apiErrors.some((e) => e.type === GetCalendlyEvents.FAILURE)
      && !apiSuccess.some((e) => e.type === GetCalendlyEvents.SUCCESS)
    ) {
      dispatch(getCalendlyEventsAction(currentTalent));
    }
  }, [apiPendingRequests, apiErrors, apiSuccess, currentTalent, dispatch, location]);

  React.useEffect(() => {
    if (auth.isAuthed && currentTalent && !!currentTalent.accessToPlatine && !currentTalent.codeOfConduct) {
      setShouldShowCoc(true);
    }
  }, [currentTalent, auth.isAuthed, setShouldShowCoc]);

  React.useEffect(() => {
    if (shouldShowCoc) {
      eventDispatcher.trigger(CoCModalOpen);
    }
  }, [shouldShowCoc]);

  React.useEffect(() => {
    if (!!currentTalent && isAccepted && !isTalentProfileEventLessThanXMonths) {
      setShouldShowEditCandidateProfileModal(true);
    }
  }, [callNotBooked, isAccepted, auth.isAuthed, currentTalent, isLessThanXMonths, isTalentProfileEventLessThanXMonths]);

  React.useEffect(() => {
    if (shouldShowEditCandidateProfileModal) {
      eventDispatcher.trigger(RcModalOpen);
    }
  }, [shouldShowEditCandidateProfileModal]);

  const renderComponent = (routeProps: RouteComponentProps): JSX.Element => {
    return auth.isAuthed ? (
      apiPendingRequests.some((req: ApiRequestProps) => req.type === GetCurrentTalent.REQUEST) && !currentTalent ? (
        <LoadingPage />
      ) : currentTalent ? (
        <>{getComponentOrRedirect(Component, currentTalent, routeProps)}</>
      ) : (
        <ErrorScene fullscreen error={apiErrors.length > 0 ? apiErrors[apiErrors.length - 1].payload.status : 500} />
      )
    ) : (
      <Redirect
        to={{
          pathname: routes.auth.login,
        }}
      />
    );
  };
  return (
    <>
      {loading && !currentTalent ? (
        <LoadingPage />
      ) : (
        <Route {...rest} render={(routeProps): JSX.Element => renderComponent(routeProps)} />
      )}
    </>
  );
};
