import cx from "classnames";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { MdDelete } from "react-icons/md";
import { Input } from "../../../../../components/Utils/Form/Input";
import { Select } from "../../../../../components/Utils/Form/Select";
import globalStyle from "../../../../../styles/global.module.scss";
import quizStyle from "../../../index.module.scss";
import styles from "./index.module.scss";

interface Props {
  submitItemModification: (values: any, element: string) => void;
  item?: { language: string; proficiency: { value: string; label: string } };
  deleteItem?: (values: any, element: string) => void;
  addItem?: (values: any, element: string) => void;
}

export const ModifyLanguage: React.FC<any> = ({ submitItemModification, item, deleteItem, addItem }: Props) => {
  return (
    <Formik
      initialValues={item ? item : { name: null, proficiency: null }}
      onSubmit={(values) => submitItemModification({ ...values, proficiency: values.proficiency?.value }, "languages")}
    >
      {({ values }): JSX.Element => (
        <Form className={cx("form", styles.form)}>
          <div className="formGroup formInlineGroup double mobileSingleLine">
            <Field name="name" label="Langue" component={Input} />
            <Field
              name="proficiency"
              label="Niveau"
              isSearchable={false}
              component={Select}
              options={[
                { value: "professional_working", label: "Professional Working" },
                { value: "native_or_bilingual", label: "Native or Bilingual" },
                { value: "limited_working", label: "Limited Working" },
                { value: "elementary", label: "Elementary" },
                { value: "full_professional", label: "Full Professional" },
              ]}
              getOptionValue={(option: any): any => option.value}
              className={styles.proficiency}
            />
          </div>
          <div className={styles.ctasContainer}>
            {item && deleteItem ? (
              <>
                <div>
                  <button
                    type="button"
                    onClick={() => deleteItem(item, "languages")}
                    className={cx(
                      quizStyle.deleteCta,
                      globalStyle.smallerCta,
                      globalStyle.cta,
                      globalStyle.alertCta,
                      globalStyle.inverted,
                    )}
                  >
                    <MdDelete />
                    <span> Supprimer</span>
                  </button>
                </div>
                <div className={styles.submitCtaContainer}>
                  <button
                    type="submit"
                    className={cx(
                      globalStyle.smallerCta,
                      globalStyle.cta,
                      globalStyle.primaryCta,
                      globalStyle.inverted,
                    )}
                  >
                    Modifier
                  </button>
                </div>
              </>
            ) : addItem ? (
              <div className={styles.submitCtaContainer}>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    addItem({ ...values, proficiency: values.proficiency?.value }, "languages");
                  }}
                  className={cx(globalStyle.smallerCta, globalStyle.cta, globalStyle.primaryCta, globalStyle.inverted)}
                >
                  Ajouter
                </button>
              </div>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};
