import { t } from "@lingui/macro";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import { CandidateProfileCard } from "../../blocks/candidateProfileCard";
import { NewNotificationsHelmet } from "../../blocks/newNotificationsHelmet";
import { PageWithTabs, TabPanel, TabTrigger } from "../../components/Tabs";
import { State } from "../../interfaces/api/state";
import { applicationOngoinProcess } from "../../interfaces/resources/application";
import { hashes } from "../../routes";

import { transPages } from "../../translations/constants/pages";
import styles from "./index.module.scss";
import { PanelMyOffers } from "./panels/PanelMyOffers";
import { PanelMyProcess } from "./panels/PanelMyProcess";

interface TabProps {
  slug: string;
  tabName: React.ReactNode;
  component?: JSX.Element;
}

export const OffersPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const locationActivePanel = !!location.hash ? location.hash : hashes.offers;
  const applications = useSelector((state: State) => state.applications);
  const [showTechMessage, setShowTechMessage] = useState(false);
  const [activePanel, setActivePanel] = useState(locationActivePanel);
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const addHashToUrl = (h: string): void => {
    if (location.hash !== h) {
      history.push(h);
    }
  };

  useEffect(() => {
    if (applications && applications.length) {
      applications.map((ap) => {
        if (ap.offer.company.slug === "elinoi" && applicationOngoinProcess.includes(ap.statusAsTalent)) {
          setShowTechMessage(true);
        }
        return ap;
      });
    }
  }, [applications]);

  useEffect(() => {
    setActivePanel(!!location.hash ? location.hash : hashes.offers);
  }, [location]);
  useEffect(() => {
    setTabs([
      {
        tabName: t({ id: transPages.offers }),
        slug: hashes.offers,
        component: <PanelMyOffers />,
      },
      {
        tabName: t({ id: transPages.process }),
        slug: hashes.process,
        component: <PanelMyProcess />,
      },
    ]);
  }, []);

  return (
    <>
      <NewNotificationsHelmet title={t`OffersListScene.title`} />
      <div className={styles.pageWrapper}>
        <PageWithTabs defaultActivePanel={activePanel} defaultActiveClass={styles.tabActive}>
          <div className={styles.pageHeader}>
            <ul className={styles.tabTriggerList} data-testid="tabtrigger-list">
              {tabs.map((tab) => {
                return (
                  <li key={`page-offer-tab--${tab.slug}`}>
                    <TabTrigger
                      tabKey={tab.slug}
                      onClick={(): void => {
                        addHashToUrl(tab.slug);
                      }}
                    >
                      <div className={cx(styles.tabTriggerContent)} data-testid={`tab-${tab.slug}`}>
                        {tab.tabName}
                      </div>
                    </TabTrigger>
                  </li>
                );
              })}
            </ul>
            {showTechMessage ? (
              <div key="techMessage" className={styles.goodLuckTextContainer}>
                <span role="img" aria-label="eyes">
                  👀
                </span>
                <div className={styles.goodLuckText}>
                  Psst... Apparemment tu passes des process chez nous <br /> Bonne chance de la part de l&apos;équipe
                  tech !
                </div>
                <span role="img" aria-label="eyes">
                  👀
                </span>
              </div>
            ) : null}
          </div>
          <div className={styles.pagePanelContainer}>
            {tabs.map((tab) => {
              return (
                <TabPanel key={`page-offer-panel--${tab.slug}`} panelKey={tab.slug}>
                  {tab.component}
                </TabPanel>
              );
            })}
          </div>
          <div className={styles.candidateProfileContainer}>
            <p className={styles.candidateProfileText}>
              Ces offres ne correspondent plus à ton profil ? <br /> Mets ton profil à jour ici 👇 et les offres seront
              plus pertinentes !
            </p>
            <div className={styles.profileCardContainer}>
              <CandidateProfileCard />
            </div>
          </div>
        </PageWithTabs>
      </div>
    </>
  );
};
