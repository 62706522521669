import { ActionType } from "../interfaces/api/actionType";

export const GetCompany: ActionType = {
  FAILURE: "GET_COMPANY_FAILURE",
  REQUEST: "GET_COMPANY_REQUEST",
  SUCCESS: "GET_COMPANY_SUCCESS",
};

export const GetCompanies: ActionType = {
  FAILURE: "GET_COMPANIES_FAILURE",
  REQUEST: "GET_COMPANIES_REQUEST",
  SUCCESS: "GET_COMPANIES_SUCCESS",
};
