import { t } from "@lingui/macro";

export const transReferral: {
  status: {
    [key: string]: string;
  };
} = {
  status: {
    accepted: t`referrals.status.accepted`,
    call_booked: t`referrals.status.call_booked`,
    interviews: t`referrals.status.interviews`,
    job_found: t`referrals.status.job_found`,
    joined: t`referrals.status.joined`,
    pending: t`referrals.status.pending`,
    refused: t`referrals.status.refused`,
  },
};

export const transTooltipMessageReferral: {
  tooltipMesssage: {
    [key: string]: string;
  };
} = {
  tooltipMesssage: {
    accepted: t`referrals.tooltipMessage.accepted`,
    call_booked: t`referrals.tooltipMessage.joined`,
    interviews: t`referrals.tooltipMessage.interviews`,
    job_found: t`referrals.tooltipMessage.job_found`,
    joined: t`referrals.tooltipMessage.joined`,
    pending: t`referrals.tooltipMessage.pending`,
    refused: t`referrals.tooltipMessage.refused`,
  },
};
