import { t } from "@lingui/macro";
import { differenceInDays, differenceInHours, format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";

import { Application, applicationStatuses, ApplicationStatusEvent } from "../../interfaces/resources/application";
import { CalendlyEvent } from "../../interfaces/resources/calendlyEvent";
import { Offer } from "../../interfaces/resources/offer";
import transApplication from "../../translations/constants/application";

export function extractIdFromIri(iri: string): number {
  return Number(iri.split("/").slice(-1)[0]);
}

export const isNew = ({ application, createdAt }: Offer): boolean => {
  return !application && differenceInDays(new Date(), new Date(createdAt)) < 7;
};

export const getOfferCardLabel = (application?: Application, calendlyEvent?: CalendlyEvent): string | null => {
  if (application) {
    if (
      application.statusAsTalent === applicationStatuses.applied
      && differenceInHours(new Date(), new Date(application.updatedAt)) > 24
    ) {
      return t({ id: transApplication.status.pending });
    }
    if (application.statusAsTalent === applicationStatuses.callBooked) {
      return calendlyEvent
        ? t({
            id: "OfferCardLabel.call_booked",
            values: {
              date: format(parseISO(calendlyEvent.startTime), "dd/MM à HH'h'mm", {
                locale: fr,
              }),
              name: calendlyEvent.coreUserFirstName,
            },
          })
        : t`application.status.call_booked`;
    }

    return t({ id: transApplication.status[application.statusAsTalent] });
  }

  return null;
};

export const hasApplied = (application: Application | null | undefined): boolean => {
  const { contacted, toContact, interesting } = applicationStatuses;
  return !!application && ![contacted, toContact, interesting].includes(application.statusAsTalent);
};

export const isActive = (application: Application): boolean =>
  !(
    (application.statusAsTalent === applicationStatuses.accepted
      && differenceInDays(new Date(), new Date(application.updatedAt)) > 5)
    || [
      applicationStatuses.interesting,
      applicationStatuses.contacted,
      applicationStatuses.toContact,
      applicationStatuses.refusedElinoi,
      applicationStatuses.refusedStartup,
      applicationStatuses.refusedTalent,
    ].includes(application.statusAsTalent)
  );

export const isPushed = (application: Application): boolean => {
  const { interesting, contacted } = applicationStatuses;
  const status = [interesting, contacted];
  return (
    status.includes(application.statusAsTalent)
    || application.applicationStatusEvents.some((e: ApplicationStatusEvent) => status.includes(e.status))
  );
};

export const isArchived = (application: Application): boolean =>
  [applicationStatuses.refusedElinoi, applicationStatuses.refusedStartup, applicationStatuses.refusedTalent].includes(
    application.statusAsTalent,
  )
  || (application.statusAsTalent === applicationStatuses.accepted
    && differenceInDays(new Date(), new Date(application.updatedAt)) > 5);

export const filterOffers = (offersToFilter: Offer[], offers: Offer[]): Offer[] =>
  offersToFilter.filter((elem1: Offer) => !offers.some((elem2: Offer) => elem1["@id"] === elem2["@id"]));

export const queryString = (params: { [key: string]: string }): string =>
  Object.keys(params)
    .map((key: string) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
