import { t } from "@lingui/macro";
import * as React from "react";
import { NewNotificationsHelmet } from "../../blocks/newNotificationsHelmet";
import { RessourcesPage } from "../../pages/RessourcesPage";

const RessourcesScene = (): JSX.Element => (
  <>
    <NewNotificationsHelmet title={t`OffersListScene.title`} />
    <RessourcesPage />
  </>
);

export default RessourcesScene;
