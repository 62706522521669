import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import { Form, FormRadioProps } from "semantic-ui-react";
import { Violation } from "../../../../interfaces/api/violation";
import { talentSearchStatusReasons } from "../../../../interfaces/resources/talent";
import { getViolationMessage } from "../../../../utils/hooks";
import ErrorMessage from "../ErrorMessage";
import styles from "./index.module.scss";

interface RadioFieldProps extends FieldProps, FormRadioProps {
  className: string;
  getOptionLabel?: (option: any, value: any) => string;
  getOptionValue?: (option: any) => string;
  item: any;
}

export const formatValue = (value: any, getOptionValue?: (option: any) => string) =>
  getOptionValue ? getOptionValue(value) : value;

export const formatLabel = (label?: any, value?: any, getOptionLabel?: (l: any, v: any) => string): any =>
  label ? (getOptionLabel ? getOptionLabel(label, value) : label) : null;

export const RadioField = ({
  error,
  field,
  form,
  getOptionLabel,
  getOptionValue,
  option,
  ...rest
}: RadioFieldProps): JSX.Element => {
  const [, meta] = useField(field.name);

  return (
    <Form.Radio
      {...field}
      checked={field.value === formatValue(option, getOptionValue)}
      error={(meta && meta.touched && !!meta.error) || !!error}
      onChange={(_e: any, data: any) => form.setFieldValue(field.name, data.value)}
      onBlur={() => form.setFieldTouched(field.name, true)}
      value={formatValue(option, getOptionValue)}
      label={formatLabel(option, field.value, getOptionLabel)}
      {...rest}
    />
  );
};

interface RadioProps extends FieldProps, FormRadioProps {
  name: string;
  options: any[];
  className?: string;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  setJobFoundCompany?: (comp: string | undefined) => void;
  jobFoundCompany?: string;
  isRequired?: boolean;
  label?: string;
  optionsProps?: any[];
  violations?: Violation[];
}

export const SearchingReasonRadio: React.FC<RadioProps> = ({
  className,
  field,
  form,
  setJobFoundCompany,
  getOptionLabel,
  getOptionValue,
  jobFoundCompany,
  isRequired = true,
  label,
  options,
  optionsProps,
  violations,
  ...rest
}: RadioProps) => {
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);

  return (
    <div className={cx(className, { error: (meta.touched && !!meta.error) || !!error })}>
      {label ? (
        <label className="formLabel">
          {label} {isRequired ? "*" : null}
        </label>
      ) : undefined}
      <div className={"radio-searching-reason-options-container"}>
        {options.map((option: keyof typeof talentSearchStatusReasons, idx) => (
          <>
            <RadioField
              error={error}
              field={field}
              form={form}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              key={option}
              option={option}
              {...rest}
              {...(Array.isArray(optionsProps) && optionsProps.length > idx ? optionsProps[idx] : [])}
            />
            {option === talentSearchStatusReasons.job_found && field.value === talentSearchStatusReasons.job_found ? (
              <div className={styles.jobFoundInputContainer} key={`${option}-jobFoundInput`}>
                <input
                  className={styles.jobFoundInput}
                  type="text"
                  placeholder="Nom de l'entreprise"
                  value={jobFoundCompany || ""}
                  onChange={(e) => {
                    const val = !!e.target.value ? e.target.value : undefined;
                    if (typeof setJobFoundCompany === "function") {
                      setJobFoundCompany(val);
                    }
                  }}
                />
              </div>
            ) : null}
          </>
        ))}
      </div>
      <ErrorMessage name={field.name} message={error} />
    </div>
  );
};
