import { ActionType } from "../interfaces/api/actionType";

export const GetReferrals: ActionType = {
  FAILURE: "GET_REFERRALS_FAILURE",
  REQUEST: "GET_REFERRALS_REQUEST",
  SUCCESS: "GET_REFERRALS_SUCCESS",
};

export const GetReferred: ActionType = {
  FAILURE: "GET_REFERRED_FAILURE",
  REQUEST: "GET_REFERRED_REQUEST",
  SUCCESS: "GET_REFERRED_SUCCESS",
};

export const CreateReferral: ActionType = {
  FAILURE: "CREATE_REFERRAL_FAILURE",
  REQUEST: "CREATE_REFERRAL_REQUEST",
  SUCCESS: "CREATE_REFERRAL_SUCCESS",
};

export const ValidateReferral: ActionType = {
  FAILURE: "VALIDATE_REFERRAL_FAILURE",
  REQUEST: "VALIDATE_REFERRAL_REQUEST",
  SUCCESS: "VALIDATE_REFERRAL_SUCCESS",
};
