import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import { messages } from "./translations/fr/messages";

i18n.load("fr", messages);
i18n.activate("fr");

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (typeof window !== "undefined" && (window as any).heapLoad) {
  const win = window as any;
  win.heapLoad(process.env.REACT_APP_HEAP_ID);
}

ReactDOM.render(
  <I18nProvider i18n={i18n}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
