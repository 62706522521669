import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetApplications } from "../../../actionTypes/applications";
import { GetOffer, GetOffers, GetLastAcceptedApplication } from "../../../actionTypes/offers";

import { apiFailure } from "../helpers";
import * as apiClient from "./api";

export function* getOffer(action: AnyAction) {
  try {
    const response = yield call(apiClient.getOffer, action.slug);
    if (response.data.hasOwnProperty("hydra:member") && response.data["hydra:member"].length > 0) {
      const offer = response.data["hydra:member"][0];
      yield put({
        offer,
        type: GetOffer.SUCCESS,
      });
    } else {
      yield put({
        type: GetOffer.FAILURE,
      });
    }
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* getOffers(action: AnyAction) {
  try {
    const response = action.currentTalent
      ? yield call(apiClient.getOffers, action.currentTalent)
      : yield call(apiClient.getOffersFromCompany, action.company);

    if (action.currentTalent) {
      yield put({
        currentTalent: action.currentTalent,
        type: GetApplications.REQUEST,
      });
    }
    const offers = response.data["hydra:member"];
    yield put({
      offers,
      type: GetOffers.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* getLastAcceptedApplications(action: AnyAction) {
  try {
    const response = yield call(apiClient.getLastAcceptedApplications, action.id);
    if (response.data.hasOwnProperty("hydra:member")) {
      const lastAcceptedApplications = response.data["hydra:member"];
      yield put({
        lastAcceptedApplications,
        type: GetLastAcceptedApplication.SUCCESS,
      });
    } else {
      yield put({
        type: GetLastAcceptedApplication.FAILURE,
      });
    }
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* offerSaga() {
  yield takeLatest(GetOffer.REQUEST, getOffer);
  yield takeLatest(GetOffers.REQUEST, getOffers);
  yield takeLatest(GetLastAcceptedApplication.REQUEST, getLastAcceptedApplications);
}
