import { push } from "connected-react-router";
import { AnyAction } from "redux";
import { ForkEffect, put, PutEffect, race, RaceEffect, take, TakeEffect, takeEvery } from "redux-saga/effects";

import { ApiAuthenticationRefresh } from "../../actionTypes/authentication";
import { routes } from "../../routes";
import { logoutAction, refreshAction } from "./authentication/actions";
import { cookies, getFailType, getSuccessType, REFRESH_TOKEN_KEY, removeTokens } from "./helpers";

const ignoreActionTypes = [ApiAuthenticationRefresh.REQUEST];

type MonitorResponseProps = Generator<
  RaceEffect<TakeEffect> | PutEffect<AnyAction>,
  void,
  {
    fail: any;
  } & {
    success: any;
  }
>;

export function monitorableAction(action: AnyAction): boolean {
  return action.type.includes("REQUEST") && ignoreActionTypes.every((fragment) => !action.type.includes(fragment));
}

export function* monitor(monitoredAction: AnyAction): MonitorResponseProps {
  const { fail } = yield race({
    fail: take(getFailType(monitoredAction)),
    success: take(getSuccessType(monitoredAction)),
  });
  if (fail && fail.payload) {
    switch (fail.payload.status) {
      case 401:
        yield put(refreshAction(cookies.get(REFRESH_TOKEN_KEY) || ""));

        const { success } = yield race({
          fail: take(ApiAuthenticationRefresh.FAILURE),
          success: take(ApiAuthenticationRefresh.SUCCESS),
        });

        if (success) {
          yield put(monitoredAction);
        } else {
          yield put(logoutAction());
          removeTokens();
        }
        break;
      case 403:
        removeTokens();
        yield put(push(routes.auth.login));
        break;
      default:
        break;
    }
  }
}

export default function* apiSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(monitorableAction, monitor);
}
