import { Responsibility, ResponsibilityWithSeniority } from "../../../interfaces/resources/responsibility";
export interface GroupedResponsibility {
  id: string;
  label: string;
  options: ResponsibilityWithSeniority[];
}

export const groupResponsibilities: (responsibilities: Responsibility[]) => GroupedResponsibility[] = (
  responsibilities,
) => {
  return responsibilities.reduce((result: any[], item: Responsibility) => {
    const responsibilityType = item.responsibilityType;
    if (result.some((e) => e.label === responsibilityType.value)) {
      result.map((e) => {
        if (e.label === responsibilityType.value) {
          e.options.push(item);
        }
        return true;
      });
    } else {
      result.push({
        id: responsibilityType["@id"],
        label: responsibilityType.value,
        options: [item],
      });
    }
    return result;
  }, []);
};
