import { t } from "@lingui/macro";
import * as React from "react";
import { NewNotificationsHelmet } from "../../../blocks/newNotificationsHelmet";

import { SettingsPage } from "../../../pages/SettingsPage";

const SettingScene = (): JSX.Element => (
  <>
    <NewNotificationsHelmet title={t`SettingScene.title`} />
    <SettingsPage />
  </>
);

export default SettingScene;
