import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import PhoneInputComp from "react-phone-number-input";
import fr from "react-phone-number-input/locale/fr.json";
import "react-phone-number-input/style.css";

import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../../utils/hooks";
import ErrorMessage from "../ErrorMessage";
import styles from "./index.module.scss";

interface PhoneInputProps extends FieldProps {
  className?: string;
  label?: string;
  placeholder?: string;
  violations?: Violation[];
}
export const PhoneInput = ({
  className = "",
  field: { name },
  form,
  label,
  placeholder,
  violations,
  ...rest
}: PhoneInputProps): JSX.Element => {
  const [field, meta] = useField(name);
  const error = violations && getViolationMessage(field.name, violations);

  return (
    <div className={cx(className, { error: (meta.touched && !!meta.error) || !!error })}>
      {label ? <label className="formLabel">{label}</label> : null}
      <PhoneInputComp
        {...rest}
        {...field}
        className={styles.phoneInput}
        value={field.value}
        defaultCountry="FR"
        placeholder={placeholder}
        labels={fr}
        onChange={(value: any): any => form.setFieldValue(field.name, value)}
      />
      <ErrorMessage name={field.name} message={error} />
    </div>
  );
};
