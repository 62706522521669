import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetSkill, GetSkills } from "../../../actionTypes/skills";
import { Skill } from "../../../interfaces/resources/skill";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri } from "../helpers";
import { getSkillSuccessAction, getSkillsAction, getSkillsSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getSkills(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": Skill[];
      "hydra:view"?: {
        "hydra:next"?: string;
      };
    }> = yield call(apiClient.getSkills, action.page);
    yield put(getSkillsSuccessAction(response.data["hydra:member"], action));
    if (response.data["hydra:view"] && response.data["hydra:view"]["hydra:next"]) {
      yield put(getSkillsAction(extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"])));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getSkill(action: AnyAction) {
  try {
    const response: AxiosResponse<any> = yield call(apiClient.getSkill, action.responsibility["@id"]);
    yield put(getSkillSuccessAction(response.data["hydra:member"]));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* getSkillsSaga() {
  yield takeLatest(GetSkills.REQUEST, getSkills);
  yield takeLatest(GetSkill.REQUEST, getSkill);
}
