import { t } from "@lingui/macro";
import cx from "classnames";
import React from "react";
import { Helmet } from "react-helmet";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetApplications } from "../../actionTypes/applications";
import { GetCompany } from "../../actionTypes/companies";
import { CreateCompanyEvent } from "../../actionTypes/companyEvent";
import { GetOffers } from "../../actionTypes/offers";
import { CompanyApplicationCta } from "../../blocks/companyApplicationCta";
import { CompanyImg } from "../../blocks/companyImg";
import { TabPanel, PageWithTabs, TabTrigger } from "../../components/Tabs";
import LoaderComp from "../../components/Utils/Loader";

import { ApiErrorProps } from "../../interfaces/api/error";
import { ApiRequestProps } from "../../interfaces/api/request";
import { State } from "../../interfaces/api/state";
import { ApiSuccessProps } from "../../interfaces/api/success";
import { Offer, offerModes, offerStatuses } from "../../interfaces/resources/offer";
import { Talent } from "../../interfaces/resources/talent";

import { routes } from "../../routes";
import { getApplications } from "../../services/api/applications/actions";
import { createCompanyEvent, getCompanyAction } from "../../services/api/company/actions";
import { getOffersFromCompanyAction } from "../../services/api/offer/actions";
import { gtagService } from "../../services/google/gtag";
import { hasBeenRefused } from "../../utils/helpers";
import { useApiSelector } from "../../utils/hooks";

import { onInitPageLoadTracking } from "../../utils/tracking";
import styles from "./index.module.scss";
import { PanelCompany } from "./panels/PanelCompany";
import { PanelOffers } from "./panels/PanelOffers";

interface Props {
  match: {
    params: {
      slug: string;
    };
  };
}

interface TabProps {
  slug: string;
  eventName?: string;
  tabName: React.ReactNode;
  component: JSX.Element;
  disabled?: boolean;
}

export const CompanyPage: React.FC<Props> = (props: Props) => {
  const { match } = props;
  const slug = match.params.slug;
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const currentTalent: Talent = useSelector((state: State) => state.currentTalent);
  const company = useSelector((state: State) => state.company);
  const application = useSelector((state: State) => state.application);
  const offers = useSelector((state: State) => state.availableOffers);
  const [spontaneousOffer, setSpontaneousOffer] = React.useState<Offer | undefined>(undefined);
  const [tabs, setTabs] = React.useState<TabProps[]>([]);
  const companyLoading =
    apiPendingRequests.some((req: ApiRequestProps) => [GetCompany.REQUEST].includes(req.type))
    || apiErrors.some((err: ApiErrorProps) => [GetCompany.FAILURE].includes(err.type));

  const offersLoading =
    apiPendingRequests.some((req: ApiRequestProps) => [GetOffers.REQUEST].includes(req.type))
    || apiErrors.some((err: ApiErrorProps) => [GetOffers.FAILURE].includes(err.type));

  const applicationsLoading =
    apiPendingRequests.some((req: ApiRequestProps) => [GetApplications.REQUEST].includes(req.type))
    || apiErrors.some((err: ApiErrorProps) => [GetApplications.FAILURE].includes(err.type));

  React.useEffect(() => {
    if (
      company
      && !apiErrors.some((err: ApiErrorProps) => err.type === CreateCompanyEvent.FAILURE)
      && !apiPendingRequests.some((req: ApiRequestProps) => req.type === CreateCompanyEvent.REQUEST)
      && !apiSuccess.some((suc: ApiSuccessProps) => suc.type === CreateCompanyEvent.SUCCESS)
    ) {
      dispatch(
        createCompanyEvent({
          company,
          status: "read",
          talent: currentTalent,
        }),
      );
    }
  }, [company, currentTalent, dispatch, apiPendingRequests, apiErrors, apiSuccess]);

  React.useEffect(() => {
    if (
      spontaneousOffer
      && !apiErrors.some((err: ApiErrorProps) => err.type === GetApplications.FAILURE)
      && !apiPendingRequests.some((req: ApiRequestProps) => req.type === GetApplications.REQUEST)
      && !apiSuccess.some((suc: ApiSuccessProps) => suc.type === GetApplications.SUCCESS)
    ) {
      dispatch(getApplications(currentTalent, spontaneousOffer));
    }
  }, [spontaneousOffer, currentTalent, dispatch, apiErrors, apiPendingRequests, apiSuccess]);

  React.useEffect(() => {
    if (offers.length) {
      const sO = offers.filter((o) => o.mode === offerModes.spontaneous && o.status === offerStatuses.activated);
      setSpontaneousOffer(sO.length ? sO[0] : undefined);
    }
  }, [offers]);

  React.useEffect(() => {
    if (!!!company) {
      dispatch(getCompanyAction(slug));
    }
  }, [company, slug, dispatch]);

  React.useEffect(() => {
    if (company) {
      if (
        !apiErrors.some((err: ApiErrorProps) => err.type === GetOffers.FAILURE)
        && !apiPendingRequests.some((req: ApiRequestProps) => req.type === GetOffers.REQUEST)
        && !apiSuccess.some((suc: ApiSuccessProps) => suc.type === GetOffers.SUCCESS)
      ) {
        dispatch(getOffersFromCompanyAction(company));
      }
      setTabs([
        {
          slug: "startup",
          tabName: t`CompanyPage.tab.company.title`,
          component: <PanelCompany company={company} />,
        },
        {
          slug: "offers",
          tabName: t`CompanyPage.tab.offers.title`,
          component: (
            <PanelOffers offers={offers.filter((o) => o.mode !== "spontaneous" && o.status === "activated")} />
          ),
        },
      ]);
    }
  }, [company, offers, dispatch, apiErrors, apiPendingRequests, apiSuccess]);

  React.useEffect(() => {
    if (company) {
      onInitPageLoadTracking();
    }
  }, [company]);

  return (
    <div>
      <Helmet>
        <title>{t({ id: "CompanyPage.title", values: { name: company && company.name } })}</title>
        <meta name="" content="" />
      </Helmet>
      {!companyLoading && company ? (
        <PageWithTabs
          defaultActivePanel="startup"
          defaultActiveClass={styles.tabActive}
          defaultDisabledClass={styles.tabDisabled}
        >
          <div className={styles.companyHeader} data-testid="company-header">
            <div className={styles.returnCtaContainer}>
              <Link
                to={routes.app.companies}
                onClick={(): void => {
                  gtagService.event("click", {
                    event_label: "company - fleche retour",
                  });
                }}
              >
                <FaLongArrowAltLeft /> {t`CompanyPage.return.text`}
              </Link>
            </div>
            <div className={styles.companyHeaderContent}>
              <div className={styles.startupLogoContainer}>
                <div className={styles.startupLogo}>
                  <CompanyImg src={company.image.url} name={company.name} />
                </div>
              </div>
              <div className={styles.companyInfoTabsContainer}>
                <div className={styles.companyTitleStartupContainer}>
                  <h1 className={styles.companyTitle}>{company.name}</h1>
                  {company.sectors.length >= 1 ? (
                    <span className={styles.startupName}>
                      {company.sectors
                        .map((sector) => {
                          return sector.value;
                        })
                        .join(" - ")}
                    </span>
                  ) : null}
                </div>
                <div>
                  <ul className={styles.tabsList}>
                    {tabs.map((tab) => {
                      return (
                        <li key={`page-company-tab--${tab.slug}`}>
                          <TabTrigger
                            tabKey={tab.slug}
                            disabled={tab.disabled}
                            onClick={(): void => {
                              gtagService.event("click", {
                                event_label: `offre - onglet - ${tab.eventName ?? tab.slug}`,
                              });
                            }}
                          >
                            <div data-testid={`tabtest-${tab.slug}`} className={cx(styles.tabsLink)}>
                              {tab.tabName}
                            </div>
                          </TabTrigger>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className={styles.applicationCtaContainer}>
                {!offersLoading && !applicationsLoading && spontaneousOffer && !hasBeenRefused(application) ? (
                  <div className={styles.ctaSupText}>
                    {t({
                      id: "CompanyPage.applicationCta.supText",
                      values: {
                        name: company.name,
                      },
                    })}
                  </div>
                ) : null}
                <div className={styles.ctaContainer}>
                  <CompanyApplicationCta
                    application={application}
                    offer={spontaneousOffer}
                    isLoading={offersLoading || applicationsLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            {tabs.map((tab) => {
              return (
                <TabPanel key={`page-company-panel--${tab.slug}`} panelKey={tab.slug}>
                  <div className={styles.tabPanelWrapper}>{tab.component}</div>
                </TabPanel>
              );
            })}
          </div>
        </PageWithTabs>
      ) : (
        <div className={styles.loaderContainer} data-testid="loader">
          <LoaderComp />
        </div>
      )}
    </div>
  );
};
