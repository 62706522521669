import { t } from "@lingui/macro";
import anime from "animejs";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ReactMarkdown from "react-markdown";
import globalStyle from "../../styles/global.module.scss";
import { eventDispatcher } from "../../utils/eventDispatcher";
import styles from "./index.module.scss";

export interface Props {
  open?: boolean;
}

export const OnboardModalOpen = "OnboardModal:open";
export const OnboardModalClose = "OnboardModal:close";

export const OnboardingModalWrapper: FC<Props> = ({ open = false }: Props) => {
  const $modalWrapper = useRef(null);
  const $modalContainer = useRef(null);
  const $modalCloseX = useRef(null);
  const $closeBtn = useRef(null);
  const openEvent = useRef<MiniSignalBinding>();
  const closeEvent = useRef<MiniSignalBinding>();
  const [show, setShow] = useState(open);
  const [hidden, setHidden] = useState(!open);

  const showAnimation = (): void => {
    document.body.style.overflow = "hidden";

    const tl = anime.timeline({
      duration: 300,
      easing: "easeInOutExpo",
    });

    tl.add({
      easing: "linear",
      opacity: 1,
      targets: $modalWrapper.current,
    }).add({
      duration: 600,
      opacity: [0, 1],
      targets: $modalContainer.current,
      translateY: [-10, 0],
    });
  };
  const hideAnimation = (): void => {
    const tl = anime.timeline({
      complete: () => {
        document.body.style.overflow = "";
        setHidden(true);
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: 0,
      targets: $modalContainer.current,
      translateY: 20,
    }).add(
      {
        opacity: 0,
        targets: $modalWrapper.current,
      },
      0,
    );
  };

  const showModal = (): void => {
    setHidden(false);
    setShow(true);
  };
  const hideModal = (): void => {
    setShow(false);
  };

  const onClickWrapper = (event: SyntheticEvent<HTMLDivElement>) => {
    if (
      event.target === $modalWrapper.current
      || event.target === $modalContainer.current
      || event.target === $modalCloseX.current
      || event.target === $closeBtn.current
    ) {
      eventDispatcher.trigger(OnboardModalClose);
    }
  };

  useEffect(() => {
    openEvent.current = eventDispatcher.on(OnboardModalOpen, showModal);
    closeEvent.current = eventDispatcher.on(OnboardModalClose, hideModal);

    return (): void => {
      if (openEvent.current) {
        eventDispatcher.off(OnboardModalOpen, openEvent.current);
      }
      if (closeEvent.current) {
        eventDispatcher.off(OnboardModalClose, closeEvent.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!show && !hidden) {
      hideAnimation();
      return;
    } else if (show && !hidden) {
      showAnimation();
    }
  }, [show, hidden]);

  return hidden ? null : (
    <div className={styles.modalWrapper} ref={$modalWrapper} style={{ opacity: 0 }} onClick={onClickWrapper}>
      <span className={styles.closeModalX} ref={$modalCloseX}>
        <AiOutlineCloseCircle />
      </span>
      <div ref={$modalContainer} className={styles.modalContainer}>
        <div className={styles.modal}>
          <div>
            <div className={styles.titleContainer}>
              <h1 className={globalStyle.mainTitle}>
                <ReactMarkdown
                  disallowedTypes={["paragraph"]}
                  unwrapDisallowed
                >{t`OnboardingModal.title`}</ReactMarkdown>
              </h1>
            </div>
            <div>
              <div className={styles.textContainer}>
                <ReactMarkdown>{t`OnboardingModal.text`}</ReactMarkdown>
              </div>
              <div className={styles.videoContainer}>
                <div className={styles.video}>
                  <iframe
                    title={t`OnboardingModal.title`}
                    src="https://www.youtube.com/embed/T6VJYGqIprw"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </div>
              <div className={styles.ctaContainer}>
                <button
                  type="button"
                  ref={$closeBtn}
                  className={cx(globalStyle.cta, globalStyle.primaryCta, globalStyle.smallerCta)}
                >
                  {t`OnboardingModal.cta.close`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
