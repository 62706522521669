import { t } from "@lingui/macro";
import cx from "classnames";

import * as React from "react";
import { CgArrowRight } from "react-icons/cg";
import InputRange from "react-input-range";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { quizContext } from "../..";
import { GetQuizAnswers } from "../../../../actionTypes/talent";
import { extractIdFromIri } from "../../../../components/Utils/helpers";
import LoaderComp from "../../../../components/Utils/Loader";
import { ResponsibilitySelect } from "../../../../components/Utils/ResponsibilitySelect";
import { State } from "../../../../interfaces/api/state";
import { QuizStepSlug } from "../../../../interfaces/resources/quiz";
import { Responsibility, ResponsibilityWithSeniority } from "../../../../interfaces/resources/responsibility";
import "react-input-range/lib/css/index.css";
import globalStyle from "../../../../styles/global.module.scss";

import { useApiSelector } from "../../../../utils/hooks";
import quizStyle from "../../index.module.scss";
import styles from "./index.module.scss";

const QUIZ_STEP_SLUG: QuizStepSlug = "career";

export const PartCareer: React.FC = () => {
  const { apiPendingRequests } = useApiSelector();
  const context = React.useContext(quizContext);
  const answer = context?.getAnswer(QUIZ_STEP_SLUG);
  const responsibilities = useSelector((state: State) => state.responsibilities);
  const [initLoading, setInitLoading] = React.useState(true);
  const [expValue, setExpValue] = React.useState<number>(
    answer?.data?.experience ? parseInt(answer.data.experience) : 0,
  );
  const [selected, setSelected] = React.useState<ResponsibilityWithSeniority[]>([]);
  const [primarySelected, setPrimarySelected] = React.useState<Responsibility>();

  const [isDisabled, setIsDisabled] = React.useState(true);
  const handleSelectedRespChange = (result: ResponsibilityWithSeniority[]): void => setSelected(result);
  const handlePrimarySelect = (resp: Responsibility) => {
    setPrimarySelected(resp);
  };
  const submitForm = (): void => {
    if (context === null) {
      return;
    }
    const cleanSelected: Partial<ResponsibilityWithSeniority>[] = [];
    selected.forEach((s) => {
      cleanSelected.push({
        id: extractIdFromIri(s["@id"]),
        value: s.value,
        seniority: s.seniority,
      });
    });

    context.handleSubmit({
      data: {
        experience: expValue,
        responsibilities: cleanSelected,
        coreResponsibility: primarySelected,
      },
      value: QUIZ_STEP_SLUG,
    });
  };

  const handlePrevious = () => {
    if (context === null) {
      return;
    }
    context.handlePrevious(QUIZ_STEP_SLUG);
  };

  React.useEffect(() => {
    if (
      initLoading
      && responsibilities.length
      && context
      && !apiPendingRequests.some((s) => s.type === GetQuizAnswers.REQUEST)
    ) {
      const ans = context?.getAnswer(QUIZ_STEP_SLUG);
      setPrimarySelected(ans?.data?.coreResponsibility);
      setExpValue(ans?.data?.experience ? parseInt(ans.data.experience) : 0);
      setSelected(
        ans
          ? responsibilities
              .filter((r: ResponsibilityWithSeniority) => {
                return (ans.data?.responsibilities as Partial<ResponsibilityWithSeniority>[])?.some((ar) => {
                  if (ar.id === extractIdFromIri(r["@id"])) {
                    return true;
                  }
                  return false;
                });
              })
              .map((r) => {
                const ansResp = (ans.data?.responsibilities as Partial<ResponsibilityWithSeniority>[]).find(
                  (ar) => ar.id === extractIdFromIri(r["@id"]),
                );
                return { ...r, seniority: ansResp?.seniority };
              })
          : [],
      );
      setInitLoading(false);
    }
  }, [initLoading, context, responsibilities, apiPendingRequests]);

  React.useEffect(() => {
    const senioritiesMissing = selected.some((s) => !!!s.seniority);
    setIsDisabled(!selected.length || senioritiesMissing || !primarySelected);
  }, [selected, primarySelected]);

  return (
    <div data-heap="question-responsibilities">
      <div className={quizStyle.questionHeader}>
        <h1 className={cx(globalStyle.mainTitle, quizStyle.mainTitle)}>
          <ReactMarkdown className="formLabel" disallowedTypes={["paragraph"]} unwrapDisallowed>
            Le poste qui vous intéresse
          </ReactMarkdown>
        </h1>
        <p className={quizStyle.textHeader}>
          <ReactMarkdown
            disallowedTypes={["paragraph"]}
            unwrapDisallowed
          >{t`Quiz.responsibilities.subtitle`}</ReactMarkdown>
        </p>
      </div>
      <div className={styles.formContainer}>
        {initLoading ? (
          <div className={quizStyle.loaderContainer}>
            <LoaderComp />
          </div>
        ) : (
          <div className="form">
            <div className="formGroup">
              <div className={styles.partHeader}>
                <h3 className={styles.partLabel}>Votre niveau d&apos;expérience totale :</h3>
              </div>
              <div className={styles.inputRangeContainer}>
                <InputRange
                  maxValue={16}
                  minValue={0}
                  step={1}
                  formatLabel={(value): string => {
                    return value === 0
                      ? "< 1 an"
                      : value === 1
                        ? `${value} an`
                        : value === 16
                          ? "+15 ans"
                          : `${value} ans`;
                  }}
                  value={expValue}
                  onChange={(val): void => {
                    setExpValue(val as number);
                  }}
                />
              </div>
            </div>
            {responsibilities.length > 0 ? (
              <div className="formGroup">
                <ResponsibilitySelect
                  onChange={handleSelectedRespChange}
                  options={responsibilities}
                  value={selected}
                  coreResponsibility={primarySelected?.["@id"]}
                  primarySelect={handlePrimarySelect}
                />
              </div>
            ) : null}
            <div className={quizStyle.ctasContainer}>
              <div>
                <span onClick={handlePrevious} className={quizStyle.previousCta}>
                  {t`Quiz.previousCta.text`}
                </span>
              </div>
              <div className={quizStyle.submitCtaContainer}>
                <button type="button" onClick={submitForm} disabled={isDisabled} className={quizStyle.submitCta}>
                  Suivant <CgArrowRight />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
