import { Application } from "./application";
import { Company } from "./company";
import { CoreUser } from "./coreUser";
import { OfferCriterion } from "./criteriaScoring";
import { DesiredPlace } from "./desiredPlace";
import { InterviewStep } from "./interviewStep";
import { OfferEvent } from "./offerEvent";
import { TalentView } from "./talentView";

export interface Offer {
  "@id": string;
  "@type": string;
  application?: Application;
  company: Company;
  document:
    | {
        url: string;
      }
    | [];
  mode?: string;
  isMarketplace?: boolean;
  stapleLocation: DesiredPlace;
  printExperienceYears: string;
  printSalaries: string;
  slug: string;
  title: string;
  offerEvents: OfferEvent[];
  offerTalentView: TalentView;
  offerCriteria: OfferCriterion[];
  interviewSteps: InterviewStep[];
  createdAt: string;
  owner: CoreUser;
  status: string | null;
  ytPitch?: string;
  ytProcess?: string;
}

export const offerStatuses = {
  activated: "activated",
  desactivated: "desactivated",
  standbyElinoi: "standby_elinoi",
};

export const offerModes = {
  spontaneous: "spontaneous",
};
