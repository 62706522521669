import anime from "animejs";
import cx from "classnames";
import React, { FC, useEffect, useRef, useState } from "react";

import { eventDispatcher } from "../../utils/eventDispatcher";
import { EditCandidateProfileModal } from "../EditCandidateProfileModal";
import styles from "./index.module.scss";

interface Props {
  open?: boolean;
}

export const RcModalCallBooked = "RcModal:callBooked";
export const RcModalOpen = "RcModal:open";
export const RcModalClose = "RcModal:close";
export const RcModalOpenReco = "RcModal:Reco:open";

export const ReconnectionModalWrapper: FC<Props> = ({ open = false }) => {
  const modalWrapper = useRef(null);
  const modal = useRef(null);
  const [show, setShow] = useState(!!open);
  const [hidden, setHidden] = useState(!!!open);

  const showAnimation = (): void => {
    document.body.style.overflow = "hidden";

    const tl = anime.timeline({
      duration: 300,
      easing: "easeInOutExpo",
    });

    tl.add({
      easing: "linear",
      opacity: 1,
      targets: modalWrapper.current,
    }).add({
      duration: 600,
      opacity: [0, 1],
      targets: modal.current,
      translateY: [-10, 0],
    });
  };

  const hideAnimation = (): void => {
    const tl = anime.timeline({
      complete: () => {
        document.body.style.overflow = "";
        setHidden(true);
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: 0,
      targets: modal.current,
      translateY: 20,
    }).add(
      {
        opacity: 0,
        targets: modalWrapper.current,
      },
      0,
    );
  };

  const showModal = (): void => {
    setHidden(false);
    setShow(true);
  };

  const hideModal = (): void => {
    setShow(false);
  };

  useEffect(() => {
    eventDispatcher.on(RcModalOpen, showModal);
    eventDispatcher.on(RcModalClose, hideModal);

    return (): void => {
      eventDispatcher.offAll(RcModalOpen);
      eventDispatcher.offAll(RcModalClose);
    };
  }, []);

  useEffect(() => {
    if (!show) {
      hideAnimation();
      return;
    }
    showAnimation();
  }, [show]);

  return hidden ? null : (
    <div className={styles.modalWrapper} ref={modalWrapper} style={{ opacity: 0 }}>
      <div className={styles.modalsContainer} ref={modal}>
        <div className={styles.innerModalsContainer}>
          <div className={cx(styles.modalContainer, styles.ecpModalContainer)}>
            <EditCandidateProfileModal closeModal={hideModal} showCancelCta={false} />
          </div>
        </div>
      </div>
    </div>
  );
};
