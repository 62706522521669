import { GetExperienceYears } from "../../../actionTypes/experienceYears";
import { ExperienceYears } from "../../../interfaces/resources/experienceYears";

export function experienceYears(state: ExperienceYears[] = [], action: any): ExperienceYears[] {
  switch (action.type) {
    case GetExperienceYears.SUCCESS:
      return !!action.page ? [...state, ...action.experienceYears] : action.experienceYears;
    default:
      return state;
  }
}
