import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { CreateCompanyEvent } from "../../../../actionTypes/companyEvent";

import { apiFailure } from "../../helpers";
import { createCompanyEventSuccess } from "../actions";
import * as apiClient from "./api";

export function* createCompanyEvent(action: AnyAction): any {
  try {
    const response = yield call(apiClient.createCompanyEvent, action.companyEvent);
    const companyEvent = response.data;
    yield put(createCompanyEventSuccess(companyEvent));
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* companyEventSaga() {
  yield takeLatest(CreateCompanyEvent.REQUEST, createCompanyEvent);
}
