import { t } from "@lingui/macro";
import * as React from "react";
import { Helmet } from "react-helmet";
import ErrorScreen from "../../../components/App/ErrorScreen";

import { FullPageLayout } from "../../../styles/components/layouts";

export interface Props {
  error: number;
  fullscreen: boolean;
}

const Meta = () => (
  <Helmet>
    <title>{t`ErrorScene.title`}</title>
    <meta name="" content="" />
  </Helmet>
);

const ErrorScene: React.FC<Props> = ({ error }) => {
  return (
    <FullPageLayout>
      <Meta />
      <ErrorScreen error={error} />
    </FullPageLayout>
  );
};

export default ErrorScene;
