import { ApplyFilter } from "../../actionTypes/internalState";

import { FilterContext } from "../../interfaces/api/filter";

import { updateFilterContext } from "../api/helpers";

export function filterContext(state: FilterContext = [], action: any) {
  switch (action.type) {
    case ApplyFilter.DEFAULT:
      return updateFilterContext(state, action.filterClause);
    case ApplyFilter.SHARE:
      return action.filterContext;
    case ApplyFilter.RESET:
      return [];
    default:
      return state;
  }
}
