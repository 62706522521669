import { ActionType } from "../interfaces/api/actionType";

export const GetOffer: ActionType = {
  FAILURE: "GET_OFFER_FAILURE",
  REQUEST: "GET_OFFER_REQUEST",
  SUCCESS: "GET_OFFER_SUCCESS",
};

export const GetOffers: ActionType = {
  FAILURE: "GET_OFFERS_FAILURE",
  REQUEST: "GET_OFFERS_REQUEST",
  SUCCESS: "GET_OFFERS_SUCCESS",
};

export const GetLastAcceptedApplication: ActionType = {
  FAILURE: "GET_LAST_ACCEPTED_APPLICATION_FAILURE",
  REQUEST: "GET_LAST_ACCEPTED_APPLICATION_REQUEST",
  SUCCESS: "GET_LAST_ACCEPTED_APPLICATION_SUCCESS",
};
