export interface Base64File extends File {
  base64URL: string;
}

const converter = (file: File) =>
  new Promise((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(String(reader.result));
    };
    reader.onerror = reject;
  });

async function toBase64(files: File[]) {
  let convertedFiles: any[] = [];
  for (const file of files) {
    const base64URL = await converter(file);
    convertedFiles = [
      ...convertedFiles,
      {
        ...file,
        base64URL,
      },
    ];
  }
  return convertedFiles;
}

export default toBase64;
