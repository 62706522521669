import cx from "classnames";
import React from "react";
import styles from "./index.module.scss";

interface Props {
  icon?: React.ReactNode;
  wording: React.ReactNode;
  isCollapsed?: boolean;
  className?: string;
  showBadge?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

export const NavCta: React.FC<Props> = (props: Props) => {
  const { icon = null, wording, isCollapsed = false, className = "", onClick, onMouseEnter, showBadge = false } = props;

  const generateIcon = (): JSX.Element | null => {
    return icon ? (
      <div className={styles.icon}>
        {icon}
        {showBadge ? <span className={styles.badge}></span> : null}
      </div>
    ) : null;
  };
  const onClickCta = () => {
    if (typeof onClick === "function") {
      onClick();
    }
  };
  const onMouseEnterCta = () => {
    if (typeof onMouseEnter === "function") {
      onMouseEnter();
    }
  };
  return (
    <div
      className={cx(styles.navCtaContainer, { [styles.collapsed]: isCollapsed }, styles[className])}
      onClick={onClickCta}
      onMouseEnter={onMouseEnterCta}
    >
      <div className={styles.navCta}>
        <div className={cx(styles.ctaContent)}>
          {generateIcon()}
          <div className={styles.wordingContainer}>
            <span className={styles.wording}>{wording}</span>
          </div>
        </div>
      </div>
      <div className={cx(styles.navCta, styles.inverted)}>
        <div className={cx(styles.ctaContent, styles.invertedContent)}>
          {generateIcon()}
          <div className={styles.wordingContainer}>
            <span>{wording}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
