import { gtagService } from "../services/google/gtag";

export const onInitPageLoadTracking = () => {
  if (typeof window !== "undefined") {
    const l = window.location;
    gtagService.pageView({
      page_location: l.href,
      page_path: l.pathname,
      page_title: document ? document.title : "",
    });
  }
};
