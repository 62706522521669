import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import SelectComponent, { ValueType } from "react-select";
import { Props } from "react-select/src/Select";
import { StylesConfig } from "react-select/src/styles";
import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../../utils/hooks";
import ErrorMessage from "../ErrorMessage";

export interface SelectFieldProps extends Props, FieldProps {
  label?: string;
  extraText?: string;
  options: any[];
  icon: string;
  violations?: Violation[];
  parse?: (option: any[]) => any[];
}

export const defaultStyles: StylesConfig<any, any> = {
  control: (base: any) => ({
    ...base,
    minHeight: "45px",
    borderColor: "#222426",
    backgroundColor: "transparent",
    borderRadius: "8px",
    padding: "2px 4px",
    "&:hover": {
      borderColor: "#222426",
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    paddingLeft: "0",
  }),
  multiValue: (base: any) => ({
    ...base,
    alignItems: "baseline",
    borderWidth: "1px",
    borderColor: "#222426",
    borderStyle: "solid",
    borderRadius: "4px",
    backgroundColor: "#F1E8FF",
    padding: "6px 4px 4px",
    textTransform: "uppercase",
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: "#303030",
    fontSize: "16px",
    letterSpacing: "0.03em",
    fontFamily: "Thunder",
    padding: "0",
    paddingLeft: "4px",
    paddingRight: "2px",
  }),

  multiValueRemove: (base: any) => ({
    ...base,
    "&:hover": {
      backgroundColor: "white",
      color: "#E34369",
      cursor: "pointer",
    },
    paddingRight: "0",
    paddingLeft: "0",
    padding: "2px",
    transition: "all 0.2s ease-in-out",
  }),
};

export const Select: any = ({
  className,
  extraText,
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  icon,
  label,
  parse,
  styles,
  violations,
  ...rest
}: SelectFieldProps) => {
  const [, meta] = useField(name);
  const error = violations && getViolationMessage(name, violations);
  const getValue = (): any => {
    if (rest.options && value) {
      return rest.isMulti && value.length
        ? rest.options.filter((option: any) =>
            value.some((e: any) =>
              rest.getOptionValue ? rest.getOptionValue(option) === rest.getOptionValue(e) : option.value === e.value,
            ),
          )
        : rest.options.find((option: any) =>
            rest.getOptionValue ? rest.getOptionValue(option) === rest.getOptionValue(value) : option.value === value,
          );
    } else {
      return value;
    }
  };
  return (
    <div className={cx(className, { error: (meta.touched && !!meta.error) || !!error })}>
      {label ? (
        <label className="formLabel">
          <ReactMarkdown source={label} disallowedTypes={["paragraph"]} unwrapDisallowed />
        </label>
      ) : null}
      <SelectComponent
        {...rest}
        value={getValue() as ValueType<any, any>}
        className={cx("react-select-container", { [icon]: icon })}
        classNamePrefix="react-select"
        onChange={(option: any): void => {
          if (value !== option) {
            if (parse) {
              option = parse(option);
            }
            setFieldValue(name, option);
          }
        }}
        onBlur={(): any => setFieldTouched(name, true)}
        styles={styles ? styles : defaultStyles}
      />
      {extraText ? <small className="formAdditionalInfos">{extraText}</small> : null}
      <ErrorMessage name={name} message={error} />
    </div>
  );
};
