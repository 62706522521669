import { AnyAction } from "redux";
import { GetSectors } from "../../../actionTypes/sectors";
import { Sector } from "../../../interfaces/resources/sector";

export function getSectorsAction(page?: number) {
  return {
    page,
    type: GetSectors.REQUEST,
  };
}

export function getSectorsSuccessAction(sectors: Sector[], action: AnyAction) {
  return {
    page: action.page,
    sectors,
    type: GetSectors.SUCCESS,
  };
}
