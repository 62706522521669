import { GetCompanies, GetCompany } from "../../../actionTypes/companies";
import { CreateCompanyEvent } from "../../../actionTypes/companyEvent";
import { CompanyEvent } from "../../../interfaces/resources/companyEvent";

export function getCompanyAction(slug: string) {
  return {
    slug,
    type: GetCompany.REQUEST,
  };
}

export function getCompaniesAction(params?: { [key: string]: any }) {
  return {
    params,
    type: GetCompanies.REQUEST,
  };
}

export function createCompanyEvent(companyEvent: Partial<CompanyEvent>) {
  return {
    companyEvent,
    type: CreateCompanyEvent.REQUEST,
  };
}

export function createCompanyEventSuccess(companyEvent: Partial<CompanyEvent>) {
  return {
    companyEvent,
    type: CreateCompanyEvent.SUCCESS,
  };
}
