import { t } from "@lingui/macro";
import * as React from "react";

import { Offer } from "../../interfaces/resources/offer";

import LoaderComp from "../Utils/Loader";

import styles from "./index.module.scss";

interface Props {
  id?: string;
  loading?: boolean;
  offers: Offer[];
  render: (offer: Offer) => React.ReactElement;
  simpleView?: boolean;
  refElem?: React.RefObject<HTMLUListElement>;
  renderCustomCard?: () => React.ReactElement;
}

export const OffersCardList: React.FC<Props> = (props: Props) => {
  const { loading, offers, refElem, render, renderCustomCard = () => null } = props;

  return offers.length > 0 ? (
    <ul className={styles.offersList} ref={refElem}>
      {renderCustomCard()}
      {offers.map((offer: Offer) => (
        <li key={`item-${offer["@id"]}-card`} className={styles.item}>
          {render(offer)}
        </li>
      ))}
    </ul>
  ) : !!!loading ? (
    <div className="empty-list">{t`OffersCollection.empty`}</div>
  ) : (
    <LoaderComp />
  );
};
