import { AxiosResponse } from "axios";
import { FilterContext } from "../../../interfaces/api/filter";
import client from "../client";
// import qs from "query-string";

import { generateFilterQuery } from "../helpers";

export function getCompany(slug: string): Promise<AxiosResponse<any>> {
  return client.get(`/api/v2/companies?slug=${slug}&groups[]=userspace:read:company:item`);
}

export function getCompanies(filterContext: FilterContext): Promise<AxiosResponse<any>> {
  const searchParams = generateFilterQuery(filterContext);
  return client.get(`/api/v2/companies${searchParams}`);
}
