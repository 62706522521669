import { GetSkill, GetSkills } from "../../../actionTypes/skills";
import { Skill } from "../../../interfaces/resources/skill";

export function skills(state: Skill[] = [], action: any): Skill[] {
  switch (action.type) {
    case GetSkills.SUCCESS:
      return !!action.page ? [...state, ...action.skills] : action.skills;
    case GetSkill.SUCCESS:
      return action.skill;
    default:
      return state;
  }
}
