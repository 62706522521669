import { ActionType } from "../interfaces/api/actionType";

export const GetAvailableApplications: ActionType = {
  FAILURE: "GET_AVAILABLE_APPLICATIONS_FAILURE",
  REQUEST: "GET_AVAILABLE_APPLICATIONS_REQUEST",
  SUCCESS: "GET_AVAILABLE_APPLICATIONS_SUCCESS",
};

export const GetApplications: ActionType = {
  FAILURE: "GET_APPLICATIONS_FAILURE",
  REQUEST: "GET_APPLICATIONS_REQUEST",
  SUCCESS: "GET_APPLICATIONS_SUCCESS",
};

export const SubmitApplication: ActionType = {
  FAILURE: "SUBMIT_APPLICATION_FAILURE",
  REQUEST: "SUBMIT_APPLICATION_REQUEST",
  SUCCESS: "SUBMIT_APPLICATION_SUCCESS",
};
