import { t } from "@lingui/macro";
import cx from "classnames";
import React, { FC, SyntheticEvent } from "react";
import { Modal } from "react-bootstrap";
import { event } from "react-ga";
import { useSelector } from "react-redux";
import { State } from "../../../interfaces/api/state";
import { CalendlyEvent } from "../../../interfaces/resources/calendlyEvent";
import { Offer } from "../../../interfaces/resources/offer";
import { Talent } from "../../../interfaces/resources/talent";

import { getOfferCardLabel, queryString } from "../../Utils/helpers";

import "./index.scss";

interface Button {
  isDisabled?: boolean;
  offer: Offer;
  setOpen: (open: boolean) => void;
  mobile?: boolean;
  trackName?: string;
  classNames?: string;
  showtooltip?: boolean;
}

interface CalendlyModal {
  open: boolean;
  offer: Offer;
  talent?: Talent;
  setOpen: (open: boolean) => void;
}

export const CalendlyModal: FC<CalendlyModal> = ({ setOpen, open, offer, talent }: CalendlyModal) => (
  <Modal show={open} onHide={(): void => setOpen(false)}>
    <Modal.Header closeButton>
      <Modal.Title>{t`CardButton.modal.title`}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <iframe
        title="calendly"
        className="calendly-inline-widget"
        src={
          offer
          && offer.owner
          && `${offer.owner.calendlyLink10}?${queryString({
            utm_term: JSON.stringify({
              offer: offer["@id"],
              talent: talent && talent["@id"],
            }),
          })}`
        }
      ></iframe>
    </Modal.Body>
    <Modal.Footer>
      <button onClick={(): void => setOpen(false)}>{t`CardButton.close.label`}</button>
    </Modal.Footer>
  </Modal>
);

const trackAction = (action: string, name: string): void =>
  event({
    action,
    category: "talent",
    label: `fiche d'offre - ${name}`,
  });

// eslint-disable-next-line
export const Button = (props: Button): JSX.Element => {
  const {
    isDisabled,
    offer: { application, owner },
    setOpen,
    trackName,
    classNames,
    showtooltip = false,
  } = props;
  const calendlyEvents: CalendlyEvent[] = useSelector((state: State) => state.calendlyEvents);
  const calendlyEvent = calendlyEvents.filter((e) => (application && application.offer["@id"]) === e.offer)[0];
  const label = getOfferCardLabel(application, calendlyEvent);
  const handleClick = (e: SyntheticEvent): void => {
    if (trackName) {
      trackAction("click", trackName);
    }
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };
  return (
    <>
      {application && label && (
        <div className="btnTooltipContainer">
          <button
            data-testid="action-button"
            disabled={isDisabled}
            onClick={(e: SyntheticEvent): false | void | undefined => handleClick(e)}
            className={cx("button-with-tooltip", classNames)}
          >
            {label}
          </button>
          {owner && owner.firstname && showtooltip ? (
            <span className="btn-tooltip">Prendre rendez-vous avec {owner.firstname}</span>
          ) : null}
        </div>
      )}
    </>
  );
};
