import cx from "classnames";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { MdDelete } from "react-icons/md";
import DatePicker from "../../../../../components/Utils/Form/DatePicker";
import { Input } from "../../../../../components/Utils/Form/Input";
import { LinkedinEducation } from "../../../../../interfaces/resources/linkedinResume";
import globalStyle from "../../../../../styles/global.module.scss";
import quizStyle from "../../../index.module.scss";
import styles from "./index.module.scss";

interface Props {
  submitItemModification: (values: any, element: string) => void;
  item?: LinkedinEducation;
  deleteItem?: (values: any, element: string) => void;
  addItem?: (values: any, element: string) => void;
}

export const ModifyEducation: React.FC<any> = ({ submitItemModification, item, deleteItem, addItem }: Props) => {
  return (
    <Formik initialValues={item ? item : {}} onSubmit={(values) => submitItemModification(values, "educations")}>
      {({ values }): JSX.Element => (
        <Form className={cx(styles.form, "form")}>
          <div>
            <div className={styles.details}>
              <Field
                name="schoolName"
                label="École"
                component={Input}
                className={cx(styles.schoolName, styles.input)}
              />
              <Field name="degreeName" label="Diplôme" component={Input} className={styles.input} />
            </div>
            <div className={styles.details}>
              <Field name="startDate" label="From" component={DatePicker} className={styles.input} />
              <Field name="endDate" label="To" component={DatePicker} className={styles.input} />
            </div>
          </div>
          <div className={styles.ctasContainer}>
            {item && deleteItem ? (
              <>
                <div>
                  <button
                    type="button"
                    onClick={() => deleteItem(item, "educations")}
                    className={cx(
                      quizStyle.deleteCta,
                      globalStyle.smallerCta,
                      globalStyle.cta,
                      globalStyle.alertCta,
                      globalStyle.inverted,
                    )}
                  >
                    <MdDelete />
                    <span> Supprimer</span>
                  </button>
                </div>
                <div className={styles.submitCtaContainer}>
                  <button
                    type="submit"
                    className={cx(
                      globalStyle.smallerCta,
                      globalStyle.cta,
                      globalStyle.primaryCta,
                      globalStyle.inverted,
                    )}
                  >
                    Modifier
                  </button>
                </div>
              </>
            ) : addItem ? (
              <div className={styles.submitCtaContainer}>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    addItem(values, "educations");
                  }}
                  className={cx(globalStyle.smallerCta, globalStyle.cta, globalStyle.primaryCta, globalStyle.inverted)}
                >
                  Ajouter
                </button>
              </div>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};
