import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import * as React from "react";

import { CgArrowRight } from "react-icons/cg";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { quizContext } from "../..";
import { ValidateReferral } from "../../../../actionTypes/referral";
import { ErrorWording } from "../../../../components/Utils/Form/ErrorMessage";
import { Input } from "../../../../components/Utils/Form/Input";
import { PhoneInput } from "../../../../components/Utils/Form/PhoneInput";
import Yup from "../../../../components/Utils/Yup";
import { State } from "../../../../interfaces/api/state";
import { QuizStepSlug } from "../../../../interfaces/resources/quiz";
import { Talent } from "../../../../interfaces/resources/talent";

import { cookies } from "../../../../services/api/helpers";
import { validateReferralAction } from "../../../../services/api/referrals/actions";
import globalStyle from "../../../../styles/global.module.scss";
import { useApiSelector } from "../../../../utils/hooks";

import quizStyle from "../../index.module.scss";
import styles from "./index.module.scss";

const QUIZ_STEP_SLUG: QuizStepSlug = "general";

export const PartGeneral: React.FC = () => {
  const context = React.useContext(quizContext);
  const dispatch = useDispatch();
  const { apiErrors } = useApiSelector();
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const validatedReferral = useSelector((state: State) => state.validatedReferral);
  const [referralCode] = React.useState<string>(cookies.get("rt") || cookies.get("rf") || "");
  const [referralError, setReferralError] = React.useState<string | undefined>(undefined);
  const referralTimeoutRef = React.useRef<any>(null);

  const validateReferral = (refCode: string, curTal: Talent): void => {
    setReferralError(undefined);
    dispatch(validateReferralAction(refCode, curTal));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t`quiz.validation.question1.email.valid`)
      .required(t`quiz.validation.question1.email.required`),
    firstname: Yup.string().required(t`quiz.validation.question1.firstname.required`),
    lastname: Yup.string().required(t`quiz.validation.question1.lastname.required`),
    phone: Yup.string()
      .phoneNumber()
      .typeError(t`quiz.validation.question1.phone.required`)
      .required(t`quiz.validation.question1.phone.required`),
  });
  const initialValues = {
    email: currentTalent.email,
    firstname: currentTalent.firstname,
    lastname: currentTalent.lastname,
    phone: currentTalent.phone,
    referralCode: validatedReferral?.referralCode || referralCode,
  };

  const submitForm = (values: any): void => {
    if (context === null) {
      return;
    }
    if (!!values.referralCode.length && !!!validatedReferral) {
      setReferralError("Attention, vous n'avez pas validé votre parrainage.");
      return;
    }
    context.handleSubmit({
      data: values,
      value: QUIZ_STEP_SLUG,
    });
  };

  const onClickValidateReferralCta = (rC: string): void => {
    validateReferral(rC, currentTalent);
  };

  React.useEffect(() => {
    const referralErrors = apiErrors.filter((er) => er.type === ValidateReferral.FAILURE);
    const l = referralErrors.length;
    if (l) {
      setReferralError(referralErrors[l - 1].payload?.message);
    }
  }, [apiErrors]);

  React.useEffect(() => {
    if (referralError)
      referralTimeoutRef.current = setTimeout(() => {
        setReferralError(undefined);
      }, 2200);
    else {
      clearTimeout(referralTimeoutRef.current);
    }
    return (): void => {
      clearTimeout(referralTimeoutRef.current);
    };
  }, [referralError, referralTimeoutRef]);

  return (
    <div data-heap="question-profile">
      <div className={quizStyle.questionHeader}>
        <h1 className={cx(globalStyle.mainTitle, quizStyle.mainTitle)}>
          <ReactMarkdown source="Informations générales" disallowedTypes={["paragraph"]} unwrapDisallowed />
        </h1>
      </div>
      <div className={styles.formContainer}>
        <Formik
          onSubmit={submitForm}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ values }): JSX.Element => (
            <Form className={cx("form")}>
              <div className="formGroup">
                <label className="formLabel">{t`Question1.name.label`}</label>
                <div className="formGroup formInlineGroup double">
                  <Field
                    name="firstname"
                    component={Input}
                    placeholder={t`Question1.firstname.placeholder`}
                    type="text"
                  />
                  <Field
                    name="lastname"
                    component={Input}
                    placeholder={t`Question1.lastname.placeholder`}
                    type="text"
                  />
                </div>
              </div>

              <div className="formGroup">
                <Field name="email" component={Input} type="text" label={t`Question1.email.label`} />
              </div>
              <div className="formGroup">
                <Field name="phone" component={PhoneInput} type="text" label={t`Question1.phone.label`} />
              </div>
              <div className="formGroup">
                <div className={styles.referralInputCtaContainer}>
                  <Field
                    name="referralCode"
                    component={Input}
                    type="text"
                    label={t`Question1.referralCode.label`}
                    sublabel={t`Question1.referralCode.sublabel`}
                    readOnly={!!validatedReferral}
                  />
                  {!!!validatedReferral && !!values.referralCode ? (
                    <div className={styles.referralCheckCtaContainer}>
                      <button
                        type="button"
                        className={cx(globalStyle.cta, globalStyle.darkCta, globalStyle.smallerCta)}
                        onClick={(): void => {
                          onClickValidateReferralCta(values.referralCode);
                        }}
                      >
                        Valider
                      </button>
                    </div>
                  ) : null}
                </div>
                {referralError || !!validatedReferral ? (
                  <div>
                    {referralError ? (
                      <ErrorWording message={referralError} />
                    ) : !!validatedReferral ? (
                      <div className={styles.referralSuccessText}>
                        Votre parrainage par {validatedReferral.firstname} a bien été validé !
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className={quizStyle.ctasContainer}>
                <div className={quizStyle.submitCtaContainer}>
                  <button type="submit" className={quizStyle.submitCta}>
                    Suivant <CgArrowRight />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
