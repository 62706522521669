import { CreateLinkedinResumeItem, GetLinkedinResume } from "../../../actionTypes/linkedinResume";
import { CreateLinkedinResume } from "../../../actionTypes/talent";
import { LinkedinResume } from "../../../interfaces/resources/linkedinResume";

export function linkedinResume(state: LinkedinResume | null = null, action: any) {
  switch (action.type) {
    case GetLinkedinResume.SUCCESS:
      return action.linkedinResume;
    case CreateLinkedinResume.SUCCESS:
      return action.linkedinResume;
    case CreateLinkedinResumeItem.SUCCESS:
      const array = (state?.[action.element as keyof LinkedinResume] as any) || [];
      array.push(action.data);
      return !!state ? { ...state, [action.element]: array } : null;
    default:
      return state;
  }
}
