import { t } from "@lingui/macro";
import cx from "classnames";
import React, { SyntheticEvent } from "react";

import defaultLogo from "../../images/defaults/company.png";

import styles from "./index.module.scss";

interface Props {
  classNames?: string;
  src?: string;
  name?: string;
}

export const CompanyImg: React.FC<Props> = (props: Props) => {
  const { classNames = "", src = "", name = "" } = props;
  const imgSrc = src ? src : defaultLogo;

  return (
    <div className={cx(styles.companyImgContainer, classNames)}>
      <div className={styles.companyImgContent}>
        <img
          onError={(i: SyntheticEvent<HTMLImageElement>): string => (i.currentTarget.src = defaultLogo)}
          className={styles.companyImg}
          src={imgSrc}
          alt={name ? name : t`alts.logo.startup`}
        />
      </div>
    </div>
  );
};
