import { t } from "@lingui/macro";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { isConsecutive } from "./helpers";

declare module "yup" {
  interface StringSchema<T extends string | null | undefined = string> extends Schema<T> {
    linkedInProfileUrl(message?: TestOptionsMessage): StringSchema<T>;
    phoneNumber(message?: TestOptionsMessage): StringSchema<T>;
  }
  type BasicArraySchema<T extends any[] | null | undefined> = Schema<T>;
  interface ArraySchema<T> extends BasicArraySchema<T[]> {
    salaryExpectation: (message?: TestOptionsMessage) => ArraySchema<T>;
  }
}

Yup.addMethod(Yup.string, "linkedInProfileUrl", () =>
  Yup.string()
    .required("Ce champs est obligatoire")
    .matches(/^https?:\/\/(?:www\.)?linkedin\.com\/in\/(?=[^/]{3,})([^\s/]+\/?)$/i, t`yup.linkedInProfileUrl`),
);

Yup.addMethod(Yup.string, "phoneNumber", () =>
  Yup.string().test(
    "phoneNumberCheck",
    ({ value }) => t({ id: "yup.phoneNumber", values: { values: value } }),
    (str: string) => isValidPhoneNumber(str),
  ),
);

Yup.addMethod(Yup.array, "salaryExpectation", () =>
  Yup.array()
    .min(1)
    .max(2)
    .test("consecutiveExpectations", t`yup.consecutiveExpectations`, (arr: any) => isConsecutive(arr)),
);

Yup.setLocale({
  array: {
    min: ({ min }): string => t({ id: "yup.array.min", values: { min } }),
    max: ({ max }) => t({ id: "yup.array.max", values: { max } }),
  },
  mixed: {
    required: (): string => t`yup.mixed.required`,
  },
  number: {
    max: ({ max }): string => t({ id: "yup.number.max", values: { max } }),
    min: ({ min }): string => t({ id: "yup.number.min", values: { min } }),
  },
  string: {
    email: ({ value }): string => t({ id: "yup.string.email", values: { value } }),
    url: ({ value }): string => t({ id: "yup.string.url", values: { value } }),
  },
});

export default Yup;
