import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import { BiCheck } from "react-icons/bi";

import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../../utils/hooks";
import ErrorMessage from "../ErrorMessage";
import styles from "./index.module.scss";

interface Props extends FieldProps {
  className?: string;
  label?: string;
  subLabel?: string;
  id?: string;
  type: string;
  placeholder?: string;
  showErrorMessage?: boolean;
  showImage?: boolean;
  onChangeCustom?: (e: any) => void;
  value: any;
  violations?: Violation[];
  submitOnChange?: boolean;
}

export const QuizRadio: React.FC<Props> = (props: Props) => {
  const {
    className = "",
    field,
    form: { setFieldTouched, submitForm },
    id,
    label,
    subLabel,
    violations,
    onChangeCustom,
    value,
    submitOnChange = false,
    showErrorMessage = true,
    ...rest
  } = props;
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);
  const fieldId = id ? id : value;

  const onBlurInput = (e: React.SyntheticEvent): void => {
    setFieldTouched(field.name, true);
    field.onBlur(e);
  };
  const onChangeInput = (e: React.SyntheticEvent): void => {
    field.onChange(e);
    if (submitOnChange) {
      submitForm();
    }
    if (typeof onChangeCustom === "function") {
      onChangeCustom(e);
    }
  };

  return (
    <div className={styles.inputContainer}>
      <input
        {...rest}
        name={field.name}
        type="radio"
        id={fieldId}
        value={value}
        onBlur={onBlurInput}
        onChange={onChangeInput}
        checked={field.value === value}
        className={styles.inputRadio}
      />
      <label className={cx(styles.label, { [className]: !!className })} htmlFor={fieldId}>
        <div className={styles.labelCheckContent}>
          <span className={styles.customCheck}>
            <BiCheck />
          </span>
          <div className={styles.labelText}>
            <span>{label}</span>
          </div>
        </div>
        {subLabel ? <div className={styles.subLabelContent}>{subLabel}</div> : null}
      </label>
      {showErrorMessage && meta.touched && !!meta.error ? <ErrorMessage name={field.name} message={error} /> : null}
    </div>
  );
};
