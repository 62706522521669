import cx from "classnames";
import { FieldProps } from "formik";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { BiUpload } from "react-icons/bi";
import { FormInputProps } from "semantic-ui-react";
import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../../utils/hooks";

import toBase64, { Base64File } from "../../../Utils/ToBase64";
import ErrorMessage from "../ErrorMessage";
import styles from "./index.module.scss";

interface Props extends FieldProps, FormInputProps {
  violations?: Violation[];
  accept?: { [key: string]: any };
  maxFiles?: number;
}

export const File = ({
  field: { name },
  form: { setFieldValue },
  label = "",
  violations,
  className = "",
  accept,
  maxFiles = 1,
}: Props) => {
  const error = violations && getViolationMessage(name, violations);
  const [base64URL, setBase64URL] = React.useState<string | undefined>(undefined);
  const handleFile = (files: File[]) => {
    toBase64(files).then((result: Base64File[]) => {
      setBase64URL(result[0].base64URL);
    });
  };
  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    handleFile(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject, acceptedFiles } =
    useDropzone({
      onDrop,
      accept,
      maxFiles,
    });
  const files = acceptedFiles.map((file) => <div key={file.name}>{file.name}</div>);

  React.useEffect(() => {
    setFieldValue(name, base64URL);
  }, [name, base64URL, setFieldValue]);

  return (
    <div className={cx("field", { [className]: !!className })}>
      <div
        {...getRootProps({
          className: cx(styles.dropzone, {
            [styles.focused]: isFocused,
            [styles.dragAccepted]: isDragAccept,
            [styles.dragRejected]: isDragReject,
          }),
        })}
      >
        <input {...getInputProps()} />
        <div className={styles.uploadLabel}>
          <BiUpload />
          {isDragActive
            ? "Déposer le fichier ici..."
            : files.length
              ? files
              : !!label
                ? label
                : "Glisser/déposer un fichier ou cliquez pour sélectionner un fichier"}
        </div>
      </div>
      <ErrorMessage name={name} message={error} />
    </div>
  );
};
