import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "@lingui/macro";
import * as React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

import { routes } from "../../../routes";

interface Props {
  error: number;
}

interface Error {
  number: number;
  message: string;
}

const errorItems: Error[] = [
  {
    message: "Nous sommes désolés mais nous ne retrouvons pas cette page",
    number: 404,
  },
];

const ErrorScreen: React.FC<Props> = ({ error }) => {
  const item = errorItems.find((e: Error) => e.number === error);
  return (
    <div className="error-wrapper">
      <div className="error-number">{error}</div>
      <div className="extra">OUPS ...</div>
      {item && <div className="message">{item.message}</div>}
      <Link className="action" to={routes.app.home}>
        {t`ErrorScreen.action`}
        <FontAwesomeIcon icon={faLongArrowAltRight} />
      </Link>
      {/* <SocialMedia /> */}
    </div>
  );
};

export default ErrorScreen;
