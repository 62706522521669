import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import MultiRangeSlider from "multi-range-slider-react";
import * as React from "react";

import { CgArrowRight } from "react-icons/cg";
import { useSelector } from "react-redux";
import { quizContext } from "../..";
import { GetDesiredPlaces } from "../../../../actionTypes/desiredPlaces";
import { GetQuizAnswers } from "../../../../actionTypes/talent";
import { Select } from "../../../../components/Utils/Form/Select";
import { extractIdFromIri } from "../../../../components/Utils/helpers";
import LoaderComp from "../../../../components/Utils/Loader";
import Yup from "../../../../components/Utils/Yup";
import { State } from "../../../../interfaces/api/state";
import { DesiredPlace, desiredPlacesRemote } from "../../../../interfaces/resources/desiredPlace";
import { QuizStepSlug } from "../../../../interfaces/resources/quiz";
import globalStyle from "../../../../styles/global.module.scss";
import { useApiSelector } from "../../../../utils/hooks";
import quizStyle from "../../index.module.scss";
import styles from "./index.module.scss";

const QUIZ_STEP_SLUG: QuizStepSlug = "location";

interface DesiredPlaceProps extends DesiredPlace {
  id: number;
}

export const PartLocation: React.FC = () => {
  const context = React.useContext(quizContext);
  const answer = context?.getAnswer(QUIZ_STEP_SLUG);
  const { apiPendingRequests } = useApiSelector();
  const desiredPlacesState = useSelector((state: State) => state.desiredPlaces);
  const desiredPlaces = desiredPlacesState.filter((e) => !desiredPlacesRemote.includes(e.value));
  const remotePreferences = desiredPlacesState.filter((e) => desiredPlacesRemote.includes(e.value));
  const [initLoading, setInitLoading] = React.useState(true);
  const [minSlryValue, setMinSlryValue] = React.useState<number | undefined>(undefined);
  const [maxSlryValue, setMaxSlryValue] = React.useState<number | undefined>(undefined);
  const [defaultMinSlryValue, setDefaultMinSlryValue] = React.useState<number>(33);
  const [defaultMaxSlryValue, setDefaultMaxSlryValue] = React.useState<number>(38);
  const [maxCaption, setMaxCaption] = React.useState<string>(`variable : ${38 - 33}k`);
  const [minCaption, setMinCaption] = React.useState<string>("fix : 33k");
  const validationSchema = Yup.object().shape({
    desiredPlaces: Yup.array()
      .min(1, t`quiz.validation.question1.desiredPlaces.min`)
      .typeError(t`quiz.validation.question1.desiredPlaces.required`)
      .required(t`quiz.validation.question1.desiredPlaces.required`),
    remotePreferences: Yup.array()
      .min(1, t`quiz.validation.remotePreferences.min`)
      .typeError(t`quiz.validation.remotePreferences.required`)
      .required(t`quiz.validation.remotePreferences.required`),
  });

  const initialValues = {
    desiredPlaces:
      desiredPlaces.filter((e) => answer?.data?.desiredPlaces.some((ae: DesiredPlaceProps) => ae.value === e.value))
      || [],
    remotePreferences:
      remotePreferences.filter((e) => answer?.data?.desiredPlaces.some((ae: DesiredPlaceProps) => ae.value === e.value))
      || [],
  };

  const isLoadingDesiredPlaces = apiPendingRequests.some((e) => e.type === GetDesiredPlaces.REQUEST);

  const submitForm = (values: any): void => {
    if (context === null) {
      return;
    }
    const dPlaces = [...values.desiredPlaces, ...values.remotePreferences];
    const cleanPlaces: Partial<DesiredPlaceProps>[] = [];
    dPlaces.forEach((s) => {
      cleanPlaces.push({
        id: extractIdFromIri(s["@id"]),
        value: s.value,
      });
    });

    context.handleSubmit({
      data: {
        desiredPlaces: cleanPlaces,
        salary: {
          fixedSalary: minSlryValue,
          fullPackage: maxSlryValue,
        },
      },
      value: QUIZ_STEP_SLUG,
    });
  };

  const handlePrevious = () => {
    if (context === null) {
      return;
    }
    context.handlePrevious(QUIZ_STEP_SLUG);
  };

  React.useEffect(() => {
    if (initLoading && context && !apiPendingRequests.some((s) => s.type === GetQuizAnswers.REQUEST)) {
      const ans = context?.getAnswer(QUIZ_STEP_SLUG);
      if (ans?.data?.salary?.fixedSalary && ans?.data?.salary?.fullPackage) {
        setDefaultMinSlryValue(ans.data.salary.fixedSalary);
        setDefaultMaxSlryValue(ans.data.salary.fullPackage);
      }
      setInitLoading(false);
    }
  }, [initLoading, context, apiPendingRequests]);

  // React.useEffect(() => {
  //   const maxVrbl =
  //     maxSlryValue && minSlryValue ? maxSlryValue - minSlryValue : defaultMaxSlryValue - defaultMinSlryValue;
  //   const minFix = minSlryValue ? minSlryValue : defaultMinSlryValue;
  //   setMaxCaption(`variable : ${maxVrbl}k`);
  //   setMinCaption(`fix : ${minFix}k`);
  // }, [minSlryValue, maxSlryValue, defaultMinSlryValue, defaultMaxSlryValue]);

  return (
    <div data-heap="question-salary">
      <div className={quizStyle.questionHeader}>
        <h1 className={cx(globalStyle.mainTitle, quizStyle.mainTitle)}>Précisions sur votre recherche</h1>
      </div>
      <div className={styles.formContainer}>
        {!!!desiredPlacesState?.length ? (
          <div className={quizStyle.loaderContainer}>
            <LoaderComp />
          </div>
        ) : (
          <Formik
            onSubmit={submitForm}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }): JSX.Element => (
              <Form className="form">
                <div className={cx("formGroup", styles.selectPlacesGroup)}>
                  <Field
                    name="desiredPlaces"
                    component={Select}
                    className="quiz-select"
                    getOptionLabel={(item: DesiredPlace): string => item.value}
                    getOptionValue={(item: DesiredPlace): string => item["@id"]}
                    isSearchable={true}
                    isClearable={false}
                    isMulti={true}
                    label={t`Question1.desirePlace.label`}
                    loading={isLoadingDesiredPlaces}
                    options={desiredPlaces}
                    placeholder={null}
                  />
                </div>
                <div className={cx("formGroup", styles.selectPlacesGroup)}>
                  <Field
                    name="remotePreferences"
                    component={Select}
                    getOptionLabel={(item: DesiredPlace): string => item.value}
                    getOptionValue={(item: DesiredPlace): string => item["@id"]}
                    isLoading={apiPendingRequests.some((e) => e.type === GetDesiredPlaces.REQUEST)}
                    isSearchable={true}
                    isClearable={false}
                    isMulti={true}
                    label={t`Question.remotePreferences.label`}
                    options={remotePreferences}
                  />
                </div>
                <div className="formGroup">
                  <div className={styles.partHeader}>
                    <h3 className="formLabel">Votre rémunération souhaitée :</h3>
                    <span className={styles.subLabel}>(fix et variable annuels en milliers d&apos;euros)</span>
                  </div>
                  <div className={styles.inputRangeContainer}>
                    <MultiRangeSlider
                      min={30}
                      max={150}
                      step={1}
                      minValue={defaultMinSlryValue}
                      maxValue={defaultMaxSlryValue}
                      labels={["30k", "150k+"]}
                      maxCaption={maxCaption}
                      minCaption={minCaption}
                      ruler={false}
                      onChange={(e): void => {
                        setMinSlryValue(e.minValue);
                        setMaxSlryValue(e.maxValue);
                      }}
                      onInput={(e): void => {
                        setMinCaption(`fix : ${e.minValue}k`);
                        setMaxCaption(`variable : ${e.maxValue - e.minValue}k`);
                      }}
                    />
                  </div>
                </div>
                <div className={quizStyle.ctasContainer}>
                  <div>
                    <span onClick={handlePrevious} className={quizStyle.previousCta}>
                      {t`Quiz.previousCta.text`}
                    </span>
                  </div>
                  <div className={quizStyle.submitCtaContainer}>
                    <button type="submit" className={quizStyle.submitCta} disabled={isSubmitting}>
                      Suivant <CgArrowRight />
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};
