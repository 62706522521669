import { CreateOfferEvent } from "../../../../actionTypes/offerEvent";
import { OfferEvent } from "../../../../interfaces/resources/offerEvent";

export function createOfferEvent(offerEvent: Partial<OfferEvent>) {
  return {
    offerEvent,
    type: CreateOfferEvent.REQUEST,
  };
}

export function createOfferEventSuccess(offerEvent: Partial<OfferEvent>) {
  return {
    offerEvent,
    type: CreateOfferEvent.SUCCESS,
  };
}
