import { t } from "@lingui/macro";
import cx from "classnames";
import { format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { CgArrowRight } from "react-icons/cg";
import { HiPlus } from "react-icons/hi";
import { MdClose, MdEdit } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import { quizContext } from "../../..";
import {
  CreateLinkedinResumeItem,
  CreateManualLinkedin,
  EditLinkedinItem,
} from "../../../../../actionTypes/linkedinResume";
import { PostAnswer } from "../../../../../actionTypes/quiz";
import { CreateLinkedinResume } from "../../../../../actionTypes/talent";
import { Loader } from "../../../../../components/Loader";
import { Input } from "../../../../../components/Utils/Form/Input";
import Yup from "../../../../../components/Utils/Yup";
import { State } from "../../../../../interfaces/api/state";
import {
  LinkedinExperience,
  LinkedinLanguage,
  LinkedinEducation,
  linkedinLanguages,
  LinkedinResume,
} from "../../../../../interfaces/resources/linkedinResume";
import { QuizStepSlug } from "../../../../../interfaces/resources/quiz";
import {
  createLinkedinItemAction,
  deleteLinkedinItemAction,
  editLinkedinItemAction,
} from "../../../../../services/api/linkedinResume/actions";

import globalStyle from "../../../../../styles/global.module.scss";
import { useApiSelector, usePrevious } from "../../../../../utils/hooks";

import quizStyle from "../../../index.module.scss";
import linkedPartStyles from "../index.module.scss";
import { ModifyEducation } from "../ModifyEducation";
import { ModifyExperience } from "../ModifyExperience";
import { ModifyLanguage } from "../ModifyLanguage";
import styles from "./index.module.scss";

const QUIZ_STEP_SLUG: QuizStepSlug = "linkedin";

type LinkedinElement = "languages" | "educations" | "experiences";

export const LinkedinResumeInfos: React.FC = () => {
  const context = React.useContext(quizContext);
  const answer = context?.getAnswer(QUIZ_STEP_SLUG);
  const dispatch = useDispatch();
  const { apiSuccess, apiErrors, apiPendingRequests } = useApiSelector();
  const [modifyOpen, setModifyOpen] = React.useState<any>(null);
  const [loader, setLoader] = React.useState(false);
  const [itemLoader, setItemLoader] = React.useState(false);
  const linkedinResume = useSelector((state: State) => state.linkedinResume);
  const prevLinkedinResume = usePrevious(linkedinResume);
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [localStateLinkedinResume, setLocalStateLinkedinResume] = React.useState<LinkedinResume | null>(null);
  const validationSchema = Yup.object().shape({
    url: Yup.string().linkedInProfileUrl(),
  });

  const checkApiSuccessForTypes = (successRequest: any, types: any) =>
    successRequest.some((e: any) => types.includes(e.type));

  const deleteItem = (
    item: LinkedinExperience | LinkedinLanguage | LinkedinEducation,
    element: LinkedinElement,
  ): void => {
    if (localStateLinkedinResume) {
      const v = localStateLinkedinResume[element].findIndex((l: any) => l["@id"] === item["@id"]);
      localStateLinkedinResume[element].splice(v, 1);
    }
    setLocalStateLinkedinResume(localStateLinkedinResume);
    dispatch(deleteLinkedinItemAction(item["@id"]));
  };

  const convertToISODateString = (date: Date | undefined): string | undefined => {
    return date ? new Date(date).toISOString() : undefined;
  };

  const addItem = (item: any, element: any) => {
    item.startDate = convertToISODateString(item.startDate);
    item.endDate = convertToISODateString(item.endDate);
    if (localStateLinkedinResume?.["@id"]) {
      dispatch(createLinkedinItemAction({ ...item, linkedinResume: localStateLinkedinResume["@id"] }, element));
    }
    setModifyOpen(null);
  };

  const modifyLinkedinItem = (item: LinkedinExperience | LinkedinLanguage | LinkedinEducation): void => {
    dispatch(editLinkedinItemAction(item));
  };

  const submitItemModification = (values: any, element: LinkedinElement) => {
    modifyLinkedinItem(values);
    if (values.startDate) {
      values.startDate = new Date(values.startDate).toISOString();
    }
    if (values.endDate) {
      values.endDate = new Date(values.endDate).toISOString();
    }
    if (localStateLinkedinResume) {
      const v = localStateLinkedinResume[element].findIndex((l: any) => l["@id"] === values["@id"]);
      localStateLinkedinResume[element][v] = values;
    }
    setLocalStateLinkedinResume(localStateLinkedinResume);
    setModifyOpen(null);
  };

  const handlePrevious = () => {
    if (context === null) {
      return;
    }
    context.handlePrevious(QUIZ_STEP_SLUG);
  };

  const submitForm = (values: any): void => {
    if (context === null) {
      return;
    }
    context.handleSubmit({
      data: {
        ...values,
        uploadedAt: new Date(),
      },
      value: QUIZ_STEP_SLUG,
    });
  };

  React.useEffect(() => {
    const hasSuccessForTypes = (types: string[]) => checkApiSuccessForTypes(apiSuccess, types);
    const hasPendingRequestForType = (type: string) => apiPendingRequests.some((e) => e.type === type);
    if (
      hasPendingRequestForType(CreateManualLinkedin.REQUEST)
      || hasPendingRequestForType(CreateLinkedinResume.REQUEST)
      || hasPendingRequestForType(PostAnswer.REQUEST)
    ) {
      setLoader(true);
    }

    if (hasPendingRequestForType(CreateLinkedinResumeItem.REQUEST)) {
      setItemLoader(true);
    }

    if (hasSuccessForTypes([CreateLinkedinResume.SUCCESS])) {
      setLoader(false);
    }

    if (hasSuccessForTypes([CreateLinkedinResumeItem.SUCCESS])) {
      setItemLoader(false);
    }
  }, [linkedinResume, apiPendingRequests, apiSuccess]);

  React.useEffect(() => {
    if (apiErrors.length > 0) {
      // Display a notification or update the component state to reflect the error
      // eslint-disable-next-line
      console.error("An error occurred:", apiErrors[0]?.payload.message);
    }
  }, [apiErrors]);

  React.useEffect(() => {
    if (localStateLinkedinResume) {
      setModifyOpen(null);
    }
  }, [setModifyOpen, localStateLinkedinResume]);

  React.useEffect(() => {
    if (linkedinResume && !linkedinResume.experiences && !linkedinResume.languages && !linkedinResume.educations) {
      setLocalStateLinkedinResume({ ...linkedinResume, experiences: [], educations: [], languages: [] });
    } else if (linkedinResume) {
      setLocalStateLinkedinResume(linkedinResume);
    }
  }, [linkedinResume]);

  React.useEffect(() => {
    if (!!!localStateLinkedinResume || localStateLinkedinResume.experiences.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [localStateLinkedinResume]);

  React.useEffect(() => {
    if (
      apiSuccess.some((e) => [CreateLinkedinResumeItem.SUCCESS, EditLinkedinItem.SUCCESS].includes(e.type))
      && prevLinkedinResume !== linkedinResume
    ) {
      setLocalStateLinkedinResume(linkedinResume);
      setModifyOpen(null);
    }
  }, [apiSuccess, linkedinResume, prevLinkedinResume]);
  return (
    <div>
      <ul className={styles.explicationsList}>
        <li>
          <div className={styles.explicationTitle}>Ajouter des experiences, formations et langues</div>
          <div>
            Cliquez sur{" "}
            <span
              className={cx(styles.updateAddItemCta, globalStyle.cta, globalStyle.primaryCta, globalStyle.inverted)}
            >
              <HiPlus />
            </span>{" "}
            pour ajouter un élément. Vous pourrez ensuite le modifier ou le supprimer si besoin.
          </div>
        </li>
      </ul>
      {loader ? (
        <Loader />
      ) : (
        <div>
          {localStateLinkedinResume ? (
            <>
              <div className={linkedPartStyles.titleContainer}>
                <h6 className={linkedPartStyles.title}>Votre parcours</h6>
              </div>
              <div>
                <Formik
                  initialValues={{ url: answer?.data?.url || localStateLinkedinResume?.url || "" }}
                  validationSchema={validationSchema}
                  onSubmit={submitForm}
                >
                  {({ isSubmitting }): JSX.Element => (
                    <>
                      {!answer?.data?.url && !localStateLinkedinResume?.url ? (
                        <Form className="form">
                          <div className="formGroup">
                            <Field
                              name="url"
                              label="URL de votre profil Linkedin"
                              placeholder="https://www.linkedin.com/in/...."
                              component={Input}
                              className={styles.urlInput}
                            />
                          </div>
                        </Form>
                      ) : null}
                      <div className={styles.linkedinResumeContainer}>
                        {localStateLinkedinResume.summary ? (
                          <div className={styles.part}>
                            <div className={styles.partHeader}>
                              <div className={styles.partTitle}>Résumé</div>
                            </div>
                            <div className={styles.partBody}>
                              <div className={styles.partText}>{localStateLinkedinResume.summary}</div>
                            </div>
                          </div>
                        ) : null}
                        {itemLoader ? <Loader /> : null}
                        <div>
                          <div className={styles.part}>
                            <div className={styles.partHeader}>
                              <div className={styles.partTitle}>Expériences</div>
                              <div>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setModifyOpen("experience" === modifyOpen ? null : "experience");
                                  }}
                                  className={cx(
                                    styles.updateAddItemCta,
                                    globalStyle.cta,
                                    globalStyle.primaryCta,
                                    globalStyle.inverted,
                                  )}
                                >
                                  {"experience" === modifyOpen ? <MdClose /> : <HiPlus />}
                                </button>
                              </div>
                            </div>
                            <div className={styles.partBody}>
                              {modifyOpen === "experience" ? (
                                <div>
                                  <ModifyExperience submitItemModification={submitItemModification} addItem={addItem} />
                                </div>
                              ) : null}
                              {localStateLinkedinResume.experiences ? (
                                <>
                                  {localStateLinkedinResume.experiences.map(
                                    (experience: LinkedinExperience, index: number) => {
                                      return (
                                        <div
                                          key={`${experience.companyName} - ${experience.jobTitle} - ${index}`}
                                          className={styles.subPart}
                                        >
                                          <div className={styles.subPartContent}>
                                            <div className={styles.subPartHeader}>
                                              <div className={styles.subPartTitle}>
                                                {experience.companyName} - {experience.jobTitle}
                                              </div>
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setModifyOpen(
                                                      modifyOpen?.["@id"] === experience["@id"] ? null : experience,
                                                    );
                                                  }}
                                                  className={cx(
                                                    styles.updateAddItemCta,
                                                    globalStyle.cta,
                                                    globalStyle.primaryCta,
                                                    globalStyle.inverted,
                                                  )}
                                                >
                                                  {modifyOpen?.["@id"] === experience["@id"] ? <MdClose /> : <MdEdit />}
                                                </button>
                                              </div>
                                            </div>
                                            <div>
                                              {modifyOpen?.["@id"] === experience["@id"] ? (
                                                <ModifyExperience
                                                  submitItemModification={submitItemModification}
                                                  item={experience}
                                                  deleteItem={deleteItem}
                                                />
                                              ) : experience.city
                                                || (experience.startDate && experience.endDate)
                                                || experience.description ? (
                                                <div className={styles.subPartBody}>
                                                  {experience.city || (experience.startDate && experience.endDate) ? (
                                                    <div className={styles.additionalInfosPart}>
                                                      {experience.city ? <div>{experience.city}</div> : null}
                                                      {experience.startDate && experience.endDate ? (
                                                        <div>
                                                          (
                                                          {format(parseISO(experience.startDate), "LLLL yyyy", {
                                                            locale: fr,
                                                          })}{" "}
                                                          -{" "}
                                                          {format(parseISO(experience.endDate), "LLLL yyyy", {
                                                            locale: fr,
                                                          })}
                                                          )
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  ) : null}
                                                  {experience.description ? (
                                                    <div className={styles.subPartDesciptionContainer}>
                                                      {experience.description.length > 75 ? (
                                                        <div className={styles.subPartText}>
                                                          {experience.description.substring(0, 75)}...
                                                        </div>
                                                      ) : (
                                                        <div className={styles.subPartText}>
                                                          {experience.description}
                                                        </div>
                                                      )}
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className={styles.part}>
                            <div className={styles.partHeader}>
                              <div className={styles.partTitle}>Formations</div>
                              <div>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setModifyOpen("education" === modifyOpen ? null : "education");
                                  }}
                                  className={cx(
                                    styles.updateAddItemCta,
                                    globalStyle.cta,
                                    globalStyle.primaryCta,
                                    globalStyle.inverted,
                                  )}
                                >
                                  {"education" === modifyOpen ? <MdClose /> : <HiPlus />}
                                </button>
                              </div>
                            </div>
                            <div className={styles.partBody}>
                              {modifyOpen === "education" ? (
                                <div>
                                  <ModifyEducation submitItemModification={submitItemModification} addItem={addItem} />
                                </div>
                              ) : null}
                              {localStateLinkedinResume.educations ? (
                                <>
                                  {localStateLinkedinResume.educations.map(
                                    (education: LinkedinEducation, index: number) => {
                                      return (
                                        <div className={styles.subPart} key={`${education.schoolName}-${index}`}>
                                          <div className={styles.subPartContent}>
                                            <div className={styles.subPartHeader}>
                                              <div className={styles.subPartTitle}>
                                                {education.schoolName ? education.schoolName : null} -{" "}
                                                {education.degreeName ? education.degreeName : null}
                                              </div>
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setModifyOpen(
                                                      modifyOpen?.["@id"] === education["@id"] ? null : education,
                                                    );
                                                  }}
                                                  className={cx(
                                                    styles.updateAddItemCta,
                                                    globalStyle.cta,
                                                    globalStyle.primaryCta,
                                                    globalStyle.inverted,
                                                  )}
                                                >
                                                  {modifyOpen?.["@id"] === education["@id"] ? <MdClose /> : <MdEdit />}
                                                </button>
                                              </div>
                                            </div>

                                            {modifyOpen?.["@id"] === education["@id"] ? (
                                              <div>
                                                <ModifyEducation
                                                  submitItemModification={submitItemModification}
                                                  item={education}
                                                  deleteItem={deleteItem}
                                                />
                                              </div>
                                            ) : education.startDate && education.endDate ? (
                                              <div className={styles.subPartBody}>
                                                <div className={styles.additionalInfosPart}>
                                                  {education.startDate && education.endDate ? (
                                                    <div>
                                                      ({format(parseISO(education.startDate), "MM/yyyy")} -{" "}
                                                      {format(parseISO(education.endDate), "MM/yyyy")})
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className={styles.part}>
                            <div className={styles.partHeader}>
                              <div className={styles.partTitle}>Langues</div>
                              <div>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setModifyOpen("language" === modifyOpen ? null : "language");
                                  }}
                                  className={cx(
                                    styles.updateAddItemCta,
                                    globalStyle.cta,
                                    globalStyle.primaryCta,
                                    globalStyle.inverted,
                                  )}
                                >
                                  {"language" === modifyOpen ? <MdClose /> : <HiPlus />}
                                </button>
                              </div>
                            </div>
                            <div className={styles.partBody}>
                              <div>
                                {modifyOpen === "language" ? (
                                  <ModifyLanguage submitItemModification={submitItemModification} addItem={addItem} />
                                ) : null}
                              </div>
                              {localStateLinkedinResume.languages ? (
                                <>
                                  {localStateLinkedinResume.languages.map(
                                    (language: LinkedinLanguage, index: number) => {
                                      return (
                                        <div className={styles.subPart} key={`${language.name}-${index}`}>
                                          <div className={styles.subPartContent}>
                                            <div className={styles.subPartHeader}>
                                              <div className={styles.subPartTitle}>
                                                {language.name}
                                                {language.proficiency
                                                  ? ` - ${linkedinLanguages[language.proficiency]}`
                                                  : null}
                                              </div>
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setModifyOpen(
                                                      modifyOpen?.["@id"] === language["@id"] ? null : language,
                                                    );
                                                  }}
                                                  className={cx(
                                                    styles.updateAddItemCta,
                                                    globalStyle.cta,
                                                    globalStyle.primaryCta,
                                                    globalStyle.inverted,
                                                  )}
                                                >
                                                  {modifyOpen?.["@id"] === language["@id"] ? <MdClose /> : <MdEdit />}
                                                </button>
                                              </div>
                                            </div>

                                            {modifyOpen?.["@id"] === language["@id"] ? (
                                              <div>
                                                <ModifyLanguage
                                                  submitItemModification={submitItemModification}
                                                  item={language}
                                                  deleteItem={deleteItem}
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      {disabled ? (
                        <p className={styles.required}>
                          *Remplissez au moins une expérience afin que nous puissions trouver les meilleurs offres pour
                          vous
                        </p>
                      ) : null}
                      <Form>
                        <div className={quizStyle.ctasContainer}>
                          <div>
                            <span onClick={handlePrevious} className={quizStyle.previousCta}>
                              {t`Quiz.previousCta.text`}
                            </span>
                          </div>
                          <div className={quizStyle.submitCtaContainer}>
                            <button type="submit" disabled={disabled || isSubmitting} className={quizStyle.submitCta}>
                              Suivant <CgArrowRight />
                            </button>
                          </div>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};
