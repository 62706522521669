import { t } from "@lingui/macro";
import cx from "classnames";
import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import { GetTalentProfile } from "../../actionTypes/talent";
import { PersonProfileImg } from "../../blocks/personProfileImg";
import { ReactComponent as AlarmSvg } from "../../images/alarm.svg";
import { ReactComponent as AppointmentIcon } from "../../images/appointment.svg";
import { ReactComponent as HomeSvg } from "../../images/home.svg";
import logo from "../../images/logos/elinoi-logo-black.svg";
import { ReactComponent as LogoutSvg } from "../../images/logout.svg";
import { ReactComponent as OffersSvg } from "../../images/offers.svg";
import { ReactComponent as SettingsSvg } from "../../images/settings.svg";
import { ReactComponent as SpeechBubblesSvg } from "../../images/speech-bubble.svg";
import { ReactComponent as SponsorSvg } from "../../images/sponsor.svg";
import { ReactComponent as ToolBoxSvg } from "../../images/toolbox.svg";
import { State } from "../../interfaces/api/state";
import { Notification } from "../../interfaces/resources/notification";
import { TalentProfile, Talent } from "../../interfaces/resources/talent";
import { routes, hashes } from "../../routes";
import { logoutAction } from "../../services/api/authentication/actions";
import { getTalentProfileAction } from "../../services/api/talent/actions";
import { gtagService } from "../../services/google/gtag";
import global from "../../styles/global.module.scss";
import { isMobile } from "../../utils/helpers";
import { useGetResourceHook, useApiSelector } from "../../utils/hooks";
import { NotificationsList } from "../NotificationsList";
import styles from "./index.module.scss";
import { NavCta } from "./NavCta";

export const MenuCollapseEvent = "Menu:collapse";

export interface MenuSettingsProps {
  width: number;
  smallWidth: number;
  duration: number;
}

export interface MenuCollapseEventProps {
  isCollapsed: boolean;
  duration: number;
}

export const menuSettings: MenuSettingsProps = {
  width: 220,
  smallWidth: 100,
  duration: 400,
};

export const Menu: React.FC = () => {
  const isMenuCollapsed = useRef<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState(isMenuCollapsed.current);
  const $menuContainer = useRef<HTMLDivElement>(null);
  const { apiPendingRequests, apiErrors, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const talentProfile: TalentProfile = useSelector((state: State) => state.talentProfile);
  const currentTalent: Talent = useSelector((state: State) => state.currentTalent);
  const notifications: Notification[] = useSelector((state: State) => state.notifications);
  const hasUnseenNotifications = notifications && notifications.some((e: Notification) => !e.seen);
  const getTalentProfile = (talent: Talent): { talent: Talent; type: string } =>
    dispatch(getTalentProfileAction(talent));
  const logout = (): { type: string } => dispatch(logoutAction());
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetTalentProfile, talentProfile, getTalentProfile, [
    currentTalent,
  ]);

  const trackAction = (name: string): void => {
    gtagService.event("click", {
      event_label: `menu - ${name}`,
    });
  };

  const onClickLogoutBtn = (): void => {
    // track logout actions
    logout();
  };

  const onClickNavMenuItem = (): void => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <div
        className={cx(styles.menuContainer, {
          [styles.isMobile]: isMobile(),
          [styles.isMenuOpen]: isMenuOpen,
        })}
        ref={$menuContainer}
        data-testid="menu-wrapper"
      >
        <div className={styles.menu}>
          <div className={styles.menuHeader}>
            <div className={styles.logoContainer}>
              <img src={logo} alt="Élinoï" />
            </div>
          </div>

          <div className={styles.menuProfileContainer}>
            <div className={styles.profileImgContainer}>
              <PersonProfileImg src={currentTalent.image.url} />
            </div>
            <div className={styles.nameLinkContainer}>
              <div className={styles.nameContainer}>
                <h2 className={styles.name}>{currentTalent.firstname}</h2>
              </div>
              <div className={styles.linkContainer}>
                <NavLink
                  to={routes.app.profile}
                  className={cx(styles.profileLink)}
                  onClick={(): void => {
                    onClickNavMenuItem();
                  }}
                >
                  {t`menu.see.profile`}
                </NavLink>
              </div>
            </div>
          </div>
          <nav className={styles.menuNavContainer}>
            <ul className={styles.navList}>
              <li
                className={cx(styles.hideOnMobile, styles.notificationItem)}
                onMouseEnter={(): void => {
                  trackAction(t`menu.notifications`);
                }}
              >
                <NavCta
                  icon={<AlarmSvg />}
                  showBadge={hasUnseenNotifications}
                  wording={t`menu.notifications`}
                  className="notificationNavCta"
                />
                <div className={styles.notificationMenuContainer}>
                  <div className={styles.notificationsList}>
                    <NotificationsList simple={true} format="short" trackingPrefix="menu" />
                  </div>
                </div>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={routes.app.contactUs}
                  onClick={(): void => {
                    trackAction(t`menu.message-us`);
                    onClickNavMenuItem();
                  }}
                >
                  <NavCta icon={<SpeechBubblesSvg />} wording={t`menu.message-us`} />
                </NavLink>
              </li>
              <li className={styles.hideOnMobile}>
                <NavLink
                  activeClassName={styles.active}
                  to={routes.app.offers}
                  onClick={(): void => {
                    trackAction(t`menu.offers`);
                    onClickNavMenuItem();
                  }}
                >
                  <NavCta icon={<OffersSvg />} wording={t`menu.offers`} />
                </NavLink>
              </li>
            </ul>
            <ul className={styles.navList}>
              <li>
                <NavLink
                  exact
                  activeClassName={styles.active}
                  to={routes.app.home}
                  onClick={(): void => {
                    trackAction(t`menu.home`);
                    onClickNavMenuItem();
                  }}
                >
                  <NavCta icon={<HomeSvg />} wording={t`menu.home`} />
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={routes.app.ressources}
                  onClick={(): void => {
                    trackAction(t`menu.toolbox`);
                    onClickNavMenuItem();
                  }}
                >
                  <NavCta icon={<ToolBoxSvg />} wording={t`menu.toolbox`} />
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={routes.app.sponsor}
                  onClick={(): void => {
                    trackAction(t`menu.sponsor`);
                    onClickNavMenuItem();
                  }}
                >
                  <NavCta icon={<SponsorSvg />} wording={t`menu.sponsor`} />
                  <span className={styles.newBadge}>{t`badge.new`}</span>
                </NavLink>
              </li>
            </ul>
          </nav>
          <ul className={cx(styles.navList, styles.navBottomList)}>
            <li>
              <NavLink
                activeClassName={styles.active}
                to={routes.app.settings}
                onClick={(): void => {
                  trackAction(t`menu.settings`);
                  onClickNavMenuItem();
                }}
                data-testid="menu-nav-settings-cta"
              >
                <NavCta icon={<SettingsSvg />} wording={t`menu.settings`} />
              </NavLink>
            </li>
            <li>
              <div className={styles.logoutBtnContainer}>
                <button
                  className={cx(global.btn, styles.logoutBtn)}
                  onClick={(): void => {
                    trackAction(t`links.menu.logout`);
                    onClickLogoutBtn();
                  }}
                >
                  <div className={styles.logoutIcon}>
                    <LogoutSvg />
                  </div>
                  <div className={styles.wordingContainer}>
                    <span className={styles.wording}>{t`links.menu.logout`}</span>
                  </div>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.mobilMenuBarWrapper}>
        <div className={styles.mobileMenuBarContainer}>
          <ul className={styles.mobileMenuList}>
            <li>
              <NavLink
                activeClassName={styles.active}
                to={routes.app.notifications}
                onClick={(): void => {
                  trackAction(t`menu.notifications`);
                  onClickNavMenuItem();
                }}
              >
                <div className={styles.mobileMenuItemContent}>
                  <div className={styles.iconContainer}>
                    <AlarmSvg />
                  </div>
                  <span className={styles.itemWording}>{t`menu.notifications`}</span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles.active}
                isActive={(): boolean =>
                  window.location.pathname === routes.app.offers && !(window.location.hash.indexOf(hashes.process) > -1)
                }
                to={routes.app.offers + hashes.offers}
                onClick={(): void => {
                  trackAction(t`menu.offers`);
                  onClickNavMenuItem();
                }}
              >
                <div className={styles.mobileMenuItemContent}>
                  <div className={styles.iconContainer}>
                    <OffersSvg />
                  </div>
                  <span className={styles.itemWording}>{t`menu.offers`}</span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles.active}
                isActive={(): boolean => window.location.hash.indexOf(hashes.process) > -1}
                to={routes.app.offers + hashes.process}
                onClick={(): void => {
                  trackAction(t`menu.process`);
                  onClickNavMenuItem();
                }}
              >
                <div className={styles.mobileMenuItemContent}>
                  <div className={styles.iconContainer}>
                    <AppointmentIcon />
                  </div>
                  <span className={styles.itemWording}>{t`menu.process`}</span>
                </div>
              </NavLink>
            </li>
            <li>
              <div className={cx(styles.mobileMenuItemContent, styles.burgerItemContent)}>
                <div
                  className={cx(styles.burgerContainer, { [styles.isMenuOpen]: isMenuOpen })}
                  onClick={(): void => setIsMenuOpen(!isMenuOpen)}
                >
                  <span className={cx(styles.lines, styles.top)}></span>
                  <span className={cx(styles.lines, styles.middle)}></span>
                  <span className={cx(styles.lines, styles.bottom)}></span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
