import { t } from "@lingui/macro";
import cx from "classnames";
import React from "react";
import { Helmet } from "react-helmet";
import { FaLock, FaUnlock, FaLongArrowAltLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetApplications, SubmitApplication } from "../../actionTypes/applications";
import { CreateOfferEvent } from "../../actionTypes/offerEvent";
import { GetOffer } from "../../actionTypes/offers";
import { CompanyImg } from "../../blocks/companyImg";
import { OfferApplicationCta } from "../../blocks/offerApplicationCta";
import { PersonProfileImg } from "../../blocks/personProfileImg";
import { TabPanel, PageWithTabs, TabTrigger } from "../../components/Tabs";
import { extractIdFromIri } from "../../components/Utils/helpers";
import LoaderComp from "../../components/Utils/Loader";

import { ApiErrorProps } from "../../interfaces/api/error";
import { ApiRequestProps } from "../../interfaces/api/request";
import { State } from "../../interfaces/api/state";
import { ApiSuccessProps } from "../../interfaces/api/success";
import { Application } from "../../interfaces/resources/application";
import { Offer as OfferInterface } from "../../interfaces/resources/offer";
import { Talent } from "../../interfaces/resources/talent";

import { routes } from "../../routes";
import { getApplications } from "../../services/api/applications/actions";
import { getOffer, getLastAcceptedApplication } from "../../services/api/offer/actions";
import { createOfferEvent } from "../../services/api/offer/offerEvent/actions";
import { getCurrentTalent } from "../../services/api/talent/actions";

import { gtagService } from "../../services/google/gtag";
import { isOdd, isProcessPanelDisabled } from "../../utils/helpers";
import { useApiSelector, usePrevious } from "../../utils/hooks";

import { onInitPageLoadTracking } from "../../utils/tracking";
import styles from "./index.module.scss";
import { PanelCompany } from "./panels/PanelCompany";
import { PanelOffer } from "./panels/PanelOffer";
import { PanelProcess } from "./panels/PanelProcess";

interface Props {
  match: {
    params: {
      slug: string;
    };
  };
  canApply?: boolean;
}

interface TabProps {
  slug: string;
  eventName?: string;
  tabName: React.ReactNode;
  component: JSX.Element;
  disabled?: boolean;
}

export const OfferPage: React.FC<Props> = (props: Props) => {
  const { match, canApply = true } = props;
  const slug = match.params.slug;
  const dispatch = useDispatch();
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const oldApiSuccess = usePrevious(apiSuccess);
  const currentTalent: Talent = useSelector((state: State) => state.currentTalent);
  const offer: OfferInterface = useSelector((state: State) => state.offer);
  const application: Application = useSelector((state: State) => state.application);
  const [disabled, setDisabled] = React.useState(true);
  const [tabs, setTabs] = React.useState<TabProps[]>([]);
  const isTalentOdd: boolean = isOdd(extractIdFromIri(currentTalent["@id"]));
  const isLoading =
    apiPendingRequests.some((req: ApiRequestProps) => [GetOffer.REQUEST, GetApplications.REQUEST].includes(req.type))
    || apiErrors.some((err: ApiErrorProps) => [GetOffer.FAILURE, GetApplications.FAILURE].includes(err.type));

  React.useEffect(() => {
    if (
      offer
      && !apiErrors.some((err: ApiErrorProps) => err.type === CreateOfferEvent.FAILURE)
      && !apiPendingRequests.some((req: ApiRequestProps) => req.type === CreateOfferEvent.REQUEST)
      && !apiSuccess.some((suc: ApiSuccessProps) => suc.type === CreateOfferEvent.SUCCESS)
    ) {
      dispatch(
        createOfferEvent({
          offer,
          status: "read",
          talent: currentTalent,
        }),
      );
    }
  }, [offer, currentTalent, dispatch, apiPendingRequests, apiErrors, apiSuccess]);

  React.useEffect(() => {
    if (offer) {
      dispatch(getApplications(currentTalent, offer));
      dispatch(getLastAcceptedApplication(offer["@id"]));
    } else {
      dispatch(getOffer(slug));
    }
  }, [offer, slug, currentTalent, dispatch]);

  React.useEffect(() => {
    if (offer) {
      setTabs([
        {
          slug: "offer",
          tabName: t`OfferPage.tab.offer.title`,
          component: <PanelOffer offer={offer} />,
        },
        {
          slug: "startup",
          tabName: t`OfferPage.tab.company.title`,
          component: <PanelCompany company={offer.company} />,
        },
        {
          slug: "process",
          eventName: "notre coup de pouce",
          tabName: (
            <>
              <span className={styles.lockIcon}>{disabled ? <FaLock /> : <FaUnlock />}</span>{" "}
              {t`OfferPage.tab.process.title`}
            </>
          ),
          component: <PanelProcess offer={offer} />,
          disabled,
        },
      ]);
    }
  }, [offer, disabled]);

  React.useEffect(() => {
    setDisabled(isProcessPanelDisabled(application));
  }, [application]);

  React.useEffect(() => {
    if (offer) {
      onInitPageLoadTracking();
    }
  }, [offer]);

  React.useEffect(() => {
    const apiSuccessIntersection = apiSuccess.slice(oldApiSuccess?.length);
    if (apiSuccessIntersection.some((action) => action.type === SubmitApplication.SUCCESS)) {
      // get talent to be sure to have the latest information. Used to know the current search status when a talent applied.
      dispatch(getCurrentTalent());
    }
  }, [apiSuccess, oldApiSuccess, dispatch]);

  return (
    <div>
      <Helmet>
        <title>{t({ id: "OfferScene.title", values: { offerTitle: offer && offer.title } })}</title>
        <meta name="" content="" />
      </Helmet>
      {!isLoading && offer && offer.offerTalentView ? (
        <PageWithTabs
          defaultActivePanel="offer"
          defaultActiveClass={styles.tabActive}
          defaultDisabledClass={styles.tabDisabled}
        >
          <div className={styles.offerHeader} data-testid="offer-header">
            <div className={styles.returnCtaContainer}>
              <Link
                to={routes.app.offers}
                onClick={() => {
                  gtagService.event("click", {
                    event_label: "offre - fleche retour",
                  });
                }}
              >
                <FaLongArrowAltLeft /> {t`OfferPage.return.text`}
              </Link>
            </div>
            <div className={styles.offerHeaderContent}>
              <div className={styles.startupLogoContainer}>
                <div className={styles.startupLogo}>
                  <CompanyImg src={offer.company.image.url} name={offer.company.name} />
                </div>
              </div>
              <div className={styles.offerInfoTabsContainer}>
                <div className={styles.offerTitleStartupContainer}>
                  <span className={styles.companyName}>{offer.company.name}</span>
                  <h1 className={styles.offerTitle}>{offer.title}</h1>
                </div>
                <div>
                  <ul className={styles.tabsList}>
                    {tabs.map((tab) => {
                      return (
                        <li key={`page-offer-tab--${tab.slug}`}>
                          <TabTrigger
                            tabKey={tab.slug}
                            disabled={tab.disabled}
                            onClick={() => {
                              gtagService.event("click", {
                                event_label: `offre - onglet - ${tab.eventName ?? tab.slug}`,
                              });
                            }}
                          >
                            <div data-testid={`tabtest-${tab.slug}`} className={cx(styles.tabsLink)}>
                              {tab.tabName}
                            </div>
                          </TabTrigger>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className={styles.offerApplicationCtaContainer}>
                {canApply ? (
                  <OfferApplicationCta
                    application={application}
                    offer={offer}
                    canApply={canApply}
                    isLoading={isLoading}
                    applyTrackingEvent={() => {
                      if (isTalentOdd) {
                        gtagService.event("click", {
                          event_label: "offre - en discuter avec Élinoï - aqua",
                        });
                      } else {
                        gtagService.event("click", {
                          event_label: "offre - en discuter avec Élinoï - red",
                        });
                      }
                    }}
                  />
                ) : null}
                {offer.owner ? (
                  <div className={styles.offerOwnerContainer}>
                    <div className={styles.offerOwnerImgContainer}>
                      <PersonProfileImg src={offer.owner.image.url} hasOverlay={false} alt={offer.owner.firstname} />
                    </div>
                    <h4 className={styles.offerOwnerName}>
                      {t({ id: "OfferPage.handledBy", values: { ownerFirstname: offer.owner.firstname } })}
                    </h4>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            {tabs.map((tab) => {
              return (
                <TabPanel key={`page-offer-panel--${tab.slug}`} panelKey={tab.slug}>
                  <div className={cx(styles.tabPanelWrapper, { [styles.isOddWrapper]: isTalentOdd })}>
                    {tab.component}
                  </div>
                </TabPanel>
              );
            })}
          </div>
          {canApply ? (
            <div className={styles.offerApplicationCtaContainerFix}>
              <OfferApplicationCta
                application={application}
                offer={offer}
                canApply={canApply}
                isLoading={isLoading}
                applyTrackingEvent={() => {
                  if (isTalentOdd) {
                    gtagService.event("click", {
                      event_label: "offre - en discuter avec Élinoï - fix - aqua",
                    });
                  } else {
                    gtagService.event("click", {
                      event_label: "offre - en discuter avec Élinoï - fix - red",
                    });
                  }
                }}
              />
              {offer.owner ? (
                <div className={styles.offerOwnerContainerTest}>
                  <div className={styles.offerOwnerImgContainer}>
                    <PersonProfileImg src={offer.owner.image.url} hasOverlay={false} alt={offer.owner.firstname} />
                  </div>
                  <h4 className={styles.offerOwnerName}>
                    {t({ id: "OfferPage.handledBy", values: { ownerFirstname: offer.owner.firstname } })}
                  </h4>
                </div>
              ) : null}
            </div>
          ) : null}
        </PageWithTabs>
      ) : (
        <div className={styles.loaderContainer} data-testid="loader">
          <LoaderComp />
        </div>
      )}
    </div>
  );
};
