import { Offer } from "./offer";
import { Talent } from "./talent";

export interface ApplicationStatusEvent {
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface Application {
  "@id": string;
  answerDate: string | null;
  applicationStatusEvents: ApplicationStatusEvent[];
  createdAt: string;
  interviewAt: string | null;
  interviewsAt: string[];
  interviewStartDate: string | null;
  offer: Offer;
  owner: any;
  statusAsTalent: string;
  talent: Talent;
  updatedAt: string;
}

export interface LastAcceptedApplication {
  "@id": string;
  talent: Talent;
}

export interface ApplicationWithStatus extends Application {
  status: string;
}

export const applicationStatuses = {
  accepted: "accepted",
  applied: "applied",
  awaitingShortlist: "awaiting_shortlist",
  callBooked: "call_booked",
  contacted: "contacted",
  interested: "interested",
  interesting: "interesting",
  interviews: "interviews",
  offer: "offer",
  refusedElinoi: "refused_elinoi",
  refusedStartup: "refused_startup",
  refusedTalent: "refused_talent",
  shortlistReady: "shortlist_ready",
  shortlisted: "shortlisted",
  toContact: "to_contact",
  toPitch: "to_pitch",
};

export const applicationStatusesActive = [
  applicationStatuses.applied,
  applicationStatuses.awaitingShortlist,
  applicationStatuses.interviews,
  applicationStatuses.offer,
  applicationStatuses.shortlistReady,
  applicationStatuses.shortlisted,
  applicationStatuses.toPitch,
];

export const applicationOngoinProcess = [
  applicationStatuses.interviews,
  applicationStatuses.offer,
  applicationStatuses.shortlisted,
];
