import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import { GetTalentSetting } from "../../actionTypes/talent";
import { CoCModalOpen } from "../../components/CodeOfConductModalWrapper";
import { OnboardModalOpen } from "../../components/OnboardingModalWrapper";
import { Radio } from "../../components/Utils/Form/Radio";
import { Switch } from "../../components/Utils/Form/Switch";
import { State } from "../../interfaces/api/state";
import { Talent, TalentSetting } from "../../interfaces/resources/talent";
import {
  editTalentSetting as editTalentSettingAction,
  getTalentSetting as getTalentSettingAction,
} from "../../services/api/talent/actions";
import globalStyle from "../../styles/global.module.scss";
import { transTalentSetting } from "../../translations/constants/talentSetting";
import { eventDispatcher } from "../../utils/eventDispatcher";
import { useApiSelector, useGetResourceHook } from "../../utils/hooks";
import { onInitPageLoadTracking } from "../../utils/tracking";

import styles from "./index.module.scss";

export const SettingsPage: React.FC = () => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const talentSetting = useSelector((state: State) => state.talentSetting);
  const getTalentSetting = (talent: Talent): void => {
    dispatch(getTalentSettingAction(talent));
  };
  const editTalentSetting = (value: TalentSetting): void => {
    dispatch(editTalentSettingAction(value));
  };

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetTalentSetting, talentSetting, getTalentSetting, [
    currentTalent,
  ]);

  const handleValidation = (values: TalentSetting): void => {
    editTalentSetting(values);
  };
  const onClickCocModalBtn = (): void => {
    eventDispatcher.trigger(CoCModalOpen);
  };
  const onClickOnboardModalBtn = (): void => {
    eventDispatcher.trigger(OnboardModalOpen);
  };

  React.useEffect(() => {
    onInitPageLoadTracking();
  }, []);

  return (
    <div className={cx(styles.pageWrapper)}>
      <h2 className={globalStyle.mainTitle}>
        <Markdown source={t`SettingsPage.title`} disallowedTypes={["paragraph"]} unwrapDisallowed />
      </h2>
      {talentSetting ? (
        <Formik onSubmit={handleValidation} initialValues={talentSetting}>
          {(): JSX.Element => (
            <Form className={styles.settingForm}>
              <div className={styles.formBlock}>
                <h4 className={styles.fieldTitle}>{t`SettingPage.opportunitySettings.title`}</h4>
                <div className={styles.fieldRadioWrapper}>
                  {Object.keys(transTalentSetting.newOffersFrequencies).map((e: string) => {
                    return (
                      <div key={`news-offer-frequence-${e}`} className={styles.fieldRadioContainer}>
                        <Field
                          name={"newOffersFrequency"}
                          value={e}
                          component={Radio}
                          label={transTalentSetting.newOffersFrequencies[e]}
                          data-heap="optionFrequency"
                          submitOnChange={true}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={cx(styles.formBlock, styles.switchBlock)}>
                <div className={styles.fieldSwitchContainer}>
                  <Field
                    name="subscribeNotifications"
                    data-heap="switchNotifSum"
                    component={Switch}
                    submitOnChange={true}
                  />
                </div>
                <div className={styles.infoSwitchContainer}>
                  <h4 className={styles.fieldTitle}>{t`SettingPage.subscribeNotifications.title`}</h4>
                  <span className={styles.fieldLabel}>{t`SettingPage.subscribeNotifications.label`}</span>
                </div>
              </div>
              <div className={cx(styles.formBlock, styles.switchBlock)}>
                <div className={styles.fieldSwitchContainer}>
                  <Field
                    name="subscribeNewsLetter"
                    data-heap="switchNewsletter"
                    component={Switch}
                    submitOnChange={true}
                  />
                </div>
                <div className={styles.infoSwitchContainer}>
                  <h4 className={styles.fieldTitle}>{t`SettingPage.subscribeNewsLetter.title`}</h4>
                  <span className={styles.fieldLabel}>{t`SettingPage.subscribeNewsLetter.label`}</span>
                </div>
              </div>
              <div className={cx(styles.formBlock, styles.switchBlock)}>
                <div className={styles.fieldSwitchContainer}>
                  <Field name="subscribeEvents" data-heap="switchEvent" component={Switch} submitOnChange={true} />
                </div>
                <div className={styles.infoSwitchContainer}>
                  <h4 className={styles.fieldTitle}>{t`SettingPage.subscribeEvents.title`}</h4>
                  <span className={styles.fieldLabel}>{t`SettingPage.subscribeEvents.label`}</span>
                </div>
              </div>

              <div className={cx(styles.formBlock, styles.cocBlock)}>
                <div>
                  <button
                    type="button"
                    className={cx(globalStyle.cta, globalStyle.secondaryCta)}
                    data-heap="btnCoC"
                    onClick={onClickCocModalBtn}
                  >
                    {t`SettingForm.readCodeOfConduct.button`}
                  </button>
                </div>
                <div className={styles.cocInfosContainer}>
                  <h4 className={cx(styles.fieldTitle)}>{t`SettingForm.readCodeOfConduct.title`}</h4>
                  <span className={styles.fieldLabel}>{t`SettingForm.readCodeOfConduct.label`}</span>
                </div>
              </div>
              <div className={cx(styles.formBlock, styles.cocBlock)}>
                <div>
                  <button
                    type="button"
                    className={cx(globalStyle.cta, globalStyle.secondaryCta)}
                    data-heap="btnOnboard"
                    onClick={onClickOnboardModalBtn}
                  >
                    {t`SettingForm.onboardingVideo.button`}
                  </button>
                </div>
                <div className={styles.cocInfosContainer}>
                  <h4 className={cx(styles.fieldTitle)}>{t`SettingForm.onboardingVideo.title`}</h4>
                  <span className={styles.fieldLabel}>{t`SettingForm.onboardingVideo.label`}</span>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};
