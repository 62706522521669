import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetApplications, SubmitApplication, GetAvailableApplications } from "../../../actionTypes/applications";
import { apiFailure } from "../helpers";
import * as apiClient from "./api";

export function* getApplications(action: AnyAction): any {
  try {
    const response = yield call(apiClient.getApplications, action.currentTalent, action.offer);
    const applications = response.data["hydra:member"];

    yield put({
      applications,
      type: GetApplications.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* getAvailableApplications(action: AnyAction): any {
  try {
    const response = yield call(apiClient.getAvailableApplications, action.currentTalent);
    const applications = response.data["hydra:member"];

    yield put({
      applications,
      type: GetAvailableApplications.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* submitApplication(action: AnyAction): any {
  const filterContext = [{ multiple: false, name: "talent", value: action.application.talent["@id"] }];
  try {
    const existingApplication = yield call(
      apiClient.getApplications,
      action.application.talent,
      action.application.offer,
      filterContext,
    );
    let response = null;
    if (existingApplication && existingApplication.data["hydra:member"].length > 0) {
      response = yield call(
        apiClient.editApplication,
        existingApplication.data["hydra:member"][0]["@id"],
        action.application,
      );
    } else {
      response = yield call(apiClient.createApplication, action.application);
    }
    const application = response.data;

    yield put({
      application,
      type: SubmitApplication.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* applicationsSaga() {
  yield takeLatest(GetApplications.REQUEST, getApplications);
  yield takeLatest(GetAvailableApplications.REQUEST, getAvailableApplications);
  yield takeLatest(SubmitApplication.REQUEST, submitApplication);
}
