import { extractIdFromIri } from "../../../components/Utils/helpers";
import client from "../client";

export function getSkills(page?: number) {
  return client.get(`/api/v2/skills${page ? `?page=${page}` : ""}`);
}

export function getSkill(responsibilityId: string) {
  return client.get(`/api/v2/skills?responsibility=${extractIdFromIri(responsibilityId)}`);
}
