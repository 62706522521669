import { AxiosResponse } from "axios";
import { FilterContext } from "../../../interfaces/api/filter";
import { ApplicationWithStatus } from "../../../interfaces/resources/application";

import { Offer, offerStatuses } from "../../../interfaces/resources/offer";
import { Talent } from "../../../interfaces/resources/talent";
import client from "../client";
import { generateFilterQuery } from "../helpers";

export function getApplications(
  currentTalent: Talent,
  offer?: Offer,
  filterContext: FilterContext = [],
): Promise<AxiosResponse<any>> {
  const fc: FilterContext = [
    {
      multiple: false,
      name: "offer",
      value: offer ? offer["@id"] : undefined,
    },
    {
      multiple: true,
      name: "offer.status",
      value: !offer ? [offerStatuses.activated, offerStatuses.standbyElinoi] : undefined,
    },
    ...filterContext,
  ];
  // If there is an offer, it's to fetch the applications for this specific offer,
  // and we want them even if the offer is disabled, or the talent might reapply.
  // If no offer, then we are fetching the list of applications to display them,
  // and we're only interested in enabled offers in this case
  return client.get(`${currentTalent["@id"]}/applications${generateFilterQuery(fc)}`);
}

export function getAvailableApplications(currentTalent: Talent): Promise<AxiosResponse<any>> {
  return client.get(`${currentTalent["@id"]}/availableApplications`);
}

export function editApplication(iri: string, application: Partial<ApplicationWithStatus>): Promise<AxiosResponse<any>> {
  return client.put(iri, { status: application.status });
}

const formData = (application: Partial<ApplicationWithStatus>) => ({
  offer: application.offer && application.offer["@id"],
  status: application.status,
  talent: application.talent && application.talent["@id"],
});

export function createApplication(application: Partial<ApplicationWithStatus>): Promise<AxiosResponse<any>> {
  return client.post("/api/v2/applications", formData(application));
}
