export type GtagArg = string | number | object;
export type GtagArgs = GtagArg[];
export type GtagCommand = "config" | "set" | "event";
export type GtagFn = (command: GtagCommand, ...commandParameters: GtagArgs) => void;
export type PageViewArgs = {
  page_location: string;
  page_path: string;
  page_title: string;
};
export type EventArgs = {
  event_category?: string;
  event_label: string;
  value?: number;
  email?: string;
  phone_number?: string;
  address?: {
    first_name?: string;
    last_name?: string;
    country?: string;
  };
};
export type GtagWindow = typeof window & {
  gtag?: GtagFn;
};

export class GtagService {
  private _gaMeasurementIds: string[] = [];
  private _initialized = false;

  public get measurementIds(): string[] {
    return this._gaMeasurementIds;
  }

  public get started(): boolean {
    return this._gaMeasurementIds.length > 0 && this._initialized;
  }
  public get win(): GtagWindow {
    return window as GtagWindow;
  }

  public init(gaMeasurementIds: string[]): void {
    if (gaMeasurementIds.length === 0) {
      return;
    }
    this._gaMeasurementIds = gaMeasurementIds;
    this.loadNativeScript();
    this._initialized = true;
  }

  public conversion(sendTo: string, args: { [key: string]: any } = {}): void {
    this.gtag("event", "conversion", { send_to: sendTo, ...args });
  }

  public event(eventName: string, eventParams: EventArgs): void {
    this.gtag("event", eventName, {
      event_category: "talent",
      ...eventParams,
    });
  }

  public pageView(eventParams: PageViewArgs): void {
    this.gtag("event", "page_view", eventParams);
  }

  public gtag(command: GtagCommand, ...commandParameters: GtagArgs): boolean {
    if (!this.started || !this.win.gtag) {
      return false;
    }
    this.win.gtag(command, ...commandParameters);
    return true;
  }

  private loadNativeScript(): void {
    if (process.env.NODE_ENV === "test") {
      return;
    }
    const url = `https://www.googletagmanager.com/gtag/js?id=${this._gaMeasurementIds[0]}`;
    const firstScript = document.getElementsByTagName("script")[0];
    if (firstScript.parentNode !== null) {
      const gtagScript = document.createElement("script");
      gtagScript.async = true;
      gtagScript.src = url;
      firstScript.parentNode.insertBefore(gtagScript, firstScript);

      const initScript = document.createElement("script");
      initScript.text =
        "window.dataLayer = window.dataLayer || []\n"
        + "function gtag(){ dataLayer.push(arguments);}\n"
        + "gtag('js', new Date());\n\n"
        + this._gaMeasurementIds.map((id) => `gtag('config', '${id}', {'send_page_view': false});`).join("\n");
      firstScript.parentNode.insertBefore(initScript, firstScript);
    }
  }
}

export const gtagService = new GtagService();
