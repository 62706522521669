import anime from "animejs";
import cx from "classnames";
import { ConnectedRouter } from "connected-react-router";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { initialize } from "react-ga";
import TagManager from "react-gtm-module";
import LinkedInTag from "react-linkedin-insight";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { matchPath, Redirect, Route, RouteComponentProps, Switch } from "react-router";

import { CompanyPage } from "../../pages/CompanyPage";
import { LoginPage } from "../../pages/LoginPage";
import { OfferPage } from "../../pages/OfferPage";
import { OffersPage } from "../../pages/OffersPage";
import { ProfilePage } from "../../pages/ProfilePage";
import { QuizPage } from "../../pages/QuizPage";
import { routes } from "../../routes";
import ErrorScene from "../../scenes/App/ErrorScene";
import HomeScene from "../../scenes/App/HomeScene";
import { NotificationsListScene } from "../../scenes/App/NotificationsListScene";
import SponsorScene from "../../scenes/App/SponsorScene";
import ContactUsScene from "../../scenes/ContactUsScene";
import RessourcesScene from "../../scenes/RessourcesScene";
import SettingScene from "../../scenes/Talent/SettingScene";
import { gtagService } from "../../services/google/gtag";
import { history } from "../../store";

import global from "../../styles/global.module.scss";
import { $cubic1 } from "../../utils/easing";
import { elinoiSettings } from "../../utils/elinoiSettings";
import { eventDispatcher } from "../../utils/eventDispatcher";
import { isMobile } from "../../utils/helpers";
import { CoCModalClose, CoCModalOpen, CodeOfConductModalWrapper } from "../CodeOfConductModalWrapper";
import LinkedInConversionSDK from "../LinkedInConversionSDK";
import { Menu, MenuCollapseEvent, MenuCollapseEventProps, menuSettings } from "../Menu";
import { OnboardModalClose, OnboardModalOpen, OnboardingModalWrapper } from "../OnboardingModalWrapper";
import { PopinForm } from "../PopinForm";
import {
  RcModalCallBooked,
  RcModalClose,
  RcModalOpen,
  RcModalOpenReco,
  ReconnectionModalWrapper,
} from "../ReconnectionModalWrapper";
import { PrivateRoute } from "../Utils/PrivateRoute";

import "../../styles/main.scss";

// Events Dispatcher setup
eventDispatcher.set(MenuCollapseEvent);
eventDispatcher.set(RcModalCallBooked);
eventDispatcher.set(RcModalOpen);
eventDispatcher.set(RcModalClose);
eventDispatcher.set(RcModalOpenReco);
eventDispatcher.set(CoCModalOpen);
eventDispatcher.set(CoCModalClose);
eventDispatcher.set(OnboardModalOpen);
eventDispatcher.set(OnboardModalClose);

interface MenuProps {
  location: Location;
}

const App: React.FC = () => {
  const menuCollapseEventRef = useRef<MiniSignalBinding>();
  const $menuWrapper = useRef<HTMLDivElement>(null);
  const [paddingMenu, setPaddingMenu] = useState(menuSettings.width);
  const setLayoutPadding = (collapsed: boolean, d?: number): void => {
    const { width, smallWidth, duration } = menuSettings;
    const widthFromTo = collapsed ? [width, smallWidth] : [smallWidth, width];
    const tl = anime.timeline({
      duration: typeof d === "number" ? d : duration,
      easing: $cubic1,
    });

    tl.add({
      paddingLeft: widthFromTo,
      targets: $menuWrapper.current,
    });
  };
  const onMenuCollapseEvent = useRef((params: MenuCollapseEventProps): void => {
    setLayoutPadding(params.isCollapsed);
  });

  useEffect(() => {
    const AnalyticsId = String(process.env.REACT_APP_ANALYTICS_ID);
    const AdwordsId = String(process.env.REACT_APP_ADWORDS_ID);
    const GtmId = String(process.env.REACT_APP_GTM_ID);
    const facebookPixelsId = String(process.env.REACT_APP_FACEBOOK_PIXELS_ID);
    const linkedinPartnerId = String(process.env.REACT_APP_LINKEDIN_PARTNER_ID);
    if (process.env.REACT_APP_GTM_ID !== undefined) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
      };
      TagManager.initialize(tagManagerArgs);
    }

    initialize(AnalyticsId, {
      titleCase: false,
    });
    gtagService.init([AnalyticsId, AdwordsId, GtmId]);

    ReactPixel.init(facebookPixelsId);
    LinkedInTag.init(linkedinPartnerId);

    // settings setup
    elinoiSettings.init();

    menuCollapseEventRef.current = eventDispatcher.on(MenuCollapseEvent, onMenuCollapseEvent.current);

    // A/B TEST - PARRAINAGE
    // const settings = elinoiSettings.get();
    // if (settings) {
    //   if (typeof settings.isMenuCollapsed === "boolean") {
    //     setPaddingMenu(settings.isMenuCollapsed ? menuSettings.smallWidth : menuSettings.width);
    //   }
    // }
    setPaddingMenu(menuSettings.width);

    return (): void => {
      if (menuCollapseEventRef.current) {
        eventDispatcher.off(MenuCollapseEvent, menuCollapseEventRef.current);
      }
    };
  }, []);

  const checkKnownPath = (location: Location): boolean => {
    const paths = Object.values(routes.app);
    for (const p of paths) {
      const isKnownPath = matchPath(location.pathname, {
        path: p,
        exact: true,
        strict: false,
      });
      if (isKnownPath) {
        return true;
      }
    }

    return false;
  };

  const MenuLayoutComponent = (props: MenuProps): JSX.Element => {
    const { location } = props;

    // Les routes des pages avec Menu doivent etre dans route.app
    if (!checkKnownPath(location)) {
      return (
        <PrivateRoute
          component={(routeProps: RouteComponentProps): JSX.Element => (
            <ErrorScene {...routeProps} error={404} fullscreen />
          )}
        />
      );
    }

    return (
      <div className={global.appLayout}>
        <div
          className={cx(global.pageWrapper, global.menuWrapper)}
          ref={$menuWrapper}
          style={{ paddingLeft: `${isMobile() ? 0 : paddingMenu}px` }}
        >
          <Menu />
          <PrivateRoute exact={true} path={routes.app.home} component={HomeScene} />
          <PrivateRoute exact={true} path={routes.app.sponsor} component={SponsorScene} />
          <PrivateRoute exact={true} path={routes.app.profile} component={ProfilePage} />
          <PrivateRoute exact={true} path={routes.app.offer} component={OfferPage} />
          <PrivateRoute exact={true} path={routes.app.offers} component={OffersPage} />
          <PrivateRoute exact={true} path={routes.app.company} component={CompanyPage} />
          <PrivateRoute exact={true} path={routes.app.ressources} component={RessourcesScene} />

          <PrivateRoute exact={true} path={routes.app.settings} component={SettingScene} />
          <PrivateRoute exact={true} path={routes.app.contactUs} component={ContactUsScene} />
          <PrivateRoute exact={true} path={routes.app.notifications} component={NotificationsListScene} />
        </div>
        <PopinForm />
      </div>
    );
  };

  return (
    <>
      <LinkedInConversionSDK />
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={routes.app.home} />} />
          <Route exact path={routes.app.companies} render={() => <Redirect to={routes.app.offers} />} />
          <Route exact={true} path={routes.auth.login} component={LoginPage} />
          <Route path={routes.auth.login_check} component={LinkedInPopUp} />
          <PrivateRoute exact={true} path={routes.quiz.quiz} component={QuizPage} />
          <PrivateRoute component={MenuLayoutComponent} />
          <PrivateRoute
            component={(props: RouteComponentProps): JSX.Element => <ErrorScene {...props} error={404} fullscreen />}
          />
        </Switch>
        <ReconnectionModalWrapper />
        <CodeOfConductModalWrapper />
        <OnboardingModalWrapper />
      </ConnectedRouter>
    </>
  );
};

export default App;
