import { LOCATION_CHANGE } from "connected-react-router";

import { GetApplications } from "../../../actionTypes/applications";
import { GetOffer, GetOffers, GetLastAcceptedApplication } from "../../../actionTypes/offers";

import { filterOffers, hasApplied, isActive, isArchived, isPushed } from "../../../components/Utils/helpers";
import { Application, applicationStatuses, LastAcceptedApplication } from "../../../interfaces/resources/application";
import { Offer } from "../../../interfaces/resources/offer";

export function activeOffers(state: Offer[] = [], action: any): Offer[] {
  switch (action.type) {
    case GetApplications.SUCCESS:
      return action.applications
        .sort(
          (a: Application, b: Application) =>
            Number(b.statusAsTalent === applicationStatuses.toPitch)
            - Number(a.statusAsTalent === applicationStatuses.toPitch),
        )
        .reduce((result: Offer[], elem: Application) => {
          if (isActive(elem)) {
            result.push({
              ...elem.offer,
              application: elem,
            });
          }
          return result;
        }, []);
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}

export function archivedOffers(state: Offer[] = [], action: any): Offer[] {
  switch (action.type) {
    case GetApplications.SUCCESS:
      return action.applications.reduce((result: Offer[], elem: Application) => {
        if (isArchived(elem)) {
          result.push({
            ...elem.offer,
            application: elem,
          });
        }
        return result;
      }, []);
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}

export function availableOffers(state: Offer[] = [], action: any): Offer[] {
  switch (action.type) {
    case GetOffers.SUCCESS:
      return filterOffers(action.offers, state);
    case GetApplications.SUCCESS:
      const selectedOffers = action.applications.reduce((result: Offer[], elem: Application) => {
        if (!isPushed(elem) && !isArchived(elem)) {
          result.push({
            ...elem.offer,
            application: elem,
          });
        }
        return result;
      }, []);
      const offers = action.applications.map((e: Application) => e.offer);
      return [...filterOffers(state, offers), ...selectedOffers];
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}

export function offer(state: Offer | null = null, action: any): Offer | null {
  switch (action.type) {
    case GetOffer.SUCCESS:
      return action.offer;
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
}

export function pushedOffers(state: Offer[] = [], action: any): Offer[] {
  switch (action.type) {
    case GetApplications.SUCCESS:
      return action.applications
        .sort(
          (a: Application, b: Application) =>
            Number(hasApplied(a))
            + Number(b.statusAsTalent === applicationStatuses.toPitch)
            - Number(hasApplied(b))
            - Number(a.statusAsTalent === applicationStatuses.toPitch),
        )
        .reduce((result: Offer[], elem: Application) => {
          if (isPushed(elem) && !isArchived(elem)) {
            result.push({
              ...elem.offer,
              application: elem,
            });
          }
          return result;
        }, []);
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}

export function lastAcceptedApplications(
  state: LastAcceptedApplication[] = [],
  action: any,
): LastAcceptedApplication[] | null {
  switch (action.type) {
    case GetLastAcceptedApplication.SUCCESS:
      return action.lastAcceptedApplications;
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}
