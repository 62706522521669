import { ActionType } from "../interfaces/api/actionType";

export const ApiAuthenticationLogin: ActionType = {
  FAILURE: "AUTHENTICATION_LOGIN_FAILURE",
  REQUEST: "AUTHENTICATION_LOGIN_REQUEST",
  SUCCESS: "AUTHENTICATION_LOGIN_SUCCESS",
};

export const ApiAuthenticationLogout: ActionType = {
  FAILURE: "AUTHENTICATION_LOGOUT_FAILURE",
  REQUEST: "AUTHENTICATION_LOGOUT_REQUEST",
  SUCCESS: "AUTHENTICATION_LOGOUT_SUCCESS",
};

export const ApiAuthenticationRefresh: ActionType = {
  FAILURE: "AUTHENTICATION_REFRESH_FAILURE",
  REQUEST: "AUTHENTICATION_REFRESH_REQUEST",
  SUCCESS: "AUTHENTICATION_REFRESH_SUCCESS",
};
