import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Form, Formik, FormikHelpers, FormikValues } from "formik";
import * as React from "react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { PersonProfileImg } from "../../blocks/personProfileImg";
import { Textarea } from "../../components/Utils/Form/Textarea";
import Yup from "../../components/Utils/Yup";
import { State } from "../../interfaces/api/state";
import { CoreUser } from "../../interfaces/resources/coreUser";
import { Talent } from "../../interfaces/resources/talent";
import { sendMessage as sendMessageAction } from "../../services/api/talent/actions";
import global from "../../styles/global.module.scss";
import { onInitPageLoadTracking } from "../../utils/tracking";

import styles from "./index.module.scss";

interface InitialValues {
  content: string;
}

export const ContactPage: React.FC = () => {
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const [submitted, setSubmitted] = React.useState(false);
  const validationSchema = Yup.object().shape({
    content: Yup.string().required(),
  });
  const dispatch = useDispatch();
  const sendMessage = (talent: Talent, content: string): void => {
    dispatch(sendMessageAction(talent, content));
  };
  const handleSubmit = (values: FormikValues, f: FormikHelpers<InitialValues>): void => {
    sendMessage(currentTalent, values.content);
    setSubmitted(true);
    f.resetForm();
  };

  React.useEffect(() => {
    onInitPageLoadTracking();
  }, []);

  return (
    <div className={cx(styles.pageWrapper)}>
      <div className={styles.headerTimContainer}>
        <div className={styles.headerTitleContainer}>
          <h2 className={global.mainTitle}>
            <Markdown disallowedTypes={["paragraph"]} unwrapDisallowed>
              {t`ContactUs.title`}
            </Markdown>
          </h2>
          <p className={cx(styles.subText, global.subText)}>
            {!!currentTalent.talentManager
              ? t({ id: "ContactPage.subText", values: { firstname: currentTalent.talentManager.firstname } })
              : t`ContactPage.subText.noTim`}
          </p>
        </div>

        {!!currentTalent.talentManager ? <TimContent talentManager={currentTalent.talentManager} /> : null}
      </div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          content: "",
        }}
        validationSchema={validationSchema}
      >
        {(): JSX.Element => (
          <Form>
            <div className={styles.formContent}>
              <div className={styles.messageContainer}>
                <Field
                  name="content"
                  component={Textarea}
                  className={styles.message}
                  rows={10}
                  data-heap="message-textarea"
                />
              </div>
              <div className={styles.btnSubmitContainer}>
                <button
                  type="submit"
                  className={cx(global.cta, global.primaryCta, { submitted })}
                  disabled={submitted}
                  data-heap="btn-submit-message"
                >
                  {submitted ? t`contact.submitted` : t`contact.submit`}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const TimContent = ({ talentManager }: { talentManager: CoreUser }): JSX.Element => (
  <div className={styles.timContainer}>
    <div className={styles.timImgContainer}>
      <PersonProfileImg src={talentManager.image.url} hasOverlay={false} alt={talentManager.firstname} />
    </div>
    <div>
      <h3 className={styles.timName}>
        <Markdown source={talentManager.firstname} disallowedTypes={["paragraph"]} unwrapDisallowed />
      </h3>
    </div>
  </div>
);
