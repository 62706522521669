import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import { Violation } from "../../interfaces/api/violation";
import { Skill } from "../../interfaces/resources/skill";
import { getStringValue } from "../../utils/helpers";
import { getViolationMessage } from "../../utils/hooks";
import ErrorMessage from "../Utils/Form/ErrorMessage";
import styles from "./index.module.scss";
interface CheckboxProps extends FieldProps {
  className?: string;
  getOptionLabel?: (option: any) => string | JSX.Element;
  getOptionValue?: (option: any) => any;
  onChangeSkills?: (option: any) => any;
  isRequired?: boolean;
  label?: string;
  instruction?: string;
  name: string;
  options: any[];
  optionsProps?: any[];
  violations?: Violation[];
}
interface CheckboxFieldProps extends FieldProps {
  error?: string;
  option: any;
  className: string;
  getOptionLabel?: (option: any, value: any) => string | JSX.Element;
  getOptionValue?: (option: any) => any;
  onChangeSkills?: (option: any) => any;
}

export const formatValue = (value: any, getOptionValue?: (option: any) => any): any => {
  return getOptionValue ? getOptionValue(value) : value;
};

export const formatLabel = (label?: any, value?: any, getOptionLabel?: (l: any, v: any) => any): any => {
  return label ? (getOptionLabel ? getOptionLabel(label, value) : label) : null;
};

const isItemChecked = (opt: any, option: any) => {
  return opt?.skill?.value ? opt.skill.value === option.value : opt === option["@id"];
};

export const CheckboxField = ({
  error,
  field,
  form,
  getOptionLabel,
  getOptionValue,
  onChangeSkills,
  option,
}: CheckboxFieldProps): JSX.Element => {
  const [, meta] = useField(field.name);
  const formatedValue = formatValue(option, getOptionValue);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = field.value;
    if (Array.isArray(newValue)) {
      if (e.target.checked) {
        newValue = [...newValue, e.target.value];
      } else {
        newValue.splice(newValue.indexOf(e.target.value), 1);
      }
    } else {
      newValue = e.target.checked;
    }
    form.setFieldValue(field.name, newValue);
    onChangeSkills && onChangeSkills(newValue);
  };
  return (
    <div className={cx(styles.inputLabelContainer, { error: (meta && meta.touched && !!meta.error) || !!error })}>
      <input
        id={`${formatedValue}`}
        type="checkbox"
        value={formatedValue}
        className={styles.checkbox}
        checked={
          Array.isArray(field.value)
            ? field.value.some((opt: any) => {
                return isItemChecked(opt, option);
              })
            : !!field.value
        }
        onChange={onChange}
        onBlur={(): any => form.setFieldTouched(field.name, true)}
      ></input>
      <label htmlFor={formatedValue}>{formatLabel(option, field.value, getOptionLabel)}</label>
    </div>
  );
};

export const SkillsComp: React.FC<CheckboxProps> = ({
  className,
  field,
  form,
  getOptionLabel,
  getOptionValue,
  onChangeSkills,
  isRequired = true,
  label,
  instruction,
  options,
  optionsProps,
  violations,
}: CheckboxProps): JSX.Element => {
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);
  const newOpt = options.reduce((accumulator: any[], skill: Skill) => {
    const currentResp = skill.responsibility ? skill.responsibility.value : "";
    if (accumulator.some((resp: any) => resp.label === currentResp)) {
      accumulator.forEach((e: any) => {
        if (e.label === currentResp) {
          e.options.push(skill);
        }
      });
    } else {
      accumulator.push({
        label: currentResp,
        options: [skill],
      });
    }
    return accumulator;
  }, []);
  return (
    <div className={cx(className, { error: (meta.touched && !!meta.error) || !!error })}>
      {label ? (
        <label className="formLabel">
          {label} {isRequired ? "*" : null}
        </label>
      ) : null}
      {instruction ? <span className="fieldInstruction">{instruction}</span> : null}
      {newOpt.length ? (
        <div className={styles.skillsContainer}>
          {newOpt.map((option: any) => {
            return (
              <div key={option.label}>
                <label className="formLabel" key={`${option.label}`}>
                  {option.label}
                </label>
                <div className="checkboxRadioGroupContainer">
                  {option.options.map((opt: any, idx: any) => {
                    return (
                      <CheckboxField
                        error={error}
                        field={field}
                        form={form}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        onChangeSkills={onChangeSkills}
                        key={getStringValue(opt, "@id") ?? formatLabel(opt, field.value, getOptionLabel) ?? opt}
                        option={opt}
                        {...(Array.isArray(optionsProps) && optionsProps.length > idx ? optionsProps[idx] : [])}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.empyList}>No skills</div>
      )}
      <ErrorMessage name={field.name} message={error} />
    </div>
  );
};
