import { GetCurrentQuestion, GetNextQuestion, GetQuizChoices, PutCurrentQuestion } from "../../../actionTypes/quiz";
import { GetCurrentTalent } from "../../../actionTypes/talent";
import { Choice, QuizStepSlug } from "../../../interfaces/resources/quiz";

export function nextQuestion(state: QuizStepSlug | null = null, action: any) {
  switch (action.type) {
    case GetNextQuestion.SUCCESS:
      return action.question;
    case GetCurrentTalent.SUCCESS:
      return action.currentTalent?.talentProfile?.nextQuizStep || state;
    default:
      return state;
  }
}

export function currentQuestion(state: QuizStepSlug | null = null, action: any) {
  switch (action.type) {
    case GetCurrentQuestion.SUCCESS:
    case PutCurrentQuestion.SUCCESS:
      return action.question;
    case GetCurrentTalent.SUCCESS:
      return action.currentTalent?.talentProfile?.currentQuizStep || state;
    default:
      return state;
  }
}

export function quizChoices(state: Choice[] | null = null, action: any) {
  switch (action.type) {
    case GetQuizChoices.SUCCESS:
      return action.choices;
    default:
      return state;
  }
}
