import { t } from "@lingui/macro";
import anime from "animejs";
import cx from "classnames";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { MdReplay } from "react-icons/md";
import ReactMarkdown from "react-markdown";

import { Company, CompanyImage } from "../../../../interfaces/resources/company";

import { chunkArray } from "../../../../utils/chunkArray";
import { $cubic1 } from "../../../../utils/easing";
import { setRef } from "../../../../utils/helpers";

import tabs from "../index.module.scss";

interface Props {
  company: Company;
}

export const PanelCompany: React.FC<Props> = (props: Props) => {
  const { company } = props;
  const $overlays = useRef([]);
  const perPage = 3;
  let currPage = useRef<number>(0).current;
  const [page, setPage] = useState<number>(0);
  const imgPages: CompanyImage[][] = !!company.companyImages ? chunkArray(company.companyImages, perPage, true) : [];
  const maxPage = useRef<number>(imgPages.length - 1).current;
  const [hasSeveralImagePages] = useState(company.companyImages?.length > perPage);
  const isAnimating = useRef<boolean>(false);

  const swtichPage = (pageIndex: number): void => {
    const tl = anime.timeline({
      duration: 600,
      easing: $cubic1,
      complete: () => {
        $overlays.current = [];
        setPage(pageIndex);
      },
    });

    tl.add({
      translateX: ["-101%", 0],
      targets: $overlays.current,
      delay: anime.stagger(80),
    });
  };

  const onClickSwitchCta = (): void => {
    if (isAnimating.current) return;
    isAnimating.current = true;
    currPage = page + 1 > maxPage ? 0 : page + 1;
    swtichPage(currPage);
  };

  const showTeamItem = (): void => {
    const tl = anime.timeline({
      duration: 600,
      easing: $cubic1,
      complete: () => {
        isAnimating.current = false;
      },
    });

    tl.add({
      translateX: [0, "101%"],
      targets: $overlays.current,
      delay: anime.stagger(40),
    });
  };

  useEffect(() => {
    showTeamItem();
  }, [page]);

  return (
    <>
      <div className={tabs.tabPanelContainer}>
        <div className={cx(tabs.inlineBlocksContainer, tabs.noBgBlocksContainer, tabs.blocksContainer)}>
          <div className={cx(tabs.inlineBlocksInnerContainer, tabs.historyDetailsInnerBlocksContainer)}>
            <div className={tabs.blocksContainer}>
              <div className={tabs.blocksRow}>
                <div className={tabs.block}>
                  <h3 className={tabs.blockTitle}>{t`PanelCompany.history.title`}</h3>
                  <div className={tabs.blockText}>
                    <ReactMarkdown source={company.foundersBackground} />
                  </div>
                </div>
              </div>
              <div className={tabs.blocksRow}>
                <div className={tabs.block}>
                  <h3 className={tabs.blockTitle}>{t`PanelCompany.description.title`}</h3>
                  <div className={tabs.blockText}>
                    <ReactMarkdown source={company.description} />
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(tabs.blockDetailsContainer, tabs.blocksContainer, tabs.noBgBlocksContainer)}>
              <div className={cx(tabs.block, tabs.noBefore)}>
                <h3 className={tabs.blockTitle}>{t`PanelCompany.details.title`}</h3>
                <div className={tabs.detailsContainer}>
                  <div>
                    <div className={tabs.detailContent}>
                      <h4 className={tabs.detailTitle}>{t`PanelCompany.details.creationDate.title`}</h4>
                      <span className={tabs.detailNb}>{format(new Date(company.creationDate), "yyyy")}</span>
                    </div>
                  </div>
                  <div>
                    <div className={tabs.detailContent}>
                      <h4 className={tabs.detailTitle}>{t`PanelCompany.details.employeesNumber.title`}</h4>
                      <span className={tabs.detailNb} data-testid="comp-nb">
                        {company.employeesNumber || "—"}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={tabs.detailContent}>
                      <h4 className={tabs.detailTitle}>{t`PanelCompany.details.averageAge.title`}</h4>
                      <span className={tabs.detailNb}>
                        {t({
                          id: "PanelCompany.details.averageAge.value",
                          values: {
                            value: company.employeesAverageAge || "—",
                          },
                        })}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={tabs.detailContent}>
                      <h4 className={tabs.detailTitle}>{t`PanelCompany.details.fundsRaisedAmount.title`}</h4>
                      <span className={tabs.detailNb}>
                        {t({
                          id: "PanelCompany.details.fundsRaisedAmount.value",
                          values: {
                            value: company.fundsRaisedAmount || "—",
                          },
                        })}
                      </span>
                    </div>
                  </div>
                  {company.sectors.length >= 1 ? (
                    <div>
                      <div className={tabs.detailContent}>
                        <h4 className={tabs.detailTitle}>{t`PanelCompany.details.sectors.title`}</h4>
                        <span className={tabs.detailNb}>
                          {company.sectors
                            .map((sector) => {
                              return sector.value;
                            })
                            .join(" - ")}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx(tabs.blocksContainer, tabs.darkBgBlocksContainer)}>
          <div className={tabs.blocksRow}>
            <div className={cx(tabs.block, tabs.noBefore)}>
              <h3 className={tabs.blockTitle}>{t`PanelCompany.specificities.title`}</h3>
              <div className={tabs.blockText}>
                <ReactMarkdown source={company.specificities} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {imgPages.length && imgPages[page].length ? (
        <div className={tabs.companyPhotosContainer} data-testid="companyImages-block">
          <div className={tabs.companyPhotosTitleContainer}>
            <h3 className={tabs.blockTitle}>{t`PanelCompany.companyImages.title`}</h3>
            {hasSeveralImagePages ? (
              <span className={tabs.switchCta} onClick={onClickSwitchCta} data-testid="switch-img">
                <MdReplay />
              </span>
            ) : null}
          </div>
          <div className={tabs.companyPhotosListWrapper}>
            <div className={tabs.companyPhotosListBackground}></div>
            <ul className={tabs.companyPhotosList}>
              {imgPages[page].map((cImg, i) => (
                <li key={`companyImages-${cImg["@id"]}-${i}`}>
                  <div className={tabs.companyImgContainer}>
                    <span className={tabs.overlay} ref={(el): void => setRef(el, $overlays)}></span>
                    {cImg["@id"] !== "placeholder" ? (
                      <img src={cImg.image.url} alt={`${company.name} - nº${i}`} data-testid="comp-img" />
                    ) : null}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};
