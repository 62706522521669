import { t } from "@lingui/macro";
import cx from "classnames";
import React from "react";
import { event as trackEvent } from "react-ga";
import { useDispatch, useSelector } from "react-redux";

import { State } from "../../interfaces/api/state";
import { Application, applicationStatuses } from "../../interfaces/resources/application";
import { Offer } from "../../interfaces/resources/offer";
import { Talent } from "../../interfaces/resources/talent";
import { submitApplication } from "../../services/api/applications/actions";

import global from "../../styles/global.module.scss";
import { hasApplied, hasBeenRefused } from "../../utils/helpers";

import styles from "./index.module.scss";

interface Props {
  application?: Application | undefined;
  isLoading?: boolean;
  offer?: Offer;
  applyTrackingEvent?: () => void;
}

export const CompanyApplicationCta: React.FC<Props> = (props: Props) => {
  const { application, offer, isLoading = true, applyTrackingEvent } = props;
  const currentTalent: Talent = useSelector((state: State) => state.currentTalent);
  const dispatch = useDispatch();
  const applied = hasApplied(application);
  const refused = hasBeenRefused(application);
  const apply = (): void => {
    dispatch(
      submitApplication({
        "@id": application && application["@id"],
        offer,
        status: applicationStatuses.applied,
        talent: currentTalent,
      }),
    );
  };
  const handleApply = (): void => {
    if (typeof applyTrackingEvent === "function") {
      applyTrackingEvent();
    } else {
      trackEvent({
        action: "click",
        category: "talent",
        label: "company - candidature spontanée",
      });
    }
    apply();
  };

  if (refused) {
    return <div className={cx(styles.refusedState, styles.cta)}>{t`CompanyApplicationCta.refused.text`}</div>;
  }

  if (applied) {
    return (
      <>
        <div className={cx(styles.appliedState, styles.cta)}>{t`CompanyApplicationCta.applied.text`}</div>
      </>
    );
  }

  if (!!!offer) {
    return null;
  }

  if (!isLoading) {
    return (
      <>
        <button
          className={cx(global.cta, global.primaryCta)}
          disabled={!!isLoading}
          onClick={handleApply}
          data-heap="spontaneous-cta"
        >
          {t`CompanyApplicationCta.apply.text`}
        </button>
      </>
    );
  }
  return null;
};
