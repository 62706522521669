import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetExperienceYears } from "../../../actionTypes/experienceYears";
import { apiFailure, extractNextPageNumberFromIri } from "../helpers";
import * as apiClient from "./api";

export function* getExperienceYears(action: AnyAction) {
  try {
    const response = yield call(apiClient.getExperienceYears, action.page);
    const experienceYears = response.data["hydra:member"];
    yield put({
      experienceYears,
      page: action.page,
      type: GetExperienceYears.SUCCESS,
    });
    if (response.data.hasOwnProperty("hydra:view") && response.data["hydra:view"].hasOwnProperty("hydra:next")) {
      yield put({
        page: extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"]),
        type: GetExperienceYears.REQUEST,
      });
    }
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* getExperienceYearsSaga() {
  yield takeLatest(GetExperienceYears.REQUEST, getExperienceYears);
}
