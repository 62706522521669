import { t } from "@lingui/macro";
import cx from "classnames";
import React from "react";
import ReactMarkdown from "react-markdown";

import { CoCModalOpen } from "../../../../components/CodeOfConductModalWrapper";
import { InterviewStep } from "../../../../interfaces/resources/interviewStep";
import { Offer } from "../../../../interfaces/resources/offer";

import { gtagService } from "../../../../services/google/gtag";
import global from "../../../../styles/global.module.scss";
import transInterviewStep from "../../../../translations/constants/interviewStep";
import { eventDispatcher } from "../../../../utils/eventDispatcher";
import tabs from "../index.module.scss";

interface Props {
  offer: Offer;
}

interface ProcessStepProps {
  interviewStep: InterviewStep;
}

const ProcessStep: React.FC<ProcessStepProps> = (props: ProcessStepProps): JSX.Element => {
  const { interviewStep } = props;

  return (
    <>
      <div className={tabs.interviewStepContainer}>
        <div className={tabs.stepNbContainer}>
          <span className={tabs.stepWording}>{t`PanelProcess.process.step.wording`}</span>
          <span className={tabs.stepNb}>{interviewStep.step}</span>
        </div>
        <div className={tabs.stepDetailContainer}>
          <div className={tabs.stepDetailContent}>
            <div className={tabs.stepTitleTagContainer}>
              <h3 className={tabs.stepTitle}>{interviewStep.title}</h3>
              <div className={tabs.stepTagContainer}>
                {interviewStep.interviewStepTypes.map((tag) => (
                  <span key={tag["@id"]} className={tabs.stepTag}>
                    {transInterviewStep.labels[tag.label]}
                  </span>
                ))}
              </div>
            </div>
            <div className={tabs.stepDescription}>
              <ReactMarkdown source={interviewStep.description} className="interview-step-description" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PanelProcess: React.FC<Props> = (props: Props) => {
  const { offer } = props;
  const onClickCocModalBtn = (): void => {
    gtagService.event("click", {
      event_label: "offre - charte",
    });
    eventDispatcher.trigger(CoCModalOpen);
  };

  return (
    <div className={tabs.tabPanelContainer}>
      <div className={cx(tabs.blocksContainer, tabs.inlineBlocksContainer, tabs.noBgBlocksContainer)}>
        <div className={cx(tabs.inlineBlocksInnerContainer, tabs.panelProcessVideoInnerContainer)}>
          {offer.offerTalentView.ytProcess ? (
            <div className={cx(tabs.videoBlocksProcessRow, tabs.videoBlocksRow, tabs.blocksRow)}>
              <div className={cx(tabs.videoBlock, tabs.block)}>
                <h3 className={tabs.blockTitle}>{t`PanelProcess.video.title`}</h3>
                <div className={tabs.videoContainer}>
                  <div className={tabs.video}>
                    <iframe
                      title={`offer-${offer.title}-video`}
                      src={offer.offerTalentView.ytProcess}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className={cx(tabs.blocksRow, tabs.cocBlocksRow)}>
            <div className={cx(tabs.block, tabs.noBefore)}>
              <h3 className={tabs.blockTitle}>{t`PanelProcess.codeOfConduct.title`}</h3>
              <div className={tabs.cocTextCtaContainer}>
                <div className={tabs.blockText}>{t`PanelProcess.codeOfConduct.text`}</div>
                <div className={tabs.cocCtaContainer}>
                  <button
                    type="button"
                    className={cx(tabs.cocCta, global.cta, global.primaryCta)}
                    onClick={onClickCocModalBtn}
                  >
                    {t`PanelProcess.codeOfConduct.cta`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cx(tabs.blocksContainer, tabs.noBgBlocksContainer, tabs.processBlocksContainer)}>
        <h2 className={tabs.blockTitle}>{t`PanelProcess.process.title`}</h2>
        {offer.interviewSteps
          && offer.interviewSteps.map((itwStep, itwStepIndex, arr) => {
            return (
              <div
                key={`itvew-itwStep-${itwStep["@id"]}`}
                className={cx(
                  tabs.blocksRow,
                  tabs.processStepsBlocksRow,
                  { [tabs.first]: itwStepIndex === 0 },
                  { [tabs.last]: itwStepIndex === arr.length - 1 },
                )}
              >
                <div className={cx(tabs.block, tabs.noBefore)}>
                  <ProcessStep interviewStep={itwStep} />
                </div>
              </div>
            );
          })}
      </div>
      {offer.offerTalentView && offer.offerTalentView.elinoiAdvice ? (
        <div className={cx(tabs.blocksContainer, tabs.darkBgBlocksContainer)} data-testid="elinoiadvice-block">
          <div className={tabs.blocksRow}>
            <div className={cx(tabs.block, tabs.noBefore)}>
              <h3 className={tabs.blockTitle}>{t`PanelProcess.elinoiAdvice.title`}</h3>
              <div className={tabs.blockText}>
                <ReactMarkdown source={offer.offerTalentView.elinoiAdvice} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
