import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import { GetApplications, GetAvailableApplications } from "../../../../actionTypes/applications";
import { GetOffers } from "../../../../actionTypes/offers";
import { OfferCard } from "../../../../blocks/offerCard";
import { EditCandidateProfileModalCta } from "../../../../components/EditCandidateProfileModal";
import { Loader } from "../../../../components/Loader";
import { OffersCardList } from "../../../../components/OffersCardList";
import { RessourcesList } from "../../../../components/RessourcesList";
import { ApiRequestProps } from "../../../../interfaces/api/request";
import { State } from "../../../../interfaces/api/state";
import { Offer, offerModes } from "../../../../interfaces/resources/offer";
import { hashes } from "../../../../routes";
import { getAvailableApplications as getAvailableApplicationsAction } from "../../../../services/api/applications/actions";
import { getOffers as getOffersAction } from "../../../../services/api/offer/actions";

import global from "../../../../styles/global.module.scss";
import { useApiSelector, useGetResourceHook } from "../../../../utils/hooks";

import { onInitPageLoadTracking } from "../../../../utils/tracking";
import tabs from "../index.module.scss";

export const PanelMyOffers: React.FC = () => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const availableOffers = useSelector((state: State) => state.availableOffers).filter(
    (o) => o.mode !== offerModes.spontaneous,
  );
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const pushedOffers = useSelector((state: State) => state.pushedOffers).filter(
    (o) => o.mode !== offerModes.spontaneous,
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const getAvailableApplications = (): void => {
    dispatch(getAvailableApplicationsAction(currentTalent));
  };
  const getOffers = (): void => {
    dispatch(getOffersAction(currentTalent));
  };

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetOffers,
    [...availableOffers, ...pushedOffers],
    getOffers,
    [currentTalent],
  );
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetAvailableApplications,
    [...availableOffers, ...pushedOffers],
    getAvailableApplications,
    [currentTalent],
  );

  React.useEffect(() => {
    setLoading(
      apiPendingRequests.some((e: ApiRequestProps) =>
        [GetOffers.REQUEST, GetAvailableApplications.REQUEST, GetApplications.REQUEST].includes(e.type),
      ),
    );
  }, [apiPendingRequests]);

  React.useEffect(() => {
    onInitPageLoadTracking();
  }, []);

  return (
    <div className={tabs.tabPanelContainer} data-testid={`panel-${hashes.offers}`}>
      <div className={tabs.tabTitleContainer}>
        <h2 className={global.mainTitle}>
          <Markdown source={t`PanelMyOffers.title`} disallowedTypes={["paragraph"]} unwrapDisallowed />
        </h2>
      </div>
      {loading ? (
        <div key="loaderContainer" className={tabs.loaderContainer} data-testid="panel-myoffers-loader-container">
          <Loader />
        </div>
      ) : (
        <div key="partsContainer" className={tabs.partsContainer}>
          {!!!pushedOffers.length && !!!availableOffers.length && !loading ? (
            <div className={tabs.part} key="ressourcespart" data-testid="panel-myoffers-ressources-container">
              <div className={tabs.partHeaderContainer}>
                <h3 className={tabs.partTitle}>{t`PanelMyOffers.noOffers.title`}</h3>
                <div className={tabs.partSubText}>
                  {t`PanelMyOffers.noOffers.subText`}
                  <div className={tabs.subTextCtaContainer}>
                    <EditCandidateProfileModalCta />
                  </div>
                </div>
              </div>
              <div className={tabs.partHeaderContainer}>
                <h3 className={tabs.partTitle}>{t`PanelMyOffersMyProcess.otherRessources.title`}</h3>
              </div>
              <div>
                <RessourcesList eventPage="mes offres" />
              </div>
            </div>
          ) : (
            <>
              {!!pushedOffers.length ? (
                <div className={tabs.part} key="pushedofferspart" data-testid="panel-myoffers-pushedoffers">
                  <div className={tabs.partHeaderContainer}>
                    <h3
                      className={cx(tabs.partTitle, {
                        [tabs.highlightTitle]: true,
                      })}
                    >
                      {t({
                        id: "PanelMyOffers.pushedOffers.title",
                        values: {
                          name:
                            (currentTalent.talentManager && `${currentTalent.talentManager.firstname}`)
                            || "Ton Talent Manager",
                        },
                      })}
                    </h3>
                    <div className={cx(tabs.partSubText, tabs.pushedOffersSubText)}>
                      <Markdown>
                        {t({
                          id: "PanelMyOffers.pushedOffers.subText",
                          values: {
                            name:
                              (currentTalent.talentManager && `${currentTalent.talentManager.firstname}`)
                              || "ton talent manager",
                          },
                        })}
                      </Markdown>
                    </div>
                  </div>
                  <div>
                    <OffersCardList
                      offers={pushedOffers}
                      render={(offer: Offer): JSX.Element => (
                        <OfferCard offer={offer} trackName="mes offres - pushed" />
                      )}
                    />
                  </div>
                </div>
              ) : null}
              {!!availableOffers.length ? (
                <div className={tabs.part} key="availableofferspart" data-testid="panel-myoffers-availableoffers">
                  <div className={tabs.partHeaderContainer}>
                    <h3 className={tabs.partTitle}>{t`PanelMyOffers.availableOffers.title`}</h3>
                    <div className={tabs.partSubText}>
                      <Markdown>
                        {t({
                          id: "PanelMyOffers.availableOffers.subText",
                          values: {
                            name:
                              (currentTalent.talentManager && `${currentTalent.talentManager.firstname}`)
                              || "ton Talent Manager",
                          },
                        })}
                      </Markdown>
                    </div>
                  </div>
                  <div>
                    <OffersCardList
                      offers={availableOffers}
                      render={(offer: Offer): JSX.Element => (
                        <OfferCard offer={offer} trackName="mes offres - available" />
                      )}
                    />
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      )}
    </div>
  );
};
