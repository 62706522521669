import { GetCalendlyEvents } from "../../../actionTypes/calendlyEvents";
import { CalendlyEvent } from "../../../interfaces/resources/calendlyEvent";

export function calendlyEvents(state: CalendlyEvent[] = [], action: any): CalendlyEvent[] {
  switch (action.type) {
    case GetCalendlyEvents.SUCCESS:
      const iri: string[] = [];
      return [...state, ...action.calendlyEvents].filter((e: CalendlyEvent) => {
        if (iri.includes(e["@id"])) {
          return false;
        }
        iri.push(e["@id"]);
        return true;
      });
    default:
      return state;
  }
}
