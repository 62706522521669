import cx from "classnames";
import * as React from "react";
import styles from "./index.module.scss";

interface LoaderProps {
  className?: string;
  testid?: string;
}

export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { className = "", testid = "loader" } = props;
  return (
    <div className={cx(styles.loaderContainer, { [className]: !!className })}>
      <div className={styles.loader} data-testid={testid}>
        <div className={styles.squareContainer}>
          <div className={styles.square}></div>
        </div>
        <div className={cx(styles.squareContainer, styles.reverse)}>
          <div className={cx(styles.square, styles.reverse)}></div>
        </div>
      </div>
    </div>
  );
};
