import { t } from "@lingui/macro";
import cx from "classnames";
import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

import { ReactComponent as CheckSolid } from "../../../../images/check-solid.svg";
import checkLogo from "../../../../images/check.svg";

import styles from "./index.module.scss";

interface TransProps {
  header: string;
  body: string;
}

export const Item: FC<TransProps> = ({ header, body }) => (
  <div className={styles.item}>
    <div className={styles.imgContainer}>
      <img src={checkLogo} alt="logo" />
    </div>
    <div className={styles.itemTextContainer}>
      <h2>{header}</h2>
      <ReactMarkdown source={body} />
    </div>
  </div>
);

export const Page1: FC = () => (
  <div className={styles.itemsContainer}>
    <Item header={t`item.header.givenews`} body={t`item.body.givenews`} />
    <Item header={t`item.header.honor`} body={t`item.body.honor`} />
    <Item header={t`item.header.prepare`} body={t`item.body.prepare`} />
  </div>
);

export const Page2: FC = () => (
  <div className={styles.itemsContainer}>
    <Item header={t`item.header.letaccountmanager`} body={t`item.body.letaccountmanager`} />
    <Item header={t`item.header.keeptmandam`} body={t`item.body.keeptmandam`} />
    <Item header={t`item.header.beactive`} body={t`item.body.beactive`} />
  </div>
);

interface Page3Props {
  accepted: boolean;
  setAccepted: (f: (p: boolean) => void) => void;
}
export const Page3: FC<Page3Props> = ({ accepted, setAccepted }) => {
  const handleClickAccepted = () => {
    setAccepted((prev: boolean) => !prev);
  };

  return (
    <div className={cx(styles.itemsContainer, styles.page3ItemsContainer)}>
      <Item header={t`item.header.introduce`} body={t`item.body.introduce`} />
      <div className={cx(styles.item, styles.itemNoIcon)}>
        <p className={styles.paragraph}>{t`item.paragraph.body`}</p>
      </div>
      <div className={cx(styles.item, styles.itemAccepted)}>
        <div
          onClick={() => handleClickAccepted()}
          className={cx(styles.checkContainer, { [styles.checked]: accepted })}
        >
          <div className={styles.checkSolidLogo}>
            <CheckSolid className={styles.svg} />
          </div>
        </div>
        <p className={styles.checkLabel} onClick={() => handleClickAccepted()}>
          {t`item.paragraph.bodycheckout`}
        </p>
      </div>
    </div>
  );
};
