import { CreateReferral, GetReferrals, GetReferred, ValidateReferral } from "../../../actionTypes/referral";
import { Referral } from "../../../interfaces/resources/referral";
import { Talent } from "../../../interfaces/resources/talent";

export function getReferralsAction(currentTalent: Talent): {
  currentTalent: Talent;
  type: string;
} {
  return {
    currentTalent,
    type: GetReferrals.REQUEST,
  };
}

export function getReferredAction(currentTalent: Talent): {
  currentTalent: Talent;
  type: string;
} {
  return {
    currentTalent,
    type: GetReferred.REQUEST,
  };
}

export function createReferralAction(referral: Partial<Referral>): {
  referral: Partial<Referral>;
  type: string;
} {
  return {
    referral,
    type: CreateReferral.REQUEST,
  };
}

export function validateReferralAction(
  referralCode: string,
  currentTalent: Talent,
): {
  referralCode: string;
  currentTalent: Talent;
  type: string;
} {
  return {
    referralCode,
    currentTalent,
    type: ValidateReferral.REQUEST,
  };
}
