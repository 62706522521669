import { GetSectors } from "../../../actionTypes/sectors";
import { Sector } from "../../../interfaces/resources/sector";

export function sectors(state: Sector[] = [], action: any): Sector[] {
  switch (action.type) {
    case GetSectors.SUCCESS:
      return !!action.page ? [...state, ...action.sectors] : action.sectors;
    default:
      return state;
  }
}
