export const routes = {
  app: {
    contactUs: "/mon-compte/nous-contacter",
    home: "/mon-compte",
    notifications: "/mon-compte/mes-notifications",
    offer: "/mon-compte/mes-opportunites/:slug([a-z-0-9]*)",
    offers: "/mon-compte/mes-opportunites",
    companies: "/mon-compte/mes-entreprises",
    company: "/mon-compte/mes-entreprises/:slug([a-z-0-9]*)",
    profile: "/mon-compte/mon-profil",
    settings: "/mon-compte/mes-paramètres",
    sponsor: "/mon-compte/inviter-des-amis",
    ressources: "/mon-compte/ressources",
  },
  auth: {
    login: "/login",
    login_check: "/login-check",
  },
  quiz: {
    quiz: "/quiz",
  },
};

// L'ORDRE DOIT CORRESPONDRE A L'ORDRE DES QUESTIONS DANS L'API
export const quizHashes = {
  general: "#general",
  career: "#carriere",
  skills: "#skills",
  linkedin: "#linkedin",
  location: "#lieux-de-travail",
  workEnvironment: "#environnement-de-travail",
  source: "#source",
  completed: "#inscription-terminee",
};

export const hashes = {
  process: "#mes-process",
  offers: "#mes-offres",
  companies: "#entreprises",
  favCompanies: "#entreprises-favorites",
  connexion: "#login",
  signup: "#sign-up",
};
