import { t } from "@lingui/macro";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";

import { GetNotifications } from "../../actionTypes/notification";

import { State } from "../../interfaces/api/state";
import { Notification } from "../../interfaces/resources/notification";

import { Talent } from "../../interfaces/resources/talent";

import { getNotifications as getNotificationsAction } from "../../services/api/notification/actions";
import { useGetResourceHook, useApiSelector } from "../../utils/hooks";

interface Props {
  title: string;
}

export const NewNotificationsHelmet: React.FC<Props> = (props: Props): JSX.Element => {
  const { title } = props;
  const [metaTitle, setMetaTitle] = React.useState<boolean>(true);
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const notifications = useSelector((state: State) => state.notifications);

  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();

  const dispatch = useDispatch();
  const getNotifications = (talent: Talent): void => {
    dispatch(getNotificationsAction(talent));
  };

  const timeOutRef = React.useRef<any>(null);

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetNotifications, notifications, getNotifications, [
    currentTalent,
  ]);

  const notificationsSeenNumber =
    notifications && notifications.filter((notification: Notification) => notification.seen === false).length;

  React.useEffect(() => {
    if (notificationsSeenNumber > 0)
      timeOutRef.current = setTimeout(() => {
        setMetaTitle(!metaTitle);
      }, 1000);
    else {
      setMetaTitle(true);
      clearTimeout(timeOutRef.current);
    }
    return (): void => {
      clearTimeout(timeOutRef.current);
    };
  }, [metaTitle, notificationsSeenNumber]);

  return (
    <Helmet>
      {metaTitle ? (
        <title>{title}</title>
      ) : (
        <title>
          ({`${notificationsSeenNumber}`}){t`Notification.New.Message.Helmet`}
        </title>
      )}
      <meta name="" content="" />
    </Helmet>
  );
};
