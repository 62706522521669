import cx from "classnames";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { MdDelete } from "react-icons/md";
import DatePicker from "../../../../../components/Utils/Form/DatePicker";
import { Input } from "../../../../../components/Utils/Form/Input";
import { Textarea } from "../../../../../components/Utils/Form/Textarea";
import { LinkedinExperience } from "../../../../../interfaces/resources/linkedinResume";
import globalStyle from "../../../../../styles/global.module.scss";
import quizStyle from "../../../index.module.scss";
import styles from "./index.module.scss";

interface Props {
  submitItemModification: (values: any, element: string) => void;
  item?: LinkedinExperience | null;
  deleteItem?: (values: any, element: string) => void;
  addItem?: (values: any, element: string) => void;
}

export const ModifyExperience: React.FC<any> = ({ submitItemModification, item, deleteItem, addItem }: Props) => {
  return (
    <Formik
      initialValues={{
        "@id": item?.["@id"] ? item["@id"] : "",
        companyName: item?.companyName ? item.companyName : "",
        jobTitle: item?.jobTitle ? item.jobTitle : "",
        endDate: item?.endDate ? item.endDate : new Date(),
        startDate: item?.startDate ? item.startDate : new Date(),
        city: item?.city ? item.city : "",
        description: item?.description ? item.description : "",
        switch: false,
      }}
      onSubmit={(values) => submitItemModification(values, "experiences")}
    >
      {({ values, setFieldValue }): JSX.Element => (
        <Form className={cx(styles.form, "form")}>
          <div className="formGroup">
            <div className={styles.details}>
              <Field
                name="companyName"
                component={Input}
                label="Entreprise"
                placeholder="Entreprise"
                className={styles.input}
              />
              <Field name="jobTitle" label="Poste" component={Input} className={styles.input} />
            </div>
            <div className={styles.currentSwitchContainer}>
              <div
                onClick={() =>
                  values.endDate
                    ? setFieldValue("endDate", null)
                    : setFieldValue("endDate", item?.endDate ? item.endDate : new Date())
                }
              >
                <input type="checkbox" name="currentJobCheck" checked={values.endDate ? false : true} readOnly />
                {"  "}J&apos;occupe actuellement ce poste
              </div>
            </div>
            <div className={styles.details}>
              <Field name="startDate" label="de" component={DatePicker} className={styles.input} />
              <Field
                name="endDate"
                label="à"
                component={DatePicker}
                className={styles.input}
                readOnly={values.endDate === null ? true : false}
              />
              <Field name="city" label="où" component={Input} className={cx(styles.input, styles.cityInput)} />
            </div>
            <div>
              <Field
                name="description"
                label="Description"
                component={Textarea}
                className={cx(styles.textArea, styles.large)}
              />
            </div>
            <div className={styles.ctasContainer}>
              {item && deleteItem ? (
                <>
                  <div>
                    <button
                      type="button"
                      onClick={() => deleteItem(item, "experiences")}
                      className={cx(
                        quizStyle.deleteCta,
                        globalStyle.smallerCta,
                        globalStyle.cta,
                        globalStyle.alertCta,
                        globalStyle.inverted,
                      )}
                    >
                      <MdDelete />
                      <span> Supprimer</span>
                    </button>
                  </div>
                  <div className={styles.submitCtaContainer}>
                    <button
                      type="submit"
                      className={cx(
                        globalStyle.smallerCta,
                        globalStyle.cta,
                        globalStyle.primaryCta,
                        globalStyle.inverted,
                      )}
                    >
                      Modifier
                    </button>
                  </div>
                </>
              ) : addItem ? (
                <div className={styles.submitCtaContainer}>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      addItem(values, "experiences");
                    }}
                    className={cx(
                      globalStyle.smallerCta,
                      globalStyle.cta,
                      globalStyle.primaryCta,
                      globalStyle.inverted,
                    )}
                  >
                    Ajouter
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
