import { GetOffer, GetOffers, GetLastAcceptedApplication } from "../../../actionTypes/offers";
import { Company } from "../../../interfaces/resources/company";

import { Talent } from "../../../interfaces/resources/talent";

export function getOffer(slug: string) {
  return {
    slug,
    type: GetOffer.REQUEST,
  };
}

export function getOffers(currentTalent: Talent) {
  return {
    currentTalent,
    type: GetOffers.REQUEST,
  };
}

export function getOffersFromCompanyAction(company: Company) {
  return {
    company,
    type: GetOffers.REQUEST,
  };
}

interface GetLastAcceptedApplicationAction {
  id: string;
  type: string;
}

export function getLastAcceptedApplication(id: string): GetLastAcceptedApplicationAction {
  return {
    id,
    type: GetLastAcceptedApplication.REQUEST,
  };
}
