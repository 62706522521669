import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import Helmet from "react-helmet";
import { BiArrowBack } from "react-icons/bi";
import { BsCalendar } from "react-icons/bs";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { quizContext } from "../..";
import { extractIdFromIri } from "../../../../components/Utils/helpers";

import { State } from "../../../../interfaces/api/state";
import { QuizStepSlug } from "../../../../interfaces/resources/quiz";
import { talentStatuses } from "../../../../interfaces/resources/talent";
import globalStyle from "../../../../styles/global.module.scss";
import styles from "./index.module.scss";

const QUIZ_STEP_SLUG: QuizStepSlug = "completed";

export const PartCompleted: React.FC = () => {
  const context = React.useContext(quizContext);
  const currentTalent = useSelector((state: State) => state.currentTalent);
  const talentStatus: { [key: string]: string } = {
    status_to_c_level: "fast-track-c-level",
    status_to_call: "call-club",
    status_to_onboard: "welcome",
    status_to_test: "coach",
  };
  const [hasPassed] = React.useState(
    Object.keys(talentStatus).includes(currentTalent.status)
      || (currentTalent.status === talentStatuses.accepted && !!!currentTalent.accessToPlatine),
  );
  const utm = currentTalent?.["@id"]
    ? encodeURI(JSON.stringify({ talent: extractIdFromIri(currentTalent["@id"]) }))
    : undefined;
  const handleRdv = (): void => {
    const url = !!currentTalent.lastTalentStatusEvent?.coreUser?.calendlyLink20
      ? currentTalent.lastTalentStatusEvent.coreUser.calendlyLink20
      : !!currentTalent?.talentManager?.calendlyLink20
        ? currentTalent.talentManager.calendlyLink20
        : `https://calendly.com/team-elinoi/${talentStatus.status_to_onboard}?utm_term=${utm}`;

    window.open(url);
  };
  const handlePrevious = () => {
    if (context === null) {
      return;
    }
    context.handlePrevious(QUIZ_STEP_SLUG);
  };
  return (
    <>
      <Helmet>
        <title>{t({ id: "ProfileCompletedScene.title" })}</title>
        <meta name="" content="" />
      </Helmet>
      <div className={styles.profileCompletedWrapper}>
        <div className={styles.pageBody}>
          <div className={styles.pageBodyPart}>
            <h1 className={cx(globalStyle.mainTitle, styles.mainTitle)}>
              <ReactMarkdown disallowedTypes={["paragraph"]} unwrapDisallowed>
                {hasPassed
                  ? t({
                      id: "EndPage.header.titleAccepted",
                      values: { name: currentTalent.firstname },
                    })
                  : t({ id: "EndPage.header", values: { name: currentTalent.firstname } })}
              </ReactMarkdown>
            </h1>
            {!hasPassed ? <ReactMarkdown>{t({ id: "EndPage.text" })}</ReactMarkdown> : null}
          </div>
          <div className={styles.pageBodyPart}>
            <h2 className={styles.partTitle}>
              <ReactMarkdown unwrapDisallowed disallowedTypes={["paragraph"]}>
                {hasPassed ? t({ id: "EndPage.lastStep.title" }) : t({ id: "EndPage.nextStep.title" })}
              </ReactMarkdown>
            </h2>
            <div className={styles.bodyTextContainer}>
              {hasPassed ? (
                <ReactMarkdown>{t({ id: "EndPage.nextStep.textAccepted" })}</ReactMarkdown>
              ) : (
                <>
                  <ReactMarkdown>{t({ id: "EndPage.nextStep.text0" })}</ReactMarkdown>
                  <ReactMarkdown>{t({ id: "EndPage.nextStep.text1" })}</ReactMarkdown>
                  <ReactMarkdown>{t({ id: "EndPage.nextStep.text2" })}</ReactMarkdown>
                </>
              )}
            </div>
          </div>
          <div className={styles.pageBodyPart}>
            {hasPassed ? (
              <button type="button" onClick={handleRdv} className={styles.submitCta}>
                {t({ id: "EndPage.nextStep.button" })} <BsCalendar />
              </button>
            ) : (
              <button type="button" onClick={handlePrevious} className={styles.previousCta}>
                <BiArrowBack /> Modifier mes réponses
              </button>
            )}
          </div>
          <div className={styles.pageBodyPart}>
            <h2 className={styles.partTitle}>
              <ReactMarkdown unwrapDisallowed disallowedTypes={["paragraph"]}>
                {t({ id: "EndPage.about.title" })}
              </ReactMarkdown>
            </h2>
            <ReactMarkdown>{t({ id: "EndPage.about.text" })}</ReactMarkdown>
            <div className={styles.linksContainer}>
              <a
                className={cx(styles.links, styles.linkedin)}
                href="https://www.linkedin.com/company/elinoi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
              <a
                className={cx(styles.links, styles.facebook)}
                href="https://www.facebook.com/TeamElinoi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
              <a
                className={cx(styles.links, styles.instagram)}
                href="https://www.instagram.com/teamelinoi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
