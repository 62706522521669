import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import { FormInputProps } from "semantic-ui-react";
import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../../utils/hooks";
import ErrorMessage from "../ErrorMessage";

import styles from "./index.module.scss";

export interface InputProps extends FieldProps, FormInputProps {
  type?: string;
  label?: string;
  subLabel?: string;
  id?: string;
  extraText?: string;
  className: string;
  violations?: Violation[];
}
export const Input: React.FC<InputProps> = ({
  className,
  disabled,
  field,
  id,
  label,
  subLabel,
  extraText,
  type,
  violations,
  placeholder,
  ...rest
}: InputProps): JSX.Element => {
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);
  return (
    <div className={cx(className, { error: (meta.touched && !!meta.error) || !!error, disabled })}>
      {label ? <label className="formLabel">{label}</label> : null}
      {subLabel ? <span>{subLabel}</span> : null}
      <div className="formInputContainer">
        <input
          {...field}
          id={id ? id : field.name}
          type={type}
          placeholder={placeholder}
          className={styles.input}
          {...(rest as any)}
        />
        {extraText ? <small className="formAdditionalInfos">{extraText}</small> : null}
      </div>
      <ErrorMessage name={field.name} message={error} />
    </div>
  );
};
