import { t } from "@lingui/macro";

export const transTalentSetting: {
  newOffersFrequencies: {
    [key: string]: string;
  };
} = {
  newOffersFrequencies: {
    desactivated: t`talentSetting.newOffersFrequency.desactivated`,
    monthly: t`talentSetting.newOffersFrequency.monthly`,
  },
};
