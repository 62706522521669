import { GetApplications, SubmitApplication, GetAvailableApplications } from "../../../actionTypes/applications";

import { ApplicationWithStatus } from "../../../interfaces/resources/application";
import { Offer } from "../../../interfaces/resources/offer";
import { Talent } from "../../../interfaces/resources/talent";

export function getApplications(currentTalent: Talent, offer?: Offer) {
  return {
    currentTalent,
    offer,
    type: GetApplications.REQUEST,
  };
}

export function getAvailableApplications(currentTalent: Talent) {
  return {
    currentTalent,
    type: GetAvailableApplications.REQUEST,
  };
}

export function submitApplication(application: Partial<ApplicationWithStatus>) {
  return {
    application,
    type: SubmitApplication.REQUEST,
  };
}
