import { ErrorMessage as ErrorMessageFormik, ErrorMessageProps } from "formik";
import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

import styles from "./index.module.scss";

interface ErrorMessageInterface extends ErrorMessageProps {
  message?: string | undefined;
}

export const ErrorWording = ({ message }: { message: string }) => (
  <div className={styles.errorMessage}>
    <ReactMarkdown source={message} disallowedTypes={["paragraph", "link"]} unwrapDisallowed />
  </div>
);

const ErrorMessage: FC<ErrorMessageInterface> = ({ name, message }) => {
  return (
    <>
      <ErrorMessageFormik name={name} render={(msg: string) => <ErrorWording message={msg} />} />
      {!!message ? <ErrorWording message={message} /> : undefined}
    </>
  );
};

export default ErrorMessage;
