import { t } from "@lingui/macro";
import cx from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CoCModalClose, CoCModalShowLeftSide } from "../";
import { Talent } from "../../../interfaces/resources/talent";
import { editTalentSetting } from "../../../services/api/talent/actions";
import { gtagService } from "../../../services/google/gtag";
import globalStyle from "../../../styles/global.module.scss";
import { eventDispatcher } from "../../../utils/eventDispatcher";

import styles from "./index.module.scss";
import { Page1, Page2, Page3 } from "./Pages";

interface Props {
  currentTalent: Talent;
}

export const SidePanel: FC<Props> = ({ currentTalent }) => {
  const showLeftSide = () => {
    eventDispatcher.trigger(CoCModalShowLeftSide);
  };
  return (
    <>
      <h1>{t({ id: "sidepanel.header", values: { firstname: currentTalent.firstname } })}</h1>
      <h2>{t`sidepanel.question`}</h2>
      <p>{t`sidepanel.paragraph`}</p>

      <div className={styles.ctaContainer}>
        <button type="button" onClick={showLeftSide} className={cx(globalStyle.cta, globalStyle.primaryCta)}>
          {t`sidepanel.cta`}
        </button>
      </div>
    </>
  );
};

interface ControlProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  accepted: boolean;
  callback: () => void;
}

export const Controls: FC<ControlProps> = ({ accepted, callback, currentPage, setCurrentPage }) => {
  const increment = () => setCurrentPage(currentPage + 1);
  const decrement = () => setCurrentPage(currentPage - 1);

  return (
    <div className={styles.modalCocFooter}>
      <div className={styles.leftButton}>
        <button onClick={decrement} disabled={!currentPage}>
          {t`modalCocFooter.button.previous`}
        </button>
      </div>
      <div className={styles.rightButton}>
        {currentPage === 2 ? (
          <button
            type="button"
            disabled={!accepted}
            onClick={callback}
            className={cx(globalStyle.cta, globalStyle.primaryCta)}
          >
            {t`modalCocFooter.button.accepte`}
          </button>
        ) : (
          <button type="button" onClick={increment} className={cx(globalStyle.cta, globalStyle.primaryCta)}>
            {t`modalCocFooter.button.next`}
          </button>
        )}
      </div>
    </div>
  );
};

export const renderPageComponent = (pageNumber: number, props?: any, infinitScroll?: boolean) => {
  if (infinitScroll) {
    return (
      <>
        <Page1 />
        <Page2 />
        <Page3 {...props} />
      </>
    );
  }
  switch (pageNumber) {
    case 0:
      return <Page1 />;
    case 1:
      return <Page2 />;
    case 2:
      return <Page3 {...props} />;
    default:
      return null;
  }
};

const CodeOfConduct: FC = () => {
  const currentTalent: Talent = useSelector((state: any) => state.currentTalent);
  const talentSetting: Talent = useSelector((state: any) => state.talentSetting);
  const [accepted, setAccepted] = useState(currentTalent.codeOfConduct);
  const [currentPage, setCurrentPage] = useState(0);
  const [showLeftSide, setShowLeftSide] = useState(false);
  const [infinitScroll, setInfinitScroll] = useState(false);
  const dispatch = useDispatch();
  const acceptCodeOfConduct = () => {
    eventDispatcher.trigger(CoCModalClose);
    if (!!!currentTalent.codeOfConduct && !!!talentSetting?.codeOfConduct) {
      gtagService.conversion("AW-853378430/gsXFCLHf3rcYEP6K9pYD", {
        value: 15.0,
        currency: "EUR",
      });
      if (currentTalent?.segment === "segment_1") {
        gtagService.event("Acceptation charte elinoï - Club", {
          event_label: "Acceptation charte elinoï - Club",
          email: currentTalent.email,
          phone_number: currentTalent.phone,
          address: {
            first_name: currentTalent.firstname,
            last_name: currentTalent.lastname,
            country: "FR",
          },
        });
      } else if (currentTalent?.segment === "segment_2") {
        gtagService.event("Acceptation charte elinoï - Marketplace", {
          event_label: "Acceptation charte elinoï - Marketplace",
          email: currentTalent.email,
          phone_number: currentTalent.phone,
          address: {
            first_name: currentTalent.firstname,
            last_name: currentTalent.lastname,
            country: "FR",
          },
        });
      }
      gtagService.event("Acceptation charte elinoï", {
        event_label: "Profil Accepté - GTM",
        email: currentTalent.email,
        phone_number: currentTalent.phone,
        address: {
          first_name: currentTalent.firstname,
          last_name: currentTalent.lastname,
          country: "FR",
        },
      });
    }
    dispatch(
      editTalentSetting({
        "@id": currentTalent.talentSetting,
        codeOfConduct: true,
      }),
    );
  };

  const onShowLeftSide = () => {
    setShowLeftSide(true);
    setInfinitScroll(true);
    setCurrentPage(2);
  };

  useEffect(() => {
    eventDispatcher.set(CoCModalShowLeftSide);
    eventDispatcher.on(CoCModalShowLeftSide, onShowLeftSide);

    return () => {
      eventDispatcher.offAll(CoCModalShowLeftSide);
    };
  }, []);

  useEffect(() => {
    if (talentSetting === null) {
      setAccepted(currentTalent.codeOfConduct);
    } else {
      setAccepted(talentSetting.codeOfConduct);
    }
  }, [currentTalent, talentSetting]);

  return (
    <div className={styles.modal}>
      <div className={styles.left}>
        <SidePanel currentTalent={currentTalent} />
      </div>
      <div className={cx(styles.right, { [styles.showLeftSide]: showLeftSide })}>
        {renderPageComponent(currentPage, { accepted, setAccepted }, infinitScroll)}
        <Controls
          accepted={accepted}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          callback={acceptCodeOfConduct}
        />
      </div>
    </div>
  );
};

export default CodeOfConduct;
