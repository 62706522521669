import { Answer } from "../../../interfaces/resources/answer";
import { DesiredPlace } from "../../../interfaces/resources/desiredPlace";
import { Question } from "../../../interfaces/resources/question";
import { Choice, QuizStepSlug } from "../../../interfaces/resources/quiz";
import { Responsibility } from "../../../interfaces/resources/responsibility";
import client from "../client";

export function formatData(answer: Partial<Answer>) {
  return {
    ...answer,
    choice: answer.choice && answer.choice["@id"],
    choices: answer.choices && answer.choices.map((choice: Choice) => choice["@id"]),
    data: answer.data
      ? {
          ...answer.data,
          linkedInProfile: answer.data.linkedInProfile
            ? {
                ...answer.data.linkedInProfile,
                educations: answer.data.linkedInProfile.educations
                  ? answer.data.linkedInProfile.educations.reduce((accumulator: any, education: any) => {
                      if (
                        (!education.endDate || education.endDate === "" || Number(education.endDate) < 0)
                        && !education.schoolName
                      ) {
                        return accumulator;
                      }
                      accumulator.push({
                        endDate:
                          !education.endDate || education.endDate === "" || Number(education.endDate) < 0
                            ? undefined
                            : Number(education.endDate),
                        schoolName: education.schoolName.name
                          ? education.schoolName.name
                          : education.schoolName || undefined,
                        talent: answer.data.talent || undefined,
                        school: education.schoolName["@id"] ? education.schoolName["@id"] : undefined,
                      });
                      return accumulator;
                    }, [])
                  : undefined,
              }
            : undefined,
          talentProfile: answer.data.talentProfile
            ? {
                ...answer.data.talentProfile,
                desiredPlaces: answer.data.talentProfile.desiredPlaces
                  ? answer.data.talentProfile.desiredPlaces.map((loc: DesiredPlace) => loc["@id"])
                  : undefined,
                experienceYears: answer.data.talentProfile.experienceYears
                  ? answer.data.talentProfile.experienceYears["@id"]
                  : undefined,
                responsibilities: answer.data.talentProfile.responsibilities
                  ? answer.data.talentProfile.responsibilities.map((resp: Responsibility) => resp["@id"])
                  : undefined,
                salaryExpectations: answer.data.talentProfile.salaryExpectations
                  ? [answer.data.talentProfile.salaryExpectations["@id"]]
                  : undefined,
                songLink: answer.data.talentProfile.songLink ? answer.data.talentProfile.songLink : undefined,
              }
            : undefined,
        }
      : undefined,
    question: answer.question && answer.question["@id"],
    talent: {
      id: answer.talent && answer.talent["@id"],
      documentFile: answer.talent && answer.talent.documentFile,
    },
  };
}

export function getNextQuestion() {
  return client.get("/api/v2/next_question");
}
export function getCurrentQuestion() {
  return client.get("/api/v2/current_question");
}

export function putCurrentQuestion(questionSlug: QuizStepSlug) {
  return client.put("/api/v2/current_question", { questionSlug });
}

export function getPreviousQuestion(question: Question) {
  return client.get(`${question["@id"]}/previous_question`);
}

export function postAnswer(answer: Partial<Answer>) {
  return client.post("/api/v2/quiz_answers", answer);
}

interface PutAnswerProps extends Answer {
  existingAnswerIri: string;
}

export function putAnswer(answer: PutAnswerProps) {
  return client.put(answer.existingAnswerIri, answer);
}

export function getQuizChoices() {
  return client.get("/api/v2/quiz_choices");
}
