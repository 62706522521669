import { t } from "@lingui/macro";
import * as React from "react";

import { NewNotificationsHelmet } from "../../../blocks/newNotificationsHelmet";

import { HomePage } from "../../../pages/HomePage";

const HomeScene = (): JSX.Element => (
  <>
    <NewNotificationsHelmet title={t`HomeScene.title`} />
    <HomePage />
  </>
);

export default HomeScene;
