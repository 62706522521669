import axios, { AxiosInstance } from "axios";

import { getAuthorizationHeader } from "./helpers";

const client: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

client.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, ...getAuthorizationHeader() };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default client;
