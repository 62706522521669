import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import ReactPixel from "react-facebook-pixel";
import { CgArrowRight } from "react-icons/cg";
import LinkedInTag from "react-linkedin-insight";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { quizContext } from "../..";
import ErrorMessage from "../../../../components/Utils/Form/ErrorMessage";
import { QuizRadio } from "../../../../components/Utils/Form/QuizRadio";
import Yup from "../../../../components/Utils/Yup";
import { QuizStepSlug } from "../../../../interfaces/resources/quiz";
import { Talent } from "../../../../interfaces/resources/talent";
import { gtagService } from "../../../../services/google/gtag";
import globalStyle from "../../../../styles/global.module.scss";
import quizStyle from "../../index.module.scss";
import styles from "./index.module.scss";

const QUIZ_STEP_SLUG: QuizStepSlug = "source";

interface ChoiceProps {
  value: string;
  title: string;
}

export const PartSource: React.FC = () => {
  const context = React.useContext(quizContext);
  const currentTalent: Talent = useSelector((state: any) => state.currentTalent);
  const answer = context?.getAnswer(QUIZ_STEP_SLUG);
  const choices: ChoiceProps[] = [
    {
      title: "Bouche à oreille",
      value: "question.referrer.word_of_mouth",
    },
    {
      title: "Parrainage",
      value: "question.referrer.sponsorship",
    },
    {
      title: "Évènement",
      value: "question.referrer.event",
    },
    {
      title: "Google",
      value: "question.referrer.google",
    },
    {
      title: "Presse",
      value: "question.referrer.press",
    },
    {
      title: "Linkedin",
      value: "question.referrer.social.linkedin",
    },
    {
      title: "Instagram ou Facebook",
      value: "question.referrer.social.facebook.instagram",
    },
    {
      title: "Contenu (blog, ebook...)",
      value: "question.referrer.content",
    },
  ];
  const validationSchema = Yup.object().shape({
    refererWebsite: Yup.string().required(t`quiz.validation.question.required`),
  });
  const initialValues = {
    refererWebsite: answer?.data?.refererWebsite || "",
  };

  const submitForm = (values: any): void => {
    if (context === null) {
      return;
    }

    ReactPixel.track("profile_completed", {});
    gtagService.conversion("AW-853378430/LGllCIvW-5UBEP6K9pYD");
    if (!!currentTalent) {
      gtagService.event("profil complet", {
        event_label: "profil - questionnaire complet",
        email: currentTalent.email,
        phone_number: currentTalent.phone,
        address: {
          first_name: currentTalent.firstname,
          last_name: currentTalent.lastname,
          country: "FR",
        },
      });
    }
    LinkedInTag.track("4672465");

    context.handleSubmit({
      data: values,
      value: QUIZ_STEP_SLUG,
    });
  };

  const handlePrevious = () => {
    if (context === null) {
      return;
    }
    context.handlePrevious(QUIZ_STEP_SLUG);
  };

  return (
    <div data-heap="question-referrer">
      <div className={quizStyle.questionHeader}>
        <h1 className={cx(globalStyle.mainTitle, quizStyle.mainTitle)}>
          <ReactMarkdown
            source={"Comment avez-vous entendu parler de nous ?"}
            disallowedTypes={["paragraph"]}
            unwrapDisallowed
          />
        </h1>
      </div>
      <div className={styles.formContainer}>
        <Formik
          onSubmit={submitForm}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }): JSX.Element => (
            <Form className={cx("form")}>
              <div className={cx("formGroup", styles.choicesList)}>
                {choices?.map((choice, i) => {
                  return (
                    <Field
                      key={`${choice.value}-${i}`}
                      name="refererWebsite"
                      label={choice.title}
                      component={QuizRadio}
                      value={choice.value}
                      showErrorMessage={false}
                    />
                  );
                })}
              </div>
              <div className="formGroup">
                <ErrorMessage name="refererWebsite" />
              </div>
              <div className={quizStyle.ctasContainer}>
                <div>
                  <span onClick={handlePrevious} className={quizStyle.previousCta}>
                    {t`Quiz.previousCta.text`}
                  </span>
                </div>
                <div className={quizStyle.submitCtaContainer}>
                  <button type="submit" disabled={isSubmitting} className={quizStyle.submitCta}>
                    Suivant <CgArrowRight />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
