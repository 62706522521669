import { ActionType } from "../interfaces/api/actionType";

export const GetSchools: ActionType = {
  FAILURE: "GET_SCHOOLS_FAILURE",
  REQUEST: "GET_SCHOOLS_REQUEST",
  SUCCESS: "GET_SCHOOLS_SUCCESS",
};

export const SearchSchools: ActionType = {
  FAILURE: "SEARCH_SCHOOLS_FAILURE",
  REQUEST: "SEARCH_SCHOOLS_REQUEST",
  SUCCESS: "SEARCH_SCHOOLS_SUCCESS",
};
