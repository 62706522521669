import cx from "classnames";
import * as React from "react";

import { BiCheck, BiInfoCircle } from "react-icons/bi";
import { Seniority } from "..";
import { Responsibility, ResponsibilityWithSeniority } from "../../../../interfaces/resources/responsibility";

import styles from "./index.module.scss";

interface Props {
  job: ResponsibilityWithSeniority;
  isChecked?: boolean;
  onClick?: () => void;
  onSeniorityChange?: (seniority: Seniority) => void;
  onPrimarySelect: (resp: Responsibility) => void;
  isPrimaryResponsibility?: boolean;
}

export const responsibilitySeniority: { [key: string]: Seniority } = {
  junior: "junior",
  midlevel: "mid-level",
  senior: "senior",
};

export const Job: React.FC<Props> = (props: Props) => {
  const { job, onClick, onSeniorityChange, isChecked, onPrimarySelect, isPrimaryResponsibility } = props;
  const [showDescription, setShowDescription] = React.useState(false);
  const onClickJob = (): void => {
    if (typeof onClick === "function") {
      onClick();
    }
  };

  const onCoreResponsibilitySelect = () => {
    onPrimarySelect(job);
  };

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    if (typeof onSeniorityChange === "function") {
      onSeniorityChange(e.target.value as Seniority);
    }
  };
  return (
    <div className={cx(styles.job, { [styles.checked]: !!isChecked })} onClick={onClickJob}>
      <div className={styles.jobTopPart}>
        <div>
          <span className={styles.customCheck}>
            <BiCheck />
          </span>
          <span className={styles.jobName}>{job.value}</span>
        </div>
        {isChecked ? (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onCoreResponsibilitySelect();
            }}
            className={cx(styles.coreSelect, { [styles.coreResp]: isPrimaryResponsibility })}
          >
            C&apos;est ma recherche principale{" "}
          </button>
        ) : null}
        <div>
          {isChecked ? (
            <div className={styles.senioritySelectContainer}>
              <select
                name="seniority"
                defaultValue={job.seniority || "default"}
                className={styles.senioritySelect}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={onChangeSelect}
              >
                <option value="default" disabled hidden>
                  Experience
                </option>
                <option value={responsibilitySeniority.junior}>Junior : 0-2ans</option>
                <option value={responsibilitySeniority.midlevel}>Intermédiaire : 3-6ans</option>
                <option value={responsibilitySeniority.senior}>Sénior : +6ans</option>
              </select>
            </div>
          ) : null}

          <span
            className={cx(styles.jobInfoCta, { [styles.showingDescription]: showDescription })}
            onClick={(e): void => {
              e.stopPropagation();
              setShowDescription(!showDescription);
            }}
          >
            <BiInfoCircle />
          </span>
        </div>
      </div>
      {showDescription ? (
        <div className={styles.jobBottomPart}>
          <p>{job.description}</p>
        </div>
      ) : null}
    </div>
  );
};
