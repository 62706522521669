import cx from "classnames";
import { addHours } from "date-fns";
import fr from "date-fns/locale/fr";
import { FieldProps, useField } from "formik";
import * as React from "react";
import ReactDatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../../utils/hooks";
import ErrorMessage from "../ErrorMessage";
import styles from "./index.module.scss";

registerLocale("fr", fr);
setDefaultLocale("fr");

interface DatePickerProps extends FieldProps<any> {
  label?: string;
  className: string;
  violations?: Violation[];
  readOnly?: boolean;
}

const DatePicker = ({ className, field, form: { setFieldValue }, label, violations, readOnly }: DatePickerProps) => {
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);

  return (
    <div className={cx({ [className]: !!className })}>
      {label ? <label className="formLabel">{label}</label> : null}
      <div className={styles.datePickerInputContainer}>
        <ReactDatePicker
          readOnly={readOnly}
          showMonthDropdown
          showYearDropdown
          onChange={(date: Date) => {
            // By default, datepicker returns date with time to 00:00:00.
            // Because of timezone management, 1993-02-13T00:00:00+01:00 becomes 1993-02-12T23:00:00Z when sending to API
            // API storing only date, truncates it to 1993-02-12 => Bad date.
            // Adding 12 hours as a "temporary" fix before better solution.
            setFieldValue(field.name, addHours(date, 12));
          }}
          selected={typeof field.value === "string" ? new Date(field.value) : field.value}
          dateFormat={"dd/MM/yyyy"}
          className={styles.input}
        />
      </div>
      {meta.touched && !!meta.error ? <ErrorMessage name={field.name} message={error} /> : null}
    </div>
  );
};

export default DatePicker;
