import { t } from "@lingui/macro";

interface TransPages {
  [key: string]: string;
}

export const transPages: TransPages = {
  applications: t`page.applications`,
  contactUs: t`page.contactUs`,
  companies: t`page.companies`,
  favCompanies: t`page.favCompanies`,
  home: t`page.home`,
  offer: t`page.offer`,
  offers: t`page.offers`,
  process: t`page.process`,
  profile: t`page.profile`,
  settings: t`page.settings`,
  sponsor: t`page.sponsor`,
};
