import { EditNotification, GetNotifications } from "../../../actionTypes/notification";
import { Notification } from "../../../interfaces/resources/notification";

export function notifications(state: Notification[] = [], action: any): Notification[] {
  switch (action.type) {
    case GetNotifications.SUCCESS:
      return action.notifications;
    case EditNotification.SUCCESS:
      const index = state.findIndex((n: Notification) => n["@id"] === action.notification["@id"]);
      const newNotifications = [...state];
      if (index !== -1) {
        newNotifications[index] = action.notification;
      }
      return newNotifications;
    default:
      return state;
  }
}
