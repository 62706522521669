interface Settings {
  isMenuCollapsed?: boolean;
  isMobile?: boolean;
  [key: string]: any;
}

const ELINOI_LOCAL_STORAGE_SETTINGS = "elinoi_settings";
const DEFAULT_SETTINGS: Settings = {
  isMenuCollapsed: false,
  isMobile: false,
};

export const elinoiSettings = {
  init: (): void => {
    const settings = localStorage.getItem(ELINOI_LOCAL_STORAGE_SETTINGS);
    if (settings === null) {
      localStorage.setItem(ELINOI_LOCAL_STORAGE_SETTINGS, JSON.stringify(DEFAULT_SETTINGS));
    }
  },
  get: (): Settings | null => {
    const settings = localStorage.getItem(ELINOI_LOCAL_STORAGE_SETTINGS);
    return settings === null ? null : JSON.parse(settings);
  },
  set: (settings: Settings): Settings | { [key: string]: any } => {
    let s: string | Settings | null = localStorage.getItem(ELINOI_LOCAL_STORAGE_SETTINGS);
    if (s === null) {
      s = "{}";
    }
    const sObj: Settings = JSON.parse(s);
    const values = { ...DEFAULT_SETTINGS, ...sObj, ...settings };
    localStorage.setItem(ELINOI_LOCAL_STORAGE_SETTINGS, JSON.stringify(values));
    return values;
  },
};
