import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetDesiredPlaces } from "../../../actionTypes/desiredPlaces";
import { apiFailure } from "../helpers";
import * as apiClient from "./api";

export function* getDesiredPlaces(action: AnyAction) {
  try {
    const response = yield call(apiClient.getDesiredPlaces, action.page);
    const desiredPlaces = response.data["hydra:member"];
    yield put({
      desiredPlaces,
      page: action.page,
      type: GetDesiredPlaces.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* getDesiredPlacesSaga() {
  yield takeLatest(GetDesiredPlaces.REQUEST, getDesiredPlaces);
}
