import qs from "query-string";

import { Notification } from "../../../interfaces/resources/notification";
import { Offer } from "../../../interfaces/resources/offer";
import { Talent } from "../../../interfaces/resources/talent";
import client from "../client";

export function getNotifications(talent: Talent, offer?: Offer) {
  const params: any = {
    offer: offer ? offer["@id"] : undefined,
    status: "complete",
  };

  return client.get(`${talent["@id"]}/talent_notifications?${qs.stringify(params)}`);
}

export function editNotification(notification: Notification) {
  return client.put(notification["@id"], notification);
}
