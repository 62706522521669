import { ActionType } from "../interfaces/api/actionType";

export const EditTalent: ActionType = {
  FAILURE: "EDIT_TALENT_FAILURE",
  REQUEST: "EDIT_TALENT_REQUEST",
  SUCCESS: "EDIT_TALENT_SUCCESS",
};

export const EditTalentOnly: ActionType = {
  FAILURE: "EDIT_TALENT_ONLY_FAILURE",
  REQUEST: "EDIT_TALENT_ONLY_REQUEST",
  SUCCESS: "EDIT_TALENT_ONLY_SUCCESS",
};

export const GetCurrentTalent: ActionType = {
  FAILURE: "GET_CURRENT_TALENT_FAILURE",
  REQUEST: "GET_CURRENT_TALENT_REQUEST",
  SUCCESS: "GET_CURRENT_TALENT_SUCCESS",
};

export const GetTalentProfile: ActionType = {
  FAILURE: "GET_TALENT_PROFILE_FAILURE",
  REQUEST: "GET_TALENT_PROFILE_REQUEST",
  SUCCESS: "GET_TALENT_PROFILE_SUCCESS",
};

export const GetTalentSetting: ActionType = {
  FAILURE: "GET_TALENT_SETTING_FAILURE",
  REQUEST: "GET_TALENT_SETTING_REQUEST",
  SUCCESS: "GET_TALENT_SETTING_SUCCESS",
};

export const GetLinkedInProfile: ActionType = {
  FAILURE: "GET_LINKED_IN_PROFILE_FAILURE",
  REQUEST: "GET_LINKED_IN_PROFILE_REQUEST",
  SUCCESS: "GET_LINKED_IN_PROFILE_SUCCESS",
};

export const GetQuizAnswers: ActionType = {
  FAILURE: "GET_QUIZ_ANSWERS_FAILURE",
  REQUEST: "GET_QUIZ_ANSWERS_REQUEST",
  SUCCESS: "GET_QUIZ_ANSWERS_SUCCESS",
};

export const EditTalentSetting: ActionType = {
  FAILURE: "EDIT_TALENT_SETTING_FAILURE",
  REQUEST: "EDIT_TALENT_SETTING_REQUEST",
  SUCCESS: "EDIT_TALENT_SETTING_SUCCESS",
};

export const SendMessage: ActionType = {
  FAILURE: "SEND_MESSAGE_FAILURE",
  REQUEST: "SEND_MESSAGE_REQUEST",
  SUCCESS: "SEND_MESSAGE_SUCCESS",
};

export const EditLinkedInProfile: ActionType = {
  FAILURE: "EDIT_LINKED_IN_PROFILE_FAILURE",
  REQUEST: "EDIT_LINKED_IN_PROFILE_REQUEST",
  SUCCESS: "EDIT_LINKED_IN_PROFILE_SUCCESS",
};

export const CreateLinkedinResume: ActionType = {
  FAILURE: "CREATE_LINKEDIN_RESUME_FAILURE",
  REQUEST: "CREATE_LINKEDIN_RESUME_REQUEST",
  SUCCESS: "CREATE_LINKEDIN_RESUME_SUCCESS",
};

export const EditLinkedinResume: ActionType = {
  FAILURE: "EDIT_LINKEDIN_RESUME_FAILURE",
  REQUEST: "EDIT_LINKEDIN_RESUME_REQUEST",
  SUCCESS: "EDIT_LINKEDIN_RESUME_SUCCESS",
};

export const CreateTalentProfileResponsibility: ActionType = {
  FAILURE: "CREATE_TALENT_PROFILE_RESPONSIBILITY_FAILURE",
  REQUEST: "CREATE_TALENT_PROFILE_RESPONSIBILITY_REQUEST",
  SUCCESS: "CREATE_TALENT_PROFILE_RESPONSIBILITY_SUCCESS",
};

export const DeleteTalentProfileResponsibility: ActionType = {
  FAILURE: "DELETE_TALENT_PROFILE_RESPONSIBILITY_FAILURE",
  REQUEST: "DELETE_TALENT_PROFILE_RESPONSIBILITY_REQUEST",
  SUCCESS: "DELETE_TALENT_PROFILE_RESPONSIBILITY_SUCCESS",
};

export const EditTalentProfileResponsibility: ActionType = {
  FAILURE: "EDIT_TALENT_PROFILE_RESPONSIBILITY_FAILURE",
  REQUEST: "EDIT_TALENT_PROFILE_RESPONSIBILITY_REQUEST",
  SUCCESS: "EDIT_TALENT_PROFILE_RESPONSIBILITY_SUCCESS",
};
