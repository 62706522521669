import { AnyAction } from "redux";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { EditNotification, GetNotifications } from "../../../actionTypes/notification";

import { apiFailure, getSuccessType } from "../helpers";
import * as apiClient from "./api";

export function* getNotifications(action: AnyAction) {
  try {
    const response = yield call(apiClient.getNotifications, action.talent, action.offer);
    const notifications = response.data["hydra:member"];
    yield put({
      notifications,
      page: action.page,
      type: GetNotifications.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* editNotification(action: AnyAction) {
  try {
    const response = yield call(apiClient.editNotification, action.notification);
    yield put({
      notification: response.data,
      type: getSuccessType(action),
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export default function* getNotificationsSaga() {
  yield takeLatest(GetNotifications.REQUEST, getNotifications);
  yield takeEvery(EditNotification.REQUEST, editNotification);
}
