import { t } from "@lingui/macro";

import { notificationLabels } from "../../interfaces/resources/notification";

export const notificationCTAs: {
  [key: string]: string;
} = {
  [notificationLabels.blogNewArticle]: t`notifications.ctas.newArticle`,
  [notificationLabels.blogPresentation]: t`notifications.ctas.blog`,
  [notificationLabels.postCall]: t`notifications.ctas.postCall`,
  [notificationLabels.pushOffers]: t`notifications.ctas.pushOffers`,
  [notificationLabels.applicationAppliedConfirm]: t`notification.ctas.applicationAppliedConfirm`,
  [notificationLabels.awaitingShortlist]: t`notifications.ctas.awaitingShortlist`,
  [notificationLabels.toPitch]: t`notification.ctas.toPitch`,
  [notificationLabels.referral]: t`notification.ctas.referral`,
  [notificationLabels.referral2]: t`notification.ctas.referral`,
  [notificationLabels.referral3]: t`notification.ctas.referral`,
  [notificationLabels.referral4]: t`notification.ctas.referral`,
  [notificationLabels.referral5]: t`notification.ctas.referral`,
  [notificationLabels.settings]: t`notification.ctas.settings`,
};
