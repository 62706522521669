import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { NewNotificationsHelmet } from "../../../blocks/newNotificationsHelmet";
import { NotificationsList } from "../../../components/NotificationsList";

import globalStyles from "../../../styles/global.module.scss";
import styles from "./index.module.scss";

export const NotificationsListScene = () => (
  <>
    <NewNotificationsHelmet title={t`NotificationsListScene.title`} />
    <div className={cx(styles.pageWrapper)}>
      <h2 className={cx(globalStyles.mainTitle)}>
        <ReactMarkdown disallowedTypes={["paragraph"]} unwrapDisallowed>{t`NotificationPage.title`}</ReactMarkdown>
      </h2>
      <div className={styles.listContainer}>
        <NotificationsList className={styles.list} format="long" trackingPrefix="menu" />
      </div>
    </div>
  </>
);

export default NotificationsListScene;
