import { t } from "@lingui/macro";
import cx from "classnames";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";
import React, { PropsWithChildren, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { GetNotifications } from "../../actionTypes/notification";
import { PersonProfileImg } from "../../blocks/personProfileImg";
import { ReactComponent as StrengthIcon } from "../../images/beginner.svg";
import { ReactComponent as BlogIcon } from "../../images/Icone-Notification-blogx2.svg";
import { ReactComponent as SignatureIcon } from "../../images/Icone-Notification-Signature.svg";
import { ReactComponent as SuccessIcon } from "../../images/Icone-notification-talent-place.svg";
import { ApiRequestProps } from "../../interfaces/api/request";
import { State } from "../../interfaces/api/state";
import { CoreUser } from "../../interfaces/resources/coreUser";
import { Notification, notificationIcons, notificationLabels } from "../../interfaces/resources/notification";
import { editNotification } from "../../services/api/notification/actions";

import global from "../../styles/global.module.scss";
import { notificationCTAs } from "../../translations/constants/notification";
import { useApiSelector } from "../../utils/hooks";
import { Loader } from "../Loader";
import styles from "./index.module.scss";

export type NotificationTrackingPrefix = "accueil" | "menu" | "offre";
export type NotificationFormatPrefix = "short" | "long";

interface Props {
  simple?: boolean;
  limit?: number;
  format?: NotificationFormatPrefix;
  trackingPrefix: NotificationTrackingPrefix;
  className?: string;
}

interface NotificationImageProps {
  label: string;
  author: CoreUser | undefined;
}

type NotificationLinkProps = PropsWithChildren<{ url: string } & React.HTMLAttributes<React.ReactElement>>;

export const NotificationsList: React.FC<Props> = (props: Props) => {
  const { format, className } = props;
  const dispatch = useDispatch();
  const { apiPendingRequests } = useApiSelector();
  const notifications = useSelector((state: State) => state.notifications);
  const loading = apiPendingRequests.some((req: ApiRequestProps) => req.type === GetNotifications.REQUEST);
  const seenTimeoutRef = useRef<number | null>(null);

  const markAsRead = (n: Notification): void => {
    dispatch(editNotification({ ...n, seen: true }));
  };

  const onMouseEnterNotif = (n: Notification): void => {
    if (!n.seen) {
      seenTimeoutRef.current = setTimeout(markAsRead, 100, n);
    }
  };

  const onMouseLeaveNotif = (): void => {
    if (seenTimeoutRef.current) clearTimeout(seenTimeoutRef.current);
  };

  if (loading) {
    return (
      <div className={styles.loaderEmptyContainer}>
        <Loader />
      </div>
    );
  } else if (!notifications?.length) {
    return <div className={styles.loaderEmptyContainer}>{t`NotificationsList.empty`}</div>;
  }

  return (
    <ul className={cx(styles.notifList, className)}>
      {notifications.map((e) => {
        const label = e.notificationType.label;
        return (
          <li key={`notif-${e["@id"]}`} className={styles.notifItem}>
            <div
              className={cx(styles.notifGrid, { [styles.unseen]: !e.seen })}
              onMouseEnter={(): void => {
                onMouseEnterNotif(e);
              }}
              onMouseLeave={(): void => {
                onMouseLeaveNotif();
              }}
            >
              <div className={styles.areaNotifImg}>
                <div className={styles.imgContainer}>
                  <NotificationImage label={label} author={e.author} />
                </div>
              </div>
              <div className={styles.areaText}>
                <div className={styles.textContainer}>
                  <ReactMarkdown
                    source={format === "short" ? e.shortText : e.text}
                    disallowedTypes={["paragraph"]}
                    unwrapDisallowed
                  />
                </div>
              </div>
              <div className={styles.areaCta}>
                {e.url ? (
                  <div className={styles.ctaContainer}>
                    <NotificationLink url={e.url}>{t({ id: notificationCTAs[label] })}</NotificationLink>
                  </div>
                ) : null}
              </div>
              <div className={styles.dateIconContainer}>
                <span className={styles.date}>{formatDistanceToNow(new Date(e.sendDate), { locale: fr })}</span>
                <div className={styles.iconContainer}>
                  <img
                    src={
                      label && notificationIcons[label]
                        ? notificationIcons[label]
                        : notificationIcons[notificationLabels.toPitch]
                    }
                    alt={t`alts.notifications.icon`}
                  />
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export const NotificationImage: React.FC<NotificationImageProps> = (props: NotificationImageProps): JSX.Element => {
  const { label, author } = props;

  switch (label) {
    case notificationLabels.welcome:
      return <SignatureIcon />;
    case notificationLabels.blogNewArticle:
    case notificationLabels.blogPresentation:
      return <BlogIcon />;
    case notificationLabels.accepted:
      return <SuccessIcon />;
    case notificationLabels.profileStrengthUp:
      return <StrengthIcon />;
    case notificationLabels.pushOffers:
    case notificationLabels.postCall:
    case notificationLabels.toPitch:
    case notificationLabels.awaitingShortlist:
    case notificationLabels.applicationAppliedConfirm:
    case notificationLabels.callBookedConfirm:
    case notificationLabels.callBookedRemind:
      return author ? <PersonProfileImg src={author.image.url} /> : <></>;
    default:
      return <></>;
  }
};

export const NotificationLink: React.FC<NotificationLinkProps> = ({ url, children }: NotificationLinkProps) => {
  const urlObject = new URL(url);
  return `${urlObject.protocol}//${urlObject.host}` === process.env.REACT_APP_BASE_URL ? (
    <Link className={cx(styles.cta, global.cta, global.primaryCta, global.smallerCta)} to={urlObject.pathname}>
      {children}
    </Link>
  ) : (
    <a
      className={cx(styles.cta, global.cta, global.primaryCta, global.smallerCta)}
      href={url}
      target="_blank"
      rel="noreferrer noopener"
    >
      {children}
    </a>
  );
};
