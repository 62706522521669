import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";

import { CreateManualLinkedin } from "../../../actionTypes/linkedinResume";
import { GetLastQuestion, GetPreviousQuestion } from "../../../actionTypes/quiz";
import { CreateTalentProfileSkill, DeleteTalentProfileSkill } from "../../../actionTypes/skills";
import {
  CreateLinkedinResume,
  CreateTalentProfileResponsibility,
  DeleteTalentProfileResponsibility,
  EditTalent,
  EditTalentOnly,
  EditTalentProfileResponsibility,
  EditTalentSetting,
  GetCurrentTalent,
  GetLinkedInProfile,
  GetQuizAnswers,
  GetTalentProfile,
  GetTalentSetting,
  SendMessage,
} from "../../../actionTypes/talent";
import { routes } from "../../../routes";
import { apiFailure, getFailType, getSuccessType } from "../helpers";
import { createTalentProfileSkillSuccessAction, deleteTalentProfileSkillSuccessAction } from "../skills/actions";
import {
  createLinkedinResumeSuccessAction,
  createTalentProfileResponsibilitySuccessAction,
  deleteTalentProfileResponsibilitySuccessAction,
  editTalentProfileResponsibilitySuccessAction,
} from "./actions";
import * as apiClient from "./api";

export function* editTalent(action: any) {
  try {
    const talentResponse = yield call(apiClient.editTalent, action.talent);
    let talent = talentResponse.data;
    if (action.type === EditTalent.REQUEST) {
      const talentProfileResponse = yield call(apiClient.editTalentProfile, action.talent.talentProfile);
      talent = {
        ...talent,
        talentProfile: talentProfileResponse.data,
      };
      yield put({
        talent,
        talentProfile: talentProfileResponse.data,
        type: EditTalent.SUCCESS,
      });
    } else {
      yield put({
        talent,
        type: EditTalentOnly.SUCCESS,
      });
    }
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put({
      payload: {
        message: e?.response?.data?.message,
        status: e?.response?.status,
      },
      type: EditTalent.FAILURE,
    });
  }
}

export function* getCurrentTalent(action: any) {
  try {
    const response = yield call(apiClient.getCurrentTalent);
    yield put({
      currentTalent: response.data,
      type: getSuccessType(action),
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put({
      payload: {
        message: e.response.data.message,
        status: e.response.status,
      },
      type: getFailType(action),
    });
  }
}

export function* getTalentProfile(action: any) {
  try {
    const response = yield call(apiClient.getTalentProfile, action.talent);
    yield put({
      talentProfile: response.data,
      type: GetTalentProfile.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put({
      payload: {
        message: e.response.data.message,
        status: e.response.status,
      },
      type: GetTalentProfile.FAILURE,
    });
  }
}
export function* getTalentSetting(action: any) {
  try {
    const response = yield call(apiClient.getTalentSetting, action.talent);
    yield put({
      talentSetting: response.data,
      type: GetTalentSetting.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put({
      payload: {
        message: e.response.data.message,
        status: e.response.status,
      },
      type: GetTalentSetting.FAILURE,
    });
  }
}

export function* getLinkedInProfile(action: any) {
  try {
    const response = yield call(apiClient.getLinkedInProfile, action.talent);
    yield put({
      linkedInProfile: response.data,
      type: GetLinkedInProfile.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put({
      payload: {
        message: e.response.data.message,
        status: e.response.status,
      },
      type: GetLinkedInProfile.FAILURE,
    });
  }
}

export function* getLastQuestion(action: any) {
  try {
    const response = yield call(apiClient.getQuizAnswers, action.talent);

    if (response.data["hydra:member"].length > 0) {
      const answer = response.data["hydra:member"][0];

      yield put({
        answer,
        type: GetPreviousQuestion.SUCCESS,
      });
      yield put({
        type: GetLastQuestion.SUCCESS,
      });
      yield put(push(routes.quiz.quiz));
    }
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put({
      payload: {
        message: e.response.data.message,
        status: e.response.status,
      },
      type: getFailType(action),
    });
  }
}

export function* getQuizAnswers(action: any) {
  try {
    const response = yield call(apiClient.getQuizAnswers, action.talent);
    const answers = Array.isArray(response.data["hydra:member"]) ? response.data["hydra:member"] : [];
    yield put({
      answers,
      type: GetQuizAnswers.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put({
      payload: {
        message: e.response.data.message,
        status: e.response.status,
      },
      type: getFailType(action),
    });
  }
}

export function* editTalentSetting(action: any) {
  try {
    const response = yield call(apiClient.editTalentSetting, action.talentSetting);
    yield put({
      talentSetting: response.data,
      type: EditTalentSetting.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put({
      payload: {
        message: e.response.data.message,
        status: e.response.status,
      },
      type: EditTalentSetting.FAILURE,
    });
  }
}

export function* sendMessage(action: any) {
  try {
    const response = yield call(apiClient.sendMessage, action.currentTalent, action.content);
    yield put({
      result: response.data,
      type: SendMessage.SUCCESS,
    });
  } catch (e) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailure(e, action));
  }
}

export function* createLinkedinResume(action: any): any {
  try {
    const response = yield call(apiClient.createLinkedinResume, action.iri, action.file);
    const linkedInResume = response.data.linkedinResume;
    yield put(createLinkedinResumeSuccessAction(linkedInResume));
  } catch (e) {
    yield put(apiFailure(e, action));
  }
}

export function* createManualLinkedin(action: any): any {
  try {
    const response = yield call(apiClient.createManualLinkedin, action.currentTalentIri);
    const linkedInResume = response.data;
    yield put(createLinkedinResumeSuccessAction(linkedInResume));
  } catch (e) {
    yield put(apiFailure(e, action));
  }
}

export function* createTalentProfileResponsibility(action: any): any {
  try {
    const response = yield call(
      apiClient.createTalentProfileResponsibility,
      action.responsibility,
      action.talentProfile,
    );
    const talentProfileResponsibility = response.data;
    yield put(createTalentProfileResponsibilitySuccessAction(talentProfileResponsibility));
  } catch (e) {
    yield put(apiFailure(e, action));
  }
}

export function* deleteTalentProfileResponsibility(action: any): any {
  try {
    const response = yield call(apiClient.deleteTalentProfileResponsibility, action.responsibility);
    const talentProfileResponsibility = response.config.url;
    yield put(deleteTalentProfileResponsibilitySuccessAction(talentProfileResponsibility));
  } catch (e) {
    yield put(apiFailure(e, action));
  }
}

export function* editTalentProfileResponsibility(action: any): any {
  try {
    const response = yield call(apiClient.editTalentProfileResponsibility, action.responsibility);
    const talentProfileResponsibility = response.data.talentProfileResponsibility;
    yield put(editTalentProfileResponsibilitySuccessAction(talentProfileResponsibility));
  } catch (e) {
    yield put(apiFailure(e, action));
  }
}

export function* createTalentProfileSkill(action: any): any {
  try {
    const response = yield call(apiClient.createTalentProfileSkill, action.skill, action.talentProfile);
    const talentProfileSkill = response.data;
    yield put(createTalentProfileSkillSuccessAction(talentProfileSkill));
  } catch (e) {
    yield put(apiFailure(e, action));
  }
}

export function* deleteTalentProfileSkill(action: any): any {
  try {
    const response = yield call(apiClient.deleteTalentProfileSkill, action.talentProfileSkill);
    const talentProfileSkill = response.config.url;
    yield put(deleteTalentProfileSkillSuccessAction(talentProfileSkill));
  } catch (e) {
    yield put(apiFailure(e, action));
  }
}

export default function* talentsSaga() {
  yield takeLatest(EditTalent.REQUEST, editTalent);
  yield takeLatest(EditTalentOnly.REQUEST, editTalent);
  yield takeLatest(GetCurrentTalent.REQUEST, getCurrentTalent);
  yield takeLatest(GetTalentProfile.REQUEST, getTalentProfile);
  yield takeLatest(GetTalentSetting.REQUEST, getTalentSetting);
  yield takeLatest(GetQuizAnswers.REQUEST, getQuizAnswers);
  yield takeLatest(GetLinkedInProfile.REQUEST, getLinkedInProfile);
  yield takeLatest(EditTalentSetting.REQUEST, editTalentSetting);
  yield takeLatest(SendMessage.REQUEST, sendMessage);
  yield takeLatest(GetLastQuestion.REQUEST, getLastQuestion);
  yield takeLatest(CreateLinkedinResume.REQUEST, createLinkedinResume);
  yield takeLatest(CreateManualLinkedin.REQUEST, createManualLinkedin);
  yield takeLatest(CreateTalentProfileResponsibility.REQUEST, createTalentProfileResponsibility);
  yield takeLatest(DeleteTalentProfileResponsibility.REQUEST, deleteTalentProfileResponsibility);
  yield takeLatest(EditTalentProfileResponsibility.REQUEST, editTalentProfileResponsibility);
  yield takeLatest(CreateTalentProfileSkill.REQUEST, createTalentProfileSkill);
  yield takeLatest(DeleteTalentProfileSkill.REQUEST, deleteTalentProfileSkill);
}
