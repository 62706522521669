import { AnyAction } from "redux";
import { getFailType } from "./helpers";

export function apiFailureAction(e: any, action: AnyAction, additionalArgs?: { [key: string]: any }): AnyAction {
  if (e.response && e.response.data) {
    return {
      ...additionalArgs,
      form: action.form,
      payload: {
        message: e.response.data.message || e.response.data["hydra:description"],
        status: e.response.status,
        violations: e.response.data.violations || undefined,
      },
      type: getFailType(action),
    };
  }
  return {
    ...additionalArgs,
    form: action.form,
    payload: {
      message: e,
    },
    type: getFailType(action),
  };
}
