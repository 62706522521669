import cx from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import { State } from "../../interfaces/api/state";

import { Offer } from "../../interfaces/resources/offer";
import { routes } from "../../routes";
import { gtagService } from "../../services/google/gtag";
import { hasApplied } from "../../utils/helpers";
import { CompanyImg } from "../companyImg";
import { OfferApplicationCta } from "../offerApplicationCta";

import styles from "./index.module.scss";

interface Props {
  offer: Offer;
  classNames?: string;
  trackName?: string;
}

export const OfferCard: React.FC<Props> = (props: Props) => {
  const { offer, classNames = "", trackName } = props;
  const currentTalent = useSelector((state: State) => state.currentTalent);

  const generateIcon = (): JSX.Element | null => {
    return !!offer.offerEvents.some(
      (event) => currentTalent && event.status === "read" && (event.talent as unknown) === currentTalent["@id"],
    ) ? null : (
      <span className={styles.badge}></span>
    );
  };

  return (
    <div className={cx(styles.offerCardContainer, classNames)}>
      <div className={styles.offerCardContent}>
        {generateIcon()}
        <Link
          to={generatePath(routes.app.offer, { slug: offer.slug })}
          className={styles.offerCardLink}
          onClick={() => {
            gtagService.event("click", {
              event_label: `fiche d'offre - ${trackName}`,
            });
          }}
          data-testid="offercard-link"
        ></Link>
        <div className={styles.cardHeader}>
          <div className={styles.companyImgContainer}>
            <CompanyImg src={offer.company.image.url} name={offer.company.name} />
          </div>
        </div>
        <div className={styles.cardBody}>
          <div key="company-sector" className={styles.sectorsContainer} data-testid="offercard-sectors-container">
            {offer.company.sectors.length >= 1 ? (
              <span>
                {offer.company.sectors
                  .map((sector) => {
                    return sector.value;
                  })
                  .join(" - ")}
              </span>
            ) : null}
          </div>
          <div className={styles.companyInfosContainer}>
            <h3 className={styles.companyName}>{offer.company.name}</h3>
            <h2 className={styles.offerTitle}>{offer.title}</h2>
          </div>
        </div>
        <div className={styles.cardFooter}>
          {hasApplied(offer.application) ? (
            <OfferApplicationCta offer={offer} canApply={false} application={offer.application} trackName={trackName} />
          ) : (
            <button type="button" className={styles.toOfferCta}>
              En savoir plus
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
